<template>
    <div class="z-select-multi-wrapper">
      <div
          :class="[
              'z-select-multi',
              {
                  'z-select-multi--filter': isFilter,
                  'z-select-multi--clearable': isClearable,
                  'is-opened': opened,
                  'is-errored': isValid === false,
                  'is-valid': isValid && !isFilter,
                  'is-filled': selected.length,
                  'is-required': required,
                  'is-disabled': !(data && data.length) || disabled
              }
          ]"
          :alt="alt"
      >
          <div class="z-select-multi__container" @click.self="opened = !opened">
              <span
                  class="z-select-multi__placeholder"
                  v-show="placeholder"
                  v-html="placeholder"
              ></span>
              <div class="z-select-multi__wrap">
                  <div class="z-select-multi__items" ref="items">
                      <span
                          class="z-select-multi__selected"
                          v-for="(item, index) in selected"
                          :key="index"
                      >
                          <span v-html="item.text"></span>
                          <span
                              class="z-select-multi__delete"
                              @click.prevent="changeSelected(item)"
                          ></span>
                      </span>
                  </div>
                  <span class="z-select-multi__count" v-if="selected.length">
                      {{ selected.length }}
                  </span>
              </div>
              <span
                  class="z-select-multi__clear"
                  v-show="selected.length && isClearable"
                  @click.self="clearSelected"
              ></span>
              <span class="z-select-multi__arrow"></span>
          </div>
          <div class="z-select-multi__dropdown" v-show="opened">
              <ul
                  class="z-select-multi__options"
                  v-for="(group, groupIndex) in groupedOptions"
                  :key="groupIndex"
              >
                  <li>
                      <span
                          class="z-select-multi__caption"
                          v-html="group.name"
                          v-if="group.name"
                      ></span>
                      <ul
                          :class="{ 'u-left-margin--one' : groupIndex !== 'nogroup' }"
                          v-if="group.items && group.items.length"
                      >
                          <li
                              class="z-select-multi__option"
                              v-for="option in group.items"
                              :class="buildClass(option)"
                              @click="changeSelected(option)"
                              v-html="option.text"
                              :key="option.id"
                          ></li>
                      </ul>
                  </li>
              </ul>
          </div>
      </div>
      <span
          :class="['z-select__error', errorClass]"
          v-if="required && error && !selected.length"
      >
          {{ text.error[$root.lang] }}
      </span>
    </div>
</template>

<script>
export default {
    name: 'z-select-multi',
    props: {
        alt: String,
        data: {
            type: Array,
            default: () => []
        },
        name: String,
        placeholder: String,
        required: Boolean,
        value: Array,
        errorClass: {
            type: String,
            default: ''
        },
        clearable: {
            type: Boolean,
            default: false
        },
        isFilter: {
            type: Boolean,
            default: false
        },
        disabled: Boolean
    },
    data () {
        return {
            selected: [],
            opened: false,
            duration: 300,
            error: false,
            options: this.data,
            isValid: null,
            text: {
                error: {
                    ru: 'Поле обязательно для заполнения',
                    en: 'Required field',
                    cn: '填项目'
                }
            }
        }
    },
    computed: {
        groupedOptions () {
            let result = {}
            this.options.forEach(item => {
                if (item.group) {
                    if (!result[item.group]) result[item.group] = {}
                    if (!result[item.group].items) result[item.group].items = []
                    result[item.group].name = item.group
                    result[item.group].items.push(item)
                } else {
                    if (!result.nogroup) result.nogroup = {}
                    if (!result.nogroup.items) result.nogroup.items = []
                    result.nogroup.name = ''
                    result.nogroup.items.push(item)
                }
            })

            return result
        },
        isClearable () {
            if (this.isFilter) return true
            return this.clearable
        }
    },
    mounted () {
        this.onLoad()

        this.$parent.$parent.$on('clear', name => {
            if (this.name === name) this.clearSelected()
        })

        document.addEventListener('click', e => this.hideDropdown(e))
    },
    beforeDestroy () {
        this.$parent.$parent.$off('clear', name => {
            if (this.name === name) this.clearSelected()
        })

        document.removeEventListener('click', e => this.hideDropdown(e))
    },
    watch: {
        // data (array) {
        //     this.options = array
        // },
        value (value) {
            // нужно вернуться и сделать нормально, переработать работу селекта, сделать нормальный v-model и унифицировать отдачу value со остальными элементами форм
            // console.log(value);
            if (value && value.length) {
                let selectedOptions = this.options.filter(item => {
                    return value.includes(item.id)
                }).map(item => {
                    return {...item, selected: true}
                })

                // console.log(selectedOptions);

                this.options = this.options.map(item => {
                    if (value.includes(item.id)) {
                        return {...item, selected: true}
                    } else {
                        return {...item, selected: false}
                    }
                })
                this.selected = selectedOptions

                // console.log(this.selected);
                // console.log(this.options);

                return
            }

            this.selected = []
            this.options = this.options.map(item => {
                return {...item, selected: false}
            })
        }
    },
    methods: {
        onLoad () {
            for (let i = 0; i < this.options.length; i++) {
                if (this.options[i].selected === true) this.initSelected(this.options[i])
            }
        },
        buildClass (option) {
            return {
                'is-selected': option.selected,
                'is-disabled': option.disabled
            }
        },
        hideDropdown (e) {
            let isOutside = this.$el !== e.target && !this.$el.contains(e.target)
            // eslint-disable-next-line no-unused-expressions
            isOutside ? this.opened = false : false
        },
        changeSelected (option) {
            // let isSelected = this.selected.find(item => item.id === option.id)
            option.selected ? this.removeSelected(option) : this.addSelected(option)
        },
        initSelected (option) {
            this.selected.push(option)
            this.toggleSelected(option, true)
        },
        addSelected (option) {
            this.initSelected(option)
            this.send()
        },
        removeSelected (option) {
            const selectedOpt = this.selected.find(item => item.id === option.id)
            let index = this.selected.indexOf(selectedOpt)
            if (index > -1) this.selected.splice(index, 1)

            this.toggleSelected(option, false)
            this.send()
        },
        toggleSelected (option, value) {
            this.options = this.options.map(item => {
                if (item.id === option.id) {
                    return { ...item, selected: value }
                }
                return item
            })
        },
        clearSelected () {
            this.selected = []
            this.opened = false
            this.options = this.options.map(item => {
                return { ...item, selected: false }
            })
            this.send(null)
        },
        send (value) {
            let data = []

            if (value !== null) {
                for (let i = 0; i < this.selected.length; i++) data.push(this.selected[i].id)
            }

            this.$nextTick(() => {
                this.$emit('input', data)
                this.$emit('change', data)
            })
            this.validate()
        },
        validate () {
            if (this.required && !this.selected.length) {
                this.isValid = false
                this.error = true
            } else {
                this.error = false
                this.isValid = true
            }
        }
    }
}
</script>

  <style lang="scss" src="./index.scss"></style>
