/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'circle-icon-5': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<rect pid="0" width="40" height="40" rx="20" _fill="#0072D9"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M19.159 13.75a1.25 1.25 0 011.681 0l5 4.546a1.25 1.25 0 01.41.925v5.53H27a.75.75 0 110 1.5H13a.75.75 0 010-1.5h.75v-5.53a1.25 1.25 0 01.41-.925l4.999-4.546zm5.591 5.582v5.418h-2.5V22A1.25 1.25 0 0021 20.75h-2A1.25 1.25 0 0017.75 22v2.75h-2.5v-5.418L20 15.013l4.75 4.319zm-5.5 5.418h1.5v-2.5h-1.5v2.5z" _fill="#fff"/>'
  }
})
