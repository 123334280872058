<template>
    <div class="page-404">
        <img class="page-404__svg" src="./images/404.svg" alt="404 robot"/>
        <z-caption tag="h2" class="page-404__title">К сожалению, эта страница не&nbsp;найдена</z-caption>
        <p class="text-size-xl">Возможно, вы ввели неправильный адрес или страница была удалена.</p>
        <p class="text-size-xl">Воспользуйтесь <z-link size="xl" :underlined="true" href="/search/">поиском</z-link> или вернитесь на&nbsp;<z-link size="xl" :underlined="true" href="/">главную страницу</z-link>.</p>
    </div>
</template>

<script>
export default {
    name: 'page-404'
}
</script>

<style lang="scss">
.page-404 {
    width: 100%;
    @include padding-level(top, 2XL);
    @include padding-level(bottom, M);
    @include padding-level(right, M);
    @include padding-level(left, M);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;

    &__svg {
        @include margin-level(bottom, M);
    }

    &__title {
        text-transform: uppercase;
        // font-size: 18px;
        font-size: var(--fontSizeL);
        font-weight: 700;
        margin-bottom: $token-spacers-s !important;
    }
}
</style>
