<template>
    <div class="z-logo">
        <template v-if="isIndex">
            <img :src="buildSrc" class="z-logo__img" alt="Логотип">
        </template>
        <template v-else>
            <a :href="siteDir" class="z-logo__link" :title="title">
                <img :src="buildSrc" class="z-logo__img" alt="Логотип">
            </a>
        </template>
    </div>
</template>

<script>
export default {
    name: 'z-logo',
    props: {
        isIndex: {
            type: Boolean
        },
        siteDir: {
            type: String,
            default: '/',
            required: true
        },
        theme: {
            type: String,
            default: 'default'
        }
    },
    data () {
        return {
            title: 'Норникель Корпоративный университет',
            lang: this.$root.lang
        }
    },
    computed: {
        buildSrc () {
            let lang = this.lang.replace(/\//g, '')

            return this.theme === 'white'
                ? require(`./images/logo-white--${lang}.svg`)
                : require(`./images/logo--${lang}.svg`)

            // return require(`./images/logo-white--${lang}.svg`)
        }
    }
}
</script>

<style lang="scss">
.z-logo {
    img {
        display: block;
        width: 100%;
        user-select: none;
    }

    &__link {
        display: block;
    }
}
</style>
