/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'youtube': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3.265 19.057a2.57 2.57 0 01-1.043-.602 2.487 2.487 0 01-.642-1.004C.845 15.487.628 7.287 2.042 5.668a2.743 2.743 0 011.848-.902c3.793-.398 15.51-.345 16.856.132.378.12.724.322 1.011.591.287.269.509.598.647.962.802 2.03.83 9.408-.109 11.358a2.539 2.539 0 01-1.182 1.168c-1.414.69-15.972.677-17.848.08zm6.022-3.848l6.796-3.45-6.796-3.477v6.927z" _fill="#1971CF"/>'
  }
})
