/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'userpic': {
    width: 120,
    height: 120,
    viewBox: '0 0 120 120',
    data: '<circle pid="0" cx="60" cy="60" r="60" _fill="#EFF6FB"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M15 99.451C19.007 78.131 37.723 62 60.207 62s41.2 16.131 45.207 37.451C94.416 112.044 78.24 120 60.207 120S26 112.044 15 99.451zM76.207 35c0 8.837-7.163 16-16 16-8.836 0-16-7.163-16-16s7.164-16 16-16c8.837 0 16 7.163 16 16z" _fill="#DEEDF7"/>'
  }
})
