<template>
    <section class="section-stats container">
        <div class="section-stats__swiper" data-scroll-scale>
            <swiper :options="swiper_options">
                <swiper-slide
                    v-for="{ id, imageSrc, num, caption, caption_2, textColor } in slider_data"
                    :key="id"
                    :style="getSwiperSlideStyle(imageSrc)">
                    <div :class="getSlideContentClass(textColor)">
                        <z-caption
                            tag="p"
                            size="m"
                            weight="600"
                            >Цифры и факты</z-caption
                        >
                        <div :class="getSlideNumClass(num)">
                            {{ beautifyNum(num) }}
                        </div>
                        <z-caption
                            tag="p"
                            size="3xl"
                            weight="600"
                            v-html="caption" />
                        <z-caption
                            tag="p"
                            size="xl"
                            weight="400"
                            v-html="caption_2" />
                    </div>
                </swiper-slide>
            </swiper>
        </div>
    </section>
</template>

<script>
import { mixinDevice } from '@/utils/mixin'
import Swiper from '../../components/Swiper/Swiper.vue'
import SwiperSlide from '../../components/SwiperSlide/SwiperSlide.vue'

export default {
    name: 'section-statistics',
    mixins: [mixinDevice],
    components: {
        Swiper,
        SwiperSlide
    },
    data () {
        return {
            swiper: undefined,
            swiper_options: {
                simulateTouch: false,
                observer: true,
                observeParents: true,
                autoplay: {
                    delay: 7000
                },
                loop: true
            },
            slider_data: [
                {
                    id: 1,
                    imageSrc: 'background-1.jpg',
                    num: 703,
                    caption: 'программы обучения',
                    caption_2: 'действуют в Корпоративном университете',
                    textColor: 'light'
                },
                {
                    id: 2,
                    imageSrc: 'background-2.jpg',
                    num: 26193,
                    caption: 'слушателя',
                    caption_2:
                        'успешно завершили обучение по&nbsp;программам дополнительного профессионального образования в&nbsp;2023 году',
                    textColor: 'dark'
                },
                {
                    id: 3,
                    imageSrc: 'background-3.jpg',
                    num: 40933,
                    caption: 'слушателя',
                    caption_2:
                        'успешно завершили обучение по&nbsp;образовательным программам в&nbsp;2023 году',
                    textColor: 'dark'
                },
                {
                    id: 4,
                    imageSrc: 'background-5.jpg',
                    num: 29582,
                    caption: 'человека',
                    caption_2: 'прошли обучение в&nbsp;дистанционной форме в&nbsp;2023 году​',
                    textColor: 'light'
                }
            ]
        }
    },
    methods: {
        getSlideContentClass (color) {
            return ['swiper-slide__content', color && `swiper-slide__content--${color}`]
        },

        getSwiperSlideStyle (imageSrc) {
            if (this.device === 'mobile') {
                return `background-image: url('/images/statistics/mob/${imageSrc}')`
            } else {
                return `background-image: url('/images/statistics/${imageSrc}')`
            }
        },
        getSlideNumClass (num) {
            const size = num.toString().length > 3 ? 'medium' : 'large'
            return ['swiper-slide__content-num', `swiper-slide__content-num--${size}`]
        },

        beautifyNum (num) {
            return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
        }
    }
}
</script>

<style scoped lang="scss">
@import './index.scss';

</style>
