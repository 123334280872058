/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'glasses': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<path pid="0" d="M28.499 13.969a2.92 2.92 0 00-1.881-.957h-.001a2.896 2.896 0 00-2.991 1.922.827.827 0 00.76 1.094.901.901 0 00.484-.15.813.813 0 00.3-.408 1.258 1.258 0 011.376-.828 1.296 1.296 0 011.071 1.299v2.365a.16.16 0 01-.241.13 4.274 4.274 0 00-6.109 1.426 1.203 1.203 0 01-1.054.617h-.14a1.207 1.207 0 01-1.052-.619 4.287 4.287 0 00-2.744-2.047 4.286 4.286 0 00-3.362.62.159.159 0 01-.159.006.158.158 0 01-.085-.128v-2.39a1.295 1.295 0 011.032-1.277 1.251 1.251 0 011.378.827.808.808 0 00.762.538h.003a.83.83 0 00.759-1.096 2.909 2.909 0 00-1.162-1.456 2.918 2.918 0 00-1.811-.445 2.92 2.92 0 00-1.881.957A2.922 2.922 0 0011 15.941v5.952c0 2.203 1.806 4.195 3.943 4.351a4.267 4.267 0 002.42-.535 4.258 4.258 0 001.716-1.786c.208-.394.365-.812.468-1.251a.576.576 0 011.139.008c.101.432.258.85.465 1.242a4.267 4.267 0 001.729 1.782c.742.41 1.583.589 2.427.521 2.138-.152 3.943-2.145 3.943-4.35v-5.934a2.92 2.92 0 00-.751-1.972zm-.9 8.253a2.648 2.648 0 01-.754 1.604 2.66 2.66 0 01-1.599.767 2.646 2.646 0 01-2.916-2.528 2.655 2.655 0 01.765-1.96 2.653 2.653 0 011.954-.78 2.644 2.644 0 012.55 2.897zm-9.683.017a2.645 2.645 0 01-2.37 2.353 2.664 2.664 0 01-2.002-.646 2.66 2.66 0 01-.895-1.905 2.655 2.655 0 01.78-1.954 2.651 2.651 0 011.959-.765 2.65 2.65 0 011.897.91c.478.551.708 1.283.631 2.007z" _fill="#000"/>'
  }
})
