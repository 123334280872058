<template>
    <div class="subscription">
        <div class="subscription__form" v-if="!isFormHidden">
            <z-caption
                tag="h2"
                size="3xl"
                weight="600"
                v-html="edit || isRequest ? 'Управление подпиской' : 'Свежие новости Корпоративного университета с&nbsp;доставкой на&nbsp;электронную почту'"
            />
            <z-caption tag="h3" size="l" v-if="showDefault">Подписывайтесь, чтобы не&nbsp;пропускать ничего важного. Если вы уже подписаны и&nbsp;хотите изменить параметры подписки, то&nbsp;перейдите на <z-link size="l" href="javascript:void(0)" @click="isRequest = true">страницу изменения параметров</z-link> подписки</z-caption>
            <z-input
                type="email"
                :placeholder="edit ? '' : 'Введите свой email'"
                name="email"
                v-model="params.email"
                ref="email"
                required
            ></z-input>
            <z-checkbox
                v-if="!isRequest"
                class="u-top-margin--s"
                name="rubrics"
                v-model="params.rubrics"
                size="l"
                :data="rubrics"
                :value="rubrics"
                :required="isRequiredRubrics"
            >
            </z-checkbox>
            <z-checkbox
                v-if="showDefault"
                class="subscription__checkbox u-top-margin--s"
                v-model="params.policy"
                name="policy"
                ref="policy"
                required
                size="s"
                :data="[{
                    text: 'В&nbsp;соответствии с&nbsp;Федеральным законом &laquo;О&nbsp;персональных данных&raquo; (&#8470;&nbsp;152-ФЗ) свободно, своей волей и&nbsp;в&nbsp;своем интересе я&nbsp;даю свое согласие ЧОУ ДПО Корпоративный университет &laquo;Норильский никель&raquo; на&nbsp;обработку моих персональных данных, указанных при заполнении формы подписки на&nbsp;новости.',
                    value: 'policy'
                }]"
            >
            </z-checkbox>

            <template v-if="isRequest">
                <div class="subscription__bottom u-top-margin--s u-bottom-margin--s">
                    <z-button :disabled="!this.validateEmail(this.params.email)" class="u-right-margin--xs" size="l" @click="submitForm('request')">
                        Отправить
                    </z-button>
                    <z-button tag="a" href="/press-center/news/" type="button" kind="secondary" size="l">
                        Перейти к&nbsp;новостям
                    </z-button>
                </div>
                <z-caption weight="400" class="u-bottom-margin--m" tag="p" size="l">Если вы еще не&nbsp;подписаны и&nbsp;хотите изменить параметры подписки, то <z-link href="javascript:void(0)" @click="isRequest = false">подпишитесь</z-link>.</z-caption>
            </template>
            <div class="subscription__bottom u-top-margin--s" v-if="showDefault">
                <z-button :disabled="!isAvailable" size="l" class="u-right-margin--xs" @click="submitForm('add')">
                    Подписаться
                </z-button>
                <z-button
                    tag="a"
                    href="/press-center/news/"
                    type="button"
                    kind="secondary"
                    size="l"
                >
                    Перейти к&nbsp;новостям
                </z-button>
            </div>
            <div class="subscription__bottom u-top-margin--s" v-if="edit">
                <z-button class="u-right-margin--xs"
                    :disabled="!isAvailable"
                    type="button"
                    @click="submitForm('edit')"
                    size="l"
                >
                    Сохранить
                </z-button>
                <z-button
                    type="button"
                    @click="submitForm('cansel')"
                    kind="secondary"
                    size="l"
                >
                    Отменить подписку
                </z-button>
            </div>
        </div>

        <notification class="subscription__notification" v-if="isFormHidden" weight="600">
            <template v-slot:image>
                <z-image src="/images/notification/subscribe.svg" width="316" height="266"></z-image>
            </template>
            <template v-slot:description>
                <z-caption size="l" decor-left decor-right>
                    <span v-if="!edit">На почтовый адрес <span class="subscription__color">{{ params.email }}</span> было отправлено письмо с&nbsp;подтверждением</span>
                    <span v-else>Вы успешно изменили подписку</span>
                </z-caption>
                <template v-if="!edit">
                    <p class="text-size-xl u-regular">В&nbsp;дальнейшем вы&nbsp;сможете менять настройки подписки на&nbsp;странице <z-link class="subscription__color" @click="isRequest = true, isFormHidden = false" href="javascript:void(0)">Управление подпиской.</z-link></p>
                </template>
                <div v-else class="u-bottom-margin--m">
                    <z-link :underlined="false" size="l" href="javascript:void(0)" @click="isFormHidden = false">Посмотрите вашу подписку</z-link>
                </div>
                <z-button class="u-right-margin--xs" tag="a" href="/press-center/news/" type="button" kind="primary" size="l">
                    Перейти к&nbsp;новостям
                </z-button>
            </template>
        </notification>
    </div>
</template>

<script>
import { sendSubscribe } from '@/api/subscription.js'
import { debounce } from 'throttle-debounce'
import { showNotyfications } from '@/utils/notifications'

export default {
    name: 'subscription',
    props: {
        initial: {
            type: Object
        },
        edit: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            defaultSelected: [],
            rubrics: this.$root.app.components['subscribe-rubrics'],
            params: {
                'email': '',
                'rubrics': [],
                'policy': []
            },
            urlApi: {
                add: '/subscribe/add/',
                cancel: '/subscribe/cancel/',
                request: '/subscribe/request-edit/',
                edit: '/subscribe/edit/'
            },
            isRequest: false,
            isFormHidden: false,
            isSubscribe: false
        }
    },
    methods: {
        submitForm: debounce(1000, function (type) {
            let url
            if (type === 'add') {
                url = this.urlApi.add
            }
            if (type === 'cansel') {
                url = this.urlApi.cancel
            }
            if (type === 'edit') {
                url = this.urlApi.edit
            }
            if (type === 'request') {
                url = this.urlApi.request
            }
            let formData = new FormData()
            for (let key in this.params) {
                if (Array.isArray(this.params[key])) {
                    this.params[key].forEach((item) => {
                        formData.append(`${key}[]`, item)
                    })
                } else {
                    formData.append(key, this.params[key])
                }
            }
            sendSubscribe(url, formData)
                .then(
                    response => {
                        if (response.status === 'error') {
                            console.log(response.message)
                            showNotyfications('Произошла ошибка при отправке, попробуйте позже', { type: 'error' })
                        } else {
                            console.log('success')
                            this.isFormHidden = true
                        }
                    }
                )
        }),
        validateEmail (email) {
            // eslint-disable-next-line no-useless-escape
            let regex = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/
            return regex.test(email)
        }
    },
    computed: {
        isAvailable () {
            if (this.edit) {
                console.log(JSON.stringify(this.defaultSelected))
                console.log(JSON.stringify(this.params.rubrics))
                if (this.defaultSelected.join() === this.params.rubrics.join()) {
                    return false
                }
                return true
            } else {
                if (this.validateEmail(this.params.email) && this.params.rubrics.length > 0 && this.params.policy.length > 0) {
                    return true
                }
                return false
            }
        },
        isRequiredRubrics () {
            if (this.edit) {
                return false
            }
            return this.params.rubrics.length <= 0
        },
        showDefault () {
            if (this.edit || this.isRequest) {
                return false
            }
            return true
        }
    },
    created () {
        if (this.edit) {
            this.rubrics.forEach((item) => {
                if (item.selected) {
                    this.params.rubrics.push(Number(item.id))
                }
            })

            this.defaultSelected = JSON.parse(JSON.stringify(this.params.rubrics))
            let params = Object.assign({}, this.params)
            params['CONFIRM_CODE'] = this.$root.app.system.params.get['CONFIRM_CODE']
            params['ID'] = this.$root.app.system.params.get['ID']

            this.params = params
        }

        // меняем значения с бека на подходщие для наших чекбоксов
        this.rubrics = JSON.parse(JSON.stringify(this.rubrics))

        for (let obj of this.rubrics) {
            obj.text = obj.name
            delete obj.name
            obj.value = obj.id
            delete obj.id
        }
    }
}
</script>

<style lang="scss">
.subscription {
    width: 100%;
    max-width: 960px;
    margin: 0 auto;
    @include padding-level(top, L);
    @include padding-level(bottom, L);
    border-radius: $borderRadius;
    background-color: $token-colors-white;

    &__form {
        @include padding-level(left, L);
        @include padding-level(right, L);

        @include breakpoint(mobile) {
            padding: 1.5rem;
        }
    }

    &__notification {
        @include padding-level(right, L);
        @include breakpoint(v-tablet) {
            @include padding-level(left, L);
        }
        @include breakpoint(mobile) {
            padding: 1.5rem;
        }
    }

    .z-checkbox-group__error {
        line-height: 1.2;
        display: block;
    }

    .z-input.is-filled .z-input__placeholder {
        background-color: $token-colors-white;
    }

    .z-link {
        text-decoration: underline;
    }

    .z-input__label p {
        margin-bottom: 0.5rem;
    }

    .z-checkbox-group__list {
        width: 90%;
        display: flex;
        flex-wrap: wrap;
    }

    .z-checkbox-group__item {
        width: 60%;
    }

    .z-checkbox-group__item:nth-of-type(odd) {
        width: 40%;
    }

    @include breakpoint(v-tablet) {
        .z-checkbox-group__item {
            width: 100%;
        }
    }

    .subscription__checkbox .z-checkbox-group__list {
        display: flex;
        width: 100%;
    }

    .subscription__checkbox .z-checkbox-group__item {
        width: 100%;
    }

    .z-button {
        display: inline-flex;
        align-items: center;
        width: fit-content;

        @include breakpoint(v-tablet) {
            display: flex;
            &:last-of-type {
                margin-top: 15px;
            }
        }
    }

    &__checkbox .z-checkbox__text {
        font-size: 15px;
        color: $token-colors-black-30;
    }

    &__color {
        color: $token-colors-primary;
    }
}
</style>
