<template>
    <div class="programs">
        <programs-filter
            class="u-bottom-margin--m"
            @change="onFilterChange"
            :data="filter"
        />
        <div v-if="items && items.length">
            <div class="row">
                <div
                    class="col-default-6 col-v-tablet-12"
                    v-for="(item, index) in items"
                    :key="index"
                >
                    <programs-card class="u-entire-height" :data="item" />
                </div>
            </div>

            <z-pagination
                class="programs__pagination"
                v-if="nav"
                show-next
                show-prev
                :show-more="{
                marginBottom: 'm',
                size: 'm'
            }"
                show-nav
                @change-page="onPageChange"
                :data="{
                currentPage: nav.current,
                totalItems: nav.count,
                totalPages: nav.total,
                pageRange: 1
            }"
            >
            </z-pagination>
        </div>
       <div v-else>
           <z-not-found></z-not-found>
       </div>
    </div>
</template>

<script>
import ProgramsFilter from './components/Filter.vue'
import ProgramsCard from './components/Card.vue'
import { getProgramsData } from '@/api/programs'
import ZNotFound from "@/components/ZNotFound/ZNotFound";

export default {
    name: 'programs',
    components: {
        ZNotFound,
        ProgramsFilter,
        ProgramsCard
    },
    data () {
        return {
            data: this.$root.app.components.programs,
            filter: this.$root.app.components.programs?.filter,
            items: this.$root.app.components.programs?.items || [],
            nav: this.$root.app.components.programs?.nav,
            loading: false,
            params: {
                page: 1,
                type: '',
                query: ''
            },
            requestType: 'change-page'
        }
    },
    methods: {
        onFilterChange (data) {
            this.params = { ...this.params, ...data, page: 1 }
            this.requestType = 'change-page'
            this.send()
        },
        onPageChange (data) {
            this.requestType = data.type
            this.params.page = data.page
            this.send()
        },
        send () {
            this.loading = true

            getProgramsData(this.params)
                .then(response => {
                    // this.filter = response.filter
                    this.nav = response.nav

                    if (this.requestType === 'change-page') {
                        this.items = response.items
                    } else {
                        this.items = this.items.concat(response.items)
                    }
                })
                .catch(error => {
                    console.log(error)
                })
                .finally(() => {
                    this.loading = false
                })
        }
    }
}
</script>

<style lang="scss">
.programs {
    &__pagination {
        margin: 0 auto;
        @include margin-level(top, M);

        @include breakpoint(mobile) {
            width: 100% !important;

            .z-pagination__wrapper {
                width: 100% !important;
            }

            .z-pagination__nav {
                width: 100% !important;
            }

            .z-pagination__list {
                justify-content: center;
                width: 100% !important;
                flex-wrap: wrap;

                li {
                    margin-bottom: $token-spacers-2-xs;
                }
            }
        }
    }
}
</style>
