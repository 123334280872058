<template>
    <div class="modal-map">
        <button class="modal-map__button" @click="handlePopup" alt="Города присутствия Корпоративного университета" />
        <z-modal id="modalMapData">
            <div class="modal-map__content">
                <button class="modal-map__pin modal-map__pin--monchegorsk" :content="monchegorsk" v-tippy="{
                    theme: 'map',
                    appendTo: 'parent',
                    sticky: true,
                    placement: 'auto',
                    maxWidth: 670,
                    }"/>
                <button class="modal-map__pin modal-map__pin--moscow" :content="moscow" v-tippy="{
                    theme: 'map',
                    appendTo: 'parent',
                    sticky: true,
                    placement: 'auto',
                    maxWidth: 670,
                    }"/>
                <button class="modal-map__pin modal-map__pin--norilsk" :content="norilsk" v-tippy="{
                    theme: 'map',
                    appendTo: 'parent',
                    sticky: true,
                    placement: 'left',
                    maxWidth: 670,
                    }"/>
                <z-caption size="4xl" weight="700" tag="h4">Города присутствия Корпоративного университета</z-caption>
                <img class="modal-map__image" width="1355" height="865" src="/images/territory/modal-map.svg" alt="Карта России."/>
            </div>
        </z-modal>
    </div>
</template>

<script>
import {mixinDevice} from '@/utils/mixin'

export default {
    name: 'modal-map',
    mixins: [mixinDevice],
    data () {
        return {
            monchegorsk: `<div class="map-tooltip-content">
                            <h4 class="map-tooltip-content__title">Мончегорск</h4>
                            <p class="map-tooltip-content__text">Учебные аудитории кольского филиала Корпоративного университета находятся в&nbsp;городах Мончегорск и&nbsp;Заполярный.</p>
                          </div>`,
            moscow: `<div class="map-tooltip-content">
                        <h4 class="map-tooltip-content__title">Москва</h4>
                        <p class="map-tooltip-content__text">В&nbsp;Москве находится представительство Корпоративного университета</p>
                     </div>`,
            norilsk: `<div class="map-tooltip-content">
                        <h4 class="map-tooltip-content__title">Норильск</h4>
                        <p class="map-tooltip-content__text">Корпоративный университет в&nbsp;Норильске&nbsp;&mdash; это учебный корпус на&nbsp;Талнахской, учебно-производственная база в&nbsp;Талнахе, а&nbsp;также учебный подземный полигон на&nbsp;базе рудника &laquo;Ангидрит&raquo;</p>
                     </div>`
        }
    },

    methods: {
        handlePopup () {
            if (this.device === 'desktop') {
                this.$root.$bus.$emit('open-modal', {id: 'modalMapData'})
            }
        }
    }
}
</script>

<style lang="scss">
.modal-map {
    $parent: &;

    .z-caption {
        text-align: center;
        position: absolute;
        width: 100%;
        top: 77px;
    }

    &__button {
        padding: 0;
        width: 314px;
        height: 314px;
        border: none;
        border-radius: 50%;
        background-color: transparent;
        background-image: url("/images/territory/map-button.svg");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;

        &:hover {
            opacity: 0.8;
        }

        @include breakpoint(tablet) {
            cursor: auto;
            &:hover {
                opacity: 1;
            }
        }

        @include breakpoint(mobile) {
            width: 230px;
            height: 230px;
        }
    }

    .z-modal {
        &__container {
            max-width: 1355px;
            max-height: 865px;
            overflow: hidden;
        }

        .modal-html {
            padding: 0;
            @include breakpoint(mobile) {
                min-height: auto;
            }
        }

        #{$parent}__content {
            position: relative;
            background-color: #C1D2DE;
        }

        #{$parent}__pin {
            padding: 0;
            width: 3vw;
            height: 5.6vh;
            border: none;
            background-color: transparent;
            position: absolute;
            background-image: url("data:image/svg+xml,%3Csvg width='44' height='49' viewBox='0 0 44 49' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M43.0631 21.4041C43.0631 37.3706 25.823 46.6085 22.5421 48.222C22.202 48.3892 21.8108 48.3892 21.4707 48.222C18.1898 46.6085 0.949707 37.3706 0.949707 21.4041C0.949707 9.77482 10.3771 0.347412 22.0064 0.347412C33.6357 0.347412 43.0631 9.77482 43.0631 21.4041Z' fill='%230077C8'/%3E%3Cpath d='M34.7255 21.4547C34.7255 28.4491 29.0553 34.1193 22.0609 34.1193C15.0664 34.1193 9.39624 28.4491 9.39624 21.4547C9.39624 14.4602 15.0664 8.79004 22.0609 8.79004C29.0553 8.79004 34.7255 14.4602 34.7255 21.4547Z' fill='white'/%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M22.0609 32.5227C28.1736 32.5227 33.1289 27.5674 33.1289 21.4547C33.1289 15.342 28.1736 10.3866 22.0609 10.3866C15.9482 10.3866 10.9928 15.342 10.9928 21.4547C10.9928 27.5674 15.9482 32.5227 22.0609 32.5227ZM22.0609 34.1193C29.0553 34.1193 34.7255 28.4491 34.7255 21.4547C34.7255 14.4602 29.0553 8.79004 22.0609 8.79004C15.0664 8.79004 9.39624 14.4602 9.39624 21.4547C9.39624 28.4491 15.0664 34.1193 22.0609 34.1193Z' fill='%230077C8'/%3E%3C/svg%3E");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;

            &:before {
                content: '';
                position: absolute;
                top: 2vh;
                left: 3.8vw;
                display: block;
            }

            &:hover {
                opacity: 0.8;
            }

            &--monchegorsk {
                top: 30%;
                left: 30.5%;

                &:before {
                    content: 'Мончегорск';
                }
            }

            &--moscow {
                top: 71%;
                left: 9.8%;

                &:before {
                    content: 'Москва';
                }
            }

            &--norilsk {
                top: 74%;
                right: 16.7%;

                &:before {
                    content: 'Норильск';
                }
            }
        }

        #{$parent}__image {
            width: 100%;
            height: auto;
            display: block;
        }
    }
}

.map-tooltip-content {
    text-align: start;

    &__title {
        font-weight: 700;
        // font-size: 20px;
        font-size: var(--fontSizeXl);
        line-height: 1.3;
        color: $token-colors-black-80;
        margin-top: 0;
        margin-bottom: 8px;
    }

    &__subtitle {
        font-weight: 600;
        // font-size: 18px;
        font-size: var(--fontSizeL);
        line-height: 1.4;
        color: $token-colors-black-80;
        margin: 0;
    }

    &__text {
        font-weight: 400;
        // font-size: 18px;
        font-size: var(--fontSizeL);
        line-height: 1.4;
        color: $token-colors-black-80;
        margin: 0;
    }
}
</style>
