var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"reviews"},[_c('div',{staticClass:"reviews__head"},[_c('div',{staticClass:"reviews__count"},[_vm._v("Отзывов: "+_vm._s(this.nav.count))]),_vm._v(" "),(!_vm.successFormSend)?_c('div',{staticClass:"reviews__write"},[_c('z-button',{attrs:{"kind":"bordered"},on:{"click":_vm.scrollToForm}},[_vm._v("\n                Оставить отзыв\n            ")])],1):_vm._e()]),_vm._v(" "),_c('div',{class:['reviews__list', { 'is-loading' : _vm.loading }]},_vm._l((this.cards),function(card,index){return _c('div',{key:index,staticClass:"reviews-card"},[_c('div',{staticClass:"reviews-card__training-name",domProps:{"innerHTML":_vm._s(card.trainingGround.value)}}),_vm._v(" "),(card.courseName.value)?_c('div',{staticClass:"reviews-card__course-name",domProps:{"innerHTML":_vm._s(card.courseName.value)}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"reviews-card__description",domProps:{"innerHTML":_vm._s(card.text.value)}}),_vm._v(" "),_c('div',{staticClass:"reviews-card__date"},[_vm._v("\n                "+_vm._s(_vm._f("momentFilter")(card.activeFrom,'DD MMMM YYYY'))+"\n            ")]),_vm._v(" "),(card.name)?_c('div',{staticClass:"reviews-card__author"},[_c('z-icon',{attrs:{"width":"20","height":"16","name":"quotes"}}),_vm._v(" "),_c('span',{domProps:{"innerHTML":_vm._s(card.name)}})],1):_vm._e()])}),0),_vm._v(" "),(_vm.loading)?_c('z-preloader'):_vm._e(),_vm._v(" "),_c('z-pagination',{staticClass:"reviews__nav",attrs:{"show-next":"","show-prev":"","show-nav":"","show-more":{
            text: 'Загрузить ещё',
            size: 'l',
            marginBottom: 'm'
        },"data":{
            currentPage: this.nav.current,
            totalItems: this.nav.count,
            totalPages: this.nav.total,
            pageRange: 1,
        }},on:{"change-page":_vm.onPageChange}}),_vm._v(" "),(!_vm.successFormSend)?_c('form',{staticClass:"reviews-form u-top-margin--l",attrs:{"id":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.submitForm.apply(null, arguments)}}},[_c('z-caption',{attrs:{"tag":"h2","size":"2xl"}},[_vm._v("Оставить отзыв")]),_vm._v(" "),_c('z-input',{ref:"name",attrs:{"placeholder":"Ваше имя","name":"name"},model:{value:(_vm.formData.fio),callback:function ($$v) {_vm.$set(_vm.formData, "fio", $$v)},expression:"formData.fio"}}),_vm._v(" "),_c('div',{staticClass:"reviews-form__wrapper"},[_c('z-input',{ref:"course",attrs:{"placeholder":"Название курса","name":"input"},model:{value:(_vm.formData['course-name']),callback:function ($$v) {_vm.$set(_vm.formData, 'course-name', $$v)},expression:"formData['course-name']"}}),_vm._v(" "),_c('z-select',{ref:"training-ground",attrs:{"required":"","placeholder":"Учебная площадка","data":this.trainingGround},model:{value:(_vm.formData['training-ground']),callback:function ($$v) {_vm.$set(_vm.formData, 'training-ground', $$v)},expression:"formData['training-ground']"}})],1),_vm._v(" "),_c('z-textarea',{ref:"text",attrs:{"placeholder":"Текст отзыва","required":"","name":"text"},model:{value:(_vm.formData.text),callback:function ($$v) {_vm.$set(_vm.formData, "text", $$v)},expression:"formData.text"}}),_vm._v(" "),_c('z-checkbox',{ref:"policy",staticClass:"u-top-margin--s",attrs:{"name":"policy","required":"","size":"s","data":[
                {
                    text: "Даю согласие на&nbsp;обработку <z-link href='/terms/'>персональных данных</z-link>.",
                    value: 'agree'
                }
            ]},model:{value:(_vm.isChecked),callback:function ($$v) {_vm.isChecked=$$v},expression:"isChecked"}}),_vm._v(" "),_c('div',{staticClass:"reviews-form__footer"},[_c('z-button',[_vm._v("Отправить")]),_vm._v(" "),(_vm.showResetButton)?_c('z-link',{attrs:{"href":"javascript: void(0)","icon-position":"right","icon":"close-round","size":"xl","underlined":false},on:{"click":_vm.clearForm}},[_vm._v("Очистить форму")]):_vm._e()],1)],1):_c('notification',{staticClass:"u-top-margin--l",scopedSlots:_vm._u([{key:"image",fn:function(){return [_c('z-image',{attrs:{"src":"/images/not-found/form-success.svg","width":"316","height":"266"}})]},proxy:true},{key:"description",fn:function(){return [_c('z-caption',{attrs:{"size":"m"}},[_vm._v("Спасибо за отзыв!")]),_vm._v(" "),_c('p',{staticClass:"text-size-xl u-regular"},[_vm._v("Ваше сообщение отправлено сотруднику университета, в дальнейшем оно может быть опубликовано на сайте.")])]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }