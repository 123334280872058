/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'microphone': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12 3a3 3 0 00-3 3v6a3 3 0 003 3 3 3 0 003-3V6a3 3 0 00-3-3zm0-1.5A4.5 4.5 0 007.5 6v6a4.5 4.5 0 009 0V6A4.5 4.5 0 0012 1.5zm.75 17.962a7.5 7.5 0 006.704-6.629.75.75 0 00-1.49-.166 6 6 0 01-11.927 0 .75.75 0 00-1.491.166 7.5 7.5 0 006.704 6.63v2.287a.75.75 0 001.5 0v-2.288z" _fill="#626C84"/>'
  }
})
