/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tag_color_dots_icon-4': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<rect pid="0" width="48" height="48" rx="24" _fill="#F9B533"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M22.24 14.927c-.586-.08-1.674-.084-2.77-.074-.669.006-1.404.02-2.11.032-.483.008-.953.017-1.378.023a2.311 2.311 0 00-2.282 2.308v13.072a2.3 2.3 0 002.3 2.3h5.958a1.519 1.519 0 01.742.42v.843h2.6v-.853a1.436 1.436 0 01.714-.41H32a2.3 2.3 0 002.3-2.3v-13.09a2.3 2.3 0 00-2.3-2.3h-.676l-.967-.016c-.78-.014-1.556-.027-2.244-.032-.945-.005-1.844.005-2.38.078a3.91 3.91 0 00-1.742.708 3.982 3.982 0 00-1.752-.709zm3.06 2.999v12.16c.14-.037.284-.066.433-.086l.087-.012H31.7v-12.49h-.406l-1-.017c-.773-.014-1.524-.027-2.197-.03-.992-.007-1.695.01-2.014.053a1.392 1.392 0 00-.691.335c-.036.031-.067.06-.092.087zm-2.6 12.167V17.936a1.508 1.508 0 00-.095-.088 1.47 1.47 0 00-.716-.344c-.358-.049-1.232-.062-2.396-.051-.665.006-1.37.018-2.06.03-.385.008-.766.015-1.133.02v12.485h5.852l.087.012c.16.021.313.053.461.093z" _fill="#1971CF"/>'
  }
})
