/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'ad': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4.673 3.653a.316.316 0 00-.073.21v17.273c0 .094.034.167.073.211.04.043.075.053.1.053h15.454c.025 0 .06-.01.1-.053a.316.316 0 00.073-.21V3.863a.316.316 0 00-.073-.21c-.04-.044-.075-.054-.1-.054H4.773c-.025 0-.06.01-.1.053zm-1.273.21c0-.743.554-1.463 1.373-1.463h15.454c.82 0 1.373.72 1.373 1.464v17.272c0 .744-.554 1.464-1.373 1.464H4.773c-.82 0-1.373-.72-1.373-1.464V3.864zm12.97 8.358c-.353.387-.782.66-1.25.804v-6.05c.468.145.897.418 1.25.803.544.594.843 1.383.843 2.222 0 .84-.3 1.629-.843 2.221zm-5.703-1.953V7.035l3.666 2.699v3.231l-3.666-2.697zm-.786 2.757a2.815 2.815 0 01-1.25-.804 3.269 3.269 0 01-.845-2.22c0-.84.3-1.63.844-2.223a2.825 2.825 0 011.25-.802v6.049zM14.333 6v2.698L10.667 6C8.642 6 7 7.791 7 10c0 2.21 1.642 4 3.667 4v-2.698L14.333 14C16.358 14 18 12.21 18 10c0-2.209-1.642-4-3.667-4zM8 18a1 1 0 100 2h9a1 1 0 100-2H8z" _fill="#626C84"/>'
  }
})
