<template>
    <component
        :is="tag"
        v-bind="$attrs"
        v-on="$listeners"
        class="z-button"
        :class="classObject"
        :href="tag === 'a' ? href : false"
        ref="zButton"
    >
        <div v-if="hasLeftSlot" class="z-button__icon z-button__icon--align-left">
            <slot name="left" />
        </div>
        <span><slot /></span>
        <div v-if="hasRightSlot" class="z-button__icon z-button__icon--align-right">
            <slot name="right" />
        </div>
        <div data-button-animation-bg-1></div>
        <div data-button-animation-bg-2></div>
    </component>
</template>

<script>
import ButtonHover from './button-hover'

export default {
    name: 'z-button',
    props: {
        tag: {
            type: String,
            default: 'button',
            comment:
                'Используется, если кнопкая является ссылкой или стандартный button по какой-то причине не подошел'
        },
        kind: {
            type: String,
            validator: (prop) => ['primary', 'secondary', 'bordered', 'black'].includes(prop),
            default: 'primary'
        },
        onlyIcon: {
            type: Boolean,
            default: false,
            comment: 'Обязательно прокидывать, если кнопка идет без текста, только с иконкой'
        },
        loading: {
            type: Boolean,
            default: false,
            comment: 'Выводит в кнопке вместо текста прелоадер'
        },
        href: {
            type: String
        },
        size: {
            type: String,
            validator: (prop) => ['s', 'm', 'l'].includes(prop),
            default: 'm'
        },
        showHoverAnimation: {
            type: Boolean,
            default: true
        }
    },
    computed: {
        hasLeftSlot () {
            return !!this.$slots['left']
        },
        hasRightSlot () {
            return !!this.$slots['right']
        },
        classObject () {
            return [
                { 'z-button--only-icon': this.onlyIcon },
                `z-button--kind-${this.kind}`,
                `z-button--size-${this.size}`,
                { 'z-button--loading': this.loading }
            ]
        }
    },
    mounted () {
        if (this.showHoverAnimation) {
            this.buttonHover = new ButtonHover(this.$refs.zButton)
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
