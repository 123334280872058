<template>
    <div class="z-input-file">
        <label
            :for="name"
            class="z-input-file__label"
            :class="{
                'is-disabled': disabled
            }"
        >
            <input
                :id="name"
                type="file"
                :name="name"
                :disabled="disabled"
                @change="change($event.target)"
                multiple
            />
            <z-icon
                name="pin"
                width="24"
                height="24"
            />
            <span>
                <slot>{{ text }}</slot>
            </span>
        </label>
        <div
            class="z-input-file__filelist"
            v-if="files.length"
        >
            <div
                class="z-input-file__file"
                v-for="(file, index) in files"
                :key="index"
            >
                <span>{{ file }}</span>
                <z-icon
                    name="close"
                    width="24"
                    height="24"
                    @click="remove(file)"
                />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'z-input-file',
    props: {
        name: {
            type: String,
            required: true
        },
        disabled: Boolean
    },
    data () {
        return {
            files: [],
            uploaded: [],
            text: this.$root.lang === 'ru' ? 'Прикрепить файлы' : 'Attach files'
        }
    },
    mounted () {
        this.$root.$bus.$on('clear', name => {
            if (this.name === name) this.clear()
        })
    },
    beforeDestroy () {
        this.$root.$bus.$off('clear', name => {
            if (this.name === name) this.clear()
        })
    },
    methods: {
        change (data) {
            this.buildList(data)
            this.$nextTick(() => this.$emit('change', { value: this.uploaded, name: this.name }))
        },
        clear () {
            this.files = []
            this.uploaded = []
            this.$nextTick(() => this.$emit('change', { value: this.uploaded, name: this.name }))
        },
        buildList (data) {
            this.files = []
            this.uploaded = data.files

            for (let i = 0; i < data.files.length; i++) {
                this.files.push(data.files[i].name)
            }
        },
        remove (file) {
            let index = this.files.indexOf(file)
            if (index > -1) this.files.splice(index, 1)
        }
    }
}

</script>

<style lang="scss" src="./index.scss"></style>
