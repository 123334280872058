<template>
    <div class="expander-content">
        <z-icon v-if="contentData.iconName" :name="contentData.iconName" width="80" height="80" />
        <z-caption
            v-if="contentData.name"
            tag="h1"
            size="xl"
            weight="600"
            v-html="contentData.name"
        />
        <z-caption
            v-if="contentData.showDate && (contentData.date || currentWeek)"
            tag="p"
            size="l"
            v-html="contentData.date || currentWeek"
        />
        <z-caption v-if="contentData.detailText" tag="p" size="l" v-html="contentData.detailText" />
        <div
            class="expander-content__buttons"
            v-if="contentData.cities && contentData.cities.length !== 0"
        >
            <z-button
                v-for="(city, idx) in contentData.cities"
                :key="idx"
                size="s"
                tag="a"
                :href="city.link"
            >
                <span v-html="city.name"></span>
            </z-button>
        </div>
        <z-link v-if="contentData.link" :href="contentData.link.to" :underlined="false">
            {{ contentData.link.text }}
        </z-link>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    name: 'expander-content',
    props: {
        contentData: {
            type: Object,
            required: true
        }
    },
    computed: {
        currentWeek () {
            let start = moment().startOf('week')
            let end = moment().endOf('week')

            if (start.format('M') === end.format('M')) {
                return `${start.format('DD')} - ${end.format('DD MMMM')}`
            }

            return `${start.format('DD MMMM')} - ${end.format('DD MMMM')}`
        }
    }
}
</script>

<style scoped lang="scss">
.expander-content {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    & > svg {
        margin-bottom: 16px;
    }

    &::v-deep path {
        fill: $token-colors-black-90;
        stroke: $token-colors-white;
    }

    & > h1 {
        color: inherit;
        line-height: 140%;
        margin-bottom: 12px;
    }

    & > p {
        line-height: 140%;
        color: inherit;
        margin-bottom: 12px;
    }
    &__buttons {
        display: flex;
        padding-top: 12px;

        @include breakpoint(mobile) {
            flex-direction: column;
            gap: 8px;
            width: 100%;
        }

        & > * {
            margin-right: 8px;

            @include breakpoint(tablet) {
                padding: 12px 24px;
                font-size: var(--fontSizeXs);
            }
            @include breakpoint(mobile) {
                padding: 10px 20px;
                margin-right: 0;
            }

            &:last-child {
                margin-right: 0;
            }
        }
    }
}
</style>
