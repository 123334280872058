/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'home': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7.848 3.302c.09-.07.215-.07.304 0l4.5 3.482c.062.047.098.12.098.199v5.516a.25.25 0 01-.25.251H8.75v-2.254a.75.75 0 00-1.5 0v2.254H3.5a.25.25 0 01-.25-.251V6.983c0-.078.036-.152.098-.2l4.5-3.48zM12.5 14.25h-9a1.75 1.75 0 01-1.75-1.751V6.983c0-.542.25-1.054.68-1.386l.459.594-.46-.594 4.5-3.481a1.749 1.749 0 012.141 0l-.459.593.46-.593 4.5 3.481c.428.332.679.844.679 1.386v5.516a1.75 1.75 0 01-1.75 1.751z" _fill="#0072D9"/>'
  }
})
