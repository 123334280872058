<template>
    <div v-if="!data.error" class="schedule">
        <schedule-top
            ref="topFilters"
            :data="filter"
            :is-mobile="isMobile"
            @change-filter="onFilterChange"
            @toggle-filter="toggleFilter"
            @change-city="onCityChange"
        ></schedule-top>
        <div ref="containerEl" class="schedule__layout container">
            <div class="schedule__main">
                <z-preloader v-if="isLoading"/>
                <div ref="resultsEl" class="schedule__results-wrapper">
                    <results
                        ref="results"
                        class="schedule__results"
                        :data="combinedItems"
                        :is-init="isInit"
                        @scroll-to-item="scrollToScheduleItemInResults"
                    />
                </div>
            </div>
            <div class="schedule__aside">
                <div ref="stickyEl" class="schedule__aside-wrapper">
                    <div class="schedule__aside-body">
                        <calendar-block
                            @scroll-to-date="scrollToScheduleItem"
                            :dates="calendarDates"
                            :is-tablet="isTablet"
                        />
                        <schedule-filter
                            v-show="!isTablet || (isTablet && isFilterVisible)"
                            ref="asideFilters"
                            class="schedule__filter"
                            :data="filter"
                            :is-tablet="isTablet"
                            @change="onFilterChange"
                            @toggle-filter="toggleFilter"
                        />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ScheduleFilter from './components/Filter.vue'
import Results from './components/Results.vue'
import CalendarBlock from './components/CalendarBlock.vue'
import { getScheduleData } from '@/api/schedule'
import { debounce } from 'throttle-debounce'
import ScheduleTop from './components/ScheduleTop.vue'
import moment from 'moment'
import { queryString } from '@/utils/queryString'

export default {
    provide () {
        return {
            studyFormXmlId: () => {
                const selectedForm = this.filter.studyFormat.find(
                    item => item.id === this.params.studyForm
                )
                return selectedForm ? selectedForm.xmlId : ''
            }
        }
    },
    name: 'schedule',
    components: {
        ScheduleTop,
        ScheduleFilter,
        Results,
        CalendarBlock
    },
    data () {
        return {
            isInit: true,
            isSticky: false,
            data: this.$root.app.components.schedule || {},
            filter: this.$root.app.components.schedule?.filter || {},
            itemsThis: this.$root.app.components.schedule?.items?.this || [],
            itemsNext: this.$root.app.components.schedule?.items?.next || [],
            isLoading: false,
            params: {
                query: '',
                city: '',
                studyFormat: '',
                group: '',
                course: '',
                curator: ''
            },
            isTablet: window.innerWidth <= 1359,
            isMobile: window.innerWidth <= 1024,
            isFilterVisible: false
        }
    },
    computed: {
        combinedItems () {
            return this.itemsThis.concat(this.itemsNext.length ? this.itemsNext : [])
        },
        calendarDates () {
            return this.combinedItems.map(item => ({
                id: item.id,
                date: item.date,
                items: item.items
            }))
        }
    },
    methods: {
        onFilterChange (params) {
            this.params = { ...this.params, ...params }
            this.fetchData()
        },
        onCityChange (params) {
            this.params = { ...this.params, ...params }
            this.$refs.asideFilters.onClear()
        },
        toggleFilter () {
            this.isFilterVisible = !this.isFilterVisible
            this.isFilterVisible
                ? document.body.classList.add('no-scroll')
                : document.body.classList.remove('no-scroll')
        },
        fetchData: debounce(500, function () {
            queryString(this.clearEmptyParams(this.params))
            this.isLoading = true

            getScheduleData(this.params)
                .then(response => {
                    this.itemsThis = response.items.this || []
                    this.itemsNext = response.items.next || []
                    this.filter = response.filter
                })
                .catch(err => {
                    console.error(err)
                })
                .finally(() => {
                    this.isInit = false
                    this.isLoading = false
                })
        }),
        getParams (url = window.location) {
            let params = {}

            new URL(url).searchParams.forEach((val, key) => {
                params[key] = this.sanitizeValue(val)
            })

            this.params = { ...this.params, ...params }
            this.updadeFilters()
        },
        updadeFilters () {
            this.$nextTick(() => {
                this.$refs.topFilters.updateParams({
                    query: this.params.query,
                    city: this.params.city
                })

                this.$refs.asideFilters.updateParams({
                    studyFormat: this.params.studyFormat,
                    group: this.params.group,
                    course: this.params.course,
                    curator: this.params.curator
                })
            })
        },
        sanitizeValue (text) {
            return text
                .replace(/&/g, '&amp;')
                .replace(/</g, '&lt;')
                .replace(/>/g, '&gt;')
                .replace(/"/g, '&quot;')
                .replace(/'/g, '&#039;')
                .replace(/\\/g, '\\\\')
                .replace(/\u0008/g, '\\b')
                .replace(/\t/g, '\\t')
                .replace(/\n/g, '\\n')
                .replace(/\f/g, '\\f')
                .replace(/\r/g, '\\r')
                .replace(/'/g, '\\\'')
                .replace(/"/g, '\\"')
        },
        clearEmptyParams (obj) {
            let params = { ...obj }
            Object.keys(params).forEach(key => {
                if (!params[key]) delete params[key]
            })
            return params
        },
        scrollToScheduleItem (date) {
            if (!date) return

            const item = this.combinedItems.find(d => moment.unix(d.date).utc().utcOffset(3).format('YYYY-MM-DD') === date)
            if (item) {
                const weekPrefix = this.itemsThis.includes(item) ? 'thisWeek' : 'nextWeek'
                const uniqueId = `${weekPrefix}-${item.id}`
                this.$refs.results.scrollToItem(uniqueId, this.getStickyOffset())
            }
        },
        scrollToScheduleItemInResults (itemId) {
            this.$refs.results.scrollToItem(itemId, this.getStickyOffset())
        },
        onResize () {
            this.isTablet = window.innerWidth <= 1359
            this.isMobile = window.innerWidth <= 1024

            if (this.isTablet || this.isMobile) {
                this.$refs.containerEl.style = ''
            }
        },
        getStickyOffset () {
            return (this.isTablet || this.isMobile) ? this.$refs.stickyEl.getBoundingClientRect().height : 0
        },
        scrollHandler () {
            const containerElRect = this.$refs.containerEl.getBoundingClientRect()
            const resultsElRect = this.$refs.resultsEl.getBoundingClientRect()
            const stickyElRect = this.$refs.stickyEl.getBoundingClientRect()

            this.isSticky = resultsElRect.height > stickyElRect.height && containerElRect.top <= 0
            this.$refs.stickyEl.classList.toggle('is-sticky', this.isSticky)
            this.$refs.stickyEl.classList.toggle('is-bottom-position', this.isSticky && containerElRect.bottom - stickyElRect.height <= 0)
            this.$refs.stickyEl.classList.toggle('scrollable', stickyElRect.height > window.innerHeight)

            if (this.isSticky && (this.isTablet || this.isMobile)) {
                this.$refs.containerEl.style = `padding-top: ${stickyElRect.height}px;`
            } else {
                this.$refs.containerEl.style = ''
            }
        }
    },
    mounted () {
        this.getParams()

        window.addEventListener('resize', this.onResize)
        window.addEventListener('scroll', this.scrollHandler)
    },
    beforeDestroy () {
        window.removeEventListener('resize', this.onResize)
        window.removeEventListener('scroll', this.scrollHandler)
        document.body.classList.remove('no-scroll')
    }
}
</script>
<style lang="scss">
body.no-scroll {
    overflow: hidden;

    .layout__container {
        overflow: visible;
    }

    .footer {
        z-index: -1;
    }
}
.schedule {
    background-color: $token-colors-bg-10;
    border-top-right-radius: $borderRadius;
    padding-bottom: 30px;

    @media (max-width: 1920px) {
        background-color: $token-colors-bg-5;
    }

    &__layout {
        display: flex;
        border-top-right-radius: $borderRadius;
        padding: 0;

        @include breakpoint(tablet) {
            flex-direction: column-reverse;
        }
    }

    &__results {
        @include padding-level(right, 2XL);
        padding-left: $token-grids-desktop-offset;

        @include breakpoint(laptop) {
            padding-left: $token-grids-laptop-offset;
        }

        @include breakpoint(tablet) {
            padding-left: $token-grids-v-tablet-offset;
            padding-right: $token-grids-v-tablet-offset;
        }

        @include breakpoint(mobile) {
            padding-left: $token-grids-mobile-offset;
            padding-right: $token-grids-mobile-offset;
        }
    }

    &__main {
        width: 75%;
        background-color: $token-colors-bg-5;
        padding-bottom: calc(#{$token-spacers-2-xl} + #{$borderRadius});
        z-index: 1;
        // overflow: auto;
        // max-height: 100vh;
        // min-height: 1167px;

        @include breakpoint(tablet) {
            padding-bottom: calc(#{$token-spacers-xl} + #{$borderRadius});
            width: 100%;
            min-height: auto;
        }
    }

    &__aside {
        width: 25%;
        background: $token-colors-bg-15;
        border-radius: 0 $borderRadius 0 0;
        position: relative;
        z-index: 1;

        @include breakpoint(tablet) {
            width: 100%;
        }
    }

    &__aside-wrapper {
        max-width: 480px;
        background: $token-colors-bg-15;
        padding: $token-spacers-m;
        padding-bottom: calc(#{$token-spacers-2-xl} + #{$borderRadius});
        transition: transform $transition;

        @include breakpoint(tablet) {
            max-width: none;
            width: 100%;
            padding: $token-spacers-3-xs 40px;
            background: $token-colors-bg-5;
            border-bottom: 1px solid $token-colors-bg-10;
        }

        @include breakpoint(v-tablet) {
            padding: $token-spacers-3-xs 21px;
        }

        @include breakpoint(mobile) {
            padding: $token-spacers-3-xs $token-spacers-2-xs;
        }

        &.is-sticky {
            width: 25%;
            max-height: calc(100vh + #{$token-spacers-2-xl});
            position: fixed;
            top: 0;

            &::-webkit-scrollbar {
                height: 5px;
                width: 5px;
                background: $token-colors-black-3;
            }

            &::-webkit-scrollbar-thumb {
                background: $token-colors-link-active;
                border-radius: 4px;
            }

            @include breakpoint(tablet) {
                width: 100%;
            }
        }

        &.is-bottom-position {
            width: 100%;
            max-height: none;
            position: absolute;
            top: auto;
            bottom: 0;
            left: 0;

            @include breakpoint(tablet) {
                position: fixed;
                top: 0;
                bottom: auto;
                transform: translateY(-100%);
            }
        }

        &.scrollable {
            overflow-y: auto;
        }
    }

    @media print {
        .schedule__filter,
        .schedule__aside,
        .schedule-tools,
        .schedule-card__remind,
        .schedule-top__wrapper,
        .schedule-top__tab:not(.schedule-top__tab--selected){
            display: none !important;
        }

        .schedule__main, .schedule__results {
            width: 100%;
            height: auto;
            overflow: visible !important;
        }

        .schedule-card {
            border: 1px solid $token-colors-primary;
            margin-left: 0;
            border-radius: 0;

            &:after {
                display: none;
            }
        }
    }
}
</style>
