/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendarbell': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M25.5 10.25a1 1 0 10-2 0v.5h-7v-.5a1 1 0 10-2 0v.5h-2a1.75 1.75 0 00-1.75 1.75v15c0 .966.784 1.75 1.75 1.75h15a1.75 1.75 0 001.75-1.75v-15a1.75 1.75 0 00-1.75-1.75h-2v-.5zm1.75 5v-2.5H25.5v.5a1 1 0 11-2 0v-.5h-7v.5a1 1 0 11-2 0v-.5h-1.75v2.5h14.5zm-14.5 2h14.5v10h-14.5v-10z" _fill="#1F2A40"/><path pid="1" d="M17.938 20.366a2.072 2.072 0 014.124 0l.107 1.075c.053.531.236 1.041.532 1.486l.323.484c.18.269.269.403.286.511a.5.5 0 01-.285.532c-.1.046-.26.046-.583.046h-4.884c-.322 0-.483 0-.583-.046a.5.5 0 01-.285-.532c.017-.108.107-.242.286-.51l.323-.485c.296-.445.479-.955.532-1.486l.107-1.075z" _fill="#1F2A40"/><path pid="2" d="M19.276 25.486c.042.04.136.075.267.1.131.025.292.039.457.039.165 0 .326-.014.457-.039.13-.025.225-.06.267-.1" _stroke="#1F2A40" stroke-linecap="round"/>'
  }
})
