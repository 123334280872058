/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'round-arrow': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12 22.5c5.799 0 10.5-4.701 10.5-10.5S17.799 1.5 12 1.5 1.5 6.201 1.5 12 6.201 22.5 12 22.5zm0 1.5c6.627 0 12-5.373 12-12S18.627 0 12 0 0 5.373 0 12s5.373 12 12 12z" _fill="#0072D9"/><g clip-path="url(#clip0_376_19265)"><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M11.502 8.091a.697.697 0 01.992-.002l3.414 3.415a.7.7 0 11-.99.99l-2.219-2.22v5.14a.7.7 0 11-1.4 0v-5.14l-2.22 2.22a.7.7 0 11-.99-.99l3.413-3.413z" _fill="#0077C8"/></g><defs><clipPath id="clip0_376_19265"><path pid="2" _fill="#fff" transform="translate(6.462 6.462)" d="M0 0h11.077v11.077H0z"/></clipPath></defs>'
  }
})
