/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tag-color/calendar': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8.39 3.067a1.2 1.2 0 00-2.4 0v.174H4.504a1.95 1.95 0 00-1.95 1.95v14.992a1.95 1.95 0 001.95 1.95h14.992a1.95 1.95 0 001.95-1.95V5.191a1.95 1.95 0 00-1.95-1.95H18.01v-.174a1.2 1.2 0 10-2.4 0v.174H8.39v-.174zM5.99 5.64v.174a1.2 1.2 0 002.4 0v-.174h7.22v.174a1.2 1.2 0 002.4 0v-.174h1.036v2.41H4.954v-2.41H5.99zm-1.036 4.81v9.282h14.092v-9.282H4.954zm8.246 2.923a1.2 1.2 0 10-2.4 0v.862h-.862a1.2 1.2 0 100 2.4h.862v.861a1.2 1.2 0 102.4 0v-.861h.861a1.2 1.2 0 100-2.4H13.2v-.862z" _fill="#1971CF"/>'
  }
})
