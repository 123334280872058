/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tag_color_dots_icon-2': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<rect pid="0" width="48" height="48" rx="24" _fill="#189FB4"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M24.196 15.171a1.5 1.5 0 00-1.392 0l-9.822 5.145c-1.102.577-1.064 2.166.064 2.69l2.658 1.234v8.242c0 1.164 1.249 1.858 2.233 1.312 2.37-1.314 4.087-1.896 5.68-1.908 1.577-.012 3.206.536 5.398 1.857.985.593 2.281-.097 2.281-1.287V24.24l1.504-.698v4.37a1.2 1.2 0 102.4 0v-6.25a1.2 1.2 0 00-.643-1.064l-10.361-5.427zm-6.092 15.827v-5.644l4.764 2.212a1.5 1.5 0 001.264 0l4.764-2.212v5.57c-1.83-.944-3.523-1.451-5.297-1.438-1.79.013-3.547.556-5.495 1.512zm11.487-8.613l1.7-.789-7.791-4.08-7.79 4.08 1.7.79 6.09 2.827 6.09-2.828z" _fill="#fff"/>'
  }
})
