<template>
    <div :key="getExpanderKey" ref="expander" :class="getCardClass">
        <z-icon :name="iconName" data-expander-icon />
        <div v-if="hasContentSlot" ref="content" data-expander-content class="expander__content">
            <slot name="content" />
        </div>
    </div>
</template>

<script>
import { mixinDevice } from '../../utils/mixin'
import Expander from './expander'

export default {
    name: 'expander',

    props: {
        theme: {
            type: String,
            validator: (prop) => ['dark', 'light'].includes(prop),
            default: 'dark'
        },
        iconName: {
            type: String,
            default: 'general/warning'
        },
        isOpen: {
            type: Boolean,
            default: false
        },
        isScrollSensitive: {
            type: Boolean,
            default: false
        }
    },

    mixins: [ mixinDevice ],

    data () {
        return {
            contentHeightObserver: null,
            expander: null
        }
    },

    mounted () {
        this.recreateExpander()
        this.observeHeight()
    },

    updated () {
        this.recreateExpander()
        this.observeHeight()
    },

    computed: {
        getCardClass () {
            return [
                'expander',
                this.cardType && `expander--${this.cardType}`,
                this.theme && `expander--${this.theme}`
            ]
        },
        hasContentSlot () {
            return !!this.$slots['content']
        },
        isIndexPage () {
            return window.location.pathname === '/'
        },
        getIsOpen () {
            if (!this.isIndexPage) return false
            if (['mobile', 'v-tablet', 'tablet'].includes(this.device)) {
                return false
            } else {
                return this.isOpen
            }
        },
        getIsScrollSensitive () {
            if (!this.isIndexPage) return false
            if (['mobile', 'v-tablet', 'tablet'].includes(this.device)) {
                return false
            } else {
                return this.isScrollSensitive
            }
        },
        getExpanderKey () {
            return `${this.getIsOpen}-${this.getIsScrollSensitive}-${this.iconName}`
        }
    },
    methods: {
        recreateExpander () {
            if (this.expander) {
                this.expander = null
            }

            this.expander = new Expander(
                this.$refs.expander,
                this.getIsOpen,
                this.getIsScrollSensitive
            )
        },
        observeHeight () {
            this.contentHeightObserver = new ResizeObserver(() => {
                this.recreateExpander()
            })
            this.contentHeightObserver.observe(this.$refs.content)
        }
    }
}
</script>

<style scoped lang="scss">
@import './index.scss';
</style>
