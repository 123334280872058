/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'settings': {
    width: 26,
    height: 23,
    viewBox: '0 0 26 23',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.861 5.308a.612.612 0 01-.612-.613V.612a.612.612 0 111.224 0v4.083a.612.612 0 01-.612.613zM2.722 11.5a.612.612 0 101.225 0V.613a.612.612 0 10-1.225 0V11.5zm19.053 10.888a.612.612 0 001.225 0v-2.722a.612.612 0 10-1.225 0v2.722zM3.335 23a.612.612 0 01-.613-.612v-5.444a.612.612 0 111.225 0v5.444a.612.612 0 01-.612.612zm18.44-11.5a.612.612 0 101.225 0V.613a.612.612 0 10-1.225 0V11.5zm-9.526 10.888a.612.612 0 001.224 0V11.5a.612.612 0 10-1.224 0v10.888z" _fill="#1F2A40"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M12.86 5.308a2.11 2.11 0 100 4.219 2.11 2.11 0 000-4.22zm-3.334 2.11a3.334 3.334 0 116.67 0 3.334 3.334 0 01-6.67 0zm-6.192 4.695a2.11 2.11 0 100 4.219 2.11 2.11 0 000-4.22zM0 14.222a3.334 3.334 0 116.669 0 3.334 3.334 0 01-6.669 0zm20.279 1.36a2.11 2.11 0 114.218 0 2.11 2.11 0 01-4.218 0zm2.109-3.333a3.334 3.334 0 100 6.668 3.334 3.334 0 000-6.668z" _fill="#1F2A40"/>'
  }
})
