<template>
    <section class="section-about">
        <div class="section-about__content container">
            <tag data-scroll-translate>О нас</tag>
            <z-caption
                tag="p"
                size="4xl"
                class="section-about__caption"
                data-scroll-translate
                >Корпоративный университет «Норильский никель» — одно из ведущих образовательных
                учреждений системы <span>профессионального образования</span> Красноярского
                края.</z-caption
            >
            <z-button
                class="section-about__button"
                tag="a"
                href="/about/territory/"
                size="l"
                data-scroll-translate
                >Об университете</z-button
            >
        </div>
    </section>
</template>

<script>
import Tag from '../../components/Tag.vue'
export default {
    name: 'section-about',
    components: {
        Tag
    }
}
</script>

<style lang="scss">
@import './index.scss';
</style>
