/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'internal': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M10.829 3.17a2 2 0 011.414 3.416l-1.768 1.768a2 2 0 01-2.829 0 .75.75 0 00-1.06 1.06 3.5 3.5 0 004.95 0l1.767-1.768a3.5 3.5 0 10-4.95-4.95L7.116 3.935a.75.75 0 001.06 1.06l1.238-1.237a2 2 0 011.415-.586zM6.174 7.214a2 2 0 012.18.433.75.75 0 001.06-1.06 3.5 3.5 0 00-4.95 0L2.697 8.354a3.5 3.5 0 104.95 4.949l1.237-1.237a.75.75 0 10-1.06-1.06l-1.238 1.237a2 2 0 11-2.829-2.829l1.768-1.768a2 2 0 01.649-.433z" _fill="#0078CE"/>'
  }
})
