/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'no-image-square': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4.75 6.164V19.25h13.086L4.75 6.164zm15.997 14.564c.311-.316.503-.75.503-1.228v-15a1.75 1.75 0 00-1.75-1.75h-15A1.75 1.75 0 002.75 4.5v15c0 .966.784 1.75 1.75 1.75h15a1.744 1.744 0 001.247-.522zm-1.497-2.892L6.164 4.75H19.25v13.086z" _fill="#0072D9"/>'
  }
})
