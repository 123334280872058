<template>
    <div class="z-not-found">
        <div class="z-not-found__image" v-if="!hideImage">
            <template v-if="!!$slots.image">
                <slot name="image"></slot>
            </template>
            <z-image v-else :src="image" width="120" height="120"></z-image>
        </div>
        <div class="z-not-found__description">
            <template v-if="!!slots.description">
                <slot name="description" v-if="!!$slots.description"></slot>
            </template>
            <template v-else>
                <div class="z-not-found__title" v-html="text.title"></div>
                <p class="z-not-found__text" v-html="text.description"></p>
            </template>
        </div>
    </div>
</template>

<script>
export default {
    name: 'z-not-found',
    props: {
        hideImage: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            image: '/images/not-found/events-nf.svg',
            text: {
                title: this.$root.localize({
                    ru: 'Ничего не найдено',
                    en: 'No results found'
                }),
                description: this.$root.localize({
                    ru: 'По вашему запросу ничего не найдено, попробуйте изменить формулировку или выполнить поиск по ключевым словам.',
                    en: 'Sorry, no matches were found for your request. Try changing your search terms.'
                })
            }
        }
    },

    computed: {
        slots () {
            return this.$slots
        }
    }
}
</script>

<style lang="scss">
.z-not-found {
    display: flex;
    align-items: center;
    border-radius: 20px;
    background-color: $token-colors-white;
    padding: $token-spacers-l $token-spacers-xl;

    @include breakpoint(mobile) {
        flex-direction: column;
        align-items: flex-start;
    }

    &__image {
        flex-shrink: 0;
        margin-inline-end: $token-spacers-2-xs;

        @include breakpoint(mobile) {
            margin-inline-end: 0;
            margin-bottom: $token-spacers-xs;
        }
    }

    &__title {
        display: block;
        color: $token-colors-black;
        font-weight: 600;
        @include typo-level(XL);
        margin-bottom: $token-spacers-2-xs;
        line-height: 1.4;
    }

    &__text {
        @include typo-level(S);
        line-height: 1.4;
        margin-bottom: 0;
    }

}
</style>
