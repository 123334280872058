/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/mp3': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" d="M8 11a8 8 0 018-8h13l12 12v21a8 8 0 01-8 8H16a8 8 0 01-8-8V11z" _fill="#C6C9D6"/><path pid="1" d="M19.896 33v-5.592l-2.184 4.164h-.924l-2.184-4.164V33H12.95v-8.52h1.776l2.52 4.848 2.543-4.848h1.764V33h-1.655zm3.435 0v-8.52h3.612c.392 0 .752.08 1.08.24.328.16.612.376.852.648.248.272.44.576.576.912.136.336.204.68.204 1.032 0 .48-.112.936-.336 1.368a2.875 2.875 0 01-.924 1.056c-.392.272-.856.408-1.392.408h-2.016V33h-1.656zm1.656-4.308h1.92c.2 0 .38-.056.54-.168.16-.112.288-.272.384-.48.096-.216.144-.46.144-.732 0-.296-.056-.548-.168-.756a1.093 1.093 0 00-.42-.468 1.029 1.029 0 00-.552-.156h-1.848v2.76zm8.197 4.392a4.36 4.36 0 01-1.248-.168 3.2 3.2 0 01-.972-.504 2.567 2.567 0 01-.648-.792l.864-1.092c.08.176.208.348.384.516.176.168.396.304.66.408.264.104.564.156.9.156.328 0 .612-.048.852-.144.24-.096.424-.228.552-.396a1.01 1.01 0 00.204-.636.96.96 0 00-.252-.672c-.16-.184-.4-.324-.72-.42-.32-.096-.712-.144-1.176-.144h-.396v-1.2h.42c.576 0 1.036-.1 1.38-.3.352-.2.528-.484.528-.852a.933.933 0 00-.18-.576 1.059 1.059 0 00-.48-.372 1.62 1.62 0 00-.66-.132c-.4 0-.756.096-1.068.288a1.877 1.877 0 00-.696.72l-1.044-1.164a2.18 2.18 0 01.72-.648 4.037 4.037 0 011.02-.432c.384-.104.78-.156 1.188-.156.568 0 1.072.1 1.512.3.44.192.784.456 1.032.792s.372.72.372 1.152c0 .32-.068.612-.204.876a1.76 1.76 0 01-.552.66c-.24.176-.524.304-.852.384.352.056.66.188.924.396.272.2.48.456.624.768.152.304.228.636.228.996 0 .504-.144.932-.432 1.284-.28.352-.664.624-1.152.816-.48.192-1.024.288-1.632.288z" _fill="#4A5D7F"/><path pid="2" d="M29 3l12 12h-8a4 4 0 01-4-4V3z" _fill="#EFF0F3"/>'
  }
})
