<template>
    <div v-if="data.length" class="news-list">
        <template v-if="isMain">
            <div v-if="['mobile', 'v-tablet'].includes(device)" class="news-list__row news-list__row--mobile">
                <template v-for="(item, index) in data">
                    <news-card :is-main="isMain" theme="secondary" :item="item" :key="`news-card-${index}`"/>
                </template>
            </div>
            <template v-else>
                <div class="news-list__row news-list__row--second">
                    <template v-for="(item, index) in formattedData.firstData">
                        <news-card :is-main="isMain" :item="item" :key="`news-card-first-${index}`"/>
                    </template>
                </div>
                <div class="news-list__row news-list__row--second">
                    <template v-for="(item, index) in formattedData.secondData">
                        <news-card :is-main="isMain" :item="item" :key="`news-card-second-${index}`"/>
                    </template>
                </div>
            </template>
        </template>
        <template v-else v-for="(item, index) in data">
            <news-card :is-main="isMain" :item="item" :key="`news-card-${index}`"/>
        </template>
    </div>
</template>

<script>
import {mixinDevice} from '../../utils/mixin'

export default {
    name: 'news-list',
    props: {
        isMain: {
            type: Boolean,
            default: false
        },
        data: {
            type: Array,
            required: true
        }
    },
    mixins: [mixinDevice],
    computed: {
        formattedData () {
            if (!(this.isMain)) {
                return
            }
            const data = this.data.slice()
            const cols = 12
            let result = 0
            const formattedData = {
                firstData: [],
                secondData: []
            }
            const indexes = []
            data.forEach((item, index) => {
                let itemCount = 0
                if (item.showOnMainPicture?.value) {
                    itemCount = 6
                } else {
                    itemCount = 3
                }

                if (this.device === 'tablet') {
                    if (item.showOnMainPicture?.value) {
                        itemCount = 8
                    } else {
                        itemCount = 4
                    }
                }

                if (result + itemCount <= cols) {
                    result += itemCount
                    formattedData.firstData.push(item)
                    indexes.push(index)
                }
            })

            result = 0

            data.filter((item, index) => !(indexes.includes(index))).forEach((item) => {
                let itemCount = 0
                if (item.showOnMainPicture?.value) {
                    itemCount = 6
                } else {
                    itemCount = 3
                }

                if (this.device === 'tablet') {
                    if (item.showOnMainPicture?.value) {
                        itemCount = 8
                    } else {
                        itemCount = 4
                    }
                }

                if (result + itemCount <= cols) {
                    result += itemCount
                    formattedData.secondData.push(item)
                }
            })

            return formattedData
        }
    }
}
</script>

<style lang="scss">
.news-list {
    display: flex;
    flex-wrap: wrap;
    margin-top: -$token-spacers-3-xs;
    margin-left: -4px;
    margin-right: -4px;

    &__row {
        width: 100%;
        display: flex;
        flex-wrap: wrap;

        &--second {
            align-items: flex-start;
        }

        &--mobile {
            flex-wrap: nowrap;
            overflow-x: scroll;
            padding-bottom: $token-spacers-xs;

            &::-webkit-scrollbar {
                height: 5px;
                background: $token-colors-bg-10;
            }

            &::-webkit-scrollbar-thumb {
                background: $token-colors-primary;
            }
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        margin-bottom: $token-spacers-m;
    }

    &__title {
        font-weight: 700;
        // // font-size: 36px;
        font-size: var(--fontSize4xl);
        line-height: 1.2;
        margin: 0;
    }
}
</style>
