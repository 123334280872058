<template>
    <component
        :is="tag"
        class="z-list"
        :class="buildClass"
    >
        <slot />
    </component>
</template>

<script>
export default {
    name: 'z-list',
    props: {
        size: {
            type: String,
            default: 'm',
            validator: prop => ['xl', 'l', 'm', 's'].includes(prop)
        },
        tag: {
            type: String,
            default: 'ul',
            validator: prop => ['ul', 'ol'].includes(prop)
        },
        bordered: {
            type: Boolean,
            default: false
        },
        resetCounter: { // обнуляет счетчик (нужно для вложенных списков)
            type: Boolean,
            default: false
        },
        icon: {
            type: String
        },
        theme: {
            type: String,
            validator: prop => ['dash', 'dot', 'square'].includes(prop),
            default: 'dot'
        }
    },
    computed: {
        buildClass () {
            return [
                `z-list--${this.tag}`,
                `z-list--size-${this.size}`,
                { 'z-list--bordered': this.bordered },
                { 'z-list--reset-counter': this.resetCounter },
                { [`z-list--theme-${this.theme}`]: this.theme && !this.icon && this.tag === 'ul' }
            ]
        }
    }
}

</script>

<style lang="scss" src="./index.scss"></style>
