<template>
    <section class="section-vk">
        <z-link target="_blank" href="https://vk.com/university.nornik">
            <div class="container">
                <div class="section-vk__container">
                    <div class="section-vk__content u-gap--xs">
                        <tag class="section-vk__tag" data-scroll-translate>#универНорникель в ВК</tag>
                        <h2 class="section-vk__title u-semi-bold">
                            Первыми узнавайте <span>свежие новости</span>&#160;КУ и&#160;получайте <span>полезные заметки</span> для самообразования
                        </h2>
                    </div>
                </div>
            </div>
        </z-link>
    </section>
</template>

<script>
import Tag from '../../components/Tag.vue'

export default {
    name: 'section-vk',
    components: {
        Tag
    }
}
</script>

<style lang="scss">
@import './index.scss';
</style>
