<template>
    <div class="employees-detail">
        <z-link
            v-if="data.nav['back-link']"
            class="employees-detail__back-link"
            size="m"
            iconPosition="left"
            icon="long_arrow-horizontal"
            :href="`${data.nav['back-link']}#tabs-${data.show.xmlId}`"
            :underlined="false"
        >
            К&nbsp;списку сотрудников Учебного отдела
        </z-link>
        <div class="employees-detail__head">
            <div
                class="employees-detail__image"
                alt="Изображение"
                :style="`background-image: url(${data.previewPicture.link || '/images/biographies/imageholder.svg' })`"
            >
            </div>
            <div class="employees-detail__person">
                <div class="employees-detail__name" v-html="data.name"></div>
                <div class="employees-detail__position">
                    <span
                        v-for="(position, indexPosition) in data.position"
                        :key="`${indexPosition} + p`"
                        v-html="position.value"
                    />
                </div>
                <div class="employee-contacts" v-if="data.email && data.email.length || data.phone && data.phone.length || data.cabinet && data.cabinet.length || data.address && data.address.length">
                    <div class="employee-contacts__email" v-if="data.email && data.email.length">
                        <z-icon name="envelope" color="#626C84"/>
                        <span class="employee-contacts__prop">Email:</span>
                        <z-link
                            v-for="(email, indexEmail) in data.email"
                            :key="`${indexEmail} + e`"
                            :href="`mailto:${email.value}`"
                            class="employee-contacts__value"
                            v-html="email.value"
                        >
                        </z-link>
                    </div>
                    <div class="employee-contacts__phone" v-if="data.phone && data.phone.length">
                        <z-icon name="mobile" color="#626C84"/>
                        <span class="employee-contacts__prop">Телефон:</span>
                        <z-link
                            v-for="(phone, indexPhone) in data.phone"
                            :key="`${indexPhone} + ph`"
                            :href="`tel:${phone.value}`"
                            class="employee-contacts__value"
                            v-html="phone.description"
                        >
                        </z-link>
                    </div>
                    <div class="employee-contacts__address" v-if="data.address && data.address.length">
                        <z-icon name="mappin" color="#626C84"/>
                        <span class="employee-contacts__prop">Адрес:</span>
                        <span
                            v-for="(address, indexAddress) in data.address"
                            :key="`${indexAddress} + a`"
                            class="employee-contacts__value"
                            v-html="address.value"
                        ></span>
                    </div>
                    <div class="employee-contacts__cabinet" v-if="data.cabinet && data.cabinet.length">
                        <z-icon name="room" color="#626C84"/>
                        <span class="employee-contacts__prop">Кабинет:</span>
                        <span
                            v-for="(cabinet, indexCabinet) in data.cabinet"
                            :key="`${indexCabinet} + c`"
                            class="employee-contacts__value"
                            v-html="cabinet.value"
                        ></span>
                    </div>
                </div>
            </div>
        </div>
        <div class="employees-detail__content">
            <div class="employee-content__item" v-if="data.disciplines && data.disciplines.value !=''">
                <z-caption tag="h2" size="2xl" weight="700">Преподаваемые дисциплины</z-caption>
                <div class="employee-disciplines">
                    <vue-raw class="html-content" :raw="data.disciplines.value" />
                </div>
            </div>
            <div class="employee-content__item" v-if="data.specialties && data.specialties.value != ''">
                <z-caption tag="h2" size="2xl" weight="700" class="u-bottom-margin--s-important">Наименование направления подготовки и\или специальности</z-caption>
                <div class="employee-specialties">
                    <vue-raw class="html-content" :raw="data.specialties.value" />
                </div>
            </div>
            <div class="employee-content__item" v-if="data.qualifications && data.qualifications.length">
                <z-caption tag="h2" size="2xl" weight="700" class="u-bottom-margin--s-important">Данные о&nbsp;повышении квалификации и&nbsp;(или) профессиональной переподготовке</z-caption>
                <div class="employee-qualifications">
                    <div
                        class="employee-qualifications__item"
                        v-for="(item, index) in data.qualifications"
                        :key="index"
                    >
                        <div class="employee-qualifications__value" v-html="item.description"></div>
                        <vue-raw class="html-content employee-qualifications__description" :raw="item.value" />
                    </div>
                </div>
            </div>
        </div>
        <page-changer
            v-if="data.nav.prev || data.nav.next"
            :data="{
                next: {
                    name: '',
                    url: data.nav.next ?  data.nav.next.url : ''
                },
                prev: {
                    name: '',
                    url: data.nav.prev ? data.nav.prev.url : ''
                },
                ['back-link']: `${data.nav['back-link']}#tabs-${data.show.xmlId}`
            }"
        />
    </div>
</template>

<script>

export default {
    name: 'employee-detail',
    data () {
        return {
            data: this.$root.app.components['governance-detail']
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
