/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/rtf': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" d="M8 11a8 8 0 018-8h13l12 12v21a8 8 0 01-8 8H16a8 8 0 01-8-8V11z" _fill="#C6C9D6"/><path pid="1" d="M14.284 33v-8.52h3.768c.393 0 .753.08 1.08.24.336.16.624.376.864.648.248.272.437.576.565.912.136.336.204.68.204 1.032 0 .36-.064.708-.192 1.044-.12.328-.297.616-.529.864-.232.248-.503.44-.816.576L21.172 33H19.35l-1.753-2.856h-1.655V33h-1.657zm1.656-4.308h2.077c.207 0 .392-.06.552-.18.16-.128.288-.296.384-.504.096-.208.143-.44.143-.696 0-.272-.055-.508-.168-.708a1.365 1.365 0 00-.431-.492.93.93 0 00-.552-.18H15.94v2.76zm12.603-2.76H25.82V33h-1.644v-7.068h-2.724V24.48h7.092v1.452zM29.601 33v-8.52h5.724v1.452h-4.068v2.196h3.384v1.344h-3.384V33H29.6z" _fill="#4A5D7F"/><path pid="2" d="M29 3l12 12h-8a4 4 0 01-4-4V3z" _fill="#EFF0F3"/>'
  }
})
