<template>
    <div class="calendar-block">
        <div ref="datepicker" class="calendar-block__datepicker"></div>
    </div>
</template>

<script>
import AirDatepicker from 'air-datepicker'
import localeEn from 'air-datepicker/locale/en'
import moment from 'moment'

export default {
    name: 'calendar-block',
    methods: {
        init () {
            const datepicker = this.$refs.datepicker
            let options = {
                inline: true,
                showOtherMonths: false,
                onRenderCell: this.generateCellContent
            }

            if (this.$root.lang === 'en') options.locale = localeEn

            this.datepicker = new AirDatepicker(datepicker, options)
        },
        generateCellContent ({date, cellType}) {
            const startOfWeek = moment().startOf('week')
            const endOfWeek = moment().endOf('week')
            const cellDate = moment(date)
            const cellWeekDay = moment(date).weekday() + 1

            let className = ''

            if (cellDate.isAfter(startOfWeek) && cellDate.isBefore(endOfWeek)) className = 'in-range'
            if (cellDate.format('DD') === startOfWeek.format('DD') && cellWeekDay === 1) className = 'is-start'
            if (cellDate.format('DD') === endOfWeek.format('DD') && cellWeekDay === 7) className = 'is-end'

            const template = `
                <div class="cell-content ${className}">
                    <span class="cell-content__date">${cellDate.format('DD')}</span>
                </div>
            `

            return {
                html: template
            }
        }
    },
    mounted () {
        this.init()
    }

}
</script>

<style lang="scss">
.calendar-block {
    .cell-content {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: var(--fontSizeXs);
        font-weight: 600;
        color: #999999;
        position: absolute;
        top: 0;
        left: 0;

        &.is-start {
            background: $token-colors-bg-10;
            color: $token-colors-black-90;
            border-radius: 50%;

            &:after {
                content: '';
                width: 50% !important;
                height: 100%;
                right: 0;
                left: auto !important;
                top: 0;
                position: absolute;
                display: block;
                background: $token-colors-bg-10;
                z-index: -1;
            }
        }

        &.is-end {
            background: $token-colors-bg-10;
            color: $token-colors-black-90;
            border-radius: 50%;

            &:after {
                content: '';
                width: 50% !important;
                height: 100%;
                left: 0;
                top: 0;
                position: absolute;
                display: block;
                background: $token-colors-bg-10;
                z-index: -1;
            }
        }

        &.in-range {
            background: $token-colors-bg-10;
            color: $token-colors-black-90;
        }
    }

    .air-datepicker-cell {
        pointer-events: none;
        min-height: auto;
        height: 0;
        padding-top: 100%;
        position: relative;
    }

    .air-datepicker-nav--action {
        display: none;
    }

    .air-datepicker-cell.-current- {
        .cell-content {
            border-radius: 50%;
            background: $token-colors-link-active;
            color: $token-colors-white;

            &:after {
                content: '';
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                position: absolute;
                display: block;
                background: $token-colors-bg-10;
                z-index: -1;
            }
        }
    }

    .air-datepicker-nav {
        display: flex;
        justify-content: center;
        padding: 0;
        border: none;
        min-height: auto;
        margin-bottom: 24px;
    }

    .air-datepicker-nav--title {
        font-size: var(--fontSizeS);
        color: #000;
        pointer-events: none;
        font-weight: 500;
        line-height: 1;
        display: block;
        padding: 0;
    }

    .air-datepicker-nav--title i {
        color: inherit;
    }

    .air-datepicker.-inline- {
        border: none;
        background: none;
        max-width: 100%;
        margin: 0 auto;
    }

    .air-datepicker-body--day-name {
        font-weight: 500;
        font-size: var(--fontSizeXs);
        color: #808080;
    }

    .air-datepicker-body--day-names {
        margin-bottom: 16px;
    }

    .air-datepicker-body--cells.-days- {
        grid-row-gap: 8px;
    }
}
</style>
