<template>
    <div class="schedule-filter">
        <div class="schedule-filter__search">
            <z-input
                is-filter
                name="query"
                class="schedule-filter__search-input"
                placeholder="Ищите по куратору, номеру группы, форме обучения или наименованию программы"
                alt="Ищите по куратору, номеру группы, форме обучения или наименованию программы"
                v-model="params.query"
                @input="onInput"
            />
        </div>
        <div class="schedule-filter__container">
            <div class="schedule-filter__row">
                <div class="schedule-filter__field">
                    <z-tooltip placement="top-start" content="ОПП&nbsp;&mdash; Отдел организации профессиональной подготовки <br> ОУП&nbsp;&mdash; Отдел организации управленческой подготовки">
                        <span class="schedule-filter__placeholder u-right-margin--3xs">Форма обучения</span>
                    </z-tooltip>
                    <z-select
                        is-filter
                        :disabled="!(filterData.studyForm && filterData.studyForm.length)"
                        :data="filterData.studyForm"
                        v-model="params.studyForm"
                        placeholder="Все"
                        alt="Форма обучения"
                        class="schedule-filter__form"
                        @change="onChange"
                    ></z-select>
                </div>
                <div class="schedule-filter__field">
                    <span class="schedule-filter__placeholder">Город</span>
                    <z-select
                        is-filter
                        :disabled="!(filterData.city && filterData.city.length)"
                        :data="filterData.city"
                        v-model="params.city"
                        placeholder="Все"
                        alt="Город"
                        class="schedule-filter__city"
                        @change="onChange"
                    ></z-select>
                </div>
                <div class="schedule-filter__field">
                    <span class="schedule-filter__placeholder">Куратор</span>
                    <z-select
                        is-filter
                        :disabled="!(filterData.curator && filterData.curator.length)"
                        :data="filterData.curator"
                        v-model="params.curator"
                        placeholder="Все"
                        alt="Куратор"
                        class="schedule-filter__name"
                        @change="onChange"
                    ></z-select>
                </div>
                <div class="schedule-filter__field">
                    <span class="schedule-filter__placeholder">Группа</span>
                    <z-select
                        is-filter
                        :disabled="!(filterData.group && filterData.group.length)"
                        :data="filterData.group"
                        v-model="params.group"
                        placeholder="Все"
                        alt="Группа"
                        @change="onChange"
                    ></z-select>
                </div>
                <div class="schedule-filter__buttons">
                    <button
                        @click="toggleFilterState"
                        class="schedule-filter__button schedule-filter__button--trigger"
                        :class="{ 'is-acitve': isFilterOpen }"
                        :disabled="!(filterData.studyFormat && filterData.studyFormat.length) && !(filterData.course && filterData.course.length)"
                    >
                        <z-icon
                            name="settings"
                            width="24"
                            height="22"
                        />
                        <z-icon
                            name="arrow_slider"
                            width="16"
                            height="16"
                            class="schedule-filter__button-arrow"
                        />
                    </button>
                    <button
                        @click="onClear"
                        class="schedule-filter__button"
                        :disabled="isClearButtonDisabled"
                    >
                        <z-icon
                            name="refresh"
                            width="16"
                            height="24"
                        />
                    </button>
                </div>
            </div>
            <div
                class="schedule-filter__row"
                v-show="isFilterOpen"
            >
                <div class="schedule-filter__field schedule-filter__field--format">
                    <span class="schedule-filter__placeholder">Формат</span>
                    <z-select
                        is-filter
                        :disabled="!(filterData.studyFormat && filterData.studyFormat.length)"
                        :data="filterData.studyFormat"
                        v-model="params.studyFormat"
                        placeholder="Все"
                        class="schedule-filter__format"
                        @change="onChange"
                    ></z-select>
                </div>
                <div class="schedule-filter__field  schedule-filter__field--name">
                    <span class="schedule-filter__placeholder">Наименование курса</span>
                    <z-select
                        is-filter
                        :disabled="!(filterData.course && filterData.course.length)"
                        class="schedule-filter__name"
                        :data="filterData.course"
                        v-model="params.course"
                        placeholder="Все"
                        @change="onChange"
                    ></z-select>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { throttle } from 'throttle-debounce'
import { queryString } from '@/utils/queryString'

export default {
    name: 'schedule-filter',
    props: {
        data: {
            type: Object,
            default: () => ({
                city: [],
                curator: [],
                group: [],
                course: [],
                studyForm: [],
                studyFormat: []
            })
        },
        initialState: Object
    },
    computed: {
        filterData () {
            return this.data || {}
        },
        isClearButtonDisabled () {
            return (
                !(this.filterData.studyFormat && this.filterData.studyFormat.length) &&
                !(this.filterData.course && this.filterData.course.length) &&
                !(this.filterData.group && this.filterData.group.length) &&
                !(this.filterData.curator && this.filterData.curator.length) &&
                !(this.filterData.city && this.filterData.city.length) &&
                !(this.filterData.studyForm && this.filterData.studyForm.length)
            )
        }
    },
    data () {
        return {
            params: {
                query: '',
                studyForm: '',
                studyFormat: '',
                course: '',
                city: '',
                curator: '',
                group: ''
            },
            isFilterOpen: false
        }
    },
    methods: {
        toggleFilterState () {
            this.isFilterOpen = !this.isFilterOpen
        },
        clear () {
            this.params = {}
            this.params = {
                query: '',
                studyForm: '',
                studyFormat: '',
                course: '',
                city: '',
                curator: '',
                group: ''
            }
        },
        onClear () {
            this.clear()
            this.send()
        },
        onChange () {
            this.send()
        },
        onInput: throttle(500, function () {
            this.send()
        }),
        send () {
            queryString(this.clearEmptyParams(this.params))
            this.$emit('change', this.params)
        },
        getParams (url = window.location) {
            let params = {}

            new URL(url).searchParams.forEach((val, key) => {
                params[key] = this.sanitizeValue(val)
            })

            this.params = { ...this.params, ...params }
        },
        sanitizeValue (text) {
            return text
                // htmlspecialchars
                .replace(/&/g, '&amp;')
                .replace(/</g, '&lt;')
                .replace(/>/g, '&gt;')
                .replace(/"/g, '&quot;')
                .replace(/'/g, '&#039;')
                // addslashes
                .replace(/\\/g, '\\\\')
                // eslint-disable-next-line
                .replace(/\u0008/g, '\\b')
                .replace(/\t/g, '\\t')
                .replace(/\n/g, '\\n')
                .replace(/\f/g, '\\f')
                .replace(/\r/g, '\\r')
                .replace(/'/g, '\\\'')
                .replace(/"/g, '\\"')
        },
        clearEmptyParams (obj) {
            let params = Object.assign({}, obj)

            Object.keys(params).forEach(function (key, index) {
                if (Array.isArray(params[key])) {
                    if (!(params[key].filter(val => { return val !== null && val !== undefined && val !== '' && val !== false }).length)) delete params[key]
                } else if (!params[key]) {
                    delete params[key]
                }
            })

            return params
        }
    },
    mounted () {
        this.getParams()
    }
}
</script>
<style lang="scss">
.schedule-filter {
    &__placeholder {
        font-weight: 400;
        // font-size: 13px;
        font-size: var(--fontSizeXs);
        line-height: 1.3;
        color: $token-colors-form-placeholder-color-default;
    }

    &__row {
        display: flex;
        margin-right: -2px;
        margin-left: -2px;
        flex-wrap: wrap;
        margin-top: -$token-spacers-3-xs;
    }

    &__buttons,
    &__field {
        margin-top: $token-spacers-3-xs;
    }

    &__buttons {
        flex-shrink: 0;
        display: flex;
        align-self: flex-end;
        justify-content: flex-end;
        margin-left: auto;
    }

    &__field {
        width: 15%;
        flex-grow: 1;
        padding: 0 2px;

        @include breakpoint (tablet) {
            width: 33.33%;
        }

        @include breakpoint (v-tablet) {
            width: 50%;
        }

        @include breakpoint (mobile) {
            width: 100%;
        }

        &--name {
            width: 75%;

            @include breakpoint (tablet) {
                width: 66.66%;
            }

            @include breakpoint (v-tablet) {
                width: 100%;
            }
        }
    }

    &__button {
        border-radius: var(--borderRadius);
        border: var(--formBorderWidth) solid var(--formBorderColorDefault);
        height: var(--fieldHeightM);
        background: none;
        margin: 0 2px;
        min-width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        transition: border-color 0.2s ease-in;
        flex-shrink: 0;
        background-color: var(--fieldBg);

        &:hover {
            border-color: var(--formBorderColorHover);
        }

        &[disabled] {
            color: $token-colors-black-30;
            border-color: $token-colors-black-30;
            background-color: transparent;
        }
    }

    &__button-arrow {
        transform: scale(1, -1);
        margin-left: 10px;

        path {
            fill: var(--formBorderColorDefault);
        }
    }

    &__button--trigger.is-acitve {
        border-color: var(--formBorderColorFilledAccent);

        .schedule-filter__button-arrow {
            transform: scale(1, 1);
        }
    }

    .z-select.is-filled .z-select__placeholder {
        display: none;
    }

    &__search {
        border-radius: 30px 0px 0px 0px;
        @include padding-level(top, S);
        @include padding-level(bottom, S);
        background: #DEEDF7;
        @include breakpoint (v-tablet) {
            width: 100%;
        }

        @include padding-level(right, 2XL);
        padding-left: $token-grids-desktop-offset;

        @include breakpoint(laptop) {
            padding-left: $token-grids-laptop-offset;
        }

        @include breakpoint(tablet) {
            padding-left: $token-grids-tablet-offset;
        }

        @include breakpoint(v-tablet) {
            padding-left: $token-grids-v-tablet-offset;
            padding-right: $token-grids-v-tablet-offset;
        }

        @include breakpoint(mobile) {
            padding-left: $token-grids-mobile-offset;
            padding-right: $token-grids-mobile-offset;
        }
    }

    &__container {
        @include padding-level(top, S);
        @include padding-level(right, 2XL);
        padding-left: $token-grids-desktop-offset;

        @include breakpoint(laptop) {
            padding-left: $token-grids-laptop-offset;
        }

        @include breakpoint(tablet) {
            padding-left: $token-grids-tablet-offset;
        }

        @include breakpoint(v-tablet) {
            padding-left: $token-grids-v-tablet-offset;
            padding-right: $token-grids-v-tablet-offset;
        }

        @include breakpoint(mobile) {
            padding-left: $token-grids-mobile-offset;
            padding-right: $token-grids-mobile-offset;
        }
    }

    &__search-input {
        position: relative;

        .z-input__input {
            padding-right: 70px !important;
        }

        .z-input__placeholder {
            max-width: calc(100% - 16px - 70px);
        }

        &.is-focused .z-input__placeholder,
        &.is-filled .z-input__placeholder {
            display: none;
        }

        .z-input__clear {
            right: 40px;
        }

        &:after {
            content: '';
            background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13 19C13 15.6863 15.6863 13 19 13C22.3137 13 25 15.6863 25 19C25 22.3137 22.3137 25 19 25C15.6863 25 13 22.3137 13 19ZM19 11C14.5817 11 11 14.5817 11 19C11 23.4183 14.5817 27 19 27C20.9036 27 22.652 26.3351 24.0255 25.2249C24.0661 25.4016 24.1552 25.5694 24.2929 25.7071L27.2929 28.7071C27.6834 29.0976 28.3166 29.0976 28.7071 28.7071C29.0976 28.3166 29.0976 27.6834 28.7071 27.2929L25.7071 24.2929C25.5694 24.1552 25.4016 24.0661 25.2249 24.0255C26.3351 22.652 27 20.9036 27 19C27 14.5817 23.4183 11 19 11Z' fill='%230078CE'/%3E%3C/svg%3E%0A");
            background-size: contain;
            background-repeat: no-repeat;
            width: 40px;
            height: 40px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 4px;
        }
    }
}
</style>
