<template>
    <div class="main-page" ref="mainPage">
        <section-hero />
        <section-about data-scroll-section data-expander-scroll-trigger />
        <section-statistics data-scroll-section />
        <section-events data-scroll-section />
        <section-news data-scroll-section />
        <section-vk data-scroll-section />
        <section-history data-scroll-section />
    </div>
</template>

<script>
import SectionHero from './sections/section-hero/SectionHero.vue'
import SectionAbout from './sections/section-about/SectionAbout.vue'
import SectionStatistics from './sections/section-statistics/SectionStatistics.vue'
import SectionEvents from './sections/section-events/SectionEvents.vue'
import SectionNews from './sections/section-news/SectionNews.vue'
import SectionVk from './sections/section-vk/SectionVk.vue'
import SectionHistory from './sections/section-history/SectionHistory.vue'

import SectionAnimation from './modules/section-animation'

export default {
    name: 'main-page',
    components: {
        SectionHero,
        SectionAbout,
        SectionStatistics,
        SectionEvents,
        SectionNews,
        SectionVk,
        SectionHistory
    },
    mounted () {
        const sections = this.$refs.mainPage.querySelectorAll('[data-scroll-section]')
        sections.forEach((section) => {
            this.animation = new SectionAnimation(section)
        })
    }
}
</script>

<style lang="scss">
@import './index.scss';
</style>
