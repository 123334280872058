<template>
    <footer class="footer">
        <div class="container">
            <div class="footer__wrapper">
                <div class="footer-main">
                    <div class="row">
                        <div class="col-default-2 col-tablet-3 col-v-tablet-5 col-mobile-12">
                            <div class="footer__logo">
                                <z-logo site-dir="/" :is-index="isIndexPage" />
                            </div>
                        </div>
                        <div
                            v-if="contactData && contactData.address && contactData.address.value"
                            class="col-default-3 col-default-offset-1 col-tablet-offset-0 col-tablet-5 col-v-tablet-7 col-mobile-12"
                        >
                            <div class="footer__info-wrapper footer__info-wrapper--address">
                                <span class="footer__title">Адрес:</span>
                                <span
                                    class="footer__text u-bottom-margin--3xs"
                                    v-html="contactData.address.value"
                                ></span>
                                <z-link
                                    href="https://yandex.ru/maps/11311/norilsk/?ll=88.214408%2C69.350209&mode=routes&rtext=~69.350209%2C88.214408&rtt=auto&ruri=~ymapsbm1%3A%2F%2Fgeo%3Fdata%3DCgoxNTAxMjU0Njc5EmbQoNC%2B0YHRgdC40Y8sINCa0YDQsNGB0L3QvtGP0YDRgdC60LjQuSDQutGA0LDQuSwg0J3QvtGA0LjQu9GM0YHQuiwg0KLQsNC70L3QsNGF0YHQutCw0Y8g0YPQu9C40YbQsCwgMzEiCg3HbbBCFU6zikI%3D&utm_source=share&z=17"
                                    external
                                    :underlined="false"
                                    target="_blank"
                                    size="s"
                                    class="u-semi-bold"
                                >Смотреть на карте</z-link>
                            </div>
                        </div>
                        <template v-if="contactData">
                            <div
                                v-if="contactData.schedule && contactData.schedule.length"
                                class="col-default-5 col-default-offset-1 col-desktop-6 col-desktop-offset-0 col-tablet-4 col-v-tablet-7 col-v-tablet-offset-5 col-mobile-12 col-mobile-offset-0"
                            >
                                <div class="row">
                                    <div class="col-default-5 col-desktop-6 col-tablet-12">
                                        <div class="footer__info-wrapper footer__info-wrapper--schedule">
                                            <span class="footer__title">Время работы:</span>
                                            <span
                                                class="footer__text"
                                                v-for="(item, index) in contactData.schedule"
                                                :key="index"
                                                v-html="formatText(item.value)"
                                            ></span>
                                        </div>
                                    </div>
                                    <div
                                        v-if="contactData.phone && contactData.phone.length || contactData.email && contactData.email.length"
                                        class="col-default-7 col-desktop-6 col-tablet-12"
                                    >
                                        <div class="footer__info-wrapper footer__info-wrapper--contact">
                                            <z-link
                                                :href="`tel:${contactData.phone[0].value}`"
                                                size="m"
                                                theme="dark"
                                                :underlined="false"
                                                class="footer__contact-link"
                                            >
                                                <span class="u-font-mono" v-html="contactData.phone[0].description"></span>
                                            </z-link>
                                            <z-link
                                                :href="`mailto:${contactData.email[0].value}`"
                                                size="m"
                                                theme="dark"
                                                :underlined="false"
                                                class="footer__contact-link"
                                                v-html="contactData.email[0].value"
                                            ></z-link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </template>
                    </div>
                    <z-button
                        kind="bordered"
                        class="footer__scroll-btn"
                        @click="scrolllUp"
                    >
                        <z-icon name="arrow_up40" />
                    </z-button>
                </div>
                <div class="footer-additional u-bottom-margin--s">
                    <div class="row">
                        <div class="col-default-2 col-v-tablet-12">
                            <div class="footer-additional__social footer-social">
                                <z-link
                                    href="https://vk.com/university.nornik"
                                    class="footer-social__item"
                                    :underlined="false"
                                    target="_blank"
                                >
                                    <z-icon name="vk" width="32" height="32" />
                                </z-link>
                                <z-link
                                    href="https://youtube.com/@university.nornik"
                                    class="footer-social__item"
                                    :underlined="false"
                                    target="_blank"
                                >
                                    <z-icon name="youtube" width="32" height="32" />
                                </z-link>
                            </div>
                        </div>
                        <div class="col-default-6 col-default-offset-1 col-desktop-5 col-tablet-offset-1 col-v-tablet-10 col-v-tablet-offset-0 col-mobile-12">
                            <navigation-bottom :data="footerNavList" columns="3" />
                        </div>
                        <div class="col-default-3 col-desktop-offset-1 col-tablet-4 col-tablet-offset-0 col-v-tablet-12 additional-nav">
                            <navigation-bottom :data="[
                                {
                                    name: 'Пользовательское соглашение',
                                    link: '/terms/'
                                },
                                {
                                    name: 'Политика конфиденциальности',
                                    link: '/privacy-policy/'
                                },
                                {
                                    name: 'Сведения об образовательной организации',
                                    link: '/sveden/'
                                }
                            ]"/>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-default-6 col-tablet-12 footer__copyright">
                        <z-copyright
                            year="1938"
                            year-current
                            text-after="ЧОУ ДПО «Корпоративный университет «Норильский никель»"
                        ></z-copyright>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
import NavigationBottom from '@/components/NavigationBottom'

export default {
    name: 'footer-layout',
    components: {
        NavigationBottom
    },
    data () {
        return {
            footerNavData: this.$root.app.components.navigation.top,
            contacts: this.$root.app.components['contacts-common']
        }
    },
    computed: {
        footerNavList () {
            if (!this.footerNavData) return []
            return this.footerNavData.concat(this.$root.app.components.navigation.bottom)
        },
        contactData () {
            if (!this.contacts) return
            return this.contacts.norilsk
        },
        isIndexPage () {
            return window.location.pathname === '/'
        }
    },
    methods: {
        scrolllUp () {
            window.scrollTo({
                top: 0,
                behavior: 'smooth'
            })
        },
        formatText (str) {
            return str.replaceAll('-', '&#8209;')
        }
    }
}
</script>

<style lang="scss" scoped>
.footer {
    $parent: &;

    border-top-left-radius: $borderRadius;
    border-top-right-radius: $borderRadius;
    z-index: 2;
    background: $token-colors-white;

    &__logo {
        max-width: 225px;

        @include breakpoint (tablet) {
            max-width: 195px;
        }

        @include breakpoint (mobile) {
            max-width: 165px;
        }
    }

    &-main {
        padding-bottom: 40px;
        position: relative;

        @include breakpoint(laptop) {
            @include padding-level(bottom, M);
        }
    }

    &__title {
        display: block;
        color: $token-colors-black-60;
        font-weight: 600;
        font-size: var(--fontSizeS);
        line-height: 140%;
        margin-bottom: 10px;

        @include breakpoint(laptop) {
            @include margin-level(bottom, 3XS);
        }
    }

    &__text {
        display: block;
        color: $token-colors-black-60;
        font-size: var(--fontSizeM);
        font-weight: 400;
        line-height: 130%;
        position: relative;

        &:after {
            content: ',\00a0';
            display: inline-block;
            position: absolute;
            bottom: 0;
        }

        &:last-of-type {
            &:after {
                display: none;
            }
        }
    }

    &__wrapper {
        display: flex;
        flex-direction: column;
        margin-top: -$borderRadius;
        padding-top: $token-spacers-l;
        padding-bottom: $token-spacers-l;

        @include breakpoint (tablet) {
            padding-top: calc(#{$token-spacers-m} + #{$borderRadius});
            padding-bottom: $token-spacers-m;
        }

        @include breakpoint (v-tablet) {
            padding-top: calc(#{$token-spacers-s} + #{$borderRadius});
            padding-bottom: $token-spacers-s;
        }

        @include breakpoint (mobile) {
            padding-top: calc(#{$token-spacers-xs} + #{$borderRadius});
            padding-bottom: $token-spacers-xs;
        }
    }

    &__copyright {
        @include breakpoint (tablet) {
            order: 1;
        }
    }

    &-additional {
        border-top: 1px solid transparent;
        border-image: linear-gradient(90deg, rgba(46,103,215,0) 0%, rgba(46,103,215,1) 50%, rgba(46,103,215,0) 100%);
        border-image-slice: 1;
        padding-top: 40px;

        @include breakpoint(laptop) {
            @include padding-level(top, M);
        }

        @include breakpoint (v-tablet) {
            padding-top: $token-spacers-xs;
        }

        &__item {
            margin-bottom: $token-spacers-s;

            @include breakpoint (tablet) {
                margin-bottom: $token-spacers-xs;
            }

            @include breakpoint (mobile) {
                margin-bottom: 0;

                & + & {
                    margin-top: $token-spacers-2-xs;
                }
            }

            .z-link {
                font-family: $primaryFont;
                font-weight: 600;
            }
        }

        .footer-social {
            display: flex;
            gap: $token-spacers-2-xs 40px;

            &__item {
                @include breakpoint (v-tablet) {

                    .z-icon {
                        width: 42px !important;
                        height: 42px !important;
                    }
                }
            }
        }
    }

    &__info-wrapper {

        &--address {
            @include breakpoint (v-tablet) {
                padding-right: 64px;
            }

            @include breakpoint (mobile) {
                padding-right: 0;
            }
        }

        &--schedule {
            @include breakpoint (tablet) {
                padding-right: 64px;
            }

            @include breakpoint (v-tablet) {
                padding-right: 0;
            }
        }

        &--contact {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            padding-right: 64px;

            @include breakpoint (tablet) {
                padding-right: 0;
            }

            #{$parent}__contact-link {
                line-height: 128%;

                &:first-child {
                    margin-bottom: 10px;

                    @include breakpoint(laptop) {
                        @include margin-level(bottom, 3XS);
                    }
                }
            }
        }
    }

    &__contact-link {
        color: $token-colors-black-60;
    }

    &__scroll-btn {
        width: fit-content;
        background: none;
        border: none;
        padding: 0;
        margin: 0;
        position: absolute;
        top: 0px;
        right: 0px;

        .z-icon {
            width: 48px;
            height: 48px;
        }
    }

    //mobile visual concat hack
    .footer-additional {
        .additional-nav {
            @include breakpoint (mobile) {
                margin-top: $token-spacers-2-xs;
            }
        }
    }
}
</style>
