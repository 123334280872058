import { gsap } from 'gsap'

class ButtonHover {
    constructor (button) {
        if (!button) {
            return
        }
        this.button = button
        this.background1 = this.button.querySelector('[data-button-animation-bg-1]')
        this.background2 = this.button.querySelector('[data-button-animation-bg-2]')

        this.init()
    }

    init () {
        this.setTimelineAnimation()
        this.addEvents()
    }

    addEvents () {
        this.button.addEventListener('mouseenter', () => this.tl.play())
        this.button.addEventListener('mouseleave', () => this.tl.reverse())
    }

    setTimelineAnimation () {
        const backgroundCommonOptions = {
            position: 'absolute',
            zIndex: 0,
            autoAlpha: 0,
            borderRadius: 'inherit',
            margin: '0',
            filter: 'blur(10px)'
        }
        gsap.set(this.background1, {
            ...backgroundCommonOptions,
            bottom: '-100%',
            width: '20%',
            height: '100%',
            backgroundColor: '#1284DD'
        })
        gsap.set(this.background2, {
            ...backgroundCommonOptions,
            backgroundColor: '#00B2FF',
            height: '140%',
            width: '20%',
            bottom: '-140%'
        })

        this.tl = gsap.timeline({
            paused: true,
            defaults: {
                duration: 0.25,
                ease: 'power2.out'
            }
        })
            .fromTo(this.background1, {
                bottom: '-100%',
                autoAlpha: 0,
                width: '20%'
            }, {
                bottom: 0,
                autoAlpha: 1,
                width: '100%'
            })
            .to(this.button, {
                color: '#fff',
                duration: 0.15
            }, '<')
            .fromTo(this.background2, {
                bottom: '-140%',
                autoAlpha: 0,
                width: '20%'
            }, {
                bottom: '-20%',
                autoAlpha: 1,
                width: '110%'
            }, '<+=0.1')
    }
}

export default ButtonHover
