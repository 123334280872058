<template>
    <a
        :href="href"
        class="z-link"
        :class="classObject"
        @click="$emit('click', $event)"
        :download="setDownloadFile() ? downloadTitle : false"
    >
        <z-icon
            v-if="iconName && position === 'left'"
            class="z-link__icon"
            :name="iconName"
        /><span class="z-link__text"><slot/></span><z-icon
            v-if="iconName && position === 'right'"
            class="z-link__icon"
            :name="iconName"
        />
    </a>
</template>

<script>
export default {
    name: 'z-link',
    props: {
        href: String,
        disabled: Boolean,
        size: {
            type: String,
            validator: prop => ['s', 'm', 'l', 'xl'].includes(prop)
        },
        theme: {
            type: String,
            validator: prop => ['dark', 'light', 'boldline', 'bubble', 'bubble-rounded', ''].includes(prop)
        },
        internal: Boolean,
        external: Boolean,
        icon: String,
        iconPosition: {
            type: String,
            validator: prop => ['left', 'right'].includes(prop),
            default: 'left'
        },
        underlined: {
            type: Boolean,
            default: true
        },
        dashed: {
            type: Boolean,
            default: false
        },
        downloadFile: Boolean,
        downloadTitle: {
            type: String,
            default: ''
        },
        inlineFlex: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        setDownloadFile () {
            return Modernizr.adownload && this.downloadFile
        }
    },
    computed: {
        classObject: function () {
            return ({
                'z-link--external': this.external,
                'z-link--internal': this.internal,
                'z-link--disabled': this.disabled,
                'z-link--dashed': this.dashed,
                'z-link--underlined': this.underlined,
                'z-link--left-icon': this.iconName && this.position === 'left',
                'z-link--right-icon': this.iconName && this.position === 'right',
                'z-link--inline-flex': this.inlineFlex,
                [`z-link--theme-${this.theme}`]: this.theme,
                [`z-link--size-${this.size}`]: this.size
            })
        },
        iconName () {
            let icon = this.icon
            if (this.external) icon = 'external'
            if (this.internal) icon = 'internal'

            return icon
        },
        position () {
            let position = this.iconPosition
            // if (this.external) position = 'right' // может быть на каком-либо проекте

            return position
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
