/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow-up-right': {
    width: 18,
    height: 20,
    viewBox: '0 0 18 20',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M13.924 5.617a.997.997 0 00-.215-.322l-.004-.004A.997.997 0 0013 5H6a1 1 0 000 2h4.586l-6.293 6.293a1 1 0 101.414 1.414L12 8.414V13a1 1 0 102 0V6v-.003a.996.996 0 00-.076-.38z" _fill="#3B4C69"/>'
  }
})
