<template>
    <div class="search">
        <div class="search__filters">
            <z-input @keyup.enter.native="searchButtonHandler" v-model="searchValue" placeholder="Введите запрос" name="search-value" is-filter/>
            <z-button :disabled="isLoading || searchValue === ''" @click="searchButtonHandler">Искать</z-button>
        </div>
        <template v-if="!init">
            <div v-if="items && items.length" class="search__content">
                <div class="search__sorting">
                    <span class="search__sorting-text">Сортировка:</span>
                    <div class="search__sorting-wrapper">
                        <button class="search__sorting-button"
                            type="button"
                            :disabled="sortingType === 'r'"
                            @click="sortingHandler('r')"
                        >
                            По релевантности
                        </button>
                        <button class="search__sorting-button"
                            type="button"
                            :disabled="sortingType === 'd'"
                            @click="sortingHandler('d')"
                        >
                            По дате
                        </button>
                    </div>
                </div>
                <div class="search__list u-bottom-margin--m">
                    <div class="search__card" v-for="(item, index) in items" :key="`search-item-${index}`">
                        <z-caption class="search__card-title" :href="item.url" size="xl" weight="600">
                            <span v-html="item.title"/>
                        </z-caption>
                        <p size="text-size-l">
                            <span v-html="item.text"/>
                        </p>
                        <span class="search__card-date text-size-m" v-if="item.date">
                            {{ item.date | momentFilter('DD MMMM YYYY') }}
                        </span>
                    </div>
                </div>
            </div>
            <notification v-else class="u-top-margin--xl search__list">
                <template v-slot:image>
                    <z-image src="/images/not-found/events-nf.svg" width="316" height="266"/>
                </template>
                <template v-slot:description>
                    <z-caption size="m" decor-left decor-right>
                        Ничего не&nbsp;найдено
                    </z-caption>
                    <p class="text-size-xl u-regular">
                        По&nbsp;вашему запросу ничего не&nbsp;найдено, попробуйте изменить
                        формулировку или выполнить поиск по&nbsp;ключевым словам.
                    </p>
                </template>
            </notification>
            <template v-if="isLoading">
                <z-preloader/>
            </template>
            <z-pagination
                v-if="Boolean(data)"
                :show-more="{ marginBottom: 'xs' }"
                show-next
                show-nav
                show-prev
                :data="adaptedNav"
                @change-page="changePagination"
            />
        </template>
        <div v-else class="u-flex--col u-top-margin--3xl u-bottom-margin--4xl">
            <z-image src="/images/not-found/search.svg" width="316" height="266"></z-image>
        </div>
    </div>
</template>

<script>
import { queryString } from '@/utils/queryString'
import { getSearch } from '../../api/search'

export default {
    name: 'search',
    data () {
        return {
            searchValue: '',
            sortingType: 'r',
            init: true,
            isMore: false,
            isLoading: false,
            prevItems: [],
            data: null,
            currentPage: 1,
            items: []
        }
    },
    mounted () {
        this.getInitial()
    },
    computed: {
        adaptedNav () {
            return {
                currentPage: this.data.nav.current,
                totalItems: this.data.nav.count,
                totalPages: this.data.nav.total,
                pageRange: 1
            }
        }
    },
    methods: {
        getData () {
            this.isLoading = true
            const params = {
                page: this.currentPage,
                how: this.sortingType,
                q: this.searchValue
            }
            getSearch(params)
                .then((response) => {
                    this.data = response
                    if (this.isMore) {
                        this.items = [...this.items, ...response.items]
                        this.isMore = false
                        return
                    }
                    this.items = response.items
                })
                .catch((err) => {
                    console.log(err)
                })
                .finally(() => {
                    this.isLoading = false
                    this.init = false
                })
        },
        refreshParams () {
            const params = {}
            this.searchValue && (params.q = this.searchValue)
            this.sortingType && (params.how = this.sortingType)
            this.currentPage && (params.currentPage = this.currentPage)
            queryString(params)
        },

        getInitial () {
            const params = new URL(document.location).searchParams
            const init = {
                searchValue: params.get('q') ? params.get('q') : '',
                sortingType: params.get('how') ? params.get('how') : 'r'
            }
            this.searchValue = this.sanitizeValue(init.searchValue)
            this.sortingType = init.sortingType
            this.currentPage = params.get('currentPage') ? Number(this.sanitizeValue(params.get('currentPage'))) : 1
            if (this.searchValue !== '') {
                this.getData()
            }
        },

        sanitizeValue (text) {
            return text
                // htmlspecialchars
                .replace(/&/g, '&amp;')
                .replace(/</g, '&lt;')
                .replace(/>/g, '&gt;')
                .replace(/"/g, '&quot;')
                .replace(/'/g, '&#039;')
                // addslashes
                .replace(/\\/g, '\\\\')
                // eslint-disable-next-line
                .replace(/\u0008/g, '\\b')
                .replace(/\t/g, '\\t')
                .replace(/\n/g, '\\n')
                .replace(/\f/g, '\\f')
                .replace(/\r/g, '\\r')
                .replace(/'/g, '\\\'')
                .replace(/"/g, '\\"')
        },

        changePagination (payload) {
            if (payload.type === 'load-more') {
                this.isMore = true
            }
            this.currentPage = payload.page
            this.refreshParams()
            this.getData()
        },

        sortingHandler (type) {
            this.currentPage = 1
            this.sortingType = type
            this.refreshParams()
            this.getData()
        },

        searchButtonHandler () {
            this.currentPage = 1
            this.refreshParams()
            this.getData()
        }
    }
}
</script>

<style lang="scss">
.search {
    &__filters {
        display: flex;
        @include margin-level(bottom, M);

        .z-input {
            @include margin-level(right, 3XS);

            @include breakpoint(mobile) {
                margin-right: 0;
                margin-bottom: $token-spacers-xs;
            }

            &.is-filled, &.is-focused {
                .z-input__placeholder {
                    top: 30%;
                }
            }
        }

        @include breakpoint(mobile) {
            flex-direction: column;
        }
    }

    &__sorting {
        display: flex;
        justify-content: flex-end;
        @include margin-level(bottom, XS);

        @include breakpoint(mobile) {
            flex-direction: column;
        }
    }

    &__sorting-wrapper {
        display: flex;

        @include breakpoint(mobile) {
            justify-content: space-around;
        }
    }

    &__sorting-text {
        font-weight: 600;
        // font-size: 18px;
        font-size: var(--fontSizeL);
        line-height: 1.4;
        color: $token-colors-black-90;
        @include margin-level(right, S);

        @include breakpoint(mobile) {
            text-align: center;
            margin-right: 0;
            margin-bottom: $token-spacers-xs;
        }
    }

    &__sorting-button {
        padding: 0;
        margin: 0;
        border: none;
        background-color: transparent;
        font-weight: 400;
        // font-size: 18px;
        font-size: var(--fontSizeL);
        line-height: 1.4;
        color: $token-colors-primary;

        &:not(:last-child) {
            @include margin-level(right, S);
        }

        &:disabled {
            color: $token-colors-black-60;
        }
    }

    &__list {
        border-radius: 20px;
        background-color: $token-colors-white;
        @include padding-level(left, XL);
        @include padding-level(right, XL);
        @include padding-level(top, L);
        @include padding-level(bottom, L);

        @include breakpoint(mobile) {
            padding: $token-spacers-2-xs;
        }
    }

    &__card {
        display: flex;
        flex-direction: column;

        &:not(:last-child) {
            @include margin-level(bottom, L);
        }

        .z-caption {
            text-decoration: none;
            @include margin-level(bottom, 3XS);

            &:last-child {
                margin-bottom: 0;
            }
        }

        &-title {
            cursor: pointer;
        }

        &-date {
            color: $token-colors-black-60;
        }
    }

    .z-pagination {
        margin: 0 auto;

        @include breakpoint(mobile) {
            width: 100%;
        }

        &__nav {
            @include breakpoint(mobile) {
                width: 100%;
                display: flex;
                justify-content: center;
            }
        }

        &__list {
            flex-wrap: wrap;
        }

        @include breakpoint(mobile) {
            li {
                margin-bottom: 16px;
            }
        }
    }
}
</style>
