<template>
    <div class="suppliers-local">
        <z-tabs only-visual @toggle-item="onTabChange">
            <template v-slot:label>
                <z-tabs-label
                    v-for="type in filterTypes"
                    :key="type.id"
                    :id="type.id"
                    :initial-state="type.selected"
                >{{type.text}}</z-tabs-label>
            </template>
        </z-tabs>
        <z-table
            :columns="['Предмет закупки', 'Способ проведения закупочной процедуры', 'Срок подачи заявки', 'Способ подачи заявки', 'Адрес заказчика', 'Контакты']"
            responsive
            rounded
            :colgroup="['20%', '15%', '10%', '20%', '15%', '20%']"
            theme="white"
            v-if="data && data.length"
        >
            <z-table-row v-for="(item, index) in data" :key="index">
                <z-table-col index="0">
                    <z-link :href="item.file.link" v-html="item.name"></z-link>
                </z-table-col>
                <z-table-col index="1">
                    {{ item.feedMethod.value }}
                </z-table-col>
                <z-table-col index="2">
                    <time :datetime="item.term.timestamp | momentFilter('YYYY-MM-DD')">{{item.term.timestamp | momentFilter('DD.MM.YYYY')}}</time>
                </z-table-col>
                <z-table-col index="3">
                    {{ item.carryingOutMethod.value }}
                </z-table-col>
                <z-table-col index="4">
                    {{ item.address.value }}
                </z-table-col>
                <z-table-col index="5">
                    <z-link :href="`mailto:${item.email.value}`" class="u-block" v-html="item.email.value"></z-link>
                    <z-link :href="`tel:${item.phone.description || item.phone.value}`" class="u-block" v-html="item.phone.value"></z-link>
                </z-table-col>
            </z-table-row>
        </z-table>
        <notification v-else class="u-top-margin--xl">
            <template v-slot:image>
                <z-image src="/images/not-found/events-nf.svg" width="316" height="266"></z-image>
            </template>
            <template v-slot:description>
                <z-caption size="m">В&nbsp;настоящее время актуальных закупок нет</z-caption>
            </template>
        </notification>
        <z-button
            class="suppliers-local__loadmore"
            @click="loadMore"
            v-if="nav.current < nav.total"
            kind="secondary"
        >Показать еще</z-button>
        <z-preloader
            v-if="isLoading"
        />
    </div>
</template>

<script>
import { getLocalItems } from '@/api/suppliers-local'
import { queryString } from '@/utils/queryString'

export default {
    name: 'suppliers-local',
    props: {
        archive: Boolean
    },
    data () {
        return {
            data: this.$root.app.components.local.items,
            nav: this.$root.app.components.local.nav,
            params: {
                page: 1,
                archive: false
            },
            isLoading: false,
            filterTypes: [
                {
                    'selected': true,
                    'id': 'actual',
                    'text': 'Актуальные закупки'
                },
                {
                    'selected': false,
                    'id': 'archive',
                    'text': 'Архив'
                }
            ]
        }
    },
    created () {
        if (this.archive) this.updateTabsState('archive')
    },
    computed: {
        clearEmptyParams () {
            let params = Object.assign({}, this.params)

            Object.keys(params).forEach(function (key, index) {
                if (Array.isArray(params[key])) {
                    if (!(params[key].filter(val => { return val !== null && val !== undefined && val !== '' && val !== false }).length)) delete params[key]
                } else if (!params[key]) {
                    delete params[key]
                }
            })

            return params
        }
    },
    methods: {
        updateTabsState (type) {
            this.filterTypes.forEach(obj => {
                if (obj.id === type) {
                    obj.selected = true
                } else {
                    obj.selected = false
                }
            })
        },
        onTabChange (tab) {
            const type = tab.replace('tabs-', '')
            this.updateTabsState(type)

            this.params.archive = type === 'archive'

            this.params.page = 1

            queryString(this.clearEmptyParams)

            this.send()
        },
        loadMore () {
            if (!this.isLoading) {
                this.params.page += 1
                this.send()
            }
        },
        send () {
            this.isLoading = true

            getLocalItems(this.params).then(res => {
                this.updateNav(res)
                this.updateData(res)
                this.isLoading = false
            })
        },
        updateNav (res) {
            if (res.hasOwnProperty('nav')) {
                this.nav.current = res.nav.current
                this.nav.total = res.nav.total
            }
        },
        updateData (res) {
            if (res.hasOwnProperty('items')) {
                if (this.nav.current === 1) {
                    this.data = res.items
                } else {
                    this.data = this.data.concat(res.items)
                }
            }
        }
    }
}
</script>

<style lang="scss">
.suppliers-local {
    .z-link + .z-link {
        margin-top: $token-spacers-3-xs;
    }

    .z-preloader {
        padding: 0;
    }

    &__loadmore {
        display: flex !important;
        margin-top: $token-spacers-s;
        margin-left: auto;
        margin-right: auto;
    }

    .z-table {       
        
        &-col:last-child {

            .z-table-col__content {
                display: flex;
                flex-direction: column;
            }
        }
    }
}
</style>
