<template>
<div class="programs-detail" v-if="data">
    <p class="u-bottom-margin--m" v-if="data.item.detailText" v-html="data.item.detailText"></p>

    <div
        ref="tableWrapper"
        class="programs-table u-bottom-margin--xl"
        v-if="data.item.detailTable.items && data.item.detailTable.items.length"
    >
        <table class="programs-table__table">
            <colgroup>
                <col width="50%">
                <col width="20%">
                <col width="15%">
                <col width="15%">
            </colgroup>
            <thead ref="tableHead" class="programs-table__head">
                <tr class="programs-table__row">
                    <th class="programs-table__cell">Профессия</th>
                    <th class="programs-table__cell">Форма подготовки</th>
                    <th class="programs-table__cell">Стоимость <br>(Норильск)</th>
                    <th class="programs-table__cell">Стоимость <br>(Мончегорск)</th>
                </tr>
            </thead>
            <tbody>
                <tr
                    class="programs-table__row"
                    v-for="(item, index) in tableItems"
                    :key="index"
                >
                    <td class="programs-table__cell">
                        <z-caption tag="p" size="2xl" weight="600" class="u-bottom-margin--xs-important">
                            <span v-if="item.profession" v-html="item.profession"></span>
                            <span v-else>Не присваивается</span>
                        </z-caption>
                        <div class="row">
                            <div class="col-default-6 col-v-tablet-12">
                                <div class="programs-table__info" v-if="item.theory">
                                    <span class="programs-table__prop">Часы теории:</span> {{ item.theory }}
                                </div>
                                <div class="programs-table__info" v-if="item.practice">
                                    <span class="programs-table__prop">Часы практики:</span> {{ item.practice }}
                                </div>
                            </div>
                            <div class="col-default-6 col-v-tablet-12">
                                <div class="programs-table__info" v-if="item.trainingProgram">
                                    <span class="programs-table__prop">Учебная программа:</span> <br> {{ item.trainingProgram }}
                                </div>
                            </div>
                            <div class="col-default-6 col-v-tablet-12">
                            </div>
                            <div class="col-default-6 col-v-tablet-12">
                                <div class="programs-table__info" v-if="item.preparation">
                                    <span class="programs-table__prop">Вид подготовки:</span> <br> {{ item.preparation }}
                                </div>
                            </div>
                        </div>
                    </td>
                    <td class="programs-table__cell">
                        <span v-html="item.form"></span>
                    </td>
                    <td class="programs-table__cell">
                        <span class="primary-text text-size-2xl u-semi-bold">
                            <template v-if="item.priceNorilsk !== 0">
                                <span v-html="item.priceNorilsk"></span>
                                <span>₽</span>
                            </template>
                            <span v-else>&mdash;</span>
                        </span>
                    </td>
                    <td class="programs-table__cell">
                        <span class="primary-text text-size-2xl u-semi-bold">
                            <template v-if="item.priceMonchegorsk !== 0">
                                <span v-html="item.priceMonchegorsk"></span>
                                <span>₽</span>
                            </template>
                            <span v-else>&mdash;</span>
                        </span>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
    <z-preloader v-if="loading"/>
    <z-pagination
        v-if="tableItemsNav"
        :data="{
            currentPage: tableItemsNav.current,
            totalItems: tableItemsNav.count,
            totalPages: tableItemsNav.total,
            pageRange: 1
        }"
        :show-nav="false"
        :show-more="true"
        :show-next="false"
        :show-prev="false"
        @change-page="changePageHandler"
    />

    <div class="programs-detail__files" v-if="data.item.files && data.item.files.length || data.nav.next || data.nav.prev">
        <template v-if="data.item.files && data.item.files.length">
            <z-caption class="u-bottom-margin--m-important" tag="h2" size="3xl" weight="600">Документы</z-caption>

            <z-filelist horizontal>
                <z-filelist-item
                    v-for="(file, index) in data.item.files"
                    :key="index"
                    :icon="`file/${file.type}`"
                    :info="`${file.size}`"
                >
                    <z-link :href="file.link" target="_blank">
                        <span v-html="file.name"></span>
                    </z-link>
                </z-filelist-item>
            </z-filelist>
        </template>

        <page-changer
            class="u-top-margin--xl"
            :data="{
                next: {
                    name: '',
                    url: data.nav.next.url ?  data.nav.next.url : ''
                },
                prev: {
                    name: '',
                    url: data.nav.prev.url ? data.nav.prev.url : ''
                },
                'back-link': data.nav['back-link']
            }"/>
    </div>
    <div class="u-block-fluid programs-detail__call-me">
        <p>По вопросам обучения обращайтесь по&nbsp;телефону <span class="u-white-space--nowrap">+7 (3919) 250-200</span></p>
    </div>
</div>
</template>

<script>
import { mapActions } from 'vuex'
import { getProgramTables } from '@/api/program-tables'

export default {
    name: 'programs-detail',
    data () {
        return {
            data: this.$root.app.components?.['programs-detail'],
            tableItems: this.$root.app.components?.['programs-detail'].item.detailTable.items,
            tableItemsNav: this.$root.app.components?.['programs-detail'].item.detailTable.nav,
            nav: this.$root.app.components?.['programs-detail'].nav,
            loading: false,
            tableWrapper: null,
            tableHead: null,
            tableHeadClone: null
        }
    },
    methods: {
        ...mapActions([
            'SetHeroTitle',
            'SetHeroSrc'
        ]),
        stickyTableHead () {
            const tablePos = this.tableWrapper.getBoundingClientRect()

            if (tablePos.top < 0) {
                if (!this.tableHeadClone) this.createTableHeadClone()

                const tableHeadClonePos = this.tableHeadClone.getBoundingClientRect()
                this.checkBottomHeadClone(tablePos, tableHeadClonePos)
            } else {
                if (this.tableHeadClone) {
                    this.tableHeadClone.remove()
                    this.tableHeadClone = null
                }
            }
        },
        createTableHeadClone () {
            const tableHeadClone = this.tableHead.cloneNode(true)
            tableHeadClone.classList.add('programs-table__head--clone')
            this.tableHeadClone = tableHeadClone
            document.body.append(this.tableHeadClone)
            this.updateTableHeadClone()
            this.updateScrollHeadClone()
        },
        updateTableHeadClone () {
            if (!this.tableHeadClone) return

            const tablePos = this.tableWrapper.getBoundingClientRect()
            const tableHeadClonePos = this.tableHeadClone.getBoundingClientRect()

            this.tableHeadClone.style.width = `${tablePos.width}px`
            this.tableHeadClone.style.left = `${tablePos.left}px`
            this.checkBottomHeadClone(tablePos, tableHeadClonePos)
        },
        updateScrollHeadClone () {
            if (!this.tableHeadClone) return

            const scrollValue = this.tableWrapper.scrollLeft
            this.tableHeadClone.scrollLeft = scrollValue
        },
        checkBottomHeadClone (tablePos, tableHeadClonePos) {
            if ((tablePos.bottom - tableHeadClonePos.height * 2) <= 0) {
                this.tableHeadClone.style.transform = 'translateY(-100%)'
            } else {
                this.tableHeadClone.style.transform = 'translateY(0%)'
            }
        },
        changePageHandler (data) {
            const path = window.location.pathname
            const id = path.split('/')[3]
            this.loading = true
            getProgramTables({ page: data.page }, id)
                .then((response) => {
                    this.tableItems = [...this.tableItems, ...response.items]
                    this.tableItemsNav = response.nav
                    this.updateTableHeadClone()
                })
                .catch((error) => {
                    console.log(error)
                })
                .finally(() => {
                    this.loading = false
                })
        }
    },
    created () {
        this.SetHeroTitle(`${this.data?.item?.index?.value} ${this.data?.item?.name?.value}`)
    },
    mounted () {
        this.tableWrapper = this.$refs.tableWrapper
        this.tableHead = this.$refs.tableHead

        if (this.tableWrapper && this.tableHead) {
            window.addEventListener('scroll', this.stickyTableHead)
            window.addEventListener('resize', this.updateTableHeadClone)
            this.tableWrapper.addEventListener('scroll', this.updateScrollHeadClone)
        }
    },
    destroyed () {
        if (this.tableWrapper && this.tableHead) {
            window.removeEventListener('scroll', this.stickyTableHead)
            window.removeEventListener('resize', this.updateTableHeadClone)
            this.tableWrapper.removeEventListener('scroll', this.updateScrollHeadClone)
        }
    }
}
</script>

<style lang="scss">
.programs-detail {
    .z-pagination {
        margin: 0 auto;
    }
}
.programs-table {
    border-radius: 20px 20px 0 0;
    overflow: auto;
    font-size: var(--fontSizeL);
    background: $token-colors-white;

    @include breakpoint (v-tablet) {
        border-radius: 10px 10px 0 0;
    }

    &::-webkit-scrollbar {
        height: 5px;
        background: $token-colors-bg-10;
    }

    &::-webkit-scrollbar-thumb {
        background: $token-colors-primary;
    }

    tbody {
        .programs-table__row {
            &:hover {
                background: #D6DEF4;
            }
        }
    }

    .row {
        margin-top: -10px;

        > [class*="col-"] {
            margin-top: 10px;
        }
    }

    &__row {
        &:nth-of-type(even) {
            background: $token-colors-bg-10;
        }
    }

    &__head {
        background: $token-colors-bg-10;
        font-weight: 600;
        line-height: 1.1;

        &--clone {
            transition: transform 0.15s;
            position: fixed;
            top: 0;
            overflow: hidden;
            z-index: 10;
        }
    }

    &__table {
        margin: 0;
    }

    &__prop {
        font-size: var(--fontSizeM);
        color: $token-colors-black-50;
    }

    &__cell {
        vertical-align: middle;
        padding: $token-spacers-xs;
        text-align: left;
        width: 15%;

        @include breakpoint (v-tablet) {
            padding: $token-spacers-2-xs;
        }

        &:first-child {
            padding-left: $token-spacers-s;
            width: 85%;
            min-width: 300px;

            @include breakpoint (v-tablet) {
                padding: $token-spacers-xs;
            }

            @include breakpoint (mobile) {
                padding: $token-spacers-2-xs;
                min-width: 240px;
            }
        }

        &:last-child {
            padding-right: $token-spacers-s;

            @include breakpoint (v-tablet) {
                padding: $token-spacers-xs;
            }

            @include breakpoint (mobile) {
                padding: $token-spacers-2-xs;
            }
        }
    }
}

.programs-detail__call-me {
    margin-top: $token-spacers-xl;
    position: relative;
    background: $token-colors-white;
    padding-top: $token-spacers-m;
    border-top-left-radius: $borderRadius;
    border-top-right-radius: $borderRadius;
    text-align: center;
    font-weight: 600;
    font-size: var(--fontSize3Xl);
    padding-bottom: calc(#{$token-spacers-m} + #{$borderRadius});
    margin-bottom: calc((#{$token-spacers-2-xl} + #{$borderRadius})* -1);

    @include breakpoint (mobile) {
        padding-bottom: calc(#{$token-spacers-m} + #{$borderRadius});
        margin-bottom: calc((#{$token-spacers-m} + #{$borderRadius})* -1);
    }

    &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 30px;
        border-top-left-radius: $borderRadius;
        border-top-right-radius: $borderRadius;
    }
}

</style>
