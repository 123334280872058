<template>
    <div class="swiper-controls__wrapper">
        <div class="swiper-controls">
            <div class="swiper-controls__pagination" data-swiper-pagination></div>

            <div class="swiper-controls__buttons">
                <div
                    class="swiper-controls__button swiper-controls__button--prev"
                    data-swiper-control-prev>
                    <z-icon name="arrow_slider-subtract" dir="left" />
                </div>
                <div
                    class="swiper-controls__button swiper-controls__button--next"
                    data-swiper-control-next>
                    <z-icon name="arrow_slider-subtract"  dir="right"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'swiper-controls'
}
</script>

<style lang="scss" src="./index.scss"></style>
