<template>
    <component
        :is="href ? 'a' : tag"
        :href="href"
        class="z-caption"
        :class="classObject"
    >
        <slot></slot>
    </component>
</template>

<script>
export default {
    name: 'z-caption',
    props: {
        tag: {
            type: String,
            default: 'span',
            validator: prop => ['span', 'p', 'caption', 'figcaption', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'a'].includes(prop)
        },
        size: {
            type: String,
            validator: prop => ['2xs', 'xs', 's', 'm', 'l', 'xl', '2xl', '3xl', '4xl', '5xl'].includes(prop)
        },
        weight: {
            type: String,
            validator: prop => ['100', '200', '300', '400', '500', '600', '700'].includes(prop)
        },
        uppercase: {
            type: Boolean
        },
        italic: {
            type: Boolean
        },
        href: String
    },
    computed: {
        classObject: function () {
            let arrClass = [
                {
                    'u-italic': this.italic,
                    'u-uppercase': this.uppercase
                }
            ]

            if (this.size) {
                arrClass.push(`z-caption--size-${this.size}`)
            }
            if (this.weight) {
                arrClass.push(`z-caption--weight-${this.weight}`)
            }

            return arrClass
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
