import Swiper from 'swiper/dist/js/swiper.js'

class SwiperMP {
    constructor (container, customOptions = {}) {
        if (!container) {
            return
        }

        this.swiperContainer = container
        this.customOptions = customOptions
        this.pagination = this.swiperContainer.querySelector('[data-swiper-pagination]')
        this.prevBtn = this.swiperContainer.querySelector('[data-swiper-control-prev]')
        this.nextBtn = this.swiperContainer.querySelector('[data-swiper-control-next]')
        this.init()
    }
    init () {
        const options = {
            navigation: {
                prevEl: this.prevBtn,
                nextEl: this.nextBtn
            },
            pagination: {
                clickable: true,
                el: this.pagination
            },
            ...this.customOptions
        }

        this.swiper = new Swiper(this.swiperContainer, options)
    }
}

export default SwiperMP
