/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/pdf': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" d="M8 11a8 8 0 018-8h13l12 12v21a8 8 0 01-8 8H16a8 8 0 01-8-8V11z" _fill="#C6C9D6"/><path pid="1" d="M13.91 33v-8.52h3.611c.392 0 .752.08 1.08.24.329.16.613.376.852.648.248.272.44.576.576.912.137.336.204.68.204 1.032 0 .48-.111.936-.335 1.368a2.875 2.875 0 01-.924 1.056c-.393.272-.857.408-1.393.408h-2.015V33h-1.657zm1.655-4.308h1.92c.2 0 .38-.056.54-.168.16-.112.289-.272.385-.48.096-.216.143-.46.143-.732 0-.296-.056-.548-.168-.756a1.093 1.093 0 00-.42-.468 1.029 1.029 0 00-.552-.156h-1.848v2.76zM21.386 33v-8.52h3.096c.928 0 1.704.192 2.328.576a3.566 3.566 0 011.404 1.536c.312.64.468 1.352.468 2.136 0 .864-.172 1.616-.516 2.256a3.643 3.643 0 01-1.464 1.488c-.624.352-1.364.528-2.22.528h-3.096zm5.628-4.272c0-.552-.1-1.036-.3-1.452a2.255 2.255 0 00-.864-.984c-.376-.24-.832-.36-1.368-.36h-1.44v5.616h1.44c.544 0 1.004-.12 1.38-.36.376-.248.66-.584.852-1.008.2-.432.3-.916.3-1.452zM29.976 33v-8.52H35.7v1.452h-4.068v2.196h3.384v1.344h-3.384V33h-1.656z" _fill="#4A5D7F"/><path pid="2" d="M29 3l12 12h-8a4 4 0 01-4-4V3z" _fill="#EFF0F3"/>'
  }
})
