<template>
    <section class="section-history">
        <div class="section-history__header container">
            <tag data-scroll-translate>История университета</tag>
            <z-caption tag="h1" size="4xl" weight="600" data-scroll-translate>
                Более 85 лет профессионального образования
            </z-caption>
        </div>
        <div class="section-history__body" data-scroll-scale data-scroll-reveal>
            <z-tabs class="container" onlyVisual @open-tab="changeTab">
                <template v-slot:label>
                    <z-tabs-label
                        v-for="year in tabsLabels"
                        :initial-state="active_tab === year"
                        :key="year"
                        :id="year"
                    >
                        {{ year }}
                    </z-tabs-label>
                </template>
            </z-tabs>

            <swiper
                ref="swiper"
                :options="swiper_options"
                class="section-history__body-swiper container"
                :pagination="false"
            >
                <swiper-slide v-for="(cardData, idx) in tabsData" :key="idx">
                    <history-card :data="cardData" />
                </swiper-slide>
                <swiper-slide>
                    <div class="section-history__body-more">
                        <div class="section-history__body-more-text">
                            <z-caption
                                tag="h1"
                                size="5xl"
                                weight="600"
                                >Узнайте<br />больше<br />о нашей<br />истории</z-caption
                            >
                            <z-button
                                tag="a"
                                size="m"
                                href="/about/history/">
                                Вперёд
                                <template v-slot:right>
                                    <z-icon name="buttons/arrow_right" width="20" height="20" />
                                </template>
                            </z-button>
                        </div>
                        <div class="section-history__body-more-img" />
                    </div>
                </swiper-slide>
            </swiper>
        </div>
    </section>
</template>

<script>
import { historyData } from './history-data'
import Tag from '../../components/Tag.vue'
import HistoryCard from './HistoryCard.vue'
import Swiper from '../../components/Swiper/Swiper.vue'
import SwiperSlide from '../../components/SwiperSlide/SwiperSlide.vue'

export default {
    name: 'section-history',
    components: {
        Tag,
        Swiper,
        SwiperSlide,
        HistoryCard
    },
    data () {
        return {
            swiper_options: {
                slidesPerView: 'auto',
                navigation: false,
                pagination: false,
                simulateTouch: true
            },
            active_tab: '2022' // latest year
        }
    },
    mounted () {
        this.swiper.on('slideChange', () => {
            const activeSlideIndex = this.swiper.activeIndex
            const activeSlideYear = this.tabsData[activeSlideIndex]?.year
            if (!activeSlideYear) {
                this.active_tab = this.tabsData[activeSlideIndex - 1].year
            } else {
                this.active_tab = activeSlideYear
            }
        })
    },
    computed: {
        swiper () {
            return this.$refs.swiper.swiper.swiper
        },
        tabsLabels () {
            return historyData.map((el) => el.year)
        },
        tabsData () {
            return historyData.flatMap((el) => {
                return el.items.reduce((acc, val, index) => {
                    const historyCardData = {
                        year: el.year,
                        isYearVisible: index === 0,
                        ...val
                    }
                    acc.push(historyCardData)
                    return acc
                }, [])
            })
        }
    },
    methods: {
        changeTab (year) {
            const selectedYearIndex = this.tabsData.findIndex((el) => Number(el.year) === year)
            this.swiper.slideTo(selectedYearIndex)
        }
    }
}
</script>

<style scoped lang="scss">
@import './index.scss';
</style>
