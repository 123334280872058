<template>
    <div class="schedule-tools" :class="{'schedule-tools--desktop' : !isMobile}">
        <z-button
            class="schedule-tools__item"
            kind="bordered"
            :rounded="isMobile"
            :onlyIcon="isMobile"
            size="s"
            @click="onPrint"
            :show-hover-animation="isMobile"
        >
            <template v-slot:left>
                <z-icon name="printer" width="22" height="22"></z-icon>
            </template>
            {{ !isMobile ? 'Распечатать' : '' }}
        </z-button>
        <z-button
            class="schedule-tools__item"
            kind="bordered"
            :rounded="isMobile"
            :onlyIcon="isMobile"
            @click="downLoadSchedule"
            size="s"
            onclick="ym(87502284, 'reachGoal', 'schedule-download'); return true;"
            :show-hover-animation="isMobile"
        >
            <template v-slot:left>
                <z-icon name="download_simple" width="22" height="22"></z-icon>
            </template>
            {{ !isMobile ? 'Скачать' : '' }}
        </z-button>
        <z-button
            class="schedule-tools__item"
            kind="bordered"
            :rounded="isMobile"
            :onlyIcon="isMobile"
            size="s"
            @click="copyLink"
            :show-hover-animation="isMobile"
        >
            <template v-slot:left>
                <z-icon name="internal" width="22" height="22"></z-icon>
            </template>
            {{ !isMobile ? 'Скопировать' : '' }}
        </z-button>
    </div>
</template>

<script>
import { showNotyfications } from '@/utils/notifications'
import { getScheduleFile } from '@/api/schedule'

export default {
    name: 'schedule-tools',
    inject: ['studyFormXmlId'],
    props: {
        downloadEnable: {
            type: Boolean,
            default: false
        },
        isMobile: {
            type: Boolean
        }
    },
    methods: {
        copyLink () {
            const link = window.location.href
            if (navigator.clipboard) {
                navigator.clipboard.writeText(link)
                    .then(() => {
                        showNotyfications('Ссылка скопирована в буфер обмена', { type: 'success' })
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else {
                const textArea = document.createElement('textarea')
                textArea.value = link
                document.body.appendChild(textArea)
                textArea.select()
                try {
                    document.execCommand('copy')
                    showNotyfications('Ссылка скопирована в буфер обмена', { type: 'success' })
                } catch (err) {
                    showNotyfications('Невозможно скопировать в буфер обмена', { type: 'error' })
                    console.log(err)
                }
                document.body.removeChild(textArea)
            }
        },
        onPrint () {
            window.print()
        },
        downLoadSchedule () {
            const xmlId = this.studyFormXmlId()
            let params = xmlId === '' ? {} : { id: xmlId }

            getScheduleFile(params)
                .then(response => {
                    if (response.status === 'success') {
                        const link = document.createElement('a')
                        link.setAttribute('target', '_blank')
                        link.setAttribute('download', 'schedule')
                        link.setAttribute('href', response.link)
                        link.click()
                    } else {
                        throw new Error(response.message)
                    }
                })
                .catch((error) => {
                    console.error(error)
                    showNotyfications('Не удалось скачать файл, попробуйте позже', { type: 'error' })
                })
        }
    }
}
</script>

<style lang="scss">
.schedule-tools {
    display: flex;
    flex-shrink: 0;
    margin: 0 -$token-spacers-3-xs;

    @include breakpoint(tablet) {
        margin: 0 -$token-spacers-3-xs / 2;
    }

    @include breakpoint(v-tablet) {
        margin: 0;
    }

    &__item {
        margin: 0 $token-spacers-3-xs;

        @include breakpoint(tablet) {
            margin: 0 $token-spacers-3-xs / 2;
        }
    }

    .z-button {
        background-color: transparent !important;
    }

    &--desktop {
        .z-button {
            border: none;
            padding: 0;
            text-transform: capitalize;
            overflow: inherit;

            .z-icon {
                margin-bottom: 0;
            }

            span {
                text-decoration: underline;
            }
        }
    }
}
</style>
