<template>
    <div
        class="scheme-card"
        :class="[
            { 'is-opened': isOpen },
            { 'is-head': isHead }
        ]"
        v-if="data"
    >
        <div class="scheme-card__img">
            <img
                :src="data.image || '/images/people/placeholder.png'"
                alt="Фото"
            >
        </div>
        <div class="scheme-card__container">
            <div class="scheme-card__header">
                <p
                    class="scheme-card__position"
                    v-html="data.position"
                    v-if="data.position"
                ></p>
                <p
                    class="scheme-card__name"
                    v-html="data.name"
                    v-if="data.name"
                ></p>
                <button
                    @click="toggleOpenState"
                    class="scheme-card__trigger"
                    v-if="hasDetailInfo"
                ></button>
            </div>
            <transition
                @enter="animationEnter"
                @leave="animationLeave"
            >
                <div class="scheme-card__content" v-show="isOpen">
                    <ul class="scheme-card__contacts">
                        <li class="scheme-card__contacts-item" v-if="data.email && data.email.length">
                            <div class="scheme-contact">
                                <span class="scheme-contact__prop">
                                    <z-icon name="envelope" color="#626C84" />
                                    <!-- <span>Почта:</span> -->
                                </span>
                                <span class="scheme-contact__value-wrapper">
                                    <z-link
                                        v-for="(email, indexEmail) in data.email"
                                        :key="`${indexEmail} + i`"
                                        :href="`mailto:${email}`"
                                        class="scheme-contact__value"
                                    >
                                        <span v-html="email"></span>
                                    </z-link>
                                </span>
                            </div>
                        </li>

                        <li class="scheme-card__contacts-item" v-if="data.phone && data.phone.length">
                            <div class="scheme-contact">
                                <span class="scheme-contact__prop">
                                    <z-icon name="mobile" color="#626C84" />
                                    <!-- <span>Телефон:</span> -->
                                </span>
                                <span class="scheme-contact__value-wrapper">
                                    <z-link
                                        v-for="(phone, indexPhone) in data.phone"
                                        :key="`${indexPhone} + j`"
                                        :href="`tel:${phone.description || phone.value}`"
                                        class="scheme-contact__value"
                                        theme="dark"
                                        :underlined="false"
                                    >
                                        <span v-html="phone.value"></span>
                                    </z-link>
                                </span>
                            </div>
                        </li>

                        <li class="scheme-card__contacts-item" v-if="data.cabinet && data.cabinet.length">
                            <div class="scheme-contact">
                                <span class="scheme-contact__prop">
                                    <z-icon name="room" color="#626C84" />
                                    <!-- <span>Адрес:</span> -->
                                </span>
                                <span
                                    v-for="(cabinet, indexCabinet) in data.cabinet"
                                    :key="`${indexCabinet} + k`"
                                    class="scheme-contact__value"
                                    v-html="cabinet"
                                ></span>
                            </div>
                        </li>
                    </ul>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import Velocity from 'velocity-animate'

export default {
    name: 'scheme-card',
    props: {
        data: [Object, Array],
        isHead: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            isOpen: false
        }
    },
    computed: {
        hasDetailInfo () {
            return (
                (this.data.email && this.data.email.length) ||
                (this.data.phone && this.data.phone.length) ||
                (this.data.cabinet && this.data.cabinet.length)
            )
        }
    },
    methods: {
        animationEnter (el, done) {
            Velocity(el, 'slideDown', { duration: this.duration })
        },
        animationLeave (el, done) {
            Velocity(el, 'slideUp', { duration: this.duration })
        },
        toggleOpenState () {
            this.isOpen = !this.isOpen
            this.$emit('toggle-open')
        }
    }
}
</script>

<style lang="scss">
.scheme-card {
    $parent: &;

    &__container {
        background: $token-colors-white;
        border: 1px solid $token-colors-black-3;
        border-radius: 10px;
    }

    &__position {
        font-size: var(--fontSizeXs);
        margin: 0;
        margin-bottom: 16px;
        font-weight: 700;
        word-wrap: break-word;
        overflow-wrap: break-word;
        word-break: break-word;
        hyphens: auto;
    }

    &__name {
        font-size: var(--fontSize2xs);
        margin: 0;
    }

    &__header {
        position: relative;
        padding: 32px 12px;

        padding-top: calc(30px + 16px); // 30 - половина высоты картинки
    }

    &__content {
        padding: 0 12px 24px;
        font-size: var(--fontSizeXs);
    }

    &__img {
        border-radius: 50%;
        overflow: hidden;
        width: 60px;
        height: 60px;
        margin-bottom: -30px;
        margin-left: 12px;
        position: relative;
        z-index: 1;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
            background: #EFF6FB;
            display: block;
        }

        .img-wrapper {
            font-size: var(--fontSizeXs);
        }
    }

    &__trigger {
        background: none;
        background-image: url("data:image/svg+xml,%3Csvg width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15 18L21 24L27 18' stroke='%23626C84' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E%0A");
        width: 42px;
        height: 42px;
        border: none;
        position: absolute;
        bottom: 0;
        right: 0;
        transition: transform $transition;
    }

    &.is-opened {
        #{$parent}__trigger {
            transform: scale(1, -1);
        }
    }

    &.is-head {
        width: 400px;
        max-width: 100%;

        @include breakpoint (v-tablet) {
            width: 324px;
        }

        @include breakpoint (mobile) {
            // width: 324px;
        }

        #{$parent}__header {
            padding: 32px;
            padding-top: calc(40px + 16px); // 40 - компенсация картинки
        }

        #{$parent}__content {
            padding-left: 32px;
            padding-right: 32px;
            font-size: var(--fontSizeS);
        }

        #{$parent}__img {
            width: 80px;
            height: 80px;
            margin-bottom: -40px;
            margin-left: 32px;
        }

        #{$parent}__position {
            font-size: var(--fontSizeXl);
            margin-bottom: 12px;
        }

        #{$parent}__name {
            font-size: var(--fontSizeS);
            color: $token-colors-black-50;
        }
    }

    &__contacts {
        margin: 0;
    }

    &__contacts-item {
        & + & {
            margin-top: 8px;
        }
    }
}

.scheme-contact {
    display: flex;
    word-break: break-all;

    &__prop {
        margin-right: 6px;
    }

    &__value {
        color: $token-colors-black-60;
        display: inline-block;
        margin-right: 8px;
        position: relative;

        &:after {
            content: ',\00a0';
            display: inline-block;
            position: absolute;
            bottom: 0;
        }

        &:last-child {
            &:after {
                display: none;
            }
        }
    }

    // костыль из-за разности высоты иконок
    span.scheme-contact__value {
        margin-top: 0.1em;
    }
}
</style>
