<template>
    <figure class="z-blockquote">
        <div class="z-blockquote__wrapper">
            <div
                class="z-blockquote__photo"
                v-if="author.photo || photo && !quote"
                :style="`background-image: url(${author.photo || photo})`"
                :alt="author.name"
            ></div>
            <div
                class="z-blockquote__quote"
                v-if="quote"
                :style="`background-image: url(${quoteImg})`"
            ></div>
            <div class="z-blockquote__info info">
                <span class="info__name">{{ author.name }}</span>
                <span class="info__text" v-if="author.info">{{ author.info }}</span>
            </div>
        </div>
        <blockquote class="z-blockquote__text" v-if="$slots['text']">
            <slot name="text"></slot>
        </blockquote>
        <div class="z-blockquote__source" v-if="author.source">
            {{ author.source }}
        </div>
    </figure>
</template>

<script>
export default {
    name: 'z-blockquote',
    props: {
        author: {
            name: String,
            info: String,
            photo: String,
            source: String
        },
        quote: Boolean
    },
    data () {
        return {
            photo: require('./images/userpic.png'),
            quoteImg: require('./images/quote.svg')
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
