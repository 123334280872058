/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow_slider-subtract': {
    width: 39,
    height: 39,
    viewBox: '0 0 39 39',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M19.5 39C8.73 39 0 30.27 0 19.5S8.73 0 19.5 0 39 8.73 39 19.5 30.27 39 19.5 39zm-6.293-16.086a1 1 0 11-1.414-1.414l7.707-7.707 7.707 7.707a1 1 0 01-1.414 1.414L19.5 16.621l-6.293 6.293z" _fill="#0072D9"/>'
  }
})
