/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'room': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4.75 2.5A.25.25 0 015 2.25h6a.25.25 0 01.25.25v11a.25.25 0 01-.25.25H5a.25.25 0 01-.25-.25v-11zM5 .75A1.75 1.75 0 003.25 2.5v11c0 .966.784 1.75 1.75 1.75h6a1.75 1.75 0 001.75-1.75v-11A1.75 1.75 0 0011 .75H5zm4 6.5a.75.75 0 000 1.5h1a.75.75 0 000-1.5H9z" _fill="#0072D9"/>'
  }
})
