/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tag-color/bell': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12 .97a7.272 7.272 0 00-7.232 6.504l-.307 2.889c-.03.279-.042.391-.057.5a6.8 6.8 0 01-.726 2.239c-.052.097-.108.195-.248.44l-.642 1.117-.038.065c-.32.559-.621 1.082-.8 1.527-.19.468-.371 1.146-.034 1.847.048.1.104.197.167.29.44.64 1.119.82 1.618.89.476.066 1.08.066 1.723.065H8.623c.192.79.483 1.523.876 2.126.542.833 1.39 1.56 2.501 1.56s1.959-.727 2.501-1.56c.393-.603.684-1.335.876-2.126H18.576c.644 0 1.247 0 1.723-.065.5-.07 1.178-.25 1.618-.89.063-.093.119-.19.167-.29.337-.7.155-1.38-.033-1.847-.18-.445-.48-.968-.801-1.527l-.038-.065-.642-1.118a15.52 15.52 0 01-.248-.44 6.8 6.8 0 01-.726-2.237c-.015-.11-.027-.222-.057-.502l-.307-2.888A7.272 7.272 0 0012 .97zm.878 18.373h-1.756c.114.325.246.6.387.816.15.23.284.355.375.417.044.03.075.043.092.048a.078.078 0 00.024.006c.002 0 .009 0 .024-.006a.38.38 0 00.092-.048c.091-.062.225-.188.375-.417a3.83 3.83 0 00.387-.816zM7.155 7.728a4.872 4.872 0 019.69 0l.307 2.888.003.022c.026.251.043.406.065.56a9.198 9.198 0 00.982 3.028c.073.138.15.272.276.492l.01.018.643 1.118c.279.486.468.82.592 1.07-.279.018-.662.02-1.223.02h-13c-.56 0-.944-.002-1.223-.02.124-.25.313-.584.592-1.07l.642-1.118.011-.019c.126-.219.203-.353.276-.491a9.2 9.2 0 00.982-3.028c.022-.154.039-.309.065-.56l.003-.022.307-2.888zm12.719 9.565v0zm.244-.422l.004-.001h-.004zm-16.24-.001h.004a.071.071 0 01-.004 0zm.248.423v.004-.004zm0 .004z" _fill="#1971CF"/>'
  }
})
