<template>
    <a
        @click="toggleItem($event)"
        :href="`#${itemId}`"
        class="z-tabs-label"
        :class="classObject"
    >
        <slot />
    </a>
</template>

<script>
export default {
    name: 'z-tabs-label',
    props: {
        id: {
            type: String,
            default: '',
            required: true
        },
        rightSide: Boolean,
        initialState: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            state: this.initialState,
            name: 'z-tabs-label',
            itemId: `tabs-${this.id}`,
            itemChildrens: this.$children
        }
    },
    watch: {
        initialState (val) {
            this.state = val
        }
    },
    computed: {
        classObject () {
            return {
                'z-tabs-label--opened': this.state,
                'z-tabs-label--right': this.rightSide
            }
        }
    },
    methods: {
        toggleItem (e) {
            if (this.state || this.$parent.onlyVisual) e.preventDefault()

            this.$parent.$emit('toggle-item', this.itemId)
        }
    }
}
</script>
