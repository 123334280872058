<template>
    <div class="employees-page">
        <div class="employees-page__wrapper" :class="[{ 'is-loading': isLoading }]">
            <div
                class="employees-page__row"
                v-if="data && data.length"
            >
                <div class="employees-page__col" v-for="(item, index) in data" :key="index">
                    <employee-card :data="item" />
                </div>
            </div>
            <z-preloader
                v-if="isLoading"
            />
        </div>
        <z-pagination
            show-next
            show-prev
            :show-more="{
                marginBottom: 'xs',
                size: 'm'
            }"
            show-nav
            @change-page="onPageChange"
            :data="{
                currentPage: this.nav.current,
                totalPages: this.nav.total,
                pageRange: 1
            }"
        >
        </z-pagination>
    </div>
</template>

<script>
import { getEmployeesList } from '@/api/employees'
import EmployeeCard from './components/EmployeeCard.vue'

export default {
    name: 'employees',
    props: {
        source: {
            type: String,
            validator: prop => ['management', 'teaching-staff'].includes(prop)
        }
    },
    components: {
        EmployeeCard
    },
    data () {
        return {
            data: this.$root.app.components[this.source].items,
            nav: this.$root.app.components[this.source].nav,
            params: {
                page: 1,
                source: this.source
            },
            isLoading: false,
            requestType: ''
        }
    },
    methods: {
        onPageChange (page) {
            if (this.isLoading) return
            this.params.page = page.page
            this.requestType = page.type
            this.send()
        },
        send () {
            this.isLoading = true

            getEmployeesList(this.params).then(res => {
                if (res.hasOwnProperty('nav')) this.nav = res.nav
                if (res.hasOwnProperty('items')) {
                    if (this.requestType === 'change-page') {
                        this.data = res.items
                    } else {
                        this.data = this.data.concat(res.items)
                    }
                }
                this.isLoading = false
            })
        }
    }
}
</script>

<style lang="scss">
.employees-page {
    &__wrapper {
        position: relative;
        padding-bottom: $token-spacers-xl;

        &.is-loading {
            .employees-page__row {
                opacity: .4;
            }
        }

        .z-preloader {
            position: absolute;
            bottom: calc(#{$token-spacers-xl} / 2);
            left: 50%;
            padding: 0;
            transform: translate(-50%, 50%);
        }
    }

    &__row {
        position: relative;
        display: flex;
        flex-direction: row;
        flex: 0 1 auto;
        flex-wrap: wrap;
        margin-left: -4px;
        margin-right: -4px;
        margin-top: -8px;
    }

    &__col {
        margin-top: 8px;
        flex-basis: 33.33333%;
        max-width: 33.33333%;
        padding-left: 4px;
        padding-right: 4px;
        flex-grow: 0;
        flex-shrink: 0;

        @include breakpoint (v-tablet) {
            flex-basis: 50%;
            max-width: 50%;
        }

        @include breakpoint (mobile) {
            flex-basis: 100%;
            max-width: 100%;
        }
    }

    .z-pagination {
        position: relative;
        width: 100%;

        &__list {
            justify-content: center;
        }
    }
}
</style>
