/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'buttons/envelope': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<g clip-path="url(#clip0_1188_14243)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4 3.5h8c.985 0 1.573.003 1.995.06a1.413 1.413 0 01.354.084l.005.002.002.005a1.411 1.411 0 01.084.354c.032.237.047.527.054.91L8.559 7.882a1.25 1.25 0 01-1.118 0L1.506 4.915c.007-.383.022-.673.054-.91a1.412 1.412 0 01.084-.354l.002-.005.005-.002a1.412 1.412 0 01.354-.084C2.427 3.503 3.015 3.5 4 3.5zm-2.354.147v-.001c.001 0 .001 0 0 0 0 .001 0 .001 0 0zm0 0zM.004 4.99l-.34.68.336.168V10c0 1.886 0 2.828.586 3.414C1.172 14 2.114 14 4 14h8c1.886 0 2.828 0 3.414-.586C16 12.828 16 11.886 16 10V6v-.161l.335-.168-.34-.68c-.018-1.234-.11-1.935-.58-2.405C14.827 2 13.884 2 12 2H4c-1.886 0-2.828 0-3.414.586-.47.47-.563 1.17-.582 2.405zM14.5 6.59V10c0 .985-.003 1.573-.06 1.995a1.412 1.412 0 01-.084.354l-.002.005-.005.002a1.415 1.415 0 01-.354.084c-.422.057-1.01.06-1.995.06H4c-.985 0-1.573-.003-1.995-.06a1.414 1.414 0 01-.354-.084l-.005-.002-.002-.005a1.413 1.413 0 01-.084-.354c-.057-.422-.06-1.01-.06-1.995V6.589l5.27 2.635a2.75 2.75 0 002.46 0l5.27-2.635z" _fill="#0072D9"/></g><defs><clipPath id="clip0_1188_14243"><path pid="1" _fill="#fff" d="M0 0h16v16H0z"/></clipPath></defs>'
  }
})
