<template>
    <div v-if="digest && digest.item && digest.item.length > 0" class="current-article" :style="{ backgroundImage: 'url(' + digest.item[0].previewPicture.link + ')' }">
        <div class="current-article__card">
            <div class="img-wrapper">
                <img
                    class="current-article__image"
                    :src="digest.item[0].imageMobile.link"
                    alt="Photo article"
                    @click="onImageClick">
            </div>
            <z-caption weight="600" tag="h3" size="3xl">{{ digest.item[0].title }}
            </z-caption>
            <p class="current-article__text">{{ digest.item[0].previewText }}</p>
            <z-button
                tag="a"
                target="_blank"
                :href="digest.item[0].file.link"
            >
                Читать
            </z-button>
        </div>
    </div>
</template>

<script>
export default {
    name: 'current-article',
    props: {
        digest: {
            type: Object,
            default: () => ({ item: [] })
        }
    },
    methods: {
        onImageClick () {
            if (window.innerWidth < 600) {
                window.open(this.digest.item[0].file.link, '_blank')
            }
        }
    }
}
</script>

<style lang="scss">
.current-article {
    @include padding-level(top, XL);
    @include padding-level(bottom, XL);
    @include padding-level(left, XL);
    @include padding-level(right, XL);
    filter: drop-shadow(0px 6px 24.1px rgba(0, 85, 187, 0.60));
    border-radius: 32px;
    @include margin-level(bottom, 4XL);
    background-color: $token-colors-white;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    min-height: 740px;
    display: flex;
    align-items: flex-end;

    @include breakpoint(mobile) {
        border-radius: 24px;
    }

    &__card {
        background-color: $token-colors-white;
        padding: $token-spacers-m;
        @include padding-level(top, M);
        @include padding-level(bottom, M);
        @include padding-level(left, M);
        @include padding-level(right, M);
        max-width: 720px;
        border-radius: 24px;
        display: flex;
        flex-direction: column;
        gap: $token-spacers-xs;

        .z-caption {
            margin: 0;
        }

        .z-button {
            width: max-content;
        }

        @include breakpoint(tablet) {
            max-width: 462px;
            padding: 40px 32px;
        }

        @include breakpoint(mobile) {
            max-width: 100%;
            padding: 0;

            .z-button {
                width: 100%;
                max-width: 100%;
            }
        }
    }

    @include breakpoint(tablet) {
        padding: $token-spacers-2-xs;
        min-height: 528px;
        filter: none;
        margin-bottom: $token-spacers-2-xl;
    }

    @include breakpoint(mobile) {
        min-height: auto;
        background-image: none !important;
        margin-bottom: $token-spacers-l;
    }

    &__text {
        @include typo-level(XS);
        margin: 0 !important;
    }

    &__image {
        display: none;
        width: 100%;
        min-height: 376px;
        border-radius: 16px;
        object-fit: cover;

        @include breakpoint(mobile) {
            display: block;
        }
    }
}
</style>
