<template>
  <div class="sitemap">
    <z-caption class="sitemap__title" tag="h3" size="2xl">{{ title }}</z-caption>
    <ul class="sitemap__list">
        <li
            class="sitemap__item"
            v-for="(menuItem,index) in data"
            :key="index"
        >
            <z-link
                class="sitemap__item-title"
                :href="menuItem.link"
            >
                {{ menuItem.name }}
            </z-link>

            <ul
                v-if="menuItem.items && menuItem.items.length"
            >
                <li
                    class="sitemap__subitem"
                    v-for="(subMenuItem,index) in menuItem.items"
                    :key="index"
                >
                    <a
                        class="sitemap__subitem-title"
                        :href="subMenuItem.link"
                        :target="subMenuItem.params && subMenuItem.params.BLANK ? '_blank' : false"
                    >
                        <span class="sitemap__subitem-text" v-html="subMenuItem.name"></span>
                        <z-icon v-if="subMenuItem.params.EXTERNAL" name="external" />
                    </a>

                     <ul
                        v-if="subMenuItem.items && subMenuItem.items.length"
                        class="sitemap__sublist-third"
                    >
                        <li
                            class="sitemap__subitem"
                            v-for="(subMenuItemThird,index) in subMenuItem.items"
                            :key="index"
                        >
                            <a
                                class="sitemap__subitem-title"
                                :href="subMenuItemThird.link"
                                :target="subMenuItemThird.params && subMenuItemThird.params.BLANK ? '_blank' : false"
                            >
                                <span class="sitemap__subitem-text" v-html="subMenuItemThird.name"></span>
                                <z-icon v-if="subMenuItemThird.params.EXTERNAL" name="external" />
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
        </li>
    </ul>
  </div>
</template>

<script>
import { localize } from '@/utils/i18n.js'

export default {
    name: 'site-map',
    data () {
        return {
            data: [],
            title: localize({
                ru: 'Карта сайта',
                en: 'Site map'
            })
        }
    },
    mounted () {
        this.data = this.$root.app.components.sitemap
    }
}
</script>

<style lang="scss">
.sitemap {
    &__title{
        color: $token-colors-link-default !important;
        margin-bottom: $token-spacers-l !important;
    }

    &__list {
        display: flex;
        flex-direction: row;
        flex-flow: row wrap;
        justify-content: space-between;

        @include breakpoint (v-tablet) {
            flex-flow: column wrap;
        }
    }

    &__item {
        display: inline-block;
        vertical-align: top;
        width: calc(100%/2 - 1.75em);
        margin-bottom: $token-spacers-xl;

        @include breakpoint (v-tablet) {
            width: 100%;
        }
    }

    &__item-title {
        font-weight: 700;
        color: $token-colors-secondary;
        margin-bottom: 20px;
        display: block !important;
        line-height: 1.6;
        font-size: 1.65em;
    }

    &__subitem {
        &:not(:last-child) {
            margin-bottom: 18px;
        }
    }

    &__subitem-title {
        // // font-size: 20px;
        font-size: var(--fontSizeXl);
        line-height: 120%;
        font-weight: 400;
        text-decoration: none;
    }

    &__subitem-text {
        text-decoration: underline;
    }

    &__sublist-third {
            margin-top: 18px;
    margin-left: 22px;
    }
}
</style>
