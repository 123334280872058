<template>
    <div class="schedule-filter">
        <div class="schedule-filter__container">
            <z-button
                v-if="isTablet"
                @click="toggleFilterVisibility"
                class="schedule-filter__button-link schedule-filter__button-link--close"
                kind="bordered"
                size="m"
                :show-hover-animation="false"
            >
                Назад
                <template v-slot:left>
                    <z-icon name="round-arrow" width="20" height="20"/>
                </template>
            </z-button>

            <!-- Формат фильтра -->
            <div class="schedule-filter__field">
                <span class="schedule-filter__placeholder">Формат</span>
                <z-select
                    v-if="!isTablet"
                    :data="filterData.studyFormat"
                    v-model="params.studyFormat"
                    placeholder="Формат"
                    alt="Формат"
                    @change="send"
                    :is-filter="false"
                    ref="filterSelect"
                    :is-edit-select="false"
                ></z-select>
                <template v-if="isTablet">
                    <button
                        v-for="(item, index) in filterData.studyFormat"
                        :key="index"
                        class="schedule-filter__item"
                        :class="{
                            'is-selected': isTagActive(item.id),
                            [`schedule-filter__item--${item.xmlId}`]: item.xmlId
                        }"
                        v-html="item.text"
                        @click="toggleTag(item.id)"
                    ></button>
                </template>
            </div>

            <!-- Группа, Курс, Куратор -->
            <div v-for="(field, key) in filterFields" :key="key" class="schedule-filter__field">
                <span class="schedule-filter__placeholder">{{ field.placeholder }}</span>
                <z-select
                    v-if="field.type === 'select'"
                    :data="filterData[field.key]"
                    v-model="params[field.key]"
                    :placeholder="getTextValue(field.key, params[field.key]) || field.placeholder"
                    :alt="field.placeholder"
                    @change="send"
                    :is-filter="false"
                    :class="field.class"
                    ref="filterSelect"
                    :is-edit-select="true"
                ></z-select>
            </div>

            <div class="schedule-filter__buttons">
                <z-button
                    v-if="isTablet"
                    @click="toggleFilterVisibility"
                    :disabled="isClearButtonDisabled"
                    size="s"
                >
                    Применить
                </z-button>
                <z-link
                    v-if="!isTablet"
                    @click="scrollUp"
                    :underlined="false"
                    icon="arrow_buttons"
                    icon-position="right"
                    class="schedule-filter__scroll-up"
                >
                    Наверх
                </z-link>
                <z-link
                    v-if="!isTablet"
                    @click="onClear"
                    :disabled="isClearButtonDisabled"
                    :underlined="false"
                    icon="close"
                    icon-position="right"
                    class="schedule-filter__clear"
                >
                    Сбросить фильтр
                </z-link>
                <z-button
                    v-if="isTablet"
                    @click="onClear"
                    :disabled="isClearButtonDisabled"
                    kind="bordered"
                    size="s"
                    :show-hover-animation="false"
                >
                    Сбросить фильтр
                </z-button>
            </div>
        </div>
    </div>
</template>

<script>
import { clearKeys } from '@/utils/clearKeys'

export default {
    name: 'schedule-filter',
    props: {
        data: Object,
        initialState: Object,
        isTablet: Boolean
    },
    data () {
        return {
            params: {
                studyFormat: '',
                group: '',
                course: '',
                curator: ''
            },
            filterFields: [
                { key: 'group', placeholder: 'Группа', type: 'select' },
                { key: 'course', placeholder: 'Наименование курса', type: 'select', class: 'schedule-filter__name' },
                { key: 'curator', placeholder: 'Куратор', type: 'select', class: 'schedule-filter__name' }
            ],
            isFilterOpen: false
        }
    },
    computed: {
        filterData () {
            return {
                group: this.filtredOptions(this.data?.group) || [],
                course: this.filtredOptions(this.data?.course) || [],
                curator: this.filtredOptions(this.data?.curator) || [],
                studyFormat: this.filtredOptions(this.data?.studyFormat) || []
            }
        },
        hasStudyFormat () {
            return !!this.filterData.studyFormat.length
        },
        isClearButtonDisabled () {
            return !Object.values(this.params).some(value => value)
        }
    },
    methods: {
        toggleFilterState () {
            this.isFilterOpen = !this.isFilterOpen
        },
        scrollUp () {
            const parentContainer = this.$parent.$refs.containerEl

            if (parentContainer) {
                parentContainer.scrollIntoView({
                    behavior: 'smooth',
                    block: 'start'
                })
            } else {
                window.scrollTo({ top: 0, behavior: 'smooth' })
            }
        },
        clear () {
            this.params = clearKeys(this.params, 'studyFormat', 'group', 'course', 'curator')

            if (this.$refs.filterSelect && this.$refs.filterSelect.length) {
                this.$refs.filterSelect.forEach(elem => {
                    elem.removeSelected()
                })
            }
        },
        onClear () {
            this.clear()
            this.send()
        },
        send () {
            this.$emit('change', this.params)
        },
        toggleTag (tagId) {
            this.params.studyFormat = this.params.studyFormat === tagId ? '' : tagId
            this.send()
        },
        isTagActive (tagId) {
            return this.params.studyFormat === tagId
        },
        updateParams (params) {
            this.params = {...this.params, ...params}
        },
        getTextValue (key, id) {
            const array = this.filterData[key] || []
            const foundObject = array.find(item => item.id === id)
            return foundObject ? foundObject.text : ''
        },
        toggleFilterVisibility () {
            this.$emit('toggle-filter')
        },
        filtredOptions (options) {
            if (!options) return []
            return options.filter(item => !item.disabled)
        }
    }
}

</script>

<style lang="scss">
.schedule-filter {
    @include breakpoint(tablet) {
        position: fixed;
        top: 0;
        bottom: 0;
        background: white;
        width: 100%;
        left: 0;
        right: 0;
        height: 100%;
        padding: 40px;
        z-index: 1;
        overscroll-behavior: contain;
    }

    @include breakpoint(mobile) {
        padding: $token-spacers-s $token-spacers-2-xs;
    }

    &__container {
        display: flex;
        flex-direction: column;
        gap: $token-spacers-xs;

        @include breakpoint(tablet) {
            max-width: 607px;
        }
    }

    &__placeholder {
        font-weight: 600;
        display: block;
        font-size: var(--fontSizeXs);
        line-height: 1.3;
        color: $token-colors-form-placeholder-color-default;
        margin-bottom: 4px;
    }

    &__buttons {
        flex-shrink: 0;
        display: flex;
        justify-content: space-between;
        gap: $token-spacers-2-xs;

        @include breakpoint(tablet) {
            justify-content: flex-start;

            .z-button--size-s {
                font-size: var(--fontSize3xs);
                padding: 13px $token-spacers-xs;
                border-radius: 80px;
            }
        }

        @include breakpoint(mobile) {
            flex-direction: column;
        }

        .z-button__icon .z-icon {
            margin-bottom: 0;
        }
    }

    &__field {
        width: 100%;

        .z-select input {
            width: calc(100% - 30px);
        }

    }

    .z-select.is-filled .z-select__placeholder {
        display: none;
    }

    &__item {
        margin-top: 12px;
        margin-right: 12px;
        border: none;
        display: inline-block;
        padding: $token-spacers-3-xs $token-spacers-2-xs;
        background: $token-colors-bg-5;
        border-radius: 40px;
        color: $token-colors-black-80;
        font-size: var(--fontSize2xs);
        text-align: left;
        transition: all $transition;

        &:before {
            content: '';
            display: inline-block;
            background: #0077C8;
            border-radius: 50%;
            width: 8px;
            height: 8px;
            margin-right: $token-spacers-3-xs;
            transition: background $transition;
        }

        &:last-child {
            margin-right: 0;
        }

        &.is-selected {
            color: $token-colors-white;
            background: $token-colors-bg-30;

            &:before {
                background: $token-colors-white;
            }
        }

        &.is-disabled {
            pointer-events: none;
            opacity: .5;
        }

        &:hover {
            box-shadow: 0 8px 8px rgba(0, 32, 51, 0.04), 0 12px 28px rgba(0, 32, 51, 0.12);
        }

        &--do, &--evening, &--daytime {
            &:before {
                background: $token-colors-program-proffessional;
            }

            &.is-selected {
                background: $token-colors-program-proffessional;
            }
        }

        &--exam {
            background-color: #FFF2DB;

            &:before {
                background: $token-colors-program-distance;
            }

            &.is-selected {
                background: $token-colors-program-distance;
            }
        }
    }

    &__button-link.z-button {
        background-color: transparent !important;
        border: none;
        padding: 0;
        text-transform: unset;
        justify-content: flex-start;
        font-size: var(--fontSize3xs);
    }

    &__button-link--close.z-button {
        font-size: var(--fontSizeXl);
        .z-icon {
            transform: rotate(-90deg);
            margin-bottom: 0;
        }
    }

    &__clear,
    &__scroll-up {
        font-size: $token-typo-3-xs-desktop-size;
        cursor: pointer;
    }

    &__name {
        .z-select__dropdown {
            @media (min-width: 1360px) and (max-height: 914px) {
                top: auto;
                bottom: calc(100% + var(--formBorderWidth));
            }
        }
    }
}
</style>
