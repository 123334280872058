/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendar/calendar-31': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M16.5 2.25a.75.75 0 01.75.75v.75h2.25a1.5 1.5 0 011.5 1.5v15a1.5 1.5 0 01-1.5 1.5h-15a1.5 1.5 0 01-1.5-1.5v-15a1.5 1.5 0 011.5-1.5h2.25V3a.75.75 0 011.5 0v.75h7.5V3a.75.75 0 01.75-.75zm.75 3h2.25v3h-15v-3h2.25V6a.75.75 0 001.5 0v-.75h7.5V6a.75.75 0 001.5 0v-.75zm2.25 4.5h-15v10.5h15V9.75zM8.625 12a.75.75 0 000 1.5H9.69l-.526.656a.75.75 0 00.586 1.219.75.75 0 11-.53 1.28.75.75 0 00-1.06 1.062 2.25 2.25 0 102.845-3.46l.83-1.039A.75.75 0 0011.25 12H8.625zm6.71.079a.75.75 0 01.415.67v4.876a.75.75 0 01-1.5 0V14.25l-.3.225a.75.75 0 11-.9-1.2l1.5-1.125a.75.75 0 01.785-.071z" _fill="#81899D"/>'
  }
})
