/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tag-color/pin': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.988 4.547a22.122 22.122 0 01.477-.311l.059.055c.096.092.206.202.348.344l3.494 3.493a21.8 21.8 0 01.398.407l-.042.068c-.072.113-.158.242-.269.409l-2.064 3.096-.068.101c-.731 1.098-1.19 1.784-1.52 2.545-.044.1-.086.202-.126.304-.304.772-.466 1.582-.725 2.875l-.023.119-.182.91c-.756-.524-1.14-.792-1.514-1.067a33.7 33.7 0 01-7.126-7.126 63.386 63.386 0 01-1.066-1.514l.91-.182.118-.023c1.294-.259 2.103-.42 2.875-.725.102-.04.203-.082.304-.126.76-.33 1.447-.789 2.545-1.52l.1-.068 3.097-2.064zm.743-2.993c-.515-.051-.941.1-1.268.265-.281.14-.585.343-.871.534l-.046.03-3.096 2.065c-1.23.82-1.718 1.14-2.241 1.367a6.713 6.713 0 01-.22.091c-.53.209-1.1.328-2.55.618l-1.11.222-.05.01c-.158.03-.376.074-.56.143a2.3 2.3 0 00-1.266 3.135c.084.177.21.36.303.491l.029.042.014.02c.584.844.893 1.29 1.213 1.725a36.31 36.31 0 002.647 3.19l-4.734 4.735a1.3 1.3 0 101.838 1.838l4.734-4.734a36.305 36.305 0 003.191 2.647c.435.32.881.63 1.725 1.213l.02.014.042.03c.132.091.314.218.491.302a2.3 2.3 0 003.135-1.267c.07-.183.112-.4.143-.558l.01-.05.222-1.11c.29-1.45.41-2.021.618-2.552l.09-.219c.228-.523.548-1.01 1.368-2.24l2.064-3.097.03-.046c.192-.286.395-.59.535-.87.165-.328.316-.754.265-1.269-.05-.514-.282-.903-.508-1.191a8.806 8.806 0 00-.695-.75l-.039-.038-3.494-3.494-.039-.039a8.777 8.777 0 00-.749-.695c-.288-.226-.677-.457-1.191-.508z" _fill="#1971CF"/>'
  }
})
