<template>
    <button
        :class="[
            'z-tag__button',
            `z-tag--${computedTheme}`,
            {'z-tag--marker': isMarker},
            {'is-active': isActive}]"
        v-if="button"
        @click="$emit('click')">
        <slot />
    </button>
    <span
        :class="['z-tag', `z-tag--${computedTheme}`]"
        v-else>
        <slot />
    </span>
</template>

<script>
export default {
    name: 'z-tag',
    props: {
        button: {
            type: Boolean
        },
        xmlId: {
            type: String
        },
        theme: {
            type: String,
            default: null,
            validator: (value) => [
                'primary',
                'secondary',
                'white',
                'blue',
                'orange',
                'green',
                'violet',
                'light-blue',
                'celadon',
                'yellow',
                'purple-pink',
                'alphabet-blue'
            ].includes(value)
        },
        isMarker: {
            type: Boolean
        },
        isActive: {
            type: Boolean
        }
    },
    computed: {
        computedTheme () {
            return this.theme || this.getTheme(this.xmlId) || 'primary'
        }
    },
    methods: {
        getTheme (xmlId) {
            const themes = {
                tribune: 'light-blue',
                'library-night': 'orange',
                'master-classes': 'yellow',
                trainings: 'violet',
                'new-life': 'celadon',
                'knowledge-day': 'green',
                'without-a-project': 'alphabet-blue',
                vitaminku: 'purple-pink'
            }
            return themes[xmlId] || 'primary'
        }
    }
}
</script>

<style lang="scss">
.z-tag {
    padding: 4px $token-spacers-2-xs;
    font-weight: 400;
    font-size: var(--fontSizeS);
    color: $token-colors-black-50;
    line-height: 1.3;
    border: none;
    border-radius: 20px;
    display: inline-flex;
    max-width: 100%;
    align-items: center;

    &--primary {
        background-color: #EFF6FB;
    }

    &--secondary {
        padding: $token-spacers-3-xs $token-spacers-2-xs;
        background-color: $token-colors-alert-bg;
        color: #576B90;
    }

    &--white {
        background-color: $token-colors-white;
        color: $token-colors-black-70;
    }

    &--blue {
        background-color: #80bbe3;
        color: $token-colors-white;
    }

    &--white,
    &--blue {
        font-weight: 500;
        line-height: 1.2;
        padding: $token-spacers-3-xs $token-spacers-xs;

        @include breakpoint(v-tablet) {
            padding: 4px $token-spacers-2-xs;
            font-size: var(--fontSizeXs);
        }
    }

    &--orange {
        background-color: $token-colors-program-target;
    }

    &--yellow {
        background-color: #ECC57B;
    }

    &--green {
        background-color: #77BA76;
    }

    &--violet {
        background-color: #A69DCB;
    }

    &--light-blue {
        background-color: #71B7E7;
    }

    &--celadon {
        background-color: #23919A;
    }

    &--purple-pink {
        background-color: #dd81d1;
    }

    &--alphabet-blue {
        background-color: #abcdef;
    }

    &--orange, &--yellow, &--green, &--violet, &--light-blue, &--celadon, &--alphabet-blue, &--purple-pink {
        color: $token-colors-white;
        padding: $token-spacers-3-xs $token-spacers-xs;
        font-weight: 500;
        line-height: 1.2;

        @include breakpoint(v-tablet) {
            padding: 4px $token-spacers-2-xs;
            font-size: var(--fontSizeXs);
        }
    }

    &--marker {
        padding: $token-spacers-2-xs $token-spacers-s $token-spacers-2-xs 50px;
        position: relative;
        font-size: var(--fontSizeXs);
        font-weight: 400;
        line-height: 1.2;
        border: none;
        border-radius: 40px;
        transition: background-color .15s ease-in-out;
        background-color: $token-colors-white;
        color: #4E566A;

        &:before {
            content: '';
            width: 10px;
            height: 10px;
            position: absolute;
            border-radius: 50%;
            background-color: $token-colors-white;
            left: $token-spacers-s;
            top: 50%;
            transform: translateY(-50%);
        }

        &:hover, &.is-active {
            color: $token-colors-white;

            &:before {
                background-color: $token-colors-white !important;
            }
        }

        &.z-tag--orange {
            &:before, &:hover, &.is-active {
                background-color: $token-colors-program-target;
            }
        }

        &.z-tag--yellow {
            &:before, &:hover, &.is-active {
                background-color: #ECC57B;
            }
        }

        &.z-tag--green {
            &:before, &:hover, &.is-active {
                background-color: #77BA76;
            }
        }

        &.z-tag--violet {
            &:before, &:hover, &.is-active {
                background-color: #A69DCB;
            }
        }

        &.z-tag--light-blue {
            &:before, &:hover, &.is-active {
                background-color: #71B7E7;
            }
        }

        &.z-tag--celadon {
            &:before, &:hover, &.is-active {
                background-color: #23919A;
            }
        }

        &.z-tag--purple-pink {
            &:before, &:hover, &.is-active {
                background-color: #dd81d1;
            }
        }

        &.z-tag--alphabet-blue {
            &:before, &:hover, &.is-active {
                background-color: #abcdef;
            }
        }
    }

    &__button {

        &.z-tag--primary,
        &.z-tag--secondary {
            color: $token-colors-black-80;
            font-weight: 400;
            font-size: var(--fontSizeXs);
            line-height: 1.2;
            padding: $token-spacers-3-xs $token-spacers-2-xs;
            background-color: $token-colors-warning-bg;
            border: none;
            border-radius: 20px;
            transition: background-color .15s ease-in-out;

            &:hover {
                background-color: $token-colors-warning-bg;
            }

            &:active {
                background-color: $token-colors-bg-11;
            }

            &:disabled {
                background-color: $token-colors-black-10;
            }
        }
    }
}
</style>
