<template>
    <nav
        class="navigation-bottom"
        aria-label="Bottom navigation"
    >
        <ul class="navigation-bottom__list" :class="{[`navigation-bottom__list--columns navigation-bottom__list--columns-${columns}`]: columns}">
            <li
                v-for="(item, index) in data"
                :key="index"
                class="navigation-bottom__item"
            >
                <z-link
                    class="u-semi-bold"
                    theme="dark"
                    :href="item.link"
                    :title="item.name"
                    :underlined="false"
                >
                    <span v-html="item.name"></span>
                </z-link>
            </li>
        </ul>
    </nav>
</template>

<script>
export default {
    name: 'navigation-bottom',
    props: {
        data: Array,
        columns: [String, Number]
    }
}

</script>

<style lang="scss" src="./index.scss"></style>
