/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'round/remind-gray': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<circle pid="0" cx="20" cy="20" r="19.5" _fill="#C6C9D6" _stroke="#C6C9D6"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M25.5 10.25a1 1 0 10-2 0v.5h-7v-.5a1 1 0 10-2 0v.5h-2a1.75 1.75 0 00-1.75 1.75v15c0 .966.784 1.75 1.75 1.75h15a1.75 1.75 0 001.75-1.75v-15a1.75 1.75 0 00-1.75-1.75h-2v-.5zm1.75 5v-2.5H25.5v.5a1 1 0 11-2 0v-.5h-7v.5a1 1 0 11-2 0v-.5h-1.75v2.5h14.5zm-14.5 2h14.5v10h-14.5v-10zm4 5.25a1 1 0 011-1H19v-1.25a1 1 0 112 0v1.25h1.25a1 1 0 110 2H21v1.25a1 1 0 11-2 0V23.5h-1.25a1 1 0 01-1-1z" _fill="#7586A6"/>'
  }
})
