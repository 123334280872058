/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tag_video': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<circle pid="0" cx="24" cy="24" r="24" _fill="#3192D8"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M24 10.667c7.364 0 13.333 5.97 13.333 13.333 0 7.364-5.97 13.333-13.333 13.333-7.364 0-13.333-5.97-13.333-13.333 0-7.364 5.97-13.333 13.333-13.333zm0 2.666c-5.891 0-10.667 4.776-10.667 10.667S18.11 34.667 24 34.667 34.667 29.89 34.667 24 29.89 13.333 24 13.333zm-1.995 4.182l8 5.333c.83.485.88 1.642.147 2.205l-8.147 5.432A1.333 1.333 0 0120 29.333V18.667a1.333 1.333 0 012.005-1.152z" _fill="#EFF6FB"/>'
  }
})
