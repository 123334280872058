/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'buttons/check': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<g clip-path="url(#clip0_1188_14234)"><path pid="0" d="M5.658 10.817L2.183 7.342 1 8.517l4.658 4.658 10-10L14.483 2l-8.825 8.817z" _fill="#1971CF"/></g><defs><clipPath id="clip0_1188_14234"><path pid="1" _fill="#fff" transform="matrix(0 -1 -1 0 16 16)" d="M0 0h16v16H0z"/></clipPath></defs>'
  }
})
