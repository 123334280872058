/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'download_simple': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8 1.9a.6.6 0 01.6.6v5.552l1.6-1.6a.6.6 0 11.85.848L8.423 9.924a.6.6 0 01-.848 0L4.95 7.3a.6.6 0 11.848-.849l1.601 1.6V2.5a.6.6 0 01.6-.6zM3.1 9.5a.6.6 0 00-1.2 0V13A1.1 1.1 0 003 14.1h10a1.1 1.1 0 001.1-1.1V9.5a.6.6 0 10-1.2 0v3.4H3.1V9.5z" _fill="#0078CE"/>'
  }
})
