/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'buttons/google_calend': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<g clip-path="url(#clip0_1188_14257)"><path pid="0" d="M2.242 0C1.557 0 .988.569.988 1.254v1.542c-.21.117-.403.265-.559.456a1.909 1.909 0 00-.39 1.587v.001l.839 4.084-.837 4.073c-.116.564.03 1.15.392 1.595v.001c.364.446.909.705 1.484.705h12.14c.58 0 1.132-.262 1.5-.712v-.002c.367-.45.513-1.042.397-1.612l-.832-4.048.84-4.087a1.907 1.907 0 00-.39-1.586l-.002-.002a1.887 1.887 0 00-.559-.454V1.254C15.011.569 14.443 0 13.758 0H2.242zm.021 1.275h11.474V2.55H2.263V1.275zm-.356 2.55h11.83v.002h.37a.628.628 0 01.606.753l-.866 4.215-.026.129.026.128.857 4.177a.662.662 0 01-.648.794H1.918a.64.64 0 01-.627-.77l.862-4.201.027-.128-.027-.129-.865-4.211h.001a.63.63 0 01.619-.76zm4.369 1.912c-.797 0-1.52.217-1.883.434l.29 1.014c.29-.145.797-.363 1.304-.363.58 0 .869.29.869.653 0 .506-.58.724-1.087.724H5.19v1.014h.58c.652 0 1.231.29 1.231.87 0 .434-.362.796-1.086.796-.58 0-1.16-.217-1.45-.362l-.29 1.16c.361.217 1.014.434 1.81.434 1.594 0 2.535-.797 2.535-1.884 0-.869-.58-1.376-1.303-1.521.725-.29 1.087-.796 1.087-1.448 0-.869-.725-1.521-2.028-1.521zm3.636 0v6.374h1.275V5.737H9.912z" _fill="#0072D9"/></g><defs><clipPath id="clip0_1188_14257"><path pid="1" _fill="#fff" d="M0 0h16v16H0z"/></clipPath></defs>'
  }
})
