import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/ScrollTrigger'
gsap.registerPlugin(ScrollTrigger)

class SectionAnimation {
    constructor (container) {
        if (!container) {
            return
        }

        this.container = container
        this.transformedEls = this.container.querySelectorAll('[data-scroll-translate]')
        this.scaledEls = this.container.querySelectorAll('[data-scroll-scale]')
        this.revealedEls = this.container.querySelectorAll('[data-scroll-reveal]')
        this.initScrollTrigger()
    }

    initScrollTrigger () {
        this.transformedEls.forEach((el, idx) => {
            gsap.fromTo(el, {
                autoAlpha: 0,
                x: '-10%'
            }, {
                scrollTrigger: {
                    trigger: el,
                    start: '-5% bottom',
                    end: 'bottom bottom',
                    scrub: 1
                },
                autoAlpha: 1,
                x: 0
            })
        })
        this.scaledEls.forEach((el) => {
            gsap.fromTo(el, {
                scale: 0.95
            }, {
                scrollTrigger: {
                    trigger: el,
                    start: '20% 80%',
                    end: '50% 50%',
                    scrub: 1
                },
                scale: 1
            })
        })
        this.revealedEls.forEach((el) => {
            gsap.fromTo(el, {
                autoAlpha: 0
            }, {
                scrollTrigger: {
                    trigger: el,
                    start: '-5% 70%',
                    end: '40% 50%',
                    scrub: 1
                },
                autoAlpha: 1
            })
        })
    }
}

export default SectionAnimation
