<template>
    <div class="scheme-group"
        :class="[
            { 'is-nested': nested },
            { 'is-opened': isOpen }
        ]"
        v-if="data"
    >
        <div class="scheme-group__header">
            <p
                class="scheme-group__name"
                v-if="data.name"
            >
                <span v-html="data.name"></span>
            </p>
            <button
                @click="toggleOpenState"
                class="scheme-group__trigger"
                v-if="(data.sections && data.sections.length) || (data && data.people)"
            ></button>
        </div>

        <transition
            @enter="animationEnter"
            @leave="animationLeave"
        >
            <div class="scheme-group__content" v-show="isOpen">
                <scheme-card
                    v-for="(item, index) in data.people"
                    :key="index"
                    :data="item"
                />

                <template v-if="data.sections && data.sections.length">
                    <scheme-group
                        v-for="(section, indexSection) in data.sections"
                        :key="`${indexSection}-section-nested`"
                        :data="section"
                        nested
                    />
                </template>
            </div>
        </transition>
    </div>
</template>

<script>
import Velocity from 'velocity-animate'
import SchemeCard from './Card.vue'

export default {
    name: 'scheme-group',
    props: {
        data: Object,
        nested: {
            type: Boolean,
            default: false
        }
    },
    components: {
        SchemeCard
    },
    data () {
        return {
            isOpen: false
        }
    },
    methods: {
        animationEnter (el, done) {
            Velocity(el, 'slideDown', { duration: this.duration })
        },
        animationLeave (el, done) {
            Velocity(el, 'slideUp', { duration: this.duration })
        },
        toggleOpenState () {
            this.isOpen = !this.isOpen
        }
    }
}
</script>
<style lang="scss">
.scheme-group {
    $parent: &;
    background: #DEEDF7;
    border: 1px solid #DEEDF7;
    border-radius: 10px;

    &__name {
        display: flex;
        font-size: var(--fontSizeXs);
        font-weight: 600;
        margin: 0;
    }

    &__trigger {
        background: none;
        background-image: url("data:image/svg+xml,%3Csvg width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.0004 20.6569H26.3141' stroke='%23626C84' stroke-width='1.5' stroke-linecap='round'/%3E%3Cpath d='M20.6569 15.0001V26.3138' stroke='%23626C84' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E%0A");
        width: 42px;
        height: 42px;
        border: none;
        position: absolute;
        bottom: 0;
        right: 0;
        transition: transform $transition;
    }

    &__header {
        position: relative;
        padding: 32px 12px;
        background: inherit;
        border: 1px solid inherit;
        border-radius: 10px;
    }

    &.is-opened {
        > .scheme-group__header #{$parent}__trigger {
            background-image: url("data:image/svg+xml,%3Csvg width='42' height='42' viewBox='0 0 42 42' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.0004 20.6569H26.3141' stroke='%23626C84' stroke-width='1.5' stroke-linecap='round'/%3E%3C/svg%3E%0A");
        }
    }

    &.is-nested {
        background: #EFF6FB;

        #{$parent}__name {
            font-size: var(--fontSize2xs);
            font-weight: 400;

            &:before {
                content: '';
                width: 12px;
                height: 10px;
                display: block;
                border-bottom: 1px solid;
                border-left: 1px solid;
                border-color: #A1A7B5;
                border-bottom-left-radius: 6px;
                flex-shrink: 0;
                margin-right: 6px;
                margin-top: 0.1em;
            }
        }

        .scheme-card__img {
            width: 40px;
            height: 40px;
            margin-bottom: -20px;
        }
    }
}
</style>
