/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'circle-icon-3': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<rect pid="0" width="40" height="40" rx="20" _fill="#0072D9"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M23.454 12.5H16a.5.5 0 00-.5.5v.523h8.454V13a.5.5 0 00-.5-.5zM15.5 24.977v-9.954h8.454v9.954H15.5zm0 1.5V27a.5.5 0 00.5.5h7.454a.5.5 0 00.5-.5v-.523H15.5zM16 11a2 2 0 00-2 2v14a2 2 0 002 2h7.454a2 2 0 002-2V13a2 2 0 00-2-2H16z" _fill="#fff"/>'
  }
})
