/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/txt': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" d="M8 11a8 8 0 018-8h13l12 12v21a8 8 0 01-8 8H16a8 8 0 01-8-8V11z" _fill="#C6C9D6"/><path pid="1" d="M20.451 25.932h-2.724V33h-1.644v-7.068H13.36V24.48h7.092v1.452zm1.946-1.452l2.112 3.144 2.1-3.144h1.8l-2.952 4.32 2.856 4.2h-1.8l-2.004-3.024L22.493 33h-1.812l2.856-4.2-2.952-4.32h1.812zm13.242 1.452h-2.724V33H31.27v-7.068h-2.724V24.48h7.092v1.452z" _fill="#4A5D7F"/><path pid="2" d="M29 3l12 12h-8a4 4 0 01-4-4V3z" _fill="#EFF0F3"/>'
  }
})
