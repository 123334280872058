/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'printer': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4 1.9a.6.6 0 00-.6.6v1.9h-.567c-.884 0-1.683.672-1.683 1.6v5a.6.6 0 00.6.6H3.4v2.15a.6.6 0 00.6.6h8a.6.6 0 00.6-.6V11.6h1.65a.6.6 0 00.6-.6V6c0-.928-.8-1.6-1.684-1.6H12.6V2.5a.6.6 0 00-.6-.6H4zM2.833 5.6h10.333c.313 0 .484.223.484.4v4.4H12.6v-.9a.6.6 0 00-.6-.6H4a.6.6 0 00-.6.6v.9H2.35V6c0-.177.17-.4.483-.4zM4.6 4.4h6.8V3.1H4.6v1.3zm6.8 5.7v3.05H4.6V10.1h6.8zm1.1-2.85a.75.75 0 11-1.5 0 .75.75 0 011.5 0z" _fill="#0078CE"/>'
  }
})
