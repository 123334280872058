/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'external': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<g clip-path="url(#clip0_379_14497)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9.25 2a.75.75 0 01.75-.75h4a.748.748 0 01.75.75v4a.75.75 0 01-1.5 0V3.81L7.197 9.865a.75.75 0 11-1.06-1.061l6.052-6.053H10A.75.75 0 019.25 2zM3.333 4.75a.583.583 0 00-.583.583v7.334a.583.583 0 00.583.583h7.334a.583.583 0 00.583-.583v-4a.75.75 0 011.5 0v4a2.083 2.083 0 01-2.083 2.083H3.333a2.083 2.083 0 01-2.083-2.083V5.333A2.083 2.083 0 013.333 3.25h4a.75.75 0 110 1.5h-4z" _fill="#0078CE"/></g><defs><clipPath id="clip0_379_14497"><path pid="1" _fill="#fff" d="M0 0h16v16H0z"/></clipPath></defs>'
  }
})
