/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow_slider': {
    width: 25,
    height: 15,
    viewBox: '0 0 25 15',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.29.593l11.994 11.994a1.012 1.012 0 01-1.431 1.431L12.29 3.456 1.728 14.018a1.012 1.012 0 11-1.432-1.431L12.29.593z" _fill="#333"/>'
  }
})
