/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/rar': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" d="M8 11a8 8 0 018-8h13l12 12v21a8 8 0 01-8 8H16a8 8 0 01-8-8V11z" _fill="#C6C9D6"/><path pid="1" d="M13.376 33v-8.52h3.768c.392 0 .752.08 1.08.24.336.16.624.376.864.648.248.272.436.576.564.912.136.336.204.68.204 1.032 0 .36-.064.708-.192 1.044-.12.328-.296.616-.528.864-.232.248-.504.44-.816.576L20.264 33H18.44l-1.752-2.856h-1.656V33h-1.656zm1.656-4.308h2.076c.208 0 .392-.06.552-.18.16-.128.288-.296.384-.504.096-.208.144-.44.144-.696 0-.272-.056-.508-.168-.708a1.365 1.365 0 00-.432-.492.93.93 0 00-.552-.18h-2.004v2.76zm8.64-4.212h1.68L28.486 33H26.78l-.792-2.124h-2.976L22.233 33h-1.704l3.144-8.52zm2.017 5.232l-1.176-3.516-1.224 3.516h2.4zM29.419 33v-8.52h3.768c.392 0 .752.08 1.08.24.336.16.624.376.864.648.248.272.436.576.564.912.136.336.204.68.204 1.032 0 .36-.064.708-.192 1.044-.12.328-.296.616-.528.864-.232.248-.504.44-.816.576L36.307 33h-1.824l-1.752-2.856h-1.656V33H29.42zm1.656-4.308h2.076c.208 0 .392-.06.552-.18.16-.128.288-.296.384-.504.096-.208.144-.44.144-.696 0-.272-.056-.508-.168-.708a1.365 1.365 0 00-.432-.492.93.93 0 00-.552-.18h-2.004v2.76z" _fill="#4A5D7F"/><path pid="2" d="M29 3l12 12h-8a4 4 0 01-4-4V3z" _fill="#EFF0F3"/>'
  }
})
