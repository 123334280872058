/* eslint-disable */
require('./ad')
require('./arrow_buttons')
require('./arrow_slider-defolt')
require('./arrow_slider-hover')
require('./arrow_slider-subtract')
require('./arrow_slider')
require('./arrow_up40')
require('./arrow-up-right')
require('./burger-close')
require('./burger')
require('./buttons')
require('./calendar-blank')
require('./calendar')
require('./calendarbell')
require('./calendarblank')
require('./caretup')
require('./check')
require('./circle-icon-1')
require('./circle-icon-2')
require('./circle-icon-3')
require('./circle-icon-4')
require('./circle-icon-5')
require('./circle-icon-6')
require('./clock')
require('./close-round')
require('./close')
require('./download_simple')
require('./email')
require('./enlarged_version-header')
require('./envelope')
require('./external')
require('./file')
require('./general')
require('./glasses')
require('./google')
require('./home')
require('./ical')
require('./image-square')
require('./img_box')
require('./internal')
require('./long_arrow-horizontal')
require('./long_arrow')
require('./mappin')
require('./microphone')
require('./mobile')
require('./no-image-square')
require('./outlook')
require('./owl')
require('./printer')
require('./quotes')
require('./refresh')
require('./room')
require('./round-arrow')
require('./round-up')
require('./round')
require('./search')
require('./settings')
require('./Star 1')
require('./tag_add_calend')
require('./tag_add_pin')
require('./tag_bell')
require('./tag_color_dots_icon-1')
require('./tag_color_dots_icon-2')
require('./tag_color_dots_icon-3')
require('./tag_color_dots_icon-4')
require('./tag_img')
require('./tag_share')
require('./tag_video')
require('./tag-color')
require('./teacher')
require('./tooltip')
require('./userpic')
require('./video_file')
require('./vk')
require('./youtube')
