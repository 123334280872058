/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendarblank': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M25.75 11a1 1 0 10-2 0v.5h-7V11a1 1 0 10-2 0v.5h-2A1.75 1.75 0 0011 13.25v15c0 .966.784 1.75 1.75 1.75h15a1.75 1.75 0 001.75-1.75v-15a1.75 1.75 0 00-1.75-1.75h-2V11zm1.75 5v-2.5h-1.75v.5a1 1 0 11-2 0v-.5h-7v.5a1 1 0 11-2 0v-.5H13V16h14.5zM13 18h14.5v10H13V18zm4 5.25a1 1 0 011-1h1.25V21a1 1 0 112 0v1.25h1.25a1 1 0 110 2h-1.25v1.25a1 1 0 11-2 0v-1.25H18a1 1 0 01-1-1z" _fill="#1F2A40"/>'
  }
})
