/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tag_bell': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<rect pid="0" x=".5" y=".5" width="47" height="47" rx="23.5" _fill="#DB674B"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M23.5 12.3a7.996 7.996 0 00-7.95 7.15l-.345 3.233c-.055.517-.077.725-.111.926-.111.65-.316 1.28-.607 1.87-.09.184-.195.366-.454.817l-.853 1.485-.04.072c-.348.604-.673 1.17-.86 1.648-.2.509-.379 1.246.043 1.976.423.73 1.151.942 1.692 1.023.509.075 1.161.075 1.858.075h3.961c.21.914.54 1.758.986 2.444.594.91 1.504 1.681 2.68 1.681 1.176 0 2.086-.77 2.68-1.681.446-.686.775-1.53.986-2.444h3.961c.697 0 1.349 0 1.858-.075.54-.08 1.27-.293 1.691-1.023.423-.73.244-1.467.045-1.976-.188-.479-.513-1.044-.86-1.648l-.041-.072-.853-1.485c-.26-.451-.363-.633-.454-.816a6.803 6.803 0 01-.606-1.87c-.035-.202-.057-.41-.112-.927l-.344-3.233A7.996 7.996 0 0023.5 12.3zm1.181 20.275h-2.362c.144.456.32.839.512 1.133.367.563.634.592.669.592.035 0 .302-.029.669-.592.192-.294.368-.677.512-1.133zm-6.745-12.871a5.596 5.596 0 0111.128 0l.344 3.232.004.04c.05.465.08.752.129 1.036.15.879.426 1.731.82 2.53.128.26.271.509.504.914l.02.035.854 1.486c.308.537.515.902.647 1.174-.302.022-.722.024-1.341.024h-15.09c-.62 0-1.039-.002-1.341-.024.131-.272.339-.637.647-1.174l.854-1.486.02-.035c.232-.405.376-.655.503-.913a9.2 9.2 0 00.821-2.531c.049-.284.08-.57.129-1.035l.004-.04.344-3.233zm14.6 10.826v-.003.003zm.25-.436l.003-.002-.003.002zm-18.575-.002l.002.002a.052.052 0 01-.002-.002zm.254.435v0z" _fill="#FCE6D5"/>'
  }
})
