/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'buttons/outlook': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<g clip-path="url(#clip0_1188_14263)"><path pid="0" d="M7.914 0l-.071.016L0 1.759v12.456l7.914 1.759h1.404v-1.997H16V1.997H9.318V0H7.914zm.073 1.348v13.28l-6.656-1.481V2.827l6.656-1.479zm1.331 1.98h5.35v1.331h-5.35V3.328zM4.382 4.659c-.719 0-1.295.315-1.731.943-.436.628-.654 1.45-.654 2.468 0 .963.215 1.746.643 2.345.429.6.984.9 1.664.9.698 0 1.265-.31 1.7-.93.435-.62.652-1.433.652-2.445 0-.984-.21-1.777-.628-2.379-.419-.6-.968-.902-1.646-.902zm-.035 1.329c.303 0 .541.178.715.535.175.357.263.856.263 1.497 0 .615-.09 1.096-.27 1.442-.18.347-.424.52-.73.52-.298 0-.538-.18-.721-.535-.184-.358-.276-.841-.276-1.453 0-.604.092-1.09.276-1.456.183-.367.43-.55.743-.55zm4.971.002h5.35v6.656h-5.35v-1.331h1.357V9.984H9.318V8.653h1.357V7.32H9.318v-1.33zm2.689 1.331v1.332h1.33V7.32h-1.33zm0 2.663v1.33h1.33v-1.33h-1.33z" _fill="#0072D9"/></g><defs><clipPath id="clip0_1188_14263"><path pid="1" _fill="#fff" d="M0 0h16v16H0z"/></clipPath></defs>'
  }
})
