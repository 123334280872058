<template>
    <div class="schedule-top container">
        <div class="schedule-top__wrapper">
            <div class="schedule-top__search">
                <z-input
                    is-filter
                    name="query"
                    class="schedule-top__search--input"
                    placeholder="Ищите по куратору, номеру группы или наименованию программы"
                    alt="Ищите по куратору, номеру группы или наименованию программы"
                    v-model="params.query"
                    @input="onInput"
                />
            </div>
            <z-button
                @click="toggleFilterVisibility"
                class="hide-me--default show-me--tablet schedule-filter__button schedule-filter__button--trigger"
                :class="{ 'is-acitve': isFilterOpen }"
                rounded
                onlyIcon
            >
                <template v-slot:left>
                    <z-icon
                        name="settings"
                        width="22"
                        height="22"
                        color="white"
                    />
                </template>
            </z-button>
            <tools class="schedule-top__tools hide-me--tablet" :is-mobile="isMobile"/>
        </div>
        <div class="schedule-top__bottom">
            <div class="schedule-top__tabs">
                <div
                    class="schedule-top__tab"
                    @click="toggleCity('')"
                    :class="{'schedule-top__tab--selected' : !params.city}"
                >
                    Все
                </div>
                <div
                    v-for="item in getSortedCities()"
                    :key="item.id"
                    class="schedule-top__tab"
                    :class="{'schedule-top__tab--selected' : isCityActive(item.id)}"
                    @click="toggleCity(item.id)"
                >{{ item.text }}
                </div>
            </div>
            <tools class="schedule-top__tools hide-me--default show-me--tablet" :is-mobile="isMobile"/>
        </div>
    </div>
</template>
<script>
import Tools from './Tools.vue'
import { throttle } from 'throttle-debounce'
import { clearKeys } from '@/utils/clearKeys'

export default {
    name: 'ScheduleTop',
    props: {
        data: Object,
        isMobile: Boolean
    },
    data () {
        return {
            params: {
                query: '',
                city: ''
            },
            isFilterOpen: false
        }
    },
    components: { Tools },
    methods: {
        onInput: throttle(500, function () {
            this.send()
        }),
        clear () {
            this.params = clearKeys(this.params, 'city')
        },
        clearCity () {
            this.clear()
            this.send()
        },
        send () {
            this.$emit('change-filter', this.params)
        },
        toggleCity (cityId) {
            this.params.city = this.params.city === cityId ? '' : cityId
            this.params.query = ''
            this.$emit('change-city', this.params)
        },
        isCityActive (cityId) {
            return this.params.city === cityId
        },
        updateParams (params) {
            this.params = {...this.params, ...params}
        },
        toggleFilterVisibility () {
            this.isFilterOpen = !this.isFilterOpen
            this.$emit('toggle-filter')
        },
        getSortedCities () {
            const order = ['Норильск', 'Мончегорск', 'Заполярный']
            return this.data.city.slice().sort((a, b) => {
                const indexA = order.indexOf(a.text)
                const indexB = order.indexOf(b.text)

                if (indexA === -1 && indexB === -1) {
                    return a.text.localeCompare(b.text)
                }
                if (indexA === -1) return 1
                if (indexB === -1) return -1
                return indexA - indexB
            })
        }
    }
}
</script>

<style lang="scss">
.schedule-top {
    background-color: $token-colors-bg-10;
    padding-top: $token-spacers-s;
    border-radius: $borderRadius $borderRadius 0 0;

        &__wrapper {
        display: flex;
        padding-bottom: $token-spacers-m;
        align-items: center;
        position: relative;

        @include breakpoint(tablet) {
            padding-bottom: 36px;

            .z-button {
                width: 52px;
                height: 52px;
                display: flex !important;
                align-items: center;
                justify-content: center;
            }
        }

        @include breakpoint(v-tablet) {
            padding-bottom: $token-spacers-2-xs;

            .z-button {
                position: absolute;
                left: 0;
                top: 62px;
            }
        }
    }

    &__search {
        border-radius: 30px 0 0 0;
        width: 100%;
        padding-right: $token-spacers-xl;

        @include breakpoint(v-tablet) {
            padding-right: 0;
        }

        &--input {
            position: relative;

            .z-input__input {
                padding-right: 70px !important;
            }

            .z-input__placeholder {
                max-width: calc(100% - 16px - 70px);
            }

            &.is-focused .z-input__placeholder,
            &.is-filled .z-input__placeholder {
                display: none;
            }

            .z-input__clear {
                right: 40px;
            }

            &:after {
                content: '';
                background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13 19C13 15.6863 15.6863 13 19 13C22.3137 13 25 15.6863 25 19C25 22.3137 22.3137 25 19 25C15.6863 25 13 22.3137 13 19ZM19 11C14.5817 11 11 14.5817 11 19C11 23.4183 14.5817 27 19 27C20.9036 27 22.652 26.3351 24.0255 25.2249C24.0661 25.4016 24.1552 25.5694 24.2929 25.7071L27.2929 28.7071C27.6834 29.0976 28.3166 29.0976 28.7071 28.7071C29.0976 28.3166 29.0976 27.6834 28.7071 27.2929L25.7071 24.2929C25.5694 24.1552 25.4016 24.0661 25.2249 24.0255C26.3351 22.652 27 20.9036 27 19C27 14.5817 23.4183 11 19 11Z' fill='%230078CE'/%3E%3C/svg%3E%0A");
                background-size: contain;
                background-repeat: no-repeat;
                width: 40px;
                height: 40px;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
                right: 4px;
            }
        }
    }

    &__tabs {
        display: flex;
        background-color: $token-colors-bg-15;
        border-radius: 20px 20px 0 0;
        width: max-content;

        @include breakpoint(mobile) {
            border-radius: 16px 16px 0 0;
        }
    }

    &__tab {
        display: flex;
        padding: $token-spacers-2-xs $token-spacers-m;
        color: #81899D;
        font-weight: 600;
        border-radius: 20px 20px 0 0;
        cursor: pointer;
        font-size: var(--fontSizeXl);

        @include breakpoint(tablet) {
            font-size: var(--fontSizeM);
            padding: 12px $token-spacers-xs;
        }

        @include breakpoint(mobile) {
            font-size: var(--fontSizeXs);
            padding: $token-spacers-3-xs 12px;
            border-radius: 16px 16px 0 0;
        }

        &--selected {
            color: #0077C8;
            background-color: $token-colors-bg-5;
            pointer-events: none;
        }
    }

    &__tools {
        @include breakpoint(v-tablet) {
            position: absolute;
            right: 20px;
            top: 12px;

            .z-button {
                margin: 0;
                margin-left: $token-spacers-xs;
            }
        }
    }

    &__bottom {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 40px;
        position: relative;
        margin-left: -$token-spacers-2-xl;
        margin-right: -$token-spacers-2-xl;

        @include breakpoint(laptop) {
            margin-left: -$token-spacers-xl;
            margin-right: -$token-spacers-xl;
        }
        @include breakpoint(tablet) {
            padding-right: 20px;
            margin-left: -40px;
            margin-right: -40px;
        }

        @include breakpoint(v-tablet) {
            padding-top: $token-spacers-2-xl;
            margin-left: -20px;
            margin-right: -20px;
        }

        @include breakpoint(mobile) {
            padding-right: $token-spacers-2-xs;
            margin-left: -$token-spacers-2-xs;
            margin-right: -$token-spacers-2-xs;
        }
    }
}
</style>
