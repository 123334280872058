<template>
    <div class="events">
        <div class="events__filter">
            <events-filter
                :data="filter"
                @change-filter="changeFilter"
                ref="filter"
                :init-params="initParams"
            ></events-filter>
        </div>
        <template v-if="data.length">
            <div class="events__grid">
                <event-card-new
                    class="events__item"
                    v-for="(item, index) in data"
                    :key="index"
                    :data="item"
                    tag="a"
                />
            </div>
        </template>
        <template v-else>
            <notification hide-image>
                <template v-slot:description>
                    <z-caption size="m">
                        <span v-html="notEventsText"></span>
                    </z-caption>
                    <p class="u-flex--row">Следите за&nbsp;обновлениями&nbsp;<z-link href="https://vk.com/university.nornik" external target="_blank">в&nbsp;соцсетях</z-link>&nbsp;или смотрите&nbsp;<z-link href="javascript:void(0)" @click.prevent="changeTab">наши прошедшие события</z-link>.</p>
                </template>
            </notification>
        </template>
        <z-preloader
            v-if="isLoading"
        />
        <z-pagination
            show-next
            show-prev
            show-nav
            :show-more="{
                marginBottom: 'm',
                size: 'm'
            }"
            @change-page="onPageChange"
            :data="{
                currentPage: this.nav.current,
                totalItems: this.nav.count,
                totalPages: this.nav.total,
                pageRange: 1
            }"
        >
        </z-pagination>
    </div>
</template>

<script>
import EventCardNew from './components/EventCardNew.vue'
import EventsFilter from './components/Filter.vue'
import { getEvents } from '@/api/events'

export default {
    name: 'events',
    components: {
        EventCardNew,
        EventsFilter
    },
    props: {
        source: {
            type: String,
            validator: prop => ['events', 'events-past'].includes(prop)
        },
        initParams: Object,
        past: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            data: this.$root.app.components[this.source].items,
            filter: this.$root.app.components[this.source].filter,
            nav: this.$root.app.components[this.source].nav,
            isLoading: false,
            params: {
                page: 1,
                from: '',
                to: '',
                type: [],
                tags: {},
                format: '',
                past: (this.past || '')
            },
            requestType: ''
        }
    },
    computed: {
        notEventsText () {
            return this.past ? 'Мероприятий не&nbsp;проходило' : 'В&nbsp;ближайшее время мероприятий не&nbsp;ожидается'
        }
    },
    methods: {
        changeTab () {
            this.$parent.$emit('change-tab', 'tabs-past')
            history.pushState('', document.title, window.location.pathname + window.location.search + '#tabs-past')
        },
        changeFilter (params) {
            this.params = { ...this.params, ...params, page: 1, tags: Object.keys(params.tags) }
            this.requestType = 'change-page'
            this.send()
        },
        send () {
            this.isLoading = true

            // костыль, потому что беки не сделали нормальную обработку
            if (!this.params.past) delete this.params.past
            // конец костыля

            getEvents(this.params).then(res => {
                this.updateNav(res)
                this.updateData(res)
                this.isLoading = false
            })
        },
        onPageChange: function (obj) {
            this.params.page = obj.page
            this.requestType = obj.type
            this.loading = true
            this.send()
        },
        updateNav (res) {
            if (res.hasOwnProperty('nav')) {
                this.nav.current = res.nav.current
                this.nav.total = res.nav.total
            }
        },
        updateData (res) {
            if (res.hasOwnProperty('items')) {
                if (this.requestType === 'change-page') {
                    this.data = res.items
                } else {
                    this.data = this.data.concat(res.items)
                }
            }
        }
    }
}
</script>

<style lang="scss">
.events {
    margin-bottom: $token-spacers-2-xl;

    &__filter {
        margin-bottom: $token-spacers-xl;
    }

    .z-pagination {
        margin: $token-spacers-m auto 0;
    }

    &__grid {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: $token-spacers-xl 40px;

        @include breakpoint (tablet) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include breakpoint (mobile) {
            grid-template-columns: repeat(1, 1fr);
        }
    }
}
</style>
