<template>
    <div class="slider-key-indicators">
        <div class="swiper" ref="slider">
            <div class="swiper-wrapper">
                <div class="swiper-slide" v-for="(slide, index) in data" :key="index">
                    <z-card size="m" :theme="slide.theme">
                        <template v-slot:title>
                            <div class="slider-key-indicators__image">
                                <z-icon :name="slide.icon" width="24" height="24" />
                            </div>
                            <div  class="slider-key-indicators__value" v-html="slide.value"></div>
                        </template>
                        <template v-slot:content>
                            <div class="slider-key-indicators__detail" v-html="slide.detail"></div>
                            <div class="slider-key-indicators__description" v-html="slide.description"></div>
                        </template>
                    </z-card>
                </div>
            </div>
        </div>
        <div v-if="shownNav">
            <div class="swiper-button-next"></div>
            <div class="swiper-button-prev"></div>
            <div class="swiper-pagination"></div>
        </div>
    </div>
</template>

<script>
import Swiper from 'swiper/dist/js/swiper.js'

export default {
    name: 'slider-key-indicators',
    props: {
        data: Array
    },
    data () {
        return {
            slider: undefined
        }
    },
    methods: {
        init () {
            let options = {
                // slidesPerView: "auto",
                slidesPerView: 4,
                spaceBetween: 8,
                loop: true,
                pagination: {
                    el: '.swiper-pagination',
                    clickable: true
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev'
                }
            }

            options.breakpoints = {
                1359: {
                    slidesPerView: 3
                },
                1023: {
                    slidesPerView: 2
                },
                599: {
                    slidesPerView: 1
                }
            }

            this.slider = new Swiper(this.$refs.slider, options)
        }
    },
    computed: {
        shownNav () {
            if (this.data.length > 4) {
                return true
            }
            return false
        }
    },
    mounted () {
        this.init()
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
