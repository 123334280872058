/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'video_file': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M13.75 3.82V7c0 .493.002.787.03.997a.704.704 0 00.042.177l.001.003.003.001a.706.706 0 00.177.042c.21.028.504.03.997.03h3.18a6.467 6.467 0 00-.296-.305l-3.829-3.829a6.45 6.45 0 00-.305-.295zm4.5 5.93h-3.295c-.433 0-.83 0-1.152-.043-.356-.048-.731-.16-1.04-.47-.31-.309-.422-.684-.47-1.04-.043-.323-.043-.72-.043-1.152V3.75H9c-.964 0-1.612.002-2.095.067-.461.062-.659.169-.789.3-.13.13-.237.327-.3.788C5.753 5.388 5.75 6.036 5.75 7v10c0 .964.002 1.612.067 2.095.062.461.169.659.3.789.13.13.327.237.788.3.483.064 1.131.066 2.095.066h6c.964 0 1.612-.002 2.095-.067.461-.062.659-.169.789-.3.13-.13.237-.327.3-.788.064-.483.066-1.131.066-2.095V9.75zm-4.998-7.5c.325-.001.677-.002 1.003.133.327.136.575.385.805.616l.056.056 3.829 3.829.056.056c.231.23.48.478.616.805.135.326.134.678.133 1.003v8.304c0 .899 0 1.648-.08 2.242-.084.628-.27 1.195-.726 1.65-.455.456-1.022.642-1.65.726-.594.08-1.344.08-2.242.08H8.948c-.898 0-1.648 0-2.242-.08-.628-.084-1.195-.27-1.65-.725-.456-.456-.642-1.023-.726-1.65-.08-.595-.08-1.345-.08-2.243V6.948c0-.898 0-1.648.08-2.242.084-.628.27-1.195.725-1.65.456-.456 1.023-.642 1.65-.726.595-.08 1.345-.08 2.243-.08h4.304zM10.25 13.172L14.21 15l-3.96 1.828v-3.656zm.586-1.381a1.47 1.47 0 00-2.086 1.334v3.75a1.47 1.47 0 002.086 1.334l4.298-1.983c1.046-.483 1.046-1.97 0-2.452l-4.298-1.983z" _fill="#626C84"/>'
  }
})
