<template>
    <div class="swiper-slide">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'swiper-slide'
}
</script>

<style scoped lang="scss">

.swiper-slide {
    height: auto;
    width: auto;

    .body--hide-img & {
        &::before {
            background: none !important;
        }

        &::after {
            background: var(--lightColor) !important;
        }
    }
}
</style>
