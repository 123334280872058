/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/xls': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" d="M8 11a8 8 0 018-8h13l12 12v21a8 8 0 01-8 8H16a8 8 0 01-8-8V11z" _fill="#C6C9D6"/><path pid="1" d="M15.213 24.48l2.113 3.144 2.1-3.144h1.8l-2.953 4.32L21.13 33h-1.8l-2.004-3.024L15.309 33h-1.811l2.856-4.2-2.952-4.32h1.812zm6.87 8.52v-8.52h1.656v7.068h4.344V33h-6zm12.084-6.288a1.97 1.97 0 00-.312-.228 3.064 3.064 0 00-.54-.276 4.894 4.894 0 00-.684-.228 2.698 2.698 0 00-.756-.108c-.448 0-.788.084-1.02.252-.224.168-.336.404-.336.708 0 .232.072.416.216.552.144.136.36.252.648.348.288.096.648.2 1.08.312.56.136 1.044.304 1.452.504.416.192.732.448.948.768.224.312.336.728.336 1.248 0 .456-.084.848-.252 1.176-.168.32-.4.58-.696.78-.296.2-.632.348-1.008.444a5.254 5.254 0 01-1.2.132c-.424 0-.848-.044-1.272-.132a6.99 6.99 0 01-1.224-.372 5.861 5.861 0 01-1.08-.6l.732-1.428c.072.072.2.168.384.288.184.112.408.228.672.348.264.112.552.208.864.288.312.08.628.12.948.12.448 0 .788-.076 1.02-.228a.73.73 0 00.348-.648.72.72 0 00-.276-.6c-.184-.144-.44-.268-.768-.372a24.404 24.404 0 00-1.164-.36 7.454 7.454 0 01-1.344-.504c-.36-.192-.628-.432-.804-.72-.176-.288-.264-.648-.264-1.08 0-.584.136-1.072.408-1.464.28-.4.656-.7 1.128-.9a3.857 3.857 0 011.572-.312c.4 0 .776.044 1.128.132.36.088.696.204 1.008.348.312.144.592.3.84.468l-.732 1.344z" _fill="#4A5D7F"/><path pid="2" d="M29 3l12 12h-8a4 4 0 01-4-4V3z" _fill="#EFF0F3"/>'
  }
})
