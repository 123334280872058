<template>
    <div class="schedule-tools">
        <z-button class="schedule-tools__item" kind="bordered" rounded onlyIcon @click="onPrint">
            <template v-slot:left>
                <z-icon name="printer" width="22" height="22"></z-icon>
            </template>
        </z-button>
        <z-button
            class="schedule-tools__item"
            kind="bordered"
            rounded
            onlyIcon
            @click="downLoadSchedule"
            :disabled="!downloadEnable"
            onclick="ym(87502284, 'reachGoal', 'schedule-download'); return true;"
        >
            <template v-slot:left>
                <z-icon name="download_simple" width="22" height="22"></z-icon>
            </template>
        </z-button>
        <z-button class="schedule-tools__item" kind="bordered" rounded onlyIcon @click="copyLink">
            <template v-slot:left>
                <z-icon name="internal" width="22" height="22"></z-icon>
            </template>
        </z-button>
    </div>
</template>

<script>
import { showNotyfications } from '@/utils/notifications'
import { getScheduleFile } from '@/api/schedule'

export default {
    name: 'schedule-tools',
    inject: ['studyFormXmlId'],
    props: {
        downloadEnable: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        copyLink () {
            const link = window.location.href
            if (navigator.clipboard) {
                navigator.clipboard.writeText(link)
                    .then(() => {
                        showNotyfications('Ссылка скопирована в буфер обмена', { type: 'success' })
                    })
                    .catch(err => {
                        console.log(err)
                    })
            } else {
                const textArea = document.createElement('textarea')
                textArea.value = link
                document.body.appendChild(textArea)
                textArea.select()
                try {
                    document.execCommand('copy')
                    showNotyfications('Ссылка скопирована в буфер обмена', { type: 'success' })
                } catch (err) {
                    showNotyfications('Невозможно скопировать в буфер обмена', { type: 'error' })
                    console.log(err)
                }
                document.body.removeChild(textArea)
            }
        },
        onPrint () {
            window.print()
        },
        downLoadSchedule () {
            const xmlId = this.studyFormXmlId()
            let params = xmlId === '' ? {} : { id: xmlId }

            getScheduleFile(params)
                .then(response => {
                    if (response.status === 'success') {
                        const link = document.createElement('a')
                        link.setAttribute('target', '_blank')
                        link.setAttribute('download', 'schedule')
                        link.setAttribute('href', response.link)
                        link.click()
                    } else {
                        throw new Error(response.message)
                    }
                })
                .catch((error) => {
                    console.error(error)
                    showNotyfications('Не удалось скачать файл, попробуйте позже', { type: 'error' })
                })
        }
    }
}
</script>

<style lang="scss">
.schedule-tools {
    display: flex;
    margin: 0 -$token-spacers-3-xs;

    &__item {
        margin: 0 $token-spacers-3-xs;
    }
}
</style>
