<template>
    <div class="header-menu-wrapper" v-click-outside="hideChilds">
        <nav
            :class="[
                'header-menu-nav-links',
                { 'is-opened': navIndexOpened !== -1 },
            ]"
        >
            <ul class="header-menu-nav-links__list">
                <li
                    :class="[
                        'header-menu-nav-links__list-item',
                        {
                            'header-menu-nav-links__list-item--opened': indexA === navIndexOpened,
                            'is-active': checkActiveState(itemA)
                        }
                    ]"
                    v-for="(itemA, indexA) in data"
                    :key="indexA"
                >
                    <a
                        :href="getHref(itemA)"
                        :class="[
                            'header-menu-nav-links__item-link',
                            {
                                'header-menu-nav-links__item-link--is-parent': itemA.items && itemA.items.length,
                            }
                        ]"
                        @click="showChilds($event, indexA, itemA)"
                        :target="itemA.params && itemA.params.BLANK ? '_blank' : false"
                    >
                        <span
                            class="header-menu-nav-links__link-name"
                            v-html="itemA.name"
                        ></span>
                        <span class="header-menu-nav-links__link-arrow">
                            <z-icon
                                name="caretup"
                                width="12"
                                height="12"
                                dir="down"
                            />
                        </span>
                    </a>
                </li>
            </ul>
        </nav>

        <div class="header-menu">
            <ul class="header-menu__list">
                <li
                    :class="[
                        'header-menu__list-item',
                        {
                            'header-menu__list-item--opened': navIndexOpened === indexA
                        }
                    ]"
                    v-for="(itemA, indexA) in data"
                    :key="indexA"
                >
                    <div
                        class="header-menu__submenu-wrapper container"
                        v-if="itemA.items && itemA.items.length"
                    >
                        <div class="u-relative">
                            <button type="button" class="header-menu__button" @click="hideChilds">
                                <z-icon
                                    name="close"
                                    width="32"
                                    height="32"
                                    class="header-menu__button-icon"
                                ></z-icon>
                            </button>
                            <div class="header-menu__row">
                                <div class="header-menu__col header-menu__col--1">
                                    <ul class="header-menu-item">
                                        <template
                                            v-for="(itemB, indexB) in itemA.items"
                                        >
                                            <li
                                                class="header-menu-item__list-item"
                                                :class="{
                                                    'is-active': itemB.active,
                                                    'is-opened': secondObj !== null && secondObj.link === itemB.link && secondObj.name === itemB.name
                                                }"
                                                :key="indexB"
                                            >
                                                <a
                                                    :href="getHref(itemB)"
                                                    @click="showNextLvl($event, itemB, '2')"
                                                    class="header-menu-item__item-link"
                                                    :target="itemB.params && itemB.params.BLANK ? '_blank' : false"
                                                >
                                                    <span class="header-menu-item__item-link-name" v-html="itemB.name"></span>
                                                    <z-icon v-if="itemB.params.EXTERNAL" name="external" />
                                                    <span v-if="itemB.items && itemB.items.length" class="header-menu-item__item-link-arrow">
                                                        <z-icon
                                                            name="caretup"
                                                            width="16"
                                                            height="16"
                                                            dir="right"
                                                            color="#626c84"
                                                        />
                                                    </span>
                                                </a>
                                            </li>
                                        </template>
                                    </ul>
                                </div>
                                <div
                                    class="header-menu__col header-menu__col--2"
                                    :style="{
                                        'opacity': secondObj !== null ? '1': '0'
                                    }"
                                >
                                    <ul
                                        class="header-submenu"
                                        v-if="
                                            secondObj &&
                                            secondObj.items &&
                                            secondObj.items.length
                                        "
                                    >
                                        <li
                                            class="header-submenu__item"
                                            :class="{
                                                'is-active': itemC.active,
                                            }"
                                            v-for="(
                                                itemC, indexC
                                            ) in secondObj.items"
                                            :key="indexC"
                                        >
                                            <a
                                                :href="getHref(itemC)"
                                                @click="showNextLvl($event, itemC, '3')"
                                                class="header-submenu__item-link"
                                                :target="itemC.params && itemC.params.BLANK ? '_blank' : false"
                                            >
                                                <span
                                                    class="header-submenu__link-text"
                                                    v-html="itemC.name"
                                                ></span>
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    name: 'navigation-main',
    data () {
        return {
            data: this.$root.app.components.navigation.top,
            hovered: null,
            subMenu: false,
            subMenuItems: [],
            navIndexOpened: -1,
            secondObj: null
        }
    },
    methods: {
        showNextLvl (event, item) {
            if (item.items?.length) {
                event.preventDefault()
                this.secondObj = item
            }
        },
        showChilds (event, i, item) {
            this.secondObj = null
            if (item.hasOwnProperty('items') && item.items.length) {
                event.preventDefault()
                this.navIndexOpened === i
                    ? this.hideChilds()
                    : (this.navIndexOpened = i)
                this.$emit('menuToggle', this.navIndexOpened !== -1)
            } else {
                this.hideChilds()
            }
        },
        hideChilds () {
            this.secondObj = null
            this.navIndexOpened = -1
            this.$emit('menuToggle', this.navIndexOpened !== -1)
        },
        getHref (item) {
            if (Array.isArray(item.params)) {
                return item.link
            }

            if (item.params?.redirect) {
                return item.params?.redirect
            } else {
                return item.link
            }
        },
        isRedirect (item) {
            if (Array.isArray(item.params)) {
                return false
            }

            if (item.params?.redirect) {
                return true
            } else {
                return false
            }
        },
        checkActiveState (item) {
            if (item.active === true) return true

            for (let i = 0; i < item.items?.length; i++) {
                if (item.items[i].active === true) return true
            }

            return false
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
