/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'image-square': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4.75 4.75v9.336l1.513-1.513a1.75 1.75 0 012.474 0l1.763 1.763 4.013-4.013a1.75 1.75 0 012.474 0l2.263 2.263V4.75H4.75zm2.75 9.414l-2.75 2.75v2.336h14.5v-3.836l-3.5-3.5-4.013 4.013a1.75 1.75 0 01-2.474 0L7.5 14.164zM2.75 4.5v15c0 .966.784 1.75 1.75 1.75h15a1.75 1.75 0 001.75-1.75v-15a1.75 1.75 0 00-1.75-1.75h-15A1.75 1.75 0 002.75 4.5zm7.75 4.125a1.125 1.125 0 11-2.25 0 1.125 1.125 0 012.25 0z" _fill="#0072D9"/>'
  }
})
