<template>
    <div :class="newsClass" :style="isMain && 'height: 100%;'">
        <template v-if="isShowOnPicture">
            <z-card :link="item.detailPageUrl" :image="item.previewPicture.link" :alt="item.name">
                <template v-slot:content>
                    <date-block v-if="item.activeFrom" size="l" :date="item.activeFrom" :showYear="false" class="news-card__date"/>
                </template>
                <template v-if="item.name" v-slot:bottom>
                    <h2 class="news-card__title u-top-margin--s text-size-xl--important">{{ item.name }}</h2>
                </template>
            </z-card>
        </template>
        <template v-else>
            <z-card :link="item.detailPageUrl">
                <template v-if="item.activeFrom" v-slot:top>
                    <date-block v-if="item.activeFrom" size="l" :date="item.activeFrom" :showYear="false"/>
                </template>
                <template v-if="item.name" v-slot:title>
                    <h2 class="news-card__title">{{ item.name }}</h2>
                </template>
                <template v-if="item.previewText" v-slot:content>
                    <p class="news-card__description" v-html="item.previewText"/>
                </template>
            </z-card>
        </template>
    </div>
</template>

<script>
export default {
    name: 'news-cards',
    props: {
        item: Object,
        theme: {
            type: String,
            validator: prop => ['primary', 'secondary'].includes(prop),
            default: 'primary'
        },
        isMain: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        isShowOnPicture () {
            if (this.isMain) {
                return this.item.showOnMainPicture?.value && this.item.previewPicture.link
            }
            return this.item.previewPicture.link !== ''
        },
        newsClass () {
            if (this.theme === 'secondary') {
                if (this.isShowOnPicture) {
                    return 'news-card news-card--image col-default-5'
                }
                return 'news-card col-default-3'
            }
            if (this.isShowOnPicture) {
                return 'news-card news-card--image col-default-6 col-tablet-8 col-v-tablet-12'
            }
            return 'news-card col-default-3 col-tablet-4 col-v-tablet-12'
        }
    }
}
</script>

<style lang="scss">
.news-card {
    $parent: &;
    @include padding-level(left, XS);
    @include padding-level(right, XS);
    @include padding-level(top, M);

    &__date {
        font-weight: 600;
        font-size: var(--fontSizeM);
        line-height: 1.3;
        color: $token-colors-black-40;
    }

    &__title {
        font-weight: 500;
        font-size: var(--fontSizeM);
        line-height: 1.3;
        color: $token-colors-black;
        margin-bottom: 16px;
    }

    &__description {
        font-weight: 400;
        font-size: var(--fontSizeS);
        line-height: 1.4;
        color: #333333;
    }

    .z-card {
        height: 100%;
        color: $token-colors-black;

        &:hover {
            box-shadow: 0 8px 8px rgba(0, 32, 51, 0.04), 0 12px 28px #78a5db;
        }
    }

    &--main {
        height: 100%;
    }

    &--image {
        #{$parent}__date {

            & .date-block__container {
                color: $token-colors-white
            }
        }

        #{$parent}__title {
            color: $token-colors-white;
            margin-bottom: 0;
        }

        #{$parent}__description {
            color: $token-colors-white
        }

        .z-card {
            color: $token-colors-white;
            min-height: 400px;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            position: relative;

            &__body {
                max-width: 50%;
            }

            &__image {
                &:before {
                    content: '';
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background: black;
                    opacity: .3;
                    position: absolute;
                    border-radius: 20px;
                }
            }
        }
    }
}
</style>
