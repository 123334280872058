<template>
    <div class="notification">
        <div class="notification__image" v-if="!hideImage">
            <template v-if="!!this.$slots.image">
                <slot name="image"></slot>
            </template>
            <z-image v-else :src="image" width="590" height="400"></z-image>
        </div>
        <div class="notification__description">
            <template v-if="!!this.$slots.description">
                <slot name="description" v-if="!!this.$slots.description"></slot>
            </template>
            <template v-else>
                <z-caption size="m" decor-left decor-right weight="900" uppercase>
                    <span v-html="text.title"></span>
                </z-caption>
                <p class="text-size-xl u-regular" v-html="text.description"></p>
            </template>
        </div>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'notification',
    props: {
        hideImage: Boolean
    },
    data () {
        return {
            image: '/images/not-found/page-nf.svg',
            text: {
                title: localize({
                    ru: 'Ничего не найдено',
                    en: 'No results found'
                }),
                description: localize({
                    ru: 'По&nbsp;Вашему запросу ничего не&nbsp;нашлось.',
                    en: 'Sorry, no matches were found for your request. Try changing your search terms.'
                })
            }
        }
    }
}
</script>

<style lang="scss">

.notification {
    display: flex;

    &__image {
        flex-shrink: 0;
        @include breakpoint (v-tablet) {
            margin-right: 0;
            margin-bottom: 40px;
        }
    }

    @include breakpoint (v-tablet) {
        flex-direction: column;
    }

    &__image {
        margin-right: $token-spacers-2-xs;

        @include breakpoint (v-tablet) {
            margin-right: 0;
        }
    }

    .z-caption__text {
        max-width: 100%;
    }

    .z-caption {
        display: block;
        font-weight: 600;
        // // font-size: 28px;
        font-size: var(--fontSize3xl);
        line-height: 1.4;
        margin-top: 50px;
        margin-bottom: $token-spacers-xs;
    }

    p {
        font-weight: 400;
        // // font-size: 18px;
        font-size: var(--fontSizeL);
        line-height: 1.4;

        .z-link {
            font-weight: 600;
            // // font-size: 18px;
            font-size: var(--fontSizeL);
            line-height: 1.4;
        }
    }
}

</style>
