var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"events"},[_c('div',{staticClass:"events__filter"},[_c('events-filter',{ref:"filter",attrs:{"data":_vm.filter,"init-params":_vm.initParams},on:{"change-filter":_vm.changeFilter}})],1),_vm._v(" "),(_vm.data.length)?[_c('div',{staticClass:"events__grid"},_vm._l((_vm.data),function(item,index){return _c('event-card-new',{key:index,staticClass:"events__item",attrs:{"data":item,"tag":"a"}})}),1)]:[_c('notification',{attrs:{"hide-image":""},scopedSlots:_vm._u([{key:"description",fn:function(){return [_c('z-caption',{attrs:{"size":"m"}},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.notEventsText)}})]),_vm._v(" "),_c('p',{staticClass:"u-flex--row"},[_vm._v("Следите за обновлениями "),_c('z-link',{attrs:{"href":"https://vk.com/university.nornik","external":"","target":"_blank"}},[_vm._v("в соцсетях")]),_vm._v(" или смотрите "),_c('z-link',{attrs:{"href":"javascript:void(0)"},on:{"click":function($event){$event.preventDefault();return _vm.changeTab.apply(null, arguments)}}},[_vm._v("наши прошедшие события")]),_vm._v(".")],1)]},proxy:true}])})],_vm._v(" "),(_vm.isLoading)?_c('z-preloader'):_vm._e(),_vm._v(" "),_c('z-pagination',{attrs:{"show-next":"","show-prev":"","show-nav":"","show-more":{
            marginBottom: 'm',
            size: 'm'
        },"data":{
            currentPage: this.nav.current,
            totalItems: this.nav.count,
            totalPages: this.nav.total,
            pageRange: 1
        }},on:{"change-page":_vm.onPageChange}})],2)}
var staticRenderFns = []

export { render, staticRenderFns }