/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'check': {
    width: 64,
    height: 64,
    viewBox: '0 0 64 64',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M54.884 18.885a1.25 1.25 0 00-1.768-1.768L26 44.232 12.884 31.117a1.25 1.25 0 10-1.768 1.768l14 13.999a1.25 1.25 0 001.768 0l28-27.999z" _fill="#0072D9"/>'
  }
})
