<template>
  <z-caption tag="p" size="m" class="tag" weight="600">
    <slot />
  </z-caption>
</template>

<script>
export default {
    name: 'tag'
}
</script>

<style scoped lang='scss'>
  .tag {
    color: $token-colors-black-60;
    font-size: var(--fontSizeM);
    @include margin-level(bottom, S);
  }
</style>
