import { render, staticRenderFns } from "./Expanders.vue?vue&type=template&id=125402fc&scoped=true&"
import script from "./Expanders.vue?vue&type=script&lang=js&"
export * from "./Expanders.vue?vue&type=script&lang=js&"
import style0 from "./Expanders.vue?vue&type=style&index=0&id=125402fc&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "125402fc",
  null
  
)

export default component.exports