<template>
    <div class="expanders" ref="expanders">
        <expander
            v-if="schedule_data"
            :iconName="schedule_data.iconNameMain"
            :theme="schedule_data.theme"
            isOpen
            isScrollSensitive >
            <template #content>
                <expander-content :contentData="schedule_data" />
            </template>
        </expander>
        <expander
            v-if="announcement_data && announcement_data.name"
            theme="light"
            isOpen
            isScrollSensitive >
            <template #content>
                <expander-content :contentData="announcement_data" />
            </template>
        </expander>
    </div>
</template>

<script>
import ExpanderContent from '../../components/Expander/ExpanderContent.vue'

export default {
    name: 'expanders',
    components: {
        ExpanderContent
    },

    data () {
        return {
            schedule_data: {
                theme: 'dark',
                name: 'Расписание занятий',
                showDate: true,
                iconNameMain: 'calendar/calendar-31',
                iconName: 'calendar/calendar-31-thin',
                cities: [
                    { name: 'Мончегорск', link: '/education/schedule/?city=369' },
                    { name: 'Норильск', link: '/education/schedule/?city=371' }
                ]
            },
            announcement_data: this.$root.app?.components?.announcement
        }
    },
    mounted () {
        this.observeAssistBarHeight()
    },
    methods: {
        setTop () {
            const topPos =
                document.querySelector('.assist-bar').offsetHeight +
                document.querySelector('.header').offsetHeight +
                25
            this.$refs.expanders.style.top = `${topPos}px`
        },
        observeAssistBarHeight () {
            const assistBarObserver = new ResizeObserver(() => this.setTop())
            const assistBar = document.querySelector('.assist-bar')
            assistBarObserver.observe(assistBar)
        }
    }
}
</script>

<style scoped lang="scss">
.expanders {
    position: fixed;
    z-index: 3;
    right: $token-grids-desktop-offset;
    top: 215px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    @include breakpoint(laptop) {
        right: $token-grids-laptop-offset;
    }

    @include breakpoint(tablet) {
        top: 175px;
        right: $token-grids-tablet-offset;
    }

    @include breakpoint(v-tablet) {
        top: 158px;
        right: $token-grids-v-tablet-offset;
    }

    @include breakpoint(mobile) {
        top: auto !important;
        right: $token-grids-mobile-offset;
        bottom: 40px;
    }
    & > * {
        margin-bottom: 24px;

        @include breakpoint(mobile) {
            margin-bottom: 16px;
        }

        &:last-child {
            margin-bottom: 0;
        }
    }
}
</style>
