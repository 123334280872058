<template>
    <section class="section-hero">
        <div class="section-hero__swiper">
            <swiper :options="swiper_options">
                <swiper-slide
                    v-for="{
                        id,
                        detailPicture,
                        name,
                        previewText,
                        link
                    } in swiper_data"
                    :style="getBackgroundImage(detailPicture.link)"
                    :key="id"
                >
                    <div :class="getSwiperSlideClass('light')">
                        <z-caption
                            tag="h1"
                            class="swiper-slide__content-title"
                            weight="600"
                            v-html="name"
                        />
                        <z-caption
                            tag="p"
                            size="2xl"
                            weight="600"
                            v-html="previewText"
                            class="swiper-slide__content-description"
                        />
                        <z-button
                            class="swiper-slide__content-button"
                            tag="a"
                            :href="link.value"
                            size="l"
                            target="_blank"
                        >
                            Подробнее
                        </z-button>
                    </div>
                </swiper-slide>
            </swiper>
        </div>
    </section>
</template>

<script>
// import { mixinDevice } from '@/utils/mixin'
// import { AppDeviceEnquire } from '../../../../utils/mixin'
import Swiper from '../../components/Swiper/Swiper.vue'
import SwiperSlide from '../../components/SwiperSlide/SwiperSlide.vue'

export default {
    name: 'section-hero',
    components: {
        Swiper,
        SwiperSlide
    },
    // mixins: [mixinDevice],
    data () {
        return {
            swiper: undefined,
            swiper_data: this.$root.app.components.main.slider,
            swiper_options: {
                spaceBetween: 0,
                effect: 'slide',
                simulateTouch: false,
                observer: true,
                observeParents: true,
                autoplay: {
                    delay: 7000
                },
                loop: true
            }
        }
    },
    methods: {
        getSwiperSlideClass (color) {
            return [
                'container',
                'swiper-slide__content',
                color && `swiper-slide__content--${color}`
            ]
        },

        getBackgroundImage (src) {
            return `background-image: url(${src})`
        }

        // getSwiperSlideStyle (imageSrc, imageSrcMob, imageSrcVTab) {
        //     if (this.device === 'mobile' && imageSrcMob) {
        //         return this.getBackgroundImage(imageSrcMob)
        //     } else if (this.device === 'v-tablet' && imageSrcVTab) {
        //         return this.getBackgroundImage(imageSrcVTab)
        //     } else {
        //         return this.getBackgroundImage(imageSrc)
        //     }
        // }
    }
}
</script>

<style scoped lang="scss">
@import './index.scss';
</style>
