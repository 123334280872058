/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tag-color/play': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12 2.7a9.3 9.3 0 100 18.6 9.3 9.3 0 000-18.6zM.3 12C.3 5.538 5.538.3 12 .3 18.462.3 23.7 5.538 23.7 12c0 6.462-5.238 11.7-11.7 11.7C5.538 23.7.3 18.462.3 12zm10.13-5.107l6.3 4.2a1.05 1.05 0 01.114 1.736l-6.415 4.278A1.05 1.05 0 018.85 16.2V7.8c0-.81.88-1.315 1.58-.907z" _fill="#1971CF"/>'
  }
})
