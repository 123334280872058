/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tag-color/img': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M.575 6.72a6.2 6.2 0 016.2-6.2h10.559a6.2 6.2 0 016.2 6.2v10.56a6.2 6.2 0 01-6.2 6.2H6.774a6.2 6.2 0 01-6.2-6.2v-3.462l-.07-.07.07-.07V6.72zm2.4 10.56V14.67l1.683-1.682c.911-.912 1.523-1.521 2.024-1.92.494-.394.717-.445.829-.45.212-.011.423.03.616.118.102.047.29.177.603.726.317.556.659 1.35 1.166 2.534l.069.16.02.05c.2.463.382.89.565 1.221.192.348.469.756.935 1.023a2.46 2.46 0 001.572.303c.531-.075.94-.351 1.247-.603.293-.24.621-.568.978-.925l.037-.037c.566-.566.934-.933 1.24-1.187.291-.244.436-.306.523-.33.228-.063.468-.063.696 0 .088.024.232.086.524.33.305.254.674.621 1.239 1.187l1.593 1.593v.497a3.8 3.8 0 01-3.8 3.8H6.774a3.8 3.8 0 01-3.8-3.8zM21.134 6.72v6.667c-.49-.49-.913-.91-1.293-1.227-.42-.35-.873-.651-1.43-.803a3.721 3.721 0 00-1.962 0c-.557.152-1.01.453-1.43.803-.397.332-.841.776-1.36 1.295l-.037.037c-.406.407-.65.647-.838.802l-.08.063a1.755 1.755 0 01-.052-.088c-.119-.214-.254-.528-.481-1.057l-.069-.16-.028-.066c-.472-1.102-.867-2.022-1.259-2.71-.404-.71-.906-1.362-1.687-1.72a3.72 3.72 0 00-1.736-.335c-.858.043-1.566.461-2.205.97-.62.494-1.328 1.202-2.176 2.05l-.036.036V6.721a3.8 3.8 0 013.8-3.8h10.559a3.8 3.8 0 013.8 3.8zm-3.865 1.818a1.73 1.73 0 100-3.461 1.73 1.73 0 000 3.461z" _fill="#1971CF"/>'
  }
})
