/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tag_add_calend': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<circle pid="0" cx="24" cy="24" r="24" _fill="#129BCB"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M19.46 12.317a1.2 1.2 0 00-2.4 0v.612h-2.342c-1.209 0-2.188.98-2.188 2.189V34.882c0 1.209.98 2.189 2.188 2.189h19.765c1.208 0 2.188-.98 2.188-2.189V15.118c0-1.209-.98-2.189-2.188-2.189H32.14v-.612a1.2 1.2 0 00-2.4 0v.612H19.46v-.612zm-2.4 3.012v.612a1.2 1.2 0 102.4 0v-.612H29.74v.612a1.2 1.2 0 102.4 0v-.612h2.13v3.942H14.93v-3.942h2.13zm-2.13 6.342v13h19.34v-13H14.93zm10.87 4.235a1.2 1.2 0 10-2.4 0v1.518h-1.517a1.2 1.2 0 100 2.4H23.4v1.517a1.2 1.2 0 002.4 0v-1.518h1.518a1.2 1.2 0 000-2.4H25.8v-1.517z" _fill="#D1ECF0"/>'
  }
})
