<template>
    <div class="card-for-hero">
        <div class="card-for-hero__info">
            <div class="card-for-hero__header">
                <div class="card-for-hero__date" v-if="data.dateFrom.timestamp">
                    <date-block size="xl" :date="data.dateFrom.timestamp" :date-end="data.dateTo.timestamp"/>
                </div>
                <div class="card-for-hero__time" v-if="data.time && data.time.value !== ''">
                    <z-icon name="clock" width="16" height="16"/>
                    <div class="card-for-hero__time-wrapper">
                        <span class="card-for-hero__time-clock" v-html="data.time.value"></span>
                        <span class="card-for-hero__time-utc" v-if="data.isNorilskTime.value === 'Y'">(Норильск – местное время)</span>
                        <span class="card-for-hero__time-utc" v-else>(Москва – московское время)</span>
                    </div>
                </div>
            </div>
            <ul class="card-for-hero__address" v-if="data.address && data.address.length">
                <li class="text-size-xs" v-for="(item, index) in data.address" :key="index" v-html="item.value"></li>
            </ul>
            <div class="card-for-hero-tags">
                <z-tag v-if="data.formatEvent" theme="white" v-html="data.formatEvent.value"></z-tag>
                <z-tag
                    v-if="data.project && data.project.xmlId !== ''"
                    :xmlId="data.project.xmlId"
                    v-html="data.project.value"
                ></z-tag>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'card-for-hero',
    props: {
        data: Object
    }
}
</script>

<style lang="scss">
.card-for-hero {
    display: flex;
    background: $token-colors-bg-15;
    border-radius: 20px;
    text-decoration: none;
    color: inherit;
    overflow: hidden;
    backdrop-filter: blur(13px);
    min-width: 430px;
    max-width: 465px;
    height: max-content;

    &:hover {
        color: inherit;
    }

    @include breakpoint (v-tablet) {
        flex-direction: column;
        @include margin-level(bottom, 3XL);
    }

    @include breakpoint (mobile) {
        min-width: 100%;
    }

    .date-block__date {
        font-weight: 600;
        font-size: 54px;

        &-divider {
            font-size: 54px;
            font-weight: 600;
        }

        @include breakpoint (v-tablet) {
            font-size: $token-typo-4-xl-desktop-size;

            &-divider {
                font-size: $token-typo-4-xl-desktop-size;
            }
        }
    }
    .date-block__ym-column--end {
        @include breakpoint (v-tablet) {
            margin-left: 12px;
        }
    }

    &__info {
        padding: $token-spacers-s $token-spacers-m;
        position: relative;
        max-width: 100%;
        width: 100%;

        @include breakpoint (v-tablet) {
            padding: $token-spacers-xs;
        }

        @include breakpoint (mobile) {
            padding: $token-spacers-2-xs;
        }
    }

    &__header {
        display: flex;
        justify-content: flex-start;
        gap: $token-spacers-xs;

        @include breakpoint (mobile) {
            gap: $token-spacers-2-xs;
            flex-wrap: wrap;
        }
    }

    &__time {
        font-size: var(--fontSizeXs);
        color: $token-colors-black-80;
        display: flex;
        margin-top: 0.5em;

        &-wrapper {
            display: flex;
            flex-direction: column;
            margin-left: $token-spacers-3-xs;
        }

        &-clock {
            font-weight: 600;
            line-height: 1;
            margin-bottom: 0.3em;
        }

        &-utc {
            color: $token-colors-black-60;
            line-height: 1.2;
        }
    }

    &__address {
        display: flex;
        flex-direction: column;
        gap: $token-spacers-3-xs;
        padding-top: $token-spacers-2-xs;
        margin-bottom: $token-spacers-2-xs;

        li {
            position: relative;
            padding-left: $token-spacers-2-xs;

            &:before {
                position: absolute;
                content: '';
                width: 6px;
                height: 6px;
                border-radius: 500px;
                background: $token-colors-primary;
                display: block;
                left: 0;
                top: 50%;
                transform: translateY(-50%);

            }
        }
    }

    &-tags {
        display: flex;
        flex-direction: column;
        gap: $token-spacers-2-xs;
        margin-top: $token-spacers-2-xs;

        .z-tag {
            width: max-content;
        }

        @include breakpoint (v-tablet) {
            flex-direction: row;
            flex-wrap: wrap;
        }
    }
}
</style>
