<template>
    <figure
        class="z-figure"
    >
        <img
            v-if="image || defaultImage && !video"
            class="z-figure__image"
            :src="image || defaultImage"
        />
        <div v-if="markup || video" :class="[{'z-figure__markup' : markup}, {'z-figure__video' : video}]">
            <slot></slot>
        </div>
        <figcaption
            class="z-figure__caption"
            v-if="caption"
        >
            <span v-html="caption"></span>
        </figcaption>
    </figure>
</template>

<script>
export default {
    name: 'z-figure',
    props: {
        image: {
            type: String
        },
        caption: {
            type: String
        },
        markup: {
            type: Boolean,
            default: false
        },
        video: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            defaultImage: require('./images/img.png')
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
