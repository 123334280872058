<template>
<a
    :href="data.detailPageUrl"
    class="programs-card"
    :class="{ [`programs-card--${data.type.xmlId}`]: data.type && data.type.xmlId }"
>
    <z-tag class="u-bottom-margin--2xs" v-if="data.type && data.type.value">
        <span v-html="data.type.value"></span>
    </z-tag>
    <z-caption size="xl" tag="p" weight="600">
        <span v-if="data.index && data.index.value" v-html="data.index.value"></span>
        <span v-html="data.name.value"></span>
    </z-caption>
</a>
</template>

<script>
export default {
    name: 'programs-card',
    props: {
        data: Object
    }
}
</script>

<style lang="scss">
.programs-card {
    background: $token-colors-white;
    @include padding-level(top, XS);
    @include padding-level(bottom, XS);
    @include padding-level(left, XS);
    @include padding-level(right, S);
    margin-left: 8px;
    border-radius: 0px 24px 20px 0px;
    position: relative;
    display: block;
    text-decoration: none;
    color: inherit;
    transition: box-shadow $transition;

    &:before {
        content: '';
        position: absolute;
        top: 0;
        right: 100%;
        width: 8px;
        height: 100%;
        display: block;
        background: $token-colors-black-30;
        border-radius: 10px 0 0 10px;
    }

    &:hover {
        box-shadow: 0 8px 8px rgba(0, 32, 51, 0.04), 0 12px 28px rgba(0, 32, 51, 0.12);
    }

    &--profobuchenie {
        &:before {
            background: $token-colors-program-proffessional;
        }
    }

    &--profkompetentsii {
        &:before {
            background: $token-colors-program-development;
        }
    }

    &--obuchayushchie-meropriyatiya {
        &:before {
            background: $token-colors-program-education;
        }
    }

    &--ktsn {
        &:before {
            background: $token-colors-program-target;
        }
    }

    &--dpo {
        &:before {
            background: $token-colors-program-added;
        }
    }

    &--do {
        &:before {
            background: $token-colors-program-distance;
        }
    }

}
</style>
