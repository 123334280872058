<template>
    <div :class="getCardClass">
        <div class="history-card__container">
            <div
                v-if="data.imgSrc"
                class="history-card__img-container">
                <z-image :src="data.imgSrc" />
            </div>
            <div class="history-card__content">
                <div
                    v-if="data.logoSrc"
                    class="history-card__content-logo"
                    :style="`background-image: url('${data.logoSrc}')`" />
                <z-caption
                    v-if="data.title"
                    tag="p"
                    size="xl"
                    weight="600"
                    v-html="data.title" />
                <div
                    v-if="data.statistics && data.statistics.length !== 0"
                    class="history-card__content-stats">
                    <div
                        v-for="(item, idx) in data.statistics"
                        :key="idx"
                        class="history-card__content-stats-item">
                        <z-caption
                            tag="span"
                            size="4xl"
                            weight="600"
                            v-html="item.num" />
                        <z-caption
                            class="history-card__content-text"
                            tag="span"
                            size="s"
                            weight="600"
                            v-html="item.caption" />
                    </div>
                </div>
                <p
                    v-if="data.content"
                    class="text-size-s"
                    v-html="data.content"></p>
                <z-link
                    v-if="data.link"
                    class="history-card__content-link u-semi-bold"
                    :href="data.link"
                    size="xl"
                    icon="round-arrow"
                    iconPosition="right"
                    :underlined="false"
                    >Перейти в это время</z-link
                >
            </div>
        </div>
        <div
            v-if="data.isYearVisible && data.year"
            class="history-card__background">
            {{ data.year }}
        </div>
    </div>
</template>

<script>
export default {
    name: 'history-card',
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    computed: {
        getCardClass () {
            return [ 'history-card', this.data.isYearVisible && 'history-card--year' ]
        }
    }
}
</script>

<style scoped lang="scss">
.history-card {
    &__container {
        display: flex;
        align-items: center;
        padding-top: 140px;
        padding-left: 0;

        @include breakpoint(laptop) {
            padding-top: 125px;
        }

        @include breakpoint(tablet) {
            padding-top: 80px;
            align-items: flex-end;
        }

        @include breakpoint(v-tablet) {
            padding-top: 42px;
            flex-direction: column;
            align-items: flex-start;
        }

        @include breakpoint(mobile) {
            padding-top: 52px;
        }
    }

    &__img-container {
        min-width: 572px;
        max-height: 480px;
        width: 100%;
        height: auto;
        position: relative;
        margin-right: 46px;

        @include breakpoint(laptop) {
            margin-right: 59px;
        }

        @include breakpoint(tablet) {
            min-width: 390px;
            max-height: 294px;
        }

        @include breakpoint(v-tablet) {
            margin: 0;
            min-width: 240px;
            max-height: 274px;
            width: auto;
        }

        @include breakpoint(mobile) {
            min-width: 180px;
            max-height: 150px;
        }

        &::v-deep .z-image {
            position: absolute;
            top: 0;
            left: 0;
            z-index: 1;
            height: 100%;
            width: 100%;

            & > img {
                object-fit: contain;
                width: 100%;
                height: 100%;
            }
        }

        &::before {
            display: block;
            content: '';
            padding-bottom: 84%;
        }
    }

    &__content {
        max-width: 570px;
        min-width: 520px;

        @include breakpoint(laptop) {
            max-width: 530px;
        }

        @include breakpoint(tablet) {
            max-width: 420px;
            min-width: unset;
        }

        @include breakpoint(v-tablet) {
            max-width: 470px;
        }

        @include breakpoint(mobile) {
            max-width: 260px;
        }

        & > p {
            @include margin-level(top, 2XS);
            @include margin-level(bottom, 2XS);
        }
    }

    &__content-logo {
        width: 83%;
        height: 160px;
        background-repeat: no-repeat;
        background-position: left;
        background-size: contain;
        user-select: none;

        @include breakpoint(tablet) {
            height: 116px;
            width: 77%;
        }

        @include breakpoint(v-tablet) {
            height: 72px;
        }
    }

    &__content-link {
        @include margin-level(bottom, S);

        &::v-deep svg {
            transform: rotate(90deg);
            height: 20px;
            width: 20px;
        }
    }

    &__content-stats {
        display: flex;
        @include margin-level(top, M);
        @include margin-level(bottom, M);
        gap: 10px
    }

    &__content-stats-item {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex: 1;

        &::v-deep span {
            margin-bottom: 0;
        }
    }

    &__background {
        position: absolute;
        bottom: 3%;
        left: 0;
        font-size: 700px;
        font-family: $secondaryFont;
        line-height: 1;
        background: linear-gradient(180deg, #fff 0%, rgba(255, 255, 255, 0.5) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        font-weight: 900;
        z-index: -1;
        letter-spacing: -0.01em;
        user-select: none;
        transform: scaleX(0.9);
        transform-origin: left;

        @include breakpoint(laptop) {
            font-size: 600px;
            bottom: 10%;
        }

        @include breakpoint(tablet) {
            font-size: 450px;
            bottom: 60px;
        }

        @include breakpoint(v-tablet) {
            font-size: 240px;
            bottom: unset;
            top: -40px;
        }

        @include breakpoint(mobile) {
            font-size: 140px;
            top: -20px;
        }
    }

    &--year {
        position: relative;

        & .history-card__container {
            padding-left: 240px;

            @include breakpoint(laptop) {
                padding-left: 57px;
            }

            @include breakpoint(tablet) {
                padding-left: 52px;
            }

            @include breakpoint(v-tablet) {
                padding-left: 0;
            }
        }
    }
}
</style>
