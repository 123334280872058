var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"programs"},[_c('programs-filter',{staticClass:"u-bottom-margin--m",attrs:{"data":_vm.filter},on:{"change":_vm.onFilterChange}}),_vm._v(" "),(_vm.items && _vm.items.length)?_c('div',[_c('div',{staticClass:"row"},_vm._l((_vm.items),function(item,index){return _c('div',{key:index,staticClass:"col-default-6 col-v-tablet-12"},[_c('programs-card',{staticClass:"u-entire-height",attrs:{"data":item}})],1)}),0),_vm._v(" "),(_vm.nav)?_c('z-pagination',{staticClass:"programs__pagination",attrs:{"show-next":"","show-prev":"","show-more":{
            marginBottom: 'm',
            size: 'm'
        },"show-nav":"","data":{
            currentPage: _vm.nav.current,
            totalItems: _vm.nav.count,
            totalPages: _vm.nav.total,
            pageRange: 1
        }},on:{"change-page":_vm.onPageChange}}):_vm._e()],1):_c('div',[_c('z-not-found')],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }