<template>
    <td class="z-table-col z-table-col--body" :colspan="colspan">
        <span
            class="z-table-col__title"
            v-if="isMobile()"
            v-html="data[index]"
        ></span>
        <span
            :class="[
                'z-table-col__content',
                { [`z-table-col__content--${color}`]: color }
            ]"
        >
            <slot />
        </span>
    </td>
</template>

<script>
import { mixinDevice } from '@/utils/mixin'

export default {
    name: 'z-table-col',
    mixins: [mixinDevice],
    props: {
        index: {
            type: [String, Number],
            required: true
        },
        colspan: [String, Number],
        color: String
    },
    data () {
        return {
            data: this.$parent.$parent.columns
        }
    }
}
</script>
