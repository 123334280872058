/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'circle-icon-2': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<rect pid="0" width="40" height="40" rx="20" _fill="#0072D9"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M16 15.5h8c.985 0 1.573.003 1.995.06a1.415 1.415 0 01.354.084l.005.002.002.005.01.02c.018.045.048.141.074.334.017.126.03.267.038.43l-5.858 3.347a1.25 1.25 0 01-1.24 0l-5.858-3.348c.009-.162.02-.303.038-.429a1.415 1.415 0 01.084-.354l.002-.005.005-.002a1.415 1.415 0 01.354-.084c.422-.057 1.01-.06 1.995-.06zm-2.353.146c-.002.001-.001.001 0 0h-.001zm0 0zm-.001 0c0 .001 0 .001 0 0zM13.5 18.15V22c0 .985.003 1.573.06 1.995a1.415 1.415 0 00.084.354l.002.005.005.002.02.01c.045.018.141.048.334.074.422.057 1.01.06 1.995.06h8c.985 0 1.573-.003 1.995-.06a1.415 1.415 0 00.354-.084l.004-.002.003-.005a1.415 1.415 0 00.084-.354c.057-.422.06-1.01.06-1.995v-3.85l-5.136 2.934a2.75 2.75 0 01-2.728 0L13.5 18.15zM12 18c0-1.886 0-2.828.586-3.414C13.172 14 14.114 14 16 14h8c1.886 0 2.828 0 3.414.586C28 15.172 28 16.114 28 18v4c0 1.886 0 2.828-.586 3.414C26.828 26 25.886 26 24 26h-8c-1.886 0-2.828 0-3.414-.586C12 24.828 12 23.886 12 22v-4z" _fill="#fff"/>'
  }
})
