<template>
    <div
        class="swiper-container"
        ref="swiper">
        <div class="swiper-wrapper">
            <slot />
        </div>
        <swiper-controls v-if="pagination" />
    </div>
</template>

<script>
import SwiperMP from '../../modules/swiper'
import SwiperControls from '../SwiperControls/SwiperControls.vue'

export default {
    name: 'swiper',
    components: {
        SwiperControls
    },
    props: {
        pagination: {
            type: Boolean,
            default: true
        },
        options: {
            type: Object,
            required: false
        }
    },
    mounted () {
        this.swiper = new SwiperMP(this.$refs.swiper, this.options)
    },
    updated () {
        if (this.options.loop) {
            this.swiper.swiper.loopDestroy()
            this.swiper.swiper.loopCreate()
        }
    }
}
</script>

<style scoped lang="scss">
.swiper {
    &-container {
        height: 100%;
        position: relative;
    }

    &-wrapper {
        height: auto;
    }
}
</style>
