/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tag-color/man': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M9.007 7.265a3.008 3.008 0 116.016 0 3.008 3.008 0 01-6.016 0zm3.008-5.808a5.808 5.808 0 100 11.616 5.808 5.808 0 000-11.616zM6.22 21.6c1.162-3.24 3.603-4.52 5.91-4.428 2.363.094 4.726 1.628 5.672 4.407a1.4 1.4 0 102.651-.903c-1.326-3.895-4.713-6.163-8.212-6.302-3.557-.141-7.092 1.917-8.657 6.281a1.4 1.4 0 002.636.945z" _fill="#1971CF"/>'
  }
})
