<template>
    <div class="tags-filter">
        <div class="tags-filter__list">
            <button
                class="tags-filter__item"
                :class="{ 'is-selected': selected === '' }"
                @click="clear"
            >
                Все
            </button>
            <button
                class="tags-filter__item"
                :class="[
                    { 'is-selected': selected === item.id },
                    { 'is-disabled': item.disabled },
                    { [`tags-filter__item--${item.xmlId}`]: item.xmlId }
                ]"
                v-for="(item, index) in data"
                :key="index"
                v-html="item.text"
                ref="tag"
                @click="onClick(item)"
            >
            </button>
        </div>
    </div>
</template>

<script>

export default {
    name: 'tags-filter',
    props: {
        title: String,
        name: {
            type: String,
            required: true
        },
        data: {
            type: Array,
            default: () => []
        }
    },
    data () {
        return {
            selected: ''
        }
    },
    methods: {
        onClick (data) {
            this.selected === data.id ? this.selected = '' : this.selected = data.id

            // подумать над выносом в отедльную функицю с throttle
            this.$emit('change', {
                name: this.name,
                value: this.selected
            })
        },
        clear () {
            this.selected = ''
            this.$emit('change', {
                name: this.name,
                value: ''
            })
        }
    },
    created () {
        this.selected = this.data.find(item => item.selected)?.id || ''
    }
}
</script>

<style lang="scss">
.tags-filter {
    &__list {
        margin-top: -$token-spacers-2-xs;
    }

    &__item {
        margin-top: $token-spacers-2-xs;
        margin-right: $token-spacers-2-xs;
        border: none;
        display: inline-block;
        @include padding-level(top, 2XS);
        @include padding-level(bottom, 2XS);
        @include padding-level(left, S);
        @include padding-level(right, S);
        background: $token-colors-white;
        border-radius: 40px;
        color: $token-colors-black-80;
        // @include typo-level(M);
        font-size: var(--fontSizeM);
        font-weight: 600;
        text-align: left;
        transition: all $transition;

        &:before {
            content: '';
            display: inline-block;
            background: $token-colors-program-all;
            border-radius: 50%;
            width: 10px;
            height: 10px;
            margin-right: 8px;
            transition: background $transition;
        }

        &:last-child {
            margin-right: 0;
        }

        &.is-selected {
            color: $token-colors-white;
            background: $token-colors-program-all;

            &:before {
                background: $token-colors-white;
            }
        }

        &.is-disabled {
            pointer-events: none;
            opacity: .5;
        }

        &:hover {
            box-shadow: 0 8px 8px rgba(0, 32, 51, 0.04), 0 12px 28px rgba(0, 32, 51, 0.12);
        }

        &--profobuchenie {
            &:before {
                background: $token-colors-program-proffessional;
            }

            &.is-selected {
                background: $token-colors-program-proffessional;
            }
        }

        &--profkompetentsii {
            &:before {
                background: $token-colors-program-development;
            }

            &.is-selected {
                background: $token-colors-program-development;
            }
        }

        &--obuchayushchie-meropriyatiya {
            &:before {
                background: $token-colors-program-education;
            }

            &.is-selected {
                background: $token-colors-program-education;
            }
        }

        &--ktsn {
            &:before {
                background: $token-colors-program-target;
            }

            &.is-selected {
                background: $token-colors-program-target;
            }
        }

        &--dpo {
            &:before {
                background: $token-colors-program-added;
            }

            &.is-selected {
                background: $token-colors-program-added;
            }
        }

        &--do {
            &:before {
                background: $token-colors-program-distance;
            }

            &.is-selected {
                background: $token-colors-program-distance;
            }
        }
    }
}
</style>
