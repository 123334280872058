<template>
    <section class="news">
        <div class="news__container" v-if="Boolean(data)">
            <div class="news-filter">
                <z-select :data="data.filter.type" v-model="filters.event" @change="changeEvent" placeholder="Тип новости" name="Тип новости" class="news-filter__select"/>
                <div class="news-filter__wrapper">
                    <div class="news-filter__date-pickers">
                        <z-date-picker v-model="filters.start" :lang="$root.app.page.lang" placeholder="с"/>
                        <span>–</span>
                        <z-date-picker v-model="filters.end" :lang="$root.app.page.lang" placeholder="по"/>
                    </div>
                </div>
                <z-link
                    class="news-filter__clear"
                    href="javacript:void(0)"
                    icon="close-round"
                    icon-position="right"
                    @click="clearFilters"
                    v-if="showResetButton"
                    :underlined="false"
                >Сбросить фильтры</z-link>
            </div>
            <div v-if="items.length" class="news__content">
                <news-list :data="items"/>
            </div>
            <notification v-else class="u-top-margin--xl">
                <template v-slot:image>
                    <z-image src="/images/not-found/search-nf.svg" width="316" height="266"></z-image>
                </template>
                <template v-slot:description>
                    <z-caption size="m" decor-left decor-right>К сожалению, ничего не найдено</z-caption>
                    <p class="text-size-xl u-regular">Воспользуйтесь фильтром еще раз или вернитесь на&nbsp;<z-link href="/">главную страницу</z-link>.</p>
                </template>
            </notification>
        </div>
        <template v-if="isLoading">
            <z-preloader/>
        </template>
        <z-pagination v-if="Boolean(data)" :show-more="currentPage !== adaptedNav.totalPages ? {marginBottom: 'xs'} : false" show-next show-prev :data="adaptedNav" show-nav @change-page="changePagination"></z-pagination>
    </section>
</template>

<script>
import { queryString } from '../../utils/queryString'
import {axios} from '../../utils/request'

export default {
    name: 'news',
    data () {
        return {
            error: false,
            init: true,
            isMore: false,
            filters: {
                event: '',
                start: '',
                end: ''
            },
            currentPage: 1,
            prevItems: [],
            items: [],
            data: null,
            isLoading: false
        }
    },

    mounted () {
        this.getInitial()
    },

    watch: {
        filters: {
            handler () {
                if (this.init) {
                    return
                }
                this.currentPage = 1
                this.refreshParams()
                this.getData()
            },
            deep: true
        },
        currentPage () {
            this.refreshParams()
            this.getData()
        }
    },

    computed: {
        adaptedNav () {
            return {
                currentPage: this.data.nav.current,
                totalItems: this.data.nav.count,
                totalPages: this.data.nav.total,
                pageRange: 1
            }
        },

        showResetButton () {
            return this.filters.event || this.filters.start || this.filters.end
        }
    },

    methods: {
        clearFilters () {
            this.filters.event = ''
            this.filters.start = ''
            this.filters.end = ''
        },
        getData () {
            this.isLoading = true
            const url = '/news/'
            axios.get(url, {
                params: {
                    page: this.currentPage,
                    from: this.filters.start,
                    to: this.filters.end,
                    type: this.filters.event
                }
            }).then((response) => {
                this.isLoading = false
                this.data = response
                if (this.isMore) {
                    this.items = [...this.items, ...response.items]
                    this.isMore = false
                    return
                }
                this.items = response.items
            }).catch(() => {
                this.isLoading = false
                this.error = true
            })
        },

        refreshParams () {
            const params = {}
            this.filters.event && (params.type = this.filters.event)
            this.filters.start && (params.from = this.filters.start)
            this.filters.end && (params.to = this.filters.end)
            this.currentPage && (params.currentPage = this.currentPage)
            queryString(params)
        },

        getInitial () {
            const params = new URL(document.location).searchParams
            const init = {
                event: params.get('type') ? params.get('type') : '',
                start: params.get('from') ? params.get('from') : '',
                end: params.get('to') ? params.get('to') : ''
            }
            this.filters = {
                ...init
            }
            this.currentPage = params.get('currentPage') ? Number(params.get('currentPage')) : ''
            this.getData()
            this.init = false
        },

        changePagination (payload) {
            if (payload.type === 'load-more') {
                this.isMore = true
            }
            this.currentPage = payload.page
        },

        changeEvent (event) {
            this.filters.event = event
        }
    }
}
</script>

<style lang="scss">
.news {
    border-radius: 30px;
    background-color: $token-colors-bg-5;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    &__container {
        width: 100%;
    }

    .z-pagination {
        margin-top: $token-spacers-xs;
        &__list {
            flex-wrap: wrap;
        }

        @include breakpoint(mobile) {
            li {
                margin-bottom: 16px;
            }
        }
    }
}

.news-filter {
    display: flex;
    padding-left: 0;
    padding-right: 0;
    position: relative;
    max-width: 900px;
    margin-bottom: $token-spacers-xl;

    @include breakpoint(v-tablet) {
        flex-wrap: wrap;
    }

    &__select {
        width: 100%;
        max-width: 530px;
        margin-right: 4px;

        @include breakpoint (v-tablet) {
            max-width: 100%;
            margin-bottom: $token-spacers-3-xs;
            margin-right: 0;
        }
    }

    &__clear {
        position: absolute !important;
        left: 0;
        bottom: -45px;

        .z-icon {
            bottom: 0.1em !important;
        }
    }

    &__wrapper {
        padding: 0;

        @include breakpoint (v-tablet) {
            width: 100%;
        }

        &:first-child {
            margin-right: 8px;

            @include breakpoint(v-tablet) {
                margin-right: 0;
                margin-bottom: 16px;
            }
        }
    }

    &__date-pickers {
        display: flex;
        align-items: center;

        span {
            margin-left: 4px;
            margin-right: 4px;
        }

        .mx-datepicker {
            .mx-input {
                background: var(--fieldBg);
            }
        }

        .z-date-picker {
            @include breakpoint (v-tablet) {
                width: 50%;

                .mx-input {
                    font-size: var(--fontSizeS);
                    padding: 8px 10px;
                }
            }
        }
    }
}
</style>
