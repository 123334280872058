var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"modal-map"},[_c('button',{staticClass:"modal-map__button",attrs:{"alt":"Города присутствия Корпоративного университета"},on:{"click":_vm.handlePopup}}),_vm._v(" "),_c('z-modal',{attrs:{"id":"modalMapData"}},[_c('div',{staticClass:"modal-map__content"},[_c('button',{directives:[{name:"tippy",rawName:"v-tippy",value:({
                theme: 'map',
                appendTo: 'parent',
                sticky: true,
                placement: 'auto',
                maxWidth: 670,
                }),expression:"{\n                theme: 'map',\n                appendTo: 'parent',\n                sticky: true,\n                placement: 'auto',\n                maxWidth: 670,\n                }"}],staticClass:"modal-map__pin modal-map__pin--monchegorsk",attrs:{"content":_vm.monchegorsk}}),_vm._v(" "),_c('button',{directives:[{name:"tippy",rawName:"v-tippy",value:({
                theme: 'map',
                appendTo: 'parent',
                sticky: true,
                placement: 'auto',
                maxWidth: 670,
                }),expression:"{\n                theme: 'map',\n                appendTo: 'parent',\n                sticky: true,\n                placement: 'auto',\n                maxWidth: 670,\n                }"}],staticClass:"modal-map__pin modal-map__pin--moscow",attrs:{"content":_vm.moscow}}),_vm._v(" "),_c('button',{directives:[{name:"tippy",rawName:"v-tippy",value:({
                theme: 'map',
                appendTo: 'parent',
                sticky: true,
                placement: 'left',
                maxWidth: 670,
                }),expression:"{\n                theme: 'map',\n                appendTo: 'parent',\n                sticky: true,\n                placement: 'left',\n                maxWidth: 670,\n                }"}],staticClass:"modal-map__pin modal-map__pin--norilsk",attrs:{"content":_vm.norilsk}}),_vm._v(" "),_c('z-caption',{attrs:{"size":"4xl","weight":"700","tag":"h4"}},[_vm._v("Города присутствия Корпоративного университета")]),_vm._v(" "),_c('img',{staticClass:"modal-map__image",attrs:{"width":"1355","height":"865","src":"/images/territory/modal-map.svg","alt":"Карта России."}})],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }