/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'refresh': {
    width: 17,
    height: 25,
    viewBox: '0 0 17 25',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M14.417 8.96a.604.604 0 111.046-.603 8.286 8.286 0 01-7.95 12.393l3.395 3.396-.853.854-4.39-4.39-.428-.427.427-.427 4.39-4.39.854.854-3.325 3.324a7.08 7.08 0 006.834-10.583zM2.156 16.04a.604.604 0 11-1.046.603A8.286 8.286 0 019.06 4.25L5.665.854 6.518 0l4.39 4.39.427.427-.427.427-4.39 4.39-.853-.854 3.324-3.324A7.08 7.08 0 002.156 16.04z" _fill="#1F2A40"/>'
  }
})
