import { gsap } from 'gsap'
import throttle from 'lodash/throttle'

class Expander {
    constructor (container, isOpen, isScrollSensitive) {
        this.container = container
        if (!this.container) {
            return
        }
        this.content = container.querySelector('[data-expander-content]')
        this.icon = container.querySelector('[data-expander-icon]')

        this.isOpen = isOpen
        this.isScrollSensitive = isScrollSensitive

        this.expand = this.expand.bind(this)
        this.collapse = this.collapse.bind(this)

        this.height = 0
        this.init()
    }

    init () {
        this.calculateOpenExpanderHeight()
        this.setTimelineAnimation()

        if (this.isScrollSensitive) {
            this.addScrollEvent()
        }

        if (this.isOpen) {
            this.expand()
        } else {
            this.addEvents()
        }
        window.addEventListener('resize', () => this.calculateOpenExpanderHeight())
    }

    addEvents () {
        this.container.addEventListener('mouseenter', this.expand)
        this.container.addEventListener('mouseleave', this.collapse)
    }

    addScrollEvent () {
        this.scrollTrigger = document.querySelector('[data-expander-scroll-trigger]')

        this.update = throttle(() => {
            const expanderEl = this.container.getBoundingClientRect()
            const scrollTrigger = this.scrollTrigger.getBoundingClientRect()
            const expanderElHeight = this.isOpen ? this.height : expanderEl.height

            if (scrollTrigger.top <= expanderEl.top + expanderElHeight) {
                this.isElementInScrollTrigger()
            } else {
                this.isElementOutsideScrollTrigger()
            }
        }, 200)

        window.addEventListener('scroll', () => this.update())
    }

    isElementInScrollTrigger () {
        if (this.isOpen) {
            this.collapse()
            this.container.addEventListener('mouseenter', this.expand)
            this.container.addEventListener('mouseleave', this.collapse)
        } else {
            this.expand()
        }
    }

    isElementOutsideScrollTrigger () {
        if (this.isOpen) {
            this.container.removeEventListener('mouseenter', this.expand)
            this.container.removeEventListener('mouseleave', this.collapse)
            this.expand()
        } else {
            this.collapse()
        }
    }

    expand () {
        this.tl.play()
    }

    collapse () {
        this.tl.reverse()
    }

    calculateOpenExpanderHeight () {
        const contentHeight = gsap.getProperty(this.content, 'height')
        const padding = 40
        this.height = contentHeight + padding * 2
    }

    setTimelineAnimation () {
        this.tl = gsap.timeline({
            paused: true,
            defaults: {
                duration: 0.25,
                ease: 'power2.out'
            }
        })
            .fromTo(this.icon, {
                scale: 1
            }, {
                scale: 0
            })

            .fromTo(this.container, {
                width: 72,
                height: 72,
                padding: 16,
                ease: 'sine'
            }, {
                width: 386,
                height: this.height,
                padding: 40,
                ease: 'sine'
            })

            .fromTo(this.content, {
                opacity: 0,
                pointerEvents: 'none'
            }, {
                opacity: 1,
                pointerEvents: 'all'
            })
    }
}

export default Expander
