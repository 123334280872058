<template>
    <z-card
        size="l"
        class="contacts-page-card"
        v-if="contacts &&
        (contacts.address && contacts.address.value ||
        contacts.schedule && contacts.schedule.length ||
        contacts.phone && contacts.phone.length ||
        contacts.email && contacts.email.length)"
        >
        <template v-slot:content>
            <div class="row">
                <div class="col-default-6 col-v-tablet-12" v-if="contacts.address && contacts.address.value">
                    <div class="contacts-page-card__item">
                        <z-icon name="circle-icon-5" width="40" height="40"></z-icon>
                        <div class="contacts-page-card__info">
                            <strong>Адрес:</strong>
                            <p
                                class="u-bottom-margin--3xs"
                                v-html="contacts.address.value"
                            ></p>
                            <z-link :href="contacts.map.value" external target="_blank" v-if="contacts.map && contacts.map.value">Проложить маршрут</z-link>
                        </div>
                    </div>
                </div>
                <div class="col-default-6 col-v-tablet-12" v-if="contacts.schedule && contacts.schedule.length">
                    <div class="contacts-page-card__item">
                        <z-icon name="circle-icon-1" width="40" height="40"></z-icon>
                        <div class="contacts-page-card__info">
                            <strong>Режим работы:</strong>
                            <p
                                class="contacts-page-card__schedule"
                                v-for="(item, index) in contacts.schedule"
                                v-html="item.value"
                                :key="`schedule-${index}`"
                            ></p>
                        </div>
                    </div>
                </div>
                <div class="col-default-6 col-v-tablet-12" v-if="contacts.phone && contacts.phone.length">
                    <div class="contacts-page-card__item">
                        <z-icon name="circle-icon-4" width="40" height="40"></z-icon>
                        <div class="contacts-page-card__info">
                            <strong>Телефон:</strong>
                            <z-link
                                v-for="(item, index) in contacts.phone"
                                class="contacts-page-card__phone"
                                :href="`tel:${item.value}`"
                                :key="`phone-${index}`"
                                v-html="item.description"
                            ></z-link>
                        </div>
                    </div>
                </div>
                <div class="col-default-6 col-v-tablet-12" v-if="contacts.email && contacts.email.length">
                    <div class="contacts-page-card__item">
                        <z-icon name="circle-icon-2" width="40" height="40"></z-icon>
                        <div class="contacts-page-card__info">
                            <strong>E-mail:</strong>
                            <z-link
                                v-for="(item, index) in contacts.email"
                                class="contacts-page-card__email"
                                :href="`mailto:${item.value}`"
                                :key="`email-${index}`"
                                v-html="item.value"
                            ></z-link>
                        </div>
                    </div>
                </div>
            </div>
        </template>
    </z-card>
</template>

<script>

export default {
    name: 'contacts',
    props: {
        branch: String
    },
    data () {
        return {
            contacts: this.$root.app.components.contacts[this.branch]
        }
    }
}
</script>

<style lang="scss">
.contacts-page-card {
    &__item {
        display: flex;

        > .z-icon {
            margin-right: $token-spacers-2-xs;

            path {
                fill: $token-colors-white;
            }
        }

        &--socials {
            display: flex;
            flex-direction: row;
            align-items: center;

            .z-link + .z-link {
                margin-left: $token-spacers-3-xs;
            }

            .contacts-page-card__info {
                flex-direction: row;
            }
        }
    }

    &__info {
        display: flex;
        flex-direction: column;

        .contacts-page-card__social-links {
            margin-left: $token-spacers-2-xs;
        }

        .contacts-page-card__schedule {
            margin-bottom: 0;
        }

        .contacts-page-card__phone,
        .contacts-page-card__schedule,
        .contacts-page-card__email {
            position: relative;

            &:after {
                content: ',\00a0';
                display: inline-block;
                position: absolute;
                bottom: 0;
            }

            &:last-child {
                &:after {
                    display: none;
                }
            }
        }
    }
}
</style>
