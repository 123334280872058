<template>
    <component :is="tag" :href="tag === 'a' ? data.detailPageUrl : false" class="event-card-new">
        <div class="event-card-new__top">
            <div class="event-card-new__image" alt="Изображение"
                 :style="`background-image: url('${data.previewImage.link}')`"
                 v-if="data.previewImage && data.previewImage.link">
            </div>
            <div class="event-card-new__image" alt="Изображение"
                 style="background-image: url('/images/events/imageholder.svg')" v-else></div>
            <div class="event-card-new__header">
                <div class="event-card-new__date-wrapper">
                    <div class="event-card-new__date" v-if="data.dateFrom.timestamp">
                        <date-block size="xl" :date="data.dateFrom.timestamp" :date-end="data.dateTo.timestamp"/>
                    </div>
                    <div class="event-card-new__time" v-if="data.time && data.time.value !== ''">
                        <z-icon name="clock" width="16" height="16"/>
                        <div class="event-card-new__time-wrapper">
                            <span class="event-card-new__time-clock" v-html="data.time.value"></span>
                            <span class="event-card-new__time-utc" v-if="data.isNorilskTime.value === 'Y'">(Норильск – <br>местное время)</span>
                            <span class="event-card-new__time-utc" v-else>(Москва – <br>московское время)</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="event-card-new__info">
            <div class="event-card-new-tags">
                <z-tag
                    v-if="data.project && data.project.xmlId !== ''"
                    :xmlId="data.project.xmlId"
                    v-html="data.project.value">
                </z-tag>
                <z-tag v-if="data.formatEvent" theme="white" v-html="data.formatEvent.value"></z-tag>
            </div>
            <div class="event-card-new__title" v-html="data.title"></div>
            <p class="event-card-new__preview-text"
               v-if="data.aboutLecture && data.aboutLecture.value"
               v-html="data.aboutLecture.value"></p>
        </div>
    </component>
</template>

<script>

export default {
    name: 'event-card-new',
    props: {
        data: Object,
        tag: {
            type: String
        }
    }
}
</script>

<style lang="scss">
.event-card-new {
    display: flex;
    background: transparent;
    flex-direction: column;
    text-decoration: none;
    color: inherit;
    overflow: hidden;

    &:hover {
        color: inherit;
    }

    &__top {
        position: relative;
        margin-bottom: 20px;
    }

    &__image {
        border-radius: 32px;
        width: 100%;
        padding-top: 80%;
        background-size: cover;
        background-position: 50% 50%;
        position: relative;
        display: block;

        &:before {
            content: '';
            background: linear-gradient(0deg, rgba(0, 85, 187, 0.30) 0%, rgba(0, 85, 187, 0.30) 100%);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border-radius: 32px;
        }
    }

    .date-block {
        &__date {
            font-weight: 600;

            @include breakpoint(laptop) {
                font-size: 54px;

                &-divider {
                    font-size: 54px;
                }
            }
        }

        &__ym-wrapper {
            justify-content: space-between;
        }
    }

    &__info {
        position: relative;
        display: flex;
        flex-direction: column;
        max-width: 100%;
        width: 100%;
        gap: 20px;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        position: absolute;
        top: 50px;
        left: 24px;
        right: 24px;
        bottom: 5px;

        @include breakpoint(tablet) {
            top: 30px;
            left: 30px;
        }

        @include breakpoint(v-tablet) {
            top: 15px;
        }
    }

    &__date-wrapper {
        display: flex;
        gap: $token-spacers-xs;
        flex-wrap: wrap;

        @include breakpoint(v-tablet) {
            flex-direction: column;
            flex-wrap: nowrap;
            gap: $token-spacers-3-xs;
        }

        @include breakpoint(mobile) {
            gap: $token-spacers-xs;
        }

        .date-block {
            color: $token-colors-white;
        }
    }

    &__time {
        font-size: var(--fontSizeL);
        color: $token-colors-white;
        display: flex;
        margin-top: 0.5em;

        @include breakpoint(v-tablet) {
            margin-top: 0;
        }

        svg {
            margin-top: 3px;

            path {
                fill: $token-colors-white;
            }
        }

        &-wrapper {
            display: flex;
            flex-direction: column;
            margin-left: $token-spacers-3-xs;
        }

        &-clock {
            font-weight: 600;
            line-height: 1;
            margin-bottom: 0.3em;
        }

        &-utc {
            font-weight: 600;
            color: $token-colors-white;
            line-height: 1.2;
        }
    }

    &-tags {
        display: flex;
        flex-wrap: wrap;
        min-height: 39px;
        gap: $token-spacers-2-xs;
    }

    &__title {
        font-weight: 600;
        font-size: var(--fontSize3xl);
        line-height: 1.3;
    }

    &__preview-text {
        display: -webkit-box;
        -webkit-line-clamp: 7;
        -webkit-box-orient: vertical;
        font-size: var(--fontSizeM);
        max-height: 198px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}
</style>
