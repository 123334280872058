<template>
    <div class="schedule-results">
        <div class="schedule-results__header">
            <z-caption tag="h2" class="schedule-results__current-week" size="3xl" weight="600">
                <span v-html="currentWeek"/>
            </z-caption>
            <tools class="schedule-results__tools" :download-enable="!isEmptyResult" />
        </div>
        <z-accordion
            theme="schedule"
            multiple
            :opened-index="openedAccordionIndex"
            v-if="!isEmptyResult"
        >
            <z-accordion-item
                class="schedule-results__accordion"
                :class="{
                    'schedule-results__accordion--past' : isPastDay(item.id),
                    'schedule-results__accordion--current' : isCurrentDay(item.id)
                }"
                v-for="(item, index) in data"
                :key="index"
                :id="item.id"
            >
                <template v-slot:icon>
                    <z-icon name="round-up" color="currentColor"></z-icon>
                </template>
                <template v-slot:header>
                    <span v-html="text[item.id.toLowerCase()]"/>
                    <span v-html="scheduleDay(item.date)"/>
                </template>
                <template v-slot:body>
                    <template v-if="item.items.length">
                        <schedule-card
                            v-for="(card, index) in item.items"
                            :key="index"
                            :data="card"
                            :current="isCurrentDay(item.id)"
                        />
                    </template>
                    <div class="schedule-no-classes" v-else>
                        <span class="schedule-no-classes__text">Занятий нет</span>
                    </div>
                </template>
            </z-accordion-item>
        </z-accordion>
        <div class="schedule-no-classes" v-else>
            <span class="schedule-no-classes__text" v-html="notification" />
        </div>
    </div>
</template>
<script>
import ScheduleCard from './Card.vue'
import moment from 'moment'
import Tools from './Tools.vue'

export default {
    name: 'schedule-results',
    components: {
        ScheduleCard,
        Tools
    },
    props: {
        data: {
            type: Array,
            default: () => []
        },
        isCurrentWeek: Boolean,
        isInit: Boolean
    },
    data () {
        return {
            text: {
                monday: 'Понедельник',
                tuesday: 'Вторник',
                wednesday: 'Среда',
                thursday: 'Четверг',
                friday: 'Пятница'
            },
            currentDay: moment().day()
        }
    },
    computed: {
        currentWeek () {
            let start = moment().startOf('week')
            let end = moment().endOf('week')
            if (!this.isCurrentWeek) {
                start.add(7, 'd')
                end.add(7, 'd')
            }

            if (start.format('M') === end.format('M')) {
                return `${start.format('DD')} - ${end.format('DD MMMM')}`
            }

            return `${start.format('DD MMMM')} - ${end.format('DD MMMM')}`
        },
        openedAccordionIndex () {
            if (!this.isCurrentWeek) return 0
            return this.currentDay - 1
        },
        isEmptyResult () {
            if (!(this.data && this.data.length)) return true

            let isEmpty = true
            this.data.forEach(item => {
                if (item.items.length) isEmpty = false
            })

            return isEmpty
        },
        notification () {
            return this.isInit ? 'Расписание будет добавлено позже' : 'По&nbsp;данному запросу ничего не&nbsp;найдено'
        }
    },
    methods: {
        isCurrentDay (day) {
            if (!this.isCurrentWeek) return day === 0
            const map = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday']
            const currentDay = moment().day()
            return currentDay === (map.indexOf(day.toLowerCase()) + 1)
        },
        isPastDay (day) {
            if (!this.isCurrentWeek) return false
            const map = ['monday', 'tuesday', 'wednesday', 'thursday', 'friday']
            const currentDay = moment().day()
            return (map.indexOf(day.toLowerCase()) + 1) < currentDay
        },
        scheduleDay (date) {
            return moment.unix(date).format('DD MMMM')
        }
    }
}
</script>
<style lang="scss">
.schedule-results {
    &__accordion {
        &--current {
            .z-accordion-item__header {
                color: $token-colors-primary !important;
            }
        }

        &--past {
            .z-accordion-item__header {
                color: $token-colors-black-40 !important;
            }
        }
    }

    .schedule-card + .schedule-card {
        margin-top: 8px;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        @include padding-level(top, M);
        @include padding-level(bottom, M);
        @include margin-level(top, XS, true);
        align-items: center;
    }

    &__tools,
    &__current-week {
        margin-bottom: 0 !important;
        @include margin-level(top, XS);
    }
}

.schedule-no-classes {
    background: $token-colors-white;
    border-radius: 0px 24px 20px 0px;
    @include padding-level(top, XS);
    @include padding-level(bottom, XS);
    @include padding-level(left, XS);
    @include padding-level(right, S);
    position: relative;
    margin-left: 4px;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 100%;
        height: 100%;
        width: 4px;
        background: $token-colors-black-20;
        border-radius: 10px 0px 0px 10px;
    }

    &__text {
        // @include typo-level(S);
        font-size: var(--fontSizeS);
        color: $token-colors-black-50;
        background: $token-colors-alert-bg;
        padding: 4px 16px;
        border-radius: 20px;
    }
}
</style>
