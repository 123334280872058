<template>
    <div class="remind">
        <z-tooltip
            @show="onShow"
            :trigger="getEventForDevice"
            :placement="placement"
            :interactive="true"
            theme="alternate"
            :arrow="false"
        >
            <template v-slot:trigger>
                <slot></slot>
            </template>
            <template v-slot:content>
                <div class="remind__content">
                    <z-link
                        v-for="(item, index) in data"
                        :href="item.link"
                        :key="index"
                        :target="item.type === 'google' ? '_blank' : false"
                        :download="item.type !== 'google' ? item.type : ''"
                        :icon="item.type"
                        size="s"
                    >
                        <span v-html="item.type"></span>
                    </z-link>
                </div>
            </template>
        </z-tooltip>
    </div>
</template>

<script>
import { mixinDevice } from '@/utils/mixin'

export default {
    name: 'remind',
    mixins: [mixinDevice],
    props: {
        data: [Array, Object],
        placement: {
            type: String,
            validator: prop => ['auto', 'auto-start', 'auto-end'].includes(prop),
            default: 'auto-start'
        },
        trigger: {
            type: String,
            validator: prop => ['click', 'mouseenter', 'focus'].includes(prop),
            default: 'click'
        }
    },
    methods: {
        onShow (tippy) {
            tippy.popper.classList.add('remind-popup')
        }
    },
    computed: {
        getEventForDevice () {
            if (this.device === 'mobile' || this.device === 'v-tablet') {
                return 'click'
            } else {
                return this.trigger
            }
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
