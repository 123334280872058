<template>
    <div class="schedule-results">
        <div v-if="!isEmptyResult">
            <div class="schedule-results__item"
                 :class="{ 'schedule-results__item--past' : isPastDay(item.date) }"
                 v-for="(item, index) in filtredData"
                 :key="index"
                 @click="onItemClick(item)"
                 :id="getWeekPrefixedId(item)"
            >
                <div class="schedule-results__item--top">
                    <span class="text-size-xl" v-html="text[item.id.toLowerCase()]"/>
                    <span class="text-size-m" v-html="scheduleDay(item.date)"/>
                </div>
                <div>
                    <template v-if="item.items.length">
                        <schedule-card
                            v-for="(card, index) in item.items"
                            :key="index"
                            :data="card"
                            :past="isPastDay(item.date)"
                        />
                    </template>
                    <div class="schedule-no-classes" v-else>
                        <span class="schedule-no-classes__text">Занятий нет</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="schedule-no-classes u-top-margin--m" v-else>
            <span class="schedule-no-classes__text" v-html="notification"/>
        </div>
    </div>
</template>

<script>
import ScheduleCard from './Card.vue'
import moment from 'moment'

export default {
    name: 'schedule-results',
    components: {
        ScheduleCard
    },
    props: {
        data: {
            type: Array,
            default: () => []
        },
        isInit: Boolean
    },
    data () {
        return {
            text: {
                monday: 'Понедельник',
                tuesday: 'Вторник',
                wednesday: 'Среда',
                thursday: 'Четверг',
                friday: 'Пятница',
                saturday: 'Суббота',
                sunday: 'Воскресенье'
            },
            today: moment()
        }
    },
    computed: {
        filtredData () {
            if (!(this.data && this.data.length)) return []
            const existSecondWeek = this.data.slice(7, 14).some(item => item.items && item.items.length)
            return existSecondWeek ? this.data : this.data.slice(0, 7)
        },
        isEmptyResult () {
            if (!(this.data && this.data.length)) return true

            let isEmpty = true
            this.data.forEach(item => {
                if (item.items.length) isEmpty = false
            })

            return isEmpty
        },
        notification () {
            return this.isInit ? 'Расписание будет добавлено позже' : 'По&nbsp;данному запросу ничего не&nbsp;найдено'
        }
    },
    methods: {
        getWeekPrefixedId (item) {
            const isThisWeek = moment.unix(item.date).utc().utcOffset(3).isSame(moment.unix(this.today.unix()), 'week')
            const prefix = isThisWeek ? 'thisWeek' : 'nextWeek'
            return `${prefix}-${item.id}`
        },
        onItemClick (item) {
            const isThisWeek = moment.unix(item.date).utc().utcOffset(3).isSame(moment.unix(this.today.unix()), 'week')
            const prefix = isThisWeek ? 'thisWeek' : 'nextWeek'
            const uniqueId = `${prefix}-${item.id}`

            this.$emit('scroll-to-item', uniqueId)
        },
        scrollToItem (id, offset) {
            const element = document.getElementById(id)

            if (element) {
                const elementPos = element.getBoundingClientRect().top
                const offsetPos = elementPos + window.scrollY - offset

                window.scrollTo({
                    top: offsetPos,
                    behavior: 'smooth'
                })
            }
        },
        isPastDay (date) {
            return moment.unix(date).utc().utcOffset(3).format('YYYY-MM-DD') < this.today.format('YYYY-MM-DD')
        },
        scheduleDay (date) {
            return moment.unix(date).utc().utcOffset(3).format('DD MMMM')
        }
    }
}

</script>
<style lang="scss">
.schedule-results {
    .schedule-card + .schedule-card {
        margin-top: $token-spacers-2-xs;
    }

    &__item {
        &--top {
            display: flex;
            justify-content: space-between;
            font-weight: 600;
            color: $token-colors-primary;
            margin-bottom: $token-spacers-2-xs;
            margin-top: $token-spacers-m;
        }

        &--past {
            .schedule-results__item--top {
                color: $token-colors-black-40;
            }
        }
    }
}

.schedule-no-classes {
    background: $token-colors-white;
    border-radius: 0 24px 20px 0;
    padding: $token-spacers-xs $token-spacers-s $token-spacers-xs $token-spacers-xs;
    position: relative;
    margin-left: 4px;

    @include breakpoint(mobile) {
        padding: $token-spacers-xs $token-spacers-2-xs $token-spacers-xs $token-spacers-2-xs;
    }

    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 100%;
        height: 100%;
        width: 8px;
        background: $token-colors-black-20;
        border-radius: 10px 0 0 10px;
    }

    &__text {
        font-size: var(--fontSize2xs);
        color: $token-colors-black-50;
        background: $token-colors-alert-bg;
        padding: 4px $token-spacers-2-xs;
        border-radius: 20px;
    }
}
</style>
