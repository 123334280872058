/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'circle-icon-1': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<rect pid="0" width="40" height="40" rx="20" _fill="#0072D9"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M27.5 19.5a7.5 7.5 0 10-15 0 7.5 7.5 0 0015 0zm-7.5-6a6 6 0 110 12 6 6 0 010-12zm-2 5.25a.75.75 0 000 1.5h1.75a1 1 0 001-1V15.5a.75.75 0 00-1.5 0v3.25H18z" _fill="#fff"/>'
  }
})
