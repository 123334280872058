/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'burger-close': {
    width: 43,
    height: 43,
    viewBox: '0 0 43 43',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M26.546 28.864a1.286 1.286 0 001.818-1.818l-4.344-4.344 4.546-4.546a1.286 1.286 0 10-1.818-1.818l-4.546 4.546-4.748-4.748a1.286 1.286 0 10-1.818 1.818l4.748 4.748-4.546 4.546a1.286 1.286 0 001.818 1.818l4.546-4.546 4.344 4.344z" _fill="#0C182A"/>'
  }
})
