/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow_up40': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M1 20c0 10.493 8.507 19 19 19s19-8.507 19-19S30.493 1 20 1 1 9.507 1 20zm-1 0c0 11.046 8.954 20 20 20s20-8.954 20-20S31.046 0 20 0 0 8.954 0 20zm20.233-6.162a.698.698 0 00-.495.205l-5.69 5.69a.7.7 0 00.99.99l4.495-4.495v9.691a.7.7 0 101.4 0v-9.69l4.495 4.495a.7.7 0 00.99-.99l-5.688-5.688a.698.698 0 00-.497-.207z" _fill="#0077C8"/>'
  }
})
