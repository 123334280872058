/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tag-color/share': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M17.045 3.125a1.822 1.822 0 100 3.645 1.822 1.822 0 000-3.645zm-2.893 4.899a4.222 4.222 0 10-1.21-2.073L7.848 8.924a4.222 4.222 0 100 6.152l5.095 2.968a4.222 4.222 0 101.212-2.071l-5.097-2.97a4.232 4.232 0 000-2.007l5.094-2.972zM6.5 11.034a1.273 1.273 0 00.058.099 1.814 1.814 0 01.002 1.732 1.308 1.308 0 00-.061.104A1.821 1.821 0 013.133 12a1.822 1.822 0 013.367-.967zm8.722 8.018c0-.29.069-.566.19-.81a1.237 1.237 0 00.126-.216 1.822 1.822 0 11-.316 1.026z" _fill="#1971CF"/>'
  }
})
