/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'circle-icon-6': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<rect pid="0" width="40" height="40" rx="20" _fill="#0072D9"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M19.81 13.5a5.31 5.31 0 00-5.31 5.31c0 1.873.94 3.415 2.109 4.61 1.169 1.196 2.507 1.98 3.15 2.318.033.018.069.018.102 0 .642-.337 1.98-1.122 3.15-2.317 1.169-1.196 2.109-2.738 2.109-4.611a5.31 5.31 0 00-5.31-5.31zM13 18.81a6.81 6.81 0 0113.62 0c0 2.408-1.214 4.307-2.537 5.66-1.323 1.352-2.814 2.223-3.523 2.596a1.607 1.607 0 01-1.5 0c-.71-.373-2.2-1.244-3.523-2.597C14.213 23.117 13 21.22 13 18.81zm6.81-1.847a1.847 1.847 0 100 3.694 1.847 1.847 0 000-3.694zm-3.347 1.847a3.347 3.347 0 116.694 0 3.347 3.347 0 01-6.694 0z" _fill="#fff"/>'
  }
})
