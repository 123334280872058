export const historyData = [
    {
        year: '2023',
        items: [
            {
                imgSrc: '/images/history-data/2023_1.png',
                title: 'Корпоративный университет отметил<br>85-летие',
                link: '/about/history/#2023-85-years',
                content: '<p>За&nbsp;годы работы #универНорникель стал символом надежности в&nbsp;сфере обучения и&nbsp;развития персонала в&nbsp;&laquo;Норникеле&raquo;. Наши успехи и&nbsp;достижения говорят сами за&nbsp;себя, и&nbsp;мы&nbsp;уверены, что впереди нас вместе с&nbsp;вами ждут новые высоты и&nbsp;горизонты.</p>'
            }
        ]
    },
    {
        year: '2021',
        items: [
            {
                imgSrc: '/images/history-data/2021_1.png',
                title: 'Теперь и&nbsp;в&nbsp;Мончегорске',
                link: '/about/history/#2021-monchegorsk',
                content: '<p>18&nbsp;июня 2021 года&nbsp;&mdash; важный день для Корпоративного университета. Мы&nbsp;получили лицензию на&nbsp;осуществление образовательной деятельности в&nbsp;филиале ЧОУ ДПО &laquo;Корпоративный университет &laquo;Норильский никель&raquo; в&nbsp;г.&nbsp;Мончегорск.</p>'
            }
        ]
    },
    {
        year: '2018',
        items: [
            {
                imgSrc: '/images/history-data/2018_1.png',
                link: '/about/history/#2018-reconstruction',
                title: 'Завершена реконструкция Учебного подземного полигона',
                content: '<p>Учебный подземный полигон&nbsp;КУ оборудован на&nbsp;базе технологических выработок шахты &laquo;Ангидрит&raquo; рудника &laquo;Кайерканский&raquo;. Его основная задача&nbsp;&mdash; обучение персонала, работников Заполярного филиала &laquo;Норникеля&raquo; и&nbsp;других структур по&nbsp;специальностям &laquo;Машинист ПДМ&raquo; и&nbsp;&laquo;Бурильщик шпуров&raquo;.</p>'
            }
        ]
    },
    {
        year: '2015',
        items: [
            {
                imgSrc: '/images/history-data/2015_1.png',
                title: 'Новое имя',
                link: '/about/history/#2015-name-change',
                content: '<p>Переименование в&nbsp;Частное образовательное учреждение дополнительного профессионального образования &laquo;Корпоративный университет &laquo;Норильский никель&raquo;</p>'
            },
            {
                imgSrc: '/images/history-data/2015_2.png',
                logoSrc: '/images/history-data/2015_2_logo.png',
                link: '/about/history/#2015-winner',
                title: 'Победа в&nbsp;Конкурсе социальных проектов программы «Мир новых возможностей»',
                content: '<p>Победа в&nbsp;Конкурсе социальных проектов программы &laquo;Мир новых возможностей&raquo;</p>'
            }
        ]
    },
    {
        year: '1943',
        items: [
            {
                imgSrc: '/images/history-data/1943_1.png',
                title: 'В&nbsp;годы Великой Отечественной Войны 1943&nbsp;&mdash;&nbsp;1946',
                link: '/about/history/#1943-education',
                statistics: [
                    {
                        num: '> 3 000',
                        caption: 'тысяч человек<br/>обучено'
                    },
                    {
                        num: '10 000',
                        caption: 'тысяч работников<br/>повысили квалификацию'
                    }
                ],
                content: '<p>Успехи военного времени, правительственные награды, которые получили комбинат и&nbsp;его работники в&nbsp;1943, 1945, 1946 гг., в&nbsp;значительной степени зависели и&nbsp;от службы подготовки кадров.</p>'
            },
            {
                imgSrc: '/images/history-data/1943_2.png',
                link: '/about/history/#1943-education-enrollment',
                statistics: [
                    {
                        num: '100',
                        caption: 'человек были набраны<br/>в&nbsp;новую школу.'
                    }
                ],
                content: '<p>Организованы школы фабрично-заводского обучения (ФЗО).</p>'
            }
        ]
    },
    {
        year: '1938',
        items: [
            {
                imgSrc: '/images/history-data/1938_1.png',
                title: 'Отделение подготовки кадров',
                link: '/about/history/#1938-education',
                content: '<p>Приказом А.П.&nbsp;Завенягина от 12 ноября №&nbsp;442 было создано отделение подготовки кадров для обеспечения квалифицированными рабочими цехов и&nbsp;площадок строящегося Норильского комбината (далее &mdash; комбинат).</p>'
            }
        ]
    }
]
