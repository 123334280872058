<template>
    <div class="calendar-block" :class="{ 'calendar-block--mobile' : isTablet }">
        <div ref="datepicker" class="calendar-block__datepicker"></div>
    </div>
</template>

<script>
import AirDatepicker from 'air-datepicker'
import localeEn from 'air-datepicker/locale/en'
import moment from 'moment'

export default {
    name: 'calendar-block',
    props: {
        dates: {
            type: Array,
            required: true
        },
        isTablet: {
            type: Boolean
        }
    },
    data () {
        return {
            datepicker: null,
            startDate: null,
            endDate: null,
            existSecondWeek: false,
            targetDates: []
        }
    },
    computed: {
        weeksStartDates () {
            return this.existSecondWeek
                ? [this.startDate, this.startDate.clone().add(1, 'week')]
                : [this.startDate]
        },
        weeksEndDates () {
            return this.existSecondWeek
                ? [this.startDate.clone().endOf('week'), this.endDate]
                : [this.endDate]
        }
    },
    methods: {
        init () {
            const datepicker = this.$refs.datepicker

            if (this.datepicker) {
                this.datepicker.destroy()
            }

            const options = {
                inline: true,
                // moveToOtherMonthsOnSelect: false,
                disableNavWhenOutOfRange: true,
                showOtherMonths: true,
                minDate: this.startDate.toDate(),
                maxDate: this.endDate.toDate(),
                onRenderCell: this.generateCellContent,
                navTitles: {
                    days: 'MMMM <i>yyyy</i>'
                },
                prevHtml: '<div class="datepicker-nav datepicker-nav--prev"></div>',
                nextHtml: '<div class="datepicker-nav datepicker-nav--next"></div>',
                ...(this.isTablet && {
                    view: 'days'
                })
            }

            if (this.$root.lang === 'en') options.locale = localeEn

            this.datepicker = new AirDatepicker(datepicker, options)

            if (this.targetDates.length) {
                this.targetDates.forEach(item => item.removeEventListener('click', this.scrollToDate))
            }

            this.targetDates = this.$refs.datepicker.querySelectorAll('.special-date')
            this.targetDates.forEach(item => item.addEventListener('click', this.scrollToDate))
        },
        normalizeDate (timestamp) {
            return moment.unix(timestamp).utc().utcOffset(3)
        },
        generateCellContent ({date, cellType}) {
            if (cellType !== 'day') return

            const currentDate = moment()
            const cellDate = moment(date)

            const isActual = cellDate.isBetween(currentDate, this.endDate, 'day', '[]')
            const isBeforeEnd = cellDate.isBefore(this.endDate, 'day')
            const isInRange = cellDate.isBetween(this.startDate, this.endDate, 'day', '[]')
            const isStart = this.weeksStartDates.some(date => cellDate.isSame(date, 'day'))
            const isEnd = this.weeksEndDates.some(date => cellDate.isSame(date, 'day'))
            const isSpecialDate = this.dates.some(d =>
                d.items && d.items.length > 0 && moment.unix(d.date).utc().utcOffset(3).format('YYYY-MM-DD') === cellDate.format('YYYY-MM-DD')
            )

            const classNames = []
            if (isInRange) classNames.push('in-range')
            if (isStart) classNames.push('is-start')
            if (isEnd) classNames.push('is-end')
            if (isSpecialDate) classNames.push('special-date')

            let template = ''

            if (!isActual && isBeforeEnd) {
                template = `
                    <div class="cell-content ${classNames.join(' ')}">
                        <span class="cell-content__date">${cellDate.format('DD')}</span>
                        <span class="tooltip-text">Занятия завершены</span>
                    </div>`
            } else if (isActual && !isSpecialDate) {
                template = `
                    <div class="cell-content ${classNames.join(' ')}">
                        <span class="cell-content__date">${cellDate.format('DD')}</span>
                        <span class="tooltip-text">Занятий нет</span>
                    </div>`
            } else {
                template = `
                    <div data-date="${cellDate.format('YYYY-MM-DD')}" class="cell-content ${classNames.join(' ')}">
                        <span class="cell-content__date">${cellDate.format('DD')}</span>
                    </div>
                `
            }

            return {
                html: template
            }
        },
        scrollToDate (e) {
            this.$emit('scroll-to-date', e.currentTarget.getAttribute('data-date'))
        },
        setRangeDates () {
            this.existSecondWeek = this.dates.slice(7, 14).some(item => item.items && item.items.length)
            this.startDate = moment().startOf('week')
            this.endDate = this.existSecondWeek
                ? this.startDate.clone().add(1, 'week').endOf('week')
                : this.startDate.clone().endOf('week')
        }
    },
    mounted () {
        this.setRangeDates()
        this.init()
    },
    watch: {
        dates: {
            deep: true,
            handler () {
                this.init()
            }
        },
        isTablet: {
            handler () {
                this.init()
            }
        }
    }
}
</script>

<style lang="scss">
.calendar-block {
    margin-bottom: $token-spacers-xs;

    @include breakpoint(tablet) {
        margin-top: $token-spacers-2-xs;
    }

    .cell-content {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: var(--fontSizeXs);
        color: $token-colors-black-30;
        position: relative;

        .cell-content__date {
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &.is-start {
            background: $token-colors-bg-10;
            color: $token-colors-black-40;
            border-radius: 30px 0 0 30px;
            border: 1px solid $token-colors-primary;
            border-right: none;

            &:after {
                content: '';
                width: 100%;
                height: 100%;
                right: 0;
                position: absolute;
                top: 0;
                // background: $token-colors-bg-10;
                z-index: -1;
                border-radius: 30px;
            }
        }

        &.is-end {
            background: $token-colors-bg-10;
            color: $token-colors-black-90;
            border-radius: 0 30px 30px 0;
            border: 1px solid $token-colors-primary;
            border-left: none;

            &:after {
                content: '';
                width: 100%;
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                // background: $token-colors-bg-10;
                z-index: -1;
                border-radius: 30px;
            }
        }

        &.in-range {
            background: $token-colors-bg-10;
            color: $token-colors-black-30;
            border-top: 1px solid $token-colors-primary;
            border-bottom: 1px solid $token-colors-primary;
        }

        &.special-date {
            color: $token-colors-link-default;
        }

        &.disabled {
            pointer-events: none;
            opacity: 0.5;
        }
    }

    .air-datepicker-nav--action {
        @include breakpoint(tablet) {
            max-width: 180px;
            flex: 1;
            position: relative;

            &:before {
                content: '';
                display: block;
                width: 100%;
                height: 100%;
                position: absolute;
                top: 50%;
                transform: translateY(-50%);
            }

            &[data-action="prev"] {
                &:before {
                    right: -8px;
                    padding: 16px 8px 16px 86px;
                }
            }

            &[data-action="next"] {
                &:before {
                    left: -8px;
                    padding: 16px 86px 16px 8px;
                }
            }
        }

        &:hover {
            background: transparent;
        }

        .datepicker-nav {
            width: 1em;
            height: 1em;
            background-repeat: no-repeat;
            background-size: contain;

            &--prev {
                background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1188_14229)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M2.37559 7.71737C2.33966 7.80503 2.31985 7.90101 2.31985 8.00161C2.31985 8.19951 2.39651 8.37951 2.52174 8.51354C2.52823 8.52049 2.53485 8.52731 2.5416 8.53401L7.47127 13.4637C7.76417 13.7566 8.23904 13.7566 8.53193 13.4637C8.82483 13.1708 8.82483 12.6959 8.53193 12.403L4.88052 8.75161L12.9334 8.75161C13.3476 8.75161 13.6834 8.41582 13.6834 8.00161C13.6834 7.5874 13.3476 7.25161 12.9334 7.25161L4.88051 7.25161L8.53193 3.60018C8.82483 3.30729 8.82483 2.83242 8.53193 2.53952C8.23904 2.24663 7.76417 2.24663 7.47127 2.53952L2.53952 7.47127C2.46684 7.54395 2.4122 7.62784 2.37559 7.71737Z' fill='%230078CE'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1188_14229'%3E%3Crect width='16' height='16' fill='white' transform='matrix(-4.37114e-08 -1 -1 4.37114e-08 16 16)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
            }

            &--next {
                background-image: url("data:image/svg+xml,%3Csvg width='16' height='16' viewBox='0 0 16 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cg clip-path='url(%23clip0_1188_14224)'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13.4616 8.53401C13.4684 8.52731 13.475 8.52049 13.4815 8.51354C13.5435 8.4472 13.5913 8.37235 13.6247 8.29291C13.6625 8.20335 13.6834 8.10492 13.6834 8.00161C13.6834 7.90072 13.6635 7.80449 13.6273 7.71663C13.5907 7.62738 13.5362 7.54375 13.4637 7.47127L8.53196 2.53952C8.23907 2.24663 7.76419 2.24663 7.4713 2.53952C7.17841 2.83242 7.17841 3.30729 7.4713 3.60018L11.1227 7.25161L3.06988 7.25161C2.65567 7.25161 2.31988 7.5874 2.31988 8.00161C2.31988 8.41582 2.65567 8.75161 3.06988 8.75161L11.1227 8.75161L7.4713 12.403C7.17841 12.6959 7.17841 13.1708 7.4713 13.4637C7.7642 13.7566 8.23907 13.7566 8.53196 13.4637L13.4616 8.53401Z' fill='%230078CE'/%3E%3C/g%3E%3Cdefs%3E%3CclipPath id='clip0_1188_14224'%3E%3Crect width='16' height='16' fill='white' transform='matrix(-4.37114e-08 -1 -1 4.37114e-08 16 16)'/%3E%3C/clipPath%3E%3C/defs%3E%3C/svg%3E");
            }
        }
    }

    .air-datepicker-cell.-current- {
        .cell-content .cell-content__date {
            border-radius: 50%;
            background: $token-colors-link-active;
            color: $token-colors-white;

            &:after {
                content: '';
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                position: absolute;
                display: block;
                // background: $token-colors-bg-10;
                z-index: -1;
            }
        }
    }

    .air-datepicker-nav {
        display: flex;
        padding: 0;
        border: none;
        min-height: auto;
        margin-bottom: 8px;
        justify-content: center;
        align-items: center;

        &--title, &--title i {
            font-size: var(--fontSizeXs);
            color: $token-colors-black-80;
            pointer-events: none;
            font-weight: 600;
            line-height: 1.3;
        }

        &--action {
            color: $token-colors-primary;
        }
    }

    .air-datepicker.-inline- {
        border: none;
        background: none;
        max-width: 100%;
        width: 100%;
        margin: 0 auto;
    }

    .air-datepicker-body--day-name {
        font-size: var(--fontSize3xs);
        color: $token-colors-black-40;
        text-transform: uppercase;
    }

    .air-datepicker-body--day-names {
        margin-bottom: 16px;
    }

    .air-datepicker-body--cells.-days- {
        grid-row-gap: 8px;
        grid-template-rows: 32px;
        grid-template-columns: repeat(7, minmax(32px, 1fr));
    }

    .air-datepicker-cell.-selected- {
        border-radius: 30px;
        border: none;
    }

    .air-datepicker-cell.-focus- {
        background: none !important;

        .cell-content {
            // background: none !important;

            &:after {
                display: none;
            }
        }
    }

    .air-datepicker-cell.-focus-:not(.-disabled-) {
        .cell-content.in-range {
            // background: none;
        }

        .cell-content__date {
            background: rgba($token-colors-link-active, 0.2);
            border-radius: 50%;
        }

        .cell-content.in-range.is-start,
        .cell-content.in-range.is-end {
            // background: var(--adp-cell-background-color-hover);
            // &:after {
            //     // background: var(--adp-cell-background-color-hover);
            //     z-index: 0;
            //     opacity: 0.2;
            // }
        }
        @include breakpoint(tablet) {
            .cell-content.in-range,
            .cell-content.in-range.is-start,
            .cell-content.in-range.is-end {
                background: $token-colors-bg-10;
            }
        }
    }

    &--mobile {
        .air-datepicker {
            width: 100%;
        }

        .air-datepicker-cell {
            padding-top: 0;
        }

        .air-datepicker-cell.-disabled- {
            display: none;
        }
    }
    .cell-content {
        .tooltip-text {
            background-color: $token-colors-form-border-color-hover;
            color: $token-colors-white;
            font-size: var(--fontSizeS);
            line-height: 20px;
            font-weight: 400;
            text-transform: none;
            padding: $token-spacers-xs $token-spacers-2-xs;
            border-radius: 10px;
            width: 200px;
            align-items: center;
            justify-content: center;
            position: absolute;
            bottom: 100%;
            left: 0;
            z-index: 1;
            display: none;
            transform: translateX(-20%);

            @include breakpoint(tablet) {
                padding: $token-spacers-2-xs;
                width: 150px;
            }

            @include breakpoint(mobile) {
                font-size: var(--fontSizeXs);
                padding: $token-spacers-3-xs;
                width: 120px;
            }
        }

        &.is-end {
            .tooltip-text {
                transform: translateX(-60%);
            }
        }
    }

    .air-datepicker-cell.-weekend- {
        .tooltip-text {
            transform: translateX(-60%);
        }
    }

    .air-datepicker-cell:hover .tooltip-text,
    .air-datepicker-cell.-focus- .tooltip-text {
        display: flex;
    }

}
</style>
