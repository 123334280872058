/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'quotes': {
    width: 21,
    height: 16,
    viewBox: '0 0 21 16',
    data: '<path pid="0" d="M18.316 13.708C20.203 11.66 20.013 9.03 20.007 9V1a1 1 0 00-1-1h-6c-1.103 0-2 .897-2 2v7a1 1 0 001 1h3.078a2.89 2.89 0 01-.429 1.396c-.508.801-1.465 1.348-2.846 1.624l-.803.16V16h1c2.783 0 4.906-.771 6.309-2.292zm-11.007 0C9.197 11.66 9.006 9.03 9 9V1a1 1 0 00-1-1H2C.897 0 0 .897 0 2v7a1 1 0 001 1h3.078a2.89 2.89 0 01-.429 1.396c-.508.801-1.465 1.348-2.846 1.624L0 13.18V16h1c2.783 0 4.906-.771 6.309-2.292z" _fill="#0072D9"/>'
  }
})
