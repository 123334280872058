/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'email': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2.126 2.107C1.088 2.107.071 2.864.071 4v8c0 1.136 1.017 1.893 2.055 1.893h11.748c1.038 0 2.055-.757 2.055-1.893V4.585 4c0-1.136-1.017-1.893-2.055-1.893H2.126zM1.571 12V5.848l5.363 2.988a2.213 2.213 0 002.132 0l5.363-2.988V12c0 .126-.152.393-.555.393H2.126c-.403 0-.555-.267-.555-.393zm6.765-4.474l6.093-3.395V4c0-.126-.152-.393-.555-.393H2.126c-.403 0-.555.267-.555.393v.13l6.093 3.396c.2.112.471.112.672 0z" _fill="#0072D9"/>'
  }
})
