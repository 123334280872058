/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'long_arrow': {
    width: 9,
    height: 65,
    viewBox: '0 0 9 65',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8.715 4.019L4.873.177a.604.604 0 00-.854 0L.177 4.019a.604.604 0 10.854.854L3.842 2.06V65h1.207V2.061l2.812 2.812a.604.604 0 10.854-.854z" _fill="#0078CE"/>'
  }
})
