/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'google': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M30.38 27l-1.14-8.51L30.38 10v-.37a3 3 0 00-1.15-2.33V4.53A2.53 2.53 0 0026.7 2h-21a2.53 2.53 0 00-2.53 2.53V7.3A3 3 0 002 9.65V10l1.14 8.51L2 27.05v.37a3 3 0 003 3h22.42a3 3 0 003-3v-.37l-.04-.05zM27.67 6.69H4.75V4.53a1 1 0 011-1h21a1 1 0 011 1l-.08 2.16zM3.58 27.25l1.15-8.61v-.2L3.58 9.82a1 1 0 010-.17A1.42 1.42 0 015 8.23h22.42a1.42 1.42 0 011.41 1.42c.005.057.005.113 0 .17l-1.15 8.61v.2l1.15 8.61a.91.91 0 010 .17 1.41 1.41 0 01-1.41 1.41H5a1.41 1.41 0 01-1.41-1.41.91.91 0 01-.01-.16zM11.4 5.22a.7.7 0 11-1.4 0 .7.7 0 011.4 0zm11 0a.7.7 0 11-1.4 0 .7.7 0 011.4 0zM13.2 23a1.81 1.81 0 01-1.8-1.8.7.7 0 10-1.4 0 3.199 3.199 0 105.16-2.5 3.191 3.191 0 00-2.146-5.694A3.19 3.19 0 0010 16.2a.7.7 0 001.4 0 1.8 1.8 0 111.8 1.8.7.7 0 100 1.4 1.8 1.8 0 110 3.6zm4.07-7l-.12.11v-.03a.65.65 0 01-.13-.56.74.74 0 01.36-.44l.09.15-.08-.14 3.747-1.998-.007-.012a.94.94 0 01.82 0 .69.69 0 01.45.62v10a.84.84 0 01-1.65 0V15l-2.48 1.32-.011-.02a.94.94 0 01-1.119-.18l.13-.12z" _fill="#B1ADC2"/>'
  }
})
