/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'long_arrow-horizontal': {
    width: 65,
    height: 9,
    viewBox: '0 0 65 9',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4.019.177L.177 4.019a.604.604 0 000 .854l3.842 3.842a.604.604 0 10.854-.854L2.06 5.049H65V3.842H2.061l2.812-2.811a.604.604 0 10-.854-.854z" _fill="#0078CE"/>'
  }
})
