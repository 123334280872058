<template>
    <div class="programs-filter">
        <tags-filter
            :data="data.type"
            v-if="data.type && data.type.length"
            name="type"
            @change="onTagChange"
            class="u-bottom-margin--s"
        />
        <z-input
            is-filter
            name="query"
            class="programs-filter__search-input"
            placeholder="Поиск по индексу, профессиям и наименованию программы"
            v-model="params.query"
            @input="onInput"
            alt="Поиск по индексу, профессиям и наименованию программы"
        />
    </div>
</template>

<script>
import TagsFilter from './TagsFilter.vue'
import { throttle } from 'throttle-debounce'
import { queryString } from '@/utils/queryString'

export default {
    name: 'programs-filter',
    components: {
        TagsFilter
    },
    props: {
        data: Object
    },
    data () {
        return {
            params: {
                query: '',
                type: ''
            }
        }
    },
    methods: {
        onInput: throttle(500, function () {
            this.send()
        }),
        onTagChange: throttle(200, function (data) {
            this.params[data.name] = data.value
            this.send()
        }),
        send () {
            queryString(this.clearEmptyParams(this.params))
            this.$emit('change', this.params)
        },
        clearEmptyParams (obj) {
            let params = Object.assign({}, obj)

            Object.keys(params).forEach(function (key, index) {
                if (Array.isArray(params[key])) {
                    if (!(params[key].filter(val => { return val !== null && val !== undefined && val !== '' && val !== false }).length)) delete params[key]
                } else if (!params[key]) {
                    delete params[key]
                }
            })

            return params
        },
        getParams (url = window.location) {
            let params = {}

            new URL(url).searchParams.forEach((val, key) => {
                params[key] = this.sanitizeValue(val)
            })

            this.params = { ...this.params, ...params }
        },
        sanitizeValue (text) {
            return text
                // htmlspecialchars
                .replace(/&/g, '&amp;')
                .replace(/</g, '&lt;')
                .replace(/>/g, '&gt;')
                .replace(/"/g, '&quot;')
                .replace(/'/g, '&#039;')
                // addslashes
                .replace(/\\/g, '\\\\')
                // eslint-disable-next-line
                .replace(/\u0008/g, '\\b')
                .replace(/\t/g, '\\t')
                .replace(/\n/g, '\\n')
                .replace(/\f/g, '\\f')
                .replace(/\r/g, '\\r')
                .replace(/'/g, '\\\'')
                .replace(/"/g, '\\"')
        }
    },
    mounted () {
        this.getParams()
    }
}
</script>

<style lang="scss">
.programs-filter {
    &__search-input {
        position: relative;

        .z-input__input {
            padding-right: 70px !important;
        }

        .z-input__placeholder {
            max-width: calc(100% - 16px - 70px);
        }

        &.is-focused .z-input__placeholder,
        &.is-filled .z-input__placeholder {
            display: none;
        }

        .z-input__clear {
            right: 40px;
        }

        &:after {
            content: '';
            background-image: url("data:image/svg+xml,%3Csvg width='40' height='40' viewBox='0 0 40 40' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M13 19C13 15.6863 15.6863 13 19 13C22.3137 13 25 15.6863 25 19C25 22.3137 22.3137 25 19 25C15.6863 25 13 22.3137 13 19ZM19 11C14.5817 11 11 14.5817 11 19C11 23.4183 14.5817 27 19 27C20.9036 27 22.652 26.3351 24.0255 25.2249C24.0661 25.4016 24.1552 25.5694 24.2929 25.7071L27.2929 28.7071C27.6834 29.0976 28.3166 29.0976 28.7071 28.7071C29.0976 28.3166 29.0976 27.6834 28.7071 27.2929L25.7071 24.2929C25.5694 24.1552 25.4016 24.0661 25.2249 24.0255C26.3351 22.652 27 20.9036 27 19C27 14.5817 23.4183 11 19 11Z' fill='%230078CE'/%3E%3C/svg%3E%0A");
            background-size: contain;
            background-repeat: no-repeat;
            width: 40px;
            height: 40px;
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 4px;
        }
    }
}
</style>
