/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'vk': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M21.71 18h-2.24c-.849 0-1.11-.662-2.633-2.178-1.327-1.26-1.914-1.43-2.24-1.43-.457 0-.588.128-.588.747v1.986c0 .533-.174.854-1.61.854-2.371 0-5.004-1.41-6.853-4.036C2.761 10.1 2 7.217 2 6.62 2 6.3 2.13 6 2.761 6h2.241c.566 0 .783.256 1.001.854 1.11 3.139 2.959 5.893 3.72 5.893.283 0 .414-.128.414-.832V8.669c-.087-1.495-.892-1.623-.892-2.157 0-.256.217-.512.565-.512h3.525c.478 0 .652.256.652.811v4.378c0 .47.218.64.348.64.283 0 .523-.17 1.045-.683 1.61-1.772 2.763-4.505 2.763-4.505.152-.32.413-.62.979-.62h2.24c.675 0 .827.342.675.812-.283 1.28-3.024 5.082-3.024 5.082-.24.384-.326.555 0 .982.24.32 1.022.982 1.544 1.58.958 1.067 1.698 1.964 1.893 2.584.218.619-.108.939-.74.939z" _fill="#1971CF"/>'
  }
})
