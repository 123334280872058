/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'enlarged_version-header': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M27.725 25h2.128l-4.192-10.672h-2.336L19.133 25h2.128l.784-2.048h4.896L27.725 25zm-3.232-8.784l1.92 5.088h-3.84l1.92-5.088zm-9.923.78L17.654 25H15.71l-.492-1.356h-3.432L11.282 25H9.338l3.096-8.004h2.136zm-2.316 5.148h2.496L13.502 18.7l-1.248 3.444z" _fill="#1F2A40"/>'
  }
})
