<template>
    <div class="calendar-block" :class="{ 'calendar-block--mobile' : isTablet }">
        <div ref="datepicker" class="calendar-block__datepicker"></div>
    </div>
</template>

<script>
import AirDatepicker from 'air-datepicker'
import localeEn from 'air-datepicker/locale/en'
import moment from 'moment'

export default {
    name: 'calendar-block',
    props: {
        dates: {
            type: Array,
            required: true
        },
        isTablet: {
            type: Boolean
        }
    },
    data () {
        return {
            startDate: null,
            endDate: null,
            existSecondWeek: false,
            targetDates: []
        }
    },
    computed: {
        weeksStartDates () {
            return this.existSecondWeek
                ? [this.startDate, this.startDate.clone().add(1, 'week')]
                : [this.startDate]
        },
        weeksEndDates () {
            return this.existSecondWeek
                ? [this.startDate.clone().endOf('week'), this.endDate]
                : [this.endDate]
        }
    },
    methods: {
        init () {
            const datepicker = this.$refs.datepicker

            if (this.datepicker) {
                this.datepicker.destroy()
            }

            const options = {
                inline: true,
                moveToOtherMonthsOnSelect: false,
                showOtherMonths: false,
                minDate: this.startDate.toDate(),
                maxDate: this.endDate.toDate(),
                onRenderCell: this.generateCellContent,
                navTitles: {
                    days: 'MMMM <i>yyyy</i>'
                },
                prevHtml: '&larr;',
                nextHtml: '&rarr;',
                ...(this.isTablet && {
                    view: 'days'
                })
            }

            if (this.$root.lang === 'en') options.locale = localeEn

            this.datepicker = new AirDatepicker(datepicker, options)

            if (this.targetDates.length) {
                this.targetDates.forEach(item => item.removeEventListener('click', this.scrollToDate))
            }

            this.targetDates = this.$refs.datepicker.querySelectorAll('.special-date')
            this.targetDates.forEach(item => item.addEventListener('click', this.scrollToDate))
        },
        normalizeDate (timestamp) {
            return moment.unix(timestamp).utc().utcOffset(3)
        },
        generateCellContent ({date, cellType}) {
            if (cellType !== 'day') return

            const currentDate = moment()
            const cellDate = moment(date)

            const isActual = cellDate.isBetween(currentDate, this.endDate, 'day', '[]')
            const isBeforeEnd = cellDate.isBefore(this.endDate, 'day')
            const isInRange = cellDate.isBetween(this.startDate, this.endDate, 'day', '[]')
            const isStart = this.weeksStartDates.some(date => cellDate.isSame(date, 'day'))
            const isEnd = this.weeksEndDates.some(date => cellDate.isSame(date, 'day'))
            const isSpecialDate = this.dates.some(d =>
                d.items && d.items.length > 0 && moment.unix(d.date).utc().utcOffset(3).format('YYYY-MM-DD') === cellDate.format('YYYY-MM-DD')
            )

            const classNames = []
            if (isInRange) classNames.push('in-range')
            if (isStart) classNames.push('is-start')
            if (isEnd) classNames.push('is-end')
            if (isSpecialDate) classNames.push('special-date')

            let template = ''

            if (!isActual && isBeforeEnd) {
                template = `
                    <div class="cell-content ${classNames.join(' ')}">
                        <span class="cell-content__date">${cellDate.format('DD')}</span>
                        <span class="tooltip-text">Занятия завершены</span>
                    </div>`
            } else if (isActual && !isSpecialDate) {
                template = `
                    <div class="cell-content ${classNames.join(' ')}">
                        <span class="cell-content__date">${cellDate.format('DD')}</span>
                        <span class="tooltip-text">Занятий нет</span>
                    </div>`
            } else {
                template = `
                    <div data-date="${cellDate.format('YYYY-MM-DD')}" class="cell-content ${classNames.join(' ')}">
                        <span class="cell-content__date">${cellDate.format('DD')}</span>
                    </div>
                `
            }

            return {
                html: template
            }
        },
        scrollToDate (e) {
            this.$emit('scroll-to-date', e.currentTarget.getAttribute('data-date'))
        },
        setRangeDates () {
            this.existSecondWeek = this.dates.slice(7, 14).some(item => item.items && item.items.length)
            this.startDate = moment().startOf('week')
            this.endDate = this.existSecondWeek
                ? this.startDate.clone().add(1, 'week').endOf('week')
                : this.startDate.clone().endOf('week')
        }
    },
    mounted () {
        this.setRangeDates()
        this.init()
    },
    watch: {
        dates: {
            deep: true,
            handler () {
                this.init()
            }
        },
        isTablet: {
            handler () {
                this.init()
            }
        }
    }
}
</script>

<style lang="scss">
.calendar-block {
    margin-bottom: $token-spacers-xs;

    @include breakpoint(tablet) {
        margin-top: $token-spacers-2-xs;
    }

    .cell-content {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: var(--fontSizeXs);
        color: $token-colors-black-30;
        position: relative;

        .cell-content__date {
            width: 32px;
            height: 32px;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        &.is-start {
            background: $token-colors-bg-10;
            color: $token-colors-black-40;
            border-radius: 30px 0 0 30px;
            border: 1px solid $token-colors-primary;
            border-right: none;

            &:after {
                content: '';
                width: 100%;
                height: 100%;
                right: 0;
                position: absolute;
                top: 0;
                // background: $token-colors-bg-10;
                z-index: -1;
                border-radius: 30px;
            }
        }

        &.is-end {
            background: $token-colors-bg-10;
            color: $token-colors-black-90;
            border-radius: 0 30px 30px 0;
            border: 1px solid $token-colors-primary;
            border-left: none;

            &:after {
                content: '';
                width: 100%;
                height: 100%;
                left: 0;
                position: absolute;
                top: 0;
                // background: $token-colors-bg-10;
                z-index: -1;
                border-radius: 30px;
            }
        }

        &.in-range {
            background: $token-colors-bg-10;
            color: $token-colors-black-30;
            border-top: 1px solid $token-colors-primary;
            border-bottom: 1px solid $token-colors-primary;
        }

        &.special-date {
            color: $token-colors-link-default;
        }

        &.disabled {
            pointer-events: none;
            opacity: 0.5;
        }
    }

    .air-datepicker-nav--action {
        display: none;
        cursor: pointer;
        user-select: none;
    }

    .air-datepicker-cell.-current- {
        .cell-content .cell-content__date {
            border-radius: 50%;
            background: $token-colors-link-active;
            color: $token-colors-white;

            &:after {
                content: '';
                width: 100%;
                height: 100%;
                left: 0;
                top: 0;
                position: absolute;
                display: block;
                // background: $token-colors-bg-10;
                z-index: -1;
            }
        }
    }

    .air-datepicker-nav {
        display: flex;
        padding: 0;
        border: none;
        min-height: auto;
        margin-bottom: 8px;
        justify-content: flex-start;

        &--title, &--title i {
            font-size: var(--fontSizeXs);
            color: $token-colors-black-80;
            pointer-events: none;
            font-weight: 600;
            line-height: 1.3;
        }

        &--action {
            color: $token-colors-primary;
        }
    }

    .air-datepicker.-inline- {
        border: none;
        background: none;
        max-width: 100%;
        width: 100%;
        margin: 0 auto;
    }

    .air-datepicker-body--day-name {
        font-size: var(--fontSize3xs);
        color: $token-colors-black-40;
        text-transform: uppercase;
    }

    .air-datepicker-body--day-names {
        margin-bottom: 16px;
    }

    .air-datepicker-body--cells.-days- {
        grid-row-gap: 8px;
        grid-template-rows: 32px;
        grid-template-columns: repeat(7, minmax(32px, 1fr));
    }

    .air-datepicker-cell.-selected- {
        border-radius: 30px;
        border: none;
    }

    .air-datepicker-cell.-focus- {
        background: none !important;

        .cell-content {
            // background: none !important;

            &:after {
                display: none;
            }
        }
    }

    .air-datepicker-cell.-focus-:not(.-disabled-) {
        .cell-content.in-range {
            // background: none;
        }

        .cell-content__date {
            background: rgba($token-colors-link-active, 0.2);
            border-radius: 50%;
        }

        .cell-content.in-range.is-start,
        .cell-content.in-range.is-end {
            // background: var(--adp-cell-background-color-hover);
            // &:after {
            //     // background: var(--adp-cell-background-color-hover);
            //     z-index: 0;
            //     opacity: 0.2;
            // }
        }
        @include breakpoint(tablet) {
            .cell-content.in-range,
            .cell-content.in-range.is-start,
            .cell-content.in-range.is-end {
                background: $token-colors-bg-10;
            }
        }
    }

    &--mobile {
        .air-datepicker {
            width: 100%;
        }

        .air-datepicker-cell {
            padding-top: 0;
        }

        .air-datepicker-cell.-disabled- {
            display: none;
        }
    }
    .cell-content {
        .tooltip-text {
            background-color: $token-colors-form-border-color-hover;
            color: $token-colors-white;
            font-size: var(--fontSizeS);
            line-height: 20px;
            font-weight: 400;
            text-transform: none;
            padding: $token-spacers-xs $token-spacers-2-xs;
            border-radius: 10px;
            width: 200px;
            align-items: center;
            justify-content: center;
            position: absolute;
            bottom: 100%;
            left: 0;
            z-index: 1;
            display: none;
            transform: translateX(-20%);

            @include breakpoint(tablet) {
                padding: $token-spacers-2-xs;
                width: 150px;
            }

            @include breakpoint(mobile) {
                font-size: var(--fontSizeXs);
                padding: $token-spacers-3-xs;
                width: 120px;
            }
        }

        &.is-end {
            .tooltip-text {
                transform: translateX(-60%);
            }
        }
    }

    .air-datepicker-cell.-weekend- {
        .tooltip-text {
            transform: translateX(-60%);
        }
    }

    .air-datepicker-cell:hover .tooltip-text,
    .air-datepicker-cell.-focus- .tooltip-text {
        display: flex;
    }

}
</style>
