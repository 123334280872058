<template>
    <div
        class="schedule-card"
        :class="{
            'schedule-card--exam' : data.exam.value === 'Y',
            'schedule-card--current' : current,
        }"
        v-if="data"
    >
        <header class="schedule-card__header">
            <span
                v-if="data.studyForm"
                class="schedule-card__tag schedule-card__tag--simple"
                v-html="data.studyForm.value"
            ></span>
            <span
                class="schedule-card__tag schedule-card__tag--simple"
                v-if="data.group"
                v-html="data.group.value"
            ></span>
            <span class="schedule-card__tag" v-if="data.exam.value === 'Y'">
                Экзамен
            </span>
            <span class="schedule-card__tag" v-if="data.curator">
                <span class="black-40-text">Куратор: </span><span v-html="data.curator.value"></span>
            </span>
            <div class="schedule-card__remind">
                <remind :data="data.remind">
                    <z-icon
                        class="schedule-card__remind-trigger"
                        name="round/remind-gray"
                        height="40"
                        width="40"
                    />
                </remind>
            </div>
        </header>
        <div class="schedule-card__content">
            <p class="schedule-card__time" v-html="data.time.value"></p>
            <p class="schedule-card__name" v-html="data.name"></p>
        </div>
        <footer class="schedule-card__footer" v-if="data.city || data.address || data.classroom">
            Кабинет: <span v-html="data.classroom.value"></span><template v-if="data.city.value">, г. <span v-html="data.city.value"></span></template><template v-if="data.address.value">, <span v-html="data.address.value"></span></template>
        </footer>
    </div>
</template>
<script>

export default {
    name: 'schedule-card',
    props: {
        data: Object,
        current: {
            type: Boolean,
            default: false
        }
    }
}
</script>
<style lang="scss">
.schedule-card {
    $parent: &;

    background: $token-colors-white;
    border-radius: 0 24px 20px 0;
    @include padding-level(top, XS);
    @include padding-level(bottom, XS);
    @include padding-level(left, XS);
    @include padding-level(right, S);
    position: relative;
    margin-left: 4px;
    break-inside: avoid;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 100%;
        height: 100%;
        width: 4px;
        background: $token-colors-black-20;
        border-radius: 10px 0 0 10px;
    }

    &__tag {
        background: $token-colors-bg-5;
        border-radius: 20px;
        padding: 3px 16px 5px;
        color: $token-colors-black-60;
        // @include typo-level(S);
        font-size: var(--fontSizeS);
        line-height: 1.2;
        display: inline-block;
        margin-right: $token-spacers-3-xs;
        margin-top: 8px;

        &--simple {
            padding: 0;
            background: none;
            color: $token-colors-black-40;
            border-radius: 0;
            margin-right: $token-spacers-xs;
        }
    }

    &__remind {
        position: absolute;
        top: 16px;
        right: 24px;
        transition: opacity $transition;

        &:hover {
            opacity: 0.8;
        }
    }

    &__remind-trigger {
        cursor: pointer;

        @include breakpoint (mobile) {
            width: 32px !important;
            height: 32px !important;
        }
    }

    &__header {
        padding-right: 40px;
        @include margin-level(bottom, 2XS);
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin-top: -8px;
    }

    &__time {
        // @include typo-level(3XL);
        font-size: var(--fontSize3xl);
        color: $token-colors-black-50;
        @include margin-level(bottom, 3XS);
    }

    &__name {
        // @include typo-level(XL);
        font-size: var(--fontSizeXl);
        font-weight: 600;
        line-height: 1.3;
    }

    &__footer {
        @include padding-level(top, 2XS);
        color: $token-colors-black-60;
        // @include typo-level(S);
        font-size: var(--fontSizeS);
    }

    &--current {
        #{$parent}__time {
            color: $token-colors-link-default;
        }

        &:after {
            background: $token-colors-link-default;
        }
    }

    &--exam {
        #{$parent}__time {
            color: #F9B533;
        }

        &:after {
            background: #F9B533;
        }
    }

}
</style>
