/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tag_color_dots_icon-3': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<rect pid="0" width="48" height="48" rx="24" _fill="#0072D9"/><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M20.5 19a3.5 3.5 0 117 0 3.5 3.5 0 01-7 0zm3.5-6.5a6.5 6.5 0 100 13 6.5 6.5 0 000-13zm0 15a9.5 9.5 0 00-9.176 7.041 1.5 1.5 0 002.897.777 6.5 6.5 0 0112.558 0 1.5 1.5 0 102.897-.777A9.5 9.5 0 0024 27.5z" _fill="#DAE8F8"/>'
  }
})
