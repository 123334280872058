<template>
    <transition
        @before-enter="appearAnimationBefore"
        @enter="appearAnimationEnter"
        @leave="appearAnimationLeave"
    >
        <div
            class="cookie-popup"
            v-if="visible"
        >
            <div class="cookie-popup__body">
                <div class="cookie-popup__text">
                    {{ text.description }}
                </div>
                <div class="cookie-popup__buttons">
                    <div class="cookie-popup__button">
                        <z-button
                            @click="submit"
                        >{{ text.button.agree }}</z-button>
                    </div>
                    <div class="cookie-popup__button">
                        <z-button
                            tag="a"
                            target="_blank"
                            kind="secondary"
                            href="/privacy-policy/"
                        >{{ text.button.more }}</z-button>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import Velocity from 'velocity-animate'
const cookie = require('cookie_js')

export default {
    name: 'cookie-popup',
    data () {
        return {
            duration: 500,
            visible: false,
            text: {
                description: 'Мы используем Cookies для того, чтобы сделать ваше пребывание на нашем сайте максимально комфортным. Если вы продолжаете просмотр страниц без изменения настроек, это будет означать, что вы принимаете все Cookies сайта Корпоративный университет «Норникель». Вы также можете нажать кнопку «Согласен», чтобы скрыть это сообщение. Детали о Cookies доступны по ссылке подробнее',
                button: {
                    agree: 'Согласен',
                    more: 'Подробнее'
                }
            }
        }
    },
    mounted () {
        this.visible = !cookie.get('cookies_policy')
    },
    methods: {
        submit () {
            cookie.set({
                cookies_policy: true
            }, {
                domain: '.' + location.host,
                path: '/',
                expires: 365
            })

            this.$nextTick(() => { this.visible = false })
        },
        appearAnimationBefore (el) {
            el.style.opacity = 0
        },
        appearAnimationEnter (el, done) {
            Velocity(el, { opacity: 1 }, { duration: this.duration, delay: this.duration }, { complete: done })
        },
        appearAnimationLeave (el, done) {
            Velocity(el, { opacity: 0 }, { duration: this.duration })
            Velocity(el, { display: 'none' }, { complete: done })
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
