<template>
    <div class="employee-card">
        <div class="employee-card__wrapper">
            <div
                class="employee-card__image"
                :style="`background-image: url(${data.previewPicture.link || '/images/biographies/imageholder.svg' })`"
                alt="Изображение"
            >
            </div>
            <z-link :href="`${data.detailPageUrl}?type=${data.show.xmlId}`" class="employee-card__name" :underlined="false" v-html="data.name"></z-link>
            <div class="employee-card__position" v-if="data.position">
                <span
                    v-for="(position, indexPosition) in data.position"
                    :key="indexPosition"
                    v-html="position.value"
                    class="employee-contacts__value"
                ></span>
            </div>
            <div class="employee-contacts" v-if="data.email && data.email.length || data.phone && data.phone.length || data.address && data.address.length || data.cabinet && data.cabinet.length">
                <div class="employee-contacts__email" v-if="data.email && data.email.length">
                    <z-icon name="envelope" color="#626C84" />
                    <span class="employee-contacts__prop">Email:</span>
                    <z-link
                        v-for="(email, indexEmail) in data.email"
                        :key="`${indexEmail} + i`"
                        :href="`mailto:${email.value}`"
                        class="employee-contacts__value"
                        v-html="email.value"
                    >
                    </z-link>
                </div>
                <div class="employee-contacts__phone" v-if="data.phone && data.phone.length">
                    <z-icon name="mobile" color="#626C84" />
                    <span class="employee-contacts__prop">Телефон:</span>
                    <z-link
                        v-for="(phone, indexPhone) in data.phone"
                        :key="`${indexPhone} + j`"
                        :href="`tel:${phone.description}`"
                        class="employee-contacts__value"
                        v-html="phone.value"
                    >
                    </z-link>
                </div>
                <div class="employee-contacts__address" v-if="data.address && data.address.length">
                    <z-icon name="mappin" color="#626C84"/>
                    <span class="employee-contacts__prop">Адрес:</span>
                    <span
                        v-for="(address, indexAddress) in data.address"
                        :key="`${indexAddress} + a`"
                        class="employee-contacts__value"
                        v-html="address.value"
                    ></span>
                </div>
                <div class="employee-contacts__cabinet" v-if="data.cabinet && data.cabinet.length">
                    <z-icon name="room" color="#626C84" />
                    <span class="employee-contacts__prop">Кабинет:</span>
                    <span
                        v-for="(cabinet, indexCabinet) in data.cabinet"
                        :key="`${indexCabinet} + k`"
                        class="employee-contacts__value"
                        v-html="cabinet.value"
                    ></span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

export default {
    name: 'employee-card',
    props: {
        data: Object
    }
}
</script>

<style lang="scss">
.employee-card {
    display: block;
    text-decoration: none;
    border-radius: 20px;
    background: #fff;
    border: 1px solid #FAFBFC;
    height: 100%;

    &__wrapper {
        padding: $token-spacers-xs;
    }

    &__image {
        height: 0;
        padding-top: 100%;
        position: relative;
        @include margin-level(bottom, XS);
        border-radius: 10px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: 50% 50%;
    }

    &__name {
        color: #000;
        line-height: 1.3;
        // font-size: 20px;
        font-size: var(--fontSizeXl);
        font-weight: 600;
    }

    &__position {
        margin-top: 12px;
        // font-size: 16px;
        font-size: var(--fontSizeM);
        font-weight: 400;
        line-height: 1.3;
        color: $token-colors-black-60;
        min-height: 42px;
    }
}

.employee-contacts {
    font-weight: 400;
    // font-size: 16px;
    font-size: var(--fontSizeM);
    line-height: 1.3;
    margin-top: $token-spacers-xs;

    > * + * {
        margin-top: 12px;
    }

    &__prop {
        margin-right: 8px;
    }

    &__value {
        color: $token-colors-black-60;

        &:after {
            content: ',\00a0';
            display: inline-block;
            position: relative;
        }

        &:last-child {
            &:after {
                display: none;
            }
        }
    }

    &__email,
    &__phone,
    &__address,
    &__cabinet {
        color: $token-colors-black-40;
        display: flex;
        align-items: center;
        flex-wrap: wrap;

        .z-icon {
            margin-right: 4px;
        }
    }
}
</style>
