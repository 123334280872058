// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'
import VueTippy, { TippyComponent } from 'vue-tippy'
import App from './App'
import store from './store/'
import { localize } from '@/utils/i18n'

import '@/utils/filter' // global
import '@/utils/modernizr-custom' // global
import '@/utils/polyfills/object-fit' // global
import '@/utils/correctExtensions' // global
import { VueAxios } from '@/utils/request'
import { AppDeviceEnquire } from '@/utils/mixin'
import { showNotyfications } from '@/utils/notifications'

/* Components */
import NavigationMain from './components/NavigationMain'
import NavigationMobile from './components/NavigationMobile'
import NavigationBottom from './components/NavigationBottom'
import NavigationLeft from './components/NavigationLeft'
import SiteSelector from './components/SiteSelector'
import CookiePopup from './components/CookiePopup'
import Remind from './components/Remind'
import {SliderScrollbar, Slider, Slide} from './components/Slider'
import PageChanger from './components/PageChanger'
import ZPagination from './components/ZPagination'
import ZTag from './components/ZTag'
import ZNotFound from './components/ZNotFound'
import SiteMap from './components/SiteMap'
import Notification from './components/notification'
import EventRegistrationForm from './components/EventRegistrationForm'
import DateBlock from './components/DateBlock'
import { NewsCards, NewsList } from './components/NewsList'
import SliderKeyIndicators from './components/SliderKeyIndicators'
import Contacts from './components/Contacts'
import Expander from './components/Expander'

/* Views */

import Page404 from './views/Page404'
import SuppliersLocal from './views/SuppliersLocal'
import DetailNews from './views/DetailNews'
import { Layout, HeaderLayout, FooterLayout } from './views/Layout'
import History from './views/history/history'
import Files from './views/Files'
import Reviews from './views/reviews/reviews'
import Territory from './views/Territory'
import { Employees, EmployeeDetail } from './views/Employees'
import News from './views/news/News'
import AssistBar from './views/AssistBar'
import { Events, EventsDetail } from './views/Events'
import EventsPage from './views/Events/EventsPage'
import { Programs, ProgramsDetail } from './views/Programs'
import Schedule from './views/Schedule'
import Subscription from './views/subscription/'
import Search from './views/Search'
import MainPage from './views/MainPage'
import {
    StructureScheme,
    SchemeGroup
} from './views/StructureScheme'
import Digest from './views/digest/Digest'

/* Components */
Vue.component('tippy-tooltip', TippyComponent)
Vue.component('navigation-main', NavigationMain)
Vue.component('navigation-mobile', NavigationMobile)
Vue.component('navigation-bottom', NavigationBottom)
Vue.component('navigation-left', NavigationLeft)
Vue.component('site-selector', SiteSelector)
Vue.component('cookie-popup', CookiePopup)
Vue.component('remind', Remind)
Vue.component('slider-scrollbar', SliderScrollbar)
Vue.component('slider', Slider)
Vue.component('slide', Slide)
Vue.component('page-changer', PageChanger)
Vue.component('z-pagination', ZPagination)
Vue.component('z-tag', ZTag)
Vue.component('z-not-found', ZNotFound)
Vue.component('site-map', SiteMap)
Vue.component('notification', Notification)
Vue.component('event-registration-form', EventRegistrationForm)
Vue.component('date-block', DateBlock)
Vue.component('news-card', NewsCards)
Vue.component('news-list', NewsList)
Vue.component('slider-key-indicators', SliderKeyIndicators)
Vue.component('contacts', Contacts)
Vue.component('expander', Expander)

/* Views */

Vue.component('page-404', Page404)
Vue.component('layout', Layout)
Vue.component('header-layout', HeaderLayout)
Vue.component('footer-layout', FooterLayout)
Vue.component('files', Files)
Vue.component('detail-news', DetailNews)
Vue.component('news', News)
Vue.component('history', History)
Vue.component('subscription', Subscription)
Vue.component('events', Events)
Vue.component('events-detail', EventsDetail)
Vue.component('suppliers-local', SuppliersLocal)
Vue.component('territory', Territory)
Vue.component('employees', Employees)
Vue.component('employee-detail', EmployeeDetail)
Vue.component('assist-bar', AssistBar)
Vue.component('events-page', EventsPage)
Vue.component('programs', Programs)
Vue.component('programs-detail', ProgramsDetail)
Vue.component('schedule', Schedule)
Vue.component('reviews', Reviews)
Vue.component('search', Search)
Vue.component('main-page', MainPage)
Vue.component('structure-scheme', StructureScheme)
Vue.component('scheme-group', SchemeGroup)
Vue.component('digest', Digest)

// https://webpack.js.org/guides/dependency-management/#require-context
const requireComponent = require.context(
    './components',
    true,
    /Z[\w-]+\/index\.js$/
)

requireComponent.keys().forEach(fileName => {
    const componentConfig = requireComponent(fileName)
    let componentName = fileName.split('/')[1]

    for (let key in componentConfig) {
        if (componentConfig.hasOwnProperty(key)) {
            if (key !== 'default') componentName = key
            Vue.component(componentName, componentConfig[key])
        }
    }
})

if (process.env.NODE_ENV !== 'production') {
    window.onerror = function (msg, url, lineNo, columnNo, error) {
        showNotyfications(`${msg}<br>${url}#${lineNo}`, {
            type: 'error'
        })
    }

    Vue.config.errorHandler = function (err, vm, info) {
        console.error(`Error: ${err.toString()}\nInfo: ${info}\nVm component: ${vm.$options._componentTag}`)
        showNotyfications(`Error: ${err.toString()}<br>Info: ${info}<br>Vm component: ${vm.$options._componentTag}`, {
            type: 'error'
        })
    }

    Vue.config.warnHandler = function (msg, vm, trace) {
        console.warn(`Warn: ${msg.toString()}\nInfo: ${trace}\nVm component: ${vm.$options._componentTag}`)
        showNotyfications(`Warn: ${msg.toString()}<br>Info: ${trace}<br>Vm component: ${vm.$options._componentTag}`, {
            type: 'alert'
        })
    }

    Vue.config.productionTip = false
}

Object.defineProperty(Vue.prototype, '$bus', {
    get () {
        return this.$root.bus
    }
})

Vue.config.productionTip = false

Vue.use(VueAxios)

Vue.use(VueTippy, {
    directive: 'tippy', // => v-tippy
    flipDuration: 0,
    popperOptions: {
        modifiers: {
            preventOverflow: {
                enabled: false
            }
        }
    }
})

/* eslint-disable no-new */
new Vue({
    el: '#app',
    mixins: [AppDeviceEnquire],
    store,
    data: {
        lang: window.App.page.lang,
        bus: new Vue({}),
        app: window.App
    },
    components: {
        App
    },
    mounted () {
        this.$el.classList.add('is-loaded')
    },
    methods: {
        localize: localize
    }
})
