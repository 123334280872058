<template>
    <div class="structure-scheme" v-if="data">
        <header class="structure-scheme__header" v-if="data.head && data.head.length">
            <div
                class="structure-scheme__head"
                :class="[
                    {'structure-scheme__head--two-assistant': item.assistant && item.assistant.length > 1},
                    {'structure-scheme__head--with-margin': isOpened(item)}
                    ]"
                v-for="item in data.head"
                :key="item.id"
            >
                <scheme-card :data="item" v-if="item" is-head @toggle-open="toggleOpen(item)"/>
                <div class="structure-scheme__assistant" v-if="item.assistant && item.assistant.length">
                    <div v-for="assistant in item.assistant" :key="assistant.id">
                        <scheme-card :data="assistant" v-if="assistant"/>
                    </div>
                </div>
            </div>
        </header>
        <div class="structure-scheme__content">
            <div class="structure-scheme__row" v-if="data.sections && data.sections.length">
                <div
                    class="structure-scheme__col"
                    v-for="(section, indexSection) in data.sections"
                    :key="`${indexSection}-section`"
                >
                    <div
                        class="structure-scheme__head"
                        v-for="(item, index) in section.head"
                        :key="index"
                    >
                        <scheme-card
                            class="structure-scheme__group-head"
                            :data="item"
                            v-if="item"
                        />
                    </div>
                    <template v-if="section.people && section.people.length">
                        <scheme-card
                            class="structure-scheme__person"
                            v-for="person in section.people"
                            :key="person.id"
                            :data="person"
                        />
                    </template>

                    <scheme-group
                        v-for="(group, indexGroup) in section.sections"
                        :key="`${indexGroup}-group`"
                        :data="group"
                    />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import SchemeCard from './components/Card.vue'

export default {
    name: 'structure-scheme',
    components: {
        SchemeCard
    },
    props: {
        data: [Object, Array],
        required: true
    },
    data() {
        return {
            openedCards: {}
        }
    },
    methods: {
        toggleOpen(item) {
            if (this.openedCards[item.id]) {
                this.$set(this.openedCards, item.id, false);
            } else {
                this.$set(this.openedCards, item.id, true);
            }
        },
        isOpened(item) {
            return this.openedCards[item.id];
        }
    }
}
</script>

<style lang="scss">
.structure-scheme {
    &__header {
        display: flex;
        flex-direction: column;
        align-items: center;
        overflow: hidden;
        position: relative;

        @include breakpoint(v-tablet) {
            padding-bottom: 24px;
            margin-bottom: -1px;
            max-width: max-content;
            align-items: flex-start;

            &:before {
                content: '';
                position: absolute;
                top: calc(80px + 32px); // 80 - высота картинки
                border-left: 1px solid $token-colors-black-20;
                left: 0;
                width: 100%;
                border-bottom: 1px solid $token-colors-black-20;
                height: calc(100% - (80px + 32px));
            }
        }

        .structure-scheme__head {
            min-height: 270px;
        }
    }

    &__person {
        position: relative;

        &:after {
            content: '';
            display: block;
            height: calc(48px + 40px); // 40 - половина высоты
            width: 1px;
            background: $token-colors-black-20;
            position: absolute;
            bottom: calc(100% - 30px); // 30px - компенсация фотки
            left: 50%;
            z-index: -1;

            /*@include breakpoint(mobile) {
                top: calc(80px + 32px); // 80 - высота картинки
                right: 100%;
                left: auto;
                width: 20px;
                height: 1px;
            }*/
        }
    }

    &__head {
        display: flex;
        align-items: flex-start;
        width: 100%;
        justify-content: center;
        transition: margin $transition;

        @include breakpoint(v-tablet) {
            flex-direction: column;
            padding-left: 20px;
            padding-right: 20px;
            margin-bottom: -1px; // для наложения линиий 1 - толщина линии
        }

        &--two-assistant {
            position: relative;
            @include breakpoint(v-tablet) {
                &:after {
                    content: '';
                    top: calc(80px + 32px);
                    display: block;
                    width: 1px;
                    right: 0;
                    background: $token-colors-black-20;
                    position: absolute;
                    height: calc(100% - (80px + 32px) - (60px + 32px) - 30px);
                }

                .scheme-card.is-head {
                    &:before {
                        content: '';
                        display: block;
                        background: $token-colors-black-20;
                        position: absolute;
                        z-index: -1;
                        top: calc(80px + 32px); // 80 - высота картинки
                        left: 100%;
                        right: auto;
                        width: 20px;
                        height: 1px;
                    }
                }
            }
        }

        // head card
        .scheme-card.is-head {
            position: absolute;
            margin-bottom: 48px;

            @include breakpoint(v-tablet) {
                position: relative;
                margin-bottom: 24px;
            }

            &:after {
                content: '';
                display: block;
                height: calc(48px + 40px + 100%); // 40 - половина высоты
                width: 1px;
                background: $token-colors-black-20;
                position: absolute;
                top: 100%;
                left: 50%;
                z-index: -1;

                @include breakpoint(v-tablet) {
                    top: calc(80px + 32px); // 80 - высота картинки
                    right: 100%;
                    left: auto;
                    width: 20px;
                    height: 1px;
                }
            }
        }

        &--with-margin {
            margin-bottom: 60px;
        }
    }

    &__group-head {
        margin-top: calc(64px - 30px); // 30 - половина высоты фотки
        position: relative;

        &:after {
            content: '';
            display: block;
            height: 64px;
            width: 1px;
            background: $token-colors-black-20;
            position: absolute;
            bottom: calc(100% - 30px); // 30px - компенсация фотки
            left: calc(12px + 30px); // 12 - оступ, 30 - половина фотки
            z-index: -1;
        }
    }

    // assistant card
    &__assistant {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        width: calc(400px + (174px + 60px) * 2);
        margin-bottom: 48px;

        @include breakpoint(v-tablet) {
            flex-direction: column;
            width: 100%;
        }

        .scheme-card {
            margin-left: 60px;
            margin-top: 10px; // компенсация высоты картинок
            position: relative;
            width: 174px;

            @include breakpoint(v-tablet) {
                max-width: 324px;
                width: 100%;
                margin: 0;
            }

            &:after {
                content: '';
                display: block;
                width: 60px;
                height: 1px;
                background: $token-colors-black-20;
                position: absolute;
                top: 90px;
                right: 100%;
                z-index: -1;

                @include breakpoint(v-tablet) {
                    top: auto;
                    right: auto;
                    bottom: calc(100% - 30px); // 30 - половина высоты картинки
                    width: 1px;
                    left: calc(324px / 2); // 322 - ширина карточки is-head
                    height: calc(30px + 24px); // 30 - половина высоты картинки, 24 - отступ
                }
            }
        }

        & > div:nth-child(2n) {
            .scheme-card {
                margin-left: 0;
                margin-right: 60px;

                &:after {
                    right: auto;
                    left: 100%;
                }

                @include breakpoint(v-tablet) {
                    margin-top: 20px;

                    &:after {
                        top: calc(60px + 32px);
                        left: 100%;
                        right: auto;
                        width: 20px;
                        height: 1px;
                    }
                }
            }
        }
    }

    &__row {
        display: flex;
        justify-content: space-between;
        position: relative;
        flex-grow: 1;

        &:after {
            content: '';
            width: calc(100% - (30px + 12px + 5px) - (180px - 12px - 30px - 5px));
            height: 1px;
            background: $token-colors-black-20;
            position: absolute;
            top: 0;
            left: calc(30px + 12px + 5px); // 30 - половина ширины картинки, 12 - отступ в карточке, 5 - отрицательный марджин
        }
    }

    &__content {
        overflow: auto;
        display: flex;
        margin-right: -5px;
        margin-left: -5px;
        padding-bottom: 8px;

        &::-webkit-scrollbar {
            height: 5px;
            background: $token-colors-bg-10;
        }

        &::-webkit-scrollbar-thumb {
            background: $token-colors-primary;
        }
    }

    &__col {
        padding: 0 5px;
        width: 180px;
        flex-shrink: 0;

        .scheme-card + .scheme-card {
            margin-top: 12px;
        }

        > .scheme-card + .scheme-group,
        > .scheme-group + .scheme-group,
        > .structure-scheme__head + .scheme-group {
            margin-top: 16px;
            position: relative;

            &:after {
                content: '';
                display: block;
                height: 16px;
                width: 1px;
                background: $token-colors-black-20;
                position: absolute;
                bottom: 100%;
                left: 50%;
            }
        }
    }
}
</style>
