/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'envelope': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M3 2.25A1.75 1.75 0 001.25 4v7c0 .966.783 1.75 1.75 1.75h9A1.75 1.75 0 0013.75 11V4.511 4A1.75 1.75 0 0012 2.25H3zM2.75 11V5.866l3.81 2.425a1.75 1.75 0 001.88 0l3.81-2.425V11a.25.25 0 01-.25.25H3a.25.25 0 01-.25-.25zm4.884-3.974l4.616-2.938V4a.25.25 0 00-.25-.25H3a.25.25 0 00-.25.25v.088l4.616 2.938a.25.25 0 00.268 0z" _fill="#0072D9"/>'
  }
})
