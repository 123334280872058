/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'circle-icon-4': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<rect pid="0" width="40" height="40" rx="20" _fill="#0072D9"/><g clip-path="url(#clip0_2200_15940)"><path pid="1" d="M23.312 27.414a10.242 10.242 0 01-7.292-3.021A10.245 10.245 0 0113 17.102a4.122 4.122 0 013.602-4.092 1.317 1.317 0 011.369.789l1.412 3.293a1.314 1.314 0 01-.109 1.235l-1.11 1.697c.5.966 1.3 1.76 2.271 2.249l1.645-1.126a1.314 1.314 0 011.247-.113l3.292 1.41c.258.11.478.305.619.548.143.249.201.537.167.815a4.126 4.126 0 01-4.093 3.607zM16.648 14.52a2.613 2.613 0 00-1.493.847 2.618 2.618 0 00-.655 1.735c0 2.354.917 4.567 2.581 6.231a8.75 8.75 0 006.231 2.581 2.629 2.629 0 002.581-2.149l-3.065-1.314-1.676 1.117a1.31 1.31 0 01-1.288.095 6.704 6.704 0 01-3.098-3.081l-.003-.006a1.304 1.304 0 01.088-1.286l1.113-1.7-1.316-3.07zm-.057-.134v.001-.001z" _fill="#fff"/></g><defs><clipPath id="clip0_2200_15940"><path pid="2" _fill="#fff" transform="translate(13 13)" d="M0 0h14.415v14.414H0z"/></clipPath></defs>'
  }
})
