/* eslint-disable */
require('./bell')
require('./book')
require('./calendar')
require('./cloud')
require('./img')
require('./man')
require('./pin')
require('./play')
require('./share')
require('./study')
