<template>
    <div
        :class="[
            'date-block',
            { [`date-block--size-${size}`]: size },
            { 'date-block--interval': dateEnd }
        ]">
        <div
            class="date-block__container"
            v-if="momentFormatIn && momentFormatOut">
            <span class="date-block__date">
                <div class="date-block__date">{{ buildDateString(date) }}</div>

                <span v-if="dateEnd">-</span>
                <div
                    class="date-block__date"
                    v-if="dateEnd">
                    {{ buildDateString(dateEnd) }}
                </div>
            </span>
        </div>
        <div
            class="date-block__container"
            v-else>
            <template v-if="size !== 'xl'">
                <span class="date-block__date">
                    <div class="date-block__date">{{ date | momentFilter('DD') }}</div>
                    <div class="date-block__ym-wrapper">
                        <div style="display: flex; flex-direction: column">
                            <span class="date-block__month">
                                {{ date | momentFilter('MMMM') }}
                            </span>
                            <span v-if="showYear" class="date-block__year">
                                {{ date | momentFilter('YYYY') }}
                            </span>
                        </div>
                    </div>
                    <template v-if="dateEnd && !dateEndInCurrentMonthAndYear">
                        <span>-</span>
                        <div class="date-block__date">
                            {{ dateEnd | momentFilter('DD') }}
                        </div>

                        <div class="date-block__ym-wrapper">
                            <div style="display: flex; flex-direction: column">
                                <span class="date-block__month date-block__month--end">
                                    {{ dateEnd | momentFilter('MMMM') }}</span
                                >
                                <span v-if="showYear" class="date-block__year date-block__year--end">
                                    {{ dateEnd | momentFilter('YYYY') }}</span
                                >
                            </div>
                        </div>
                    </template>
                </span>
            </template>

            <template v-else>
                <span class="date-block__date">
                    <div class="date-block__date">{{ date | momentFilter('DD') }}</div>
                    <template v-if="dateEnd && !isSameDate">
                        <span class="date-block__date-divider">&mdash;</span>
                        <div class="date-block__date">
                            {{ dateEnd | momentFilter('DD') }}
                        </div>
                    </template>
                </span>
                <div class="date-block__ym-wrapper">
                    <div class="date-block__ym-column">
                        <span class="date-block__month">
                            {{ date | momentFilter('MMMM') }}
                        </span>
                        <span v-if="showYear" class="date-block__year">
                            {{ date | momentFilter('YYYY') }}
                        </span>
                    </div>
                    <div
                        class="date-block__ym-column date-block__ym-column--end"
                        v-if="dateEnd && !dateEndInCurrentMonthAndYear">
                        <span class="divider"></span>
                        <span class="date-block__month date-block__month--end">
                            {{ dateEnd | momentFilter('MMMM') }}
                        </span>
                        <span v-if="showYear" class="date-block__year date-block__year--end">
                            {{ dateEnd | momentFilter('YYYY') }}
                        </span>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>

<script>
import moment from 'moment'

export default {
    name: 'date-block',
    props: {
        date: [String, Number],
        dateEnd: [String, Number],
        size: {
            type: String,
            default: 'm',
            validator: (prop) => ['xl', 'l', 'm', 's'].includes(prop)
        },
        momentFormatIn: String,
        momentFormatOut: String,
        showYear: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        buildDateString (date) {
            return moment(date, this.momentFormatIn).format(this.momentFormatOut)
        }
    },
    created () {
        moment.locale('ru', {
            months: [
                'января',
                'февраля',
                'марта',
                'апреля',
                'мая',
                'июня',
                'июля',
                'августа',
                'сентября',
                'октября',
                'ноября',
                'декабря'
            ]
        })
    },
    computed: {
        dateEndInCurrentMonthAndYear () {
            return (
                moment.unix(this.date).format('YYYY') ===
                    moment.unix(this.dateEnd).format('YYYY') &&
                moment.unix(this.date).format('MMMM') === moment.unix(this.dateEnd).format('MMMM')
            )
        },
        isSameDate () {
            return moment.unix(this.date).format('DD') === moment.unix(this.dateEnd).format('DD')
        }
    }
}
</script>

<style lang="scss">
.date-block {
    $parent: &;
    color: #333;
    display: flex;
    align-items: center;

    .divider {
        margin: 0 18px;
    }

    &__container {
        display: flex;
        // color: $token-colors-black-80;
        flex: 0 1 auto;
    }

    &__ym-wrapper {
        display: flex;
        justify-content: space-between;
    }

    &__ym-column {
        display: flex;
        flex-direction: column;
        min-width: 77px;

        &--end {
            margin-left: 34px;
        }
    }

    &__date {
        z-index: 2;
        align-items: center;
        font-weight: 700;
        font-family: $secondaryFont;

        &-divider {
            font-size: var(--fontSize5xl);
            transform: translate(-0.1em, 0em);
            margin-left: $token-spacers-3-xs;
        }
    }

    &__date,
    &__date--end {
        display: flex;
    }

    &__month,
    &__year {
        font-weight: 600;
        line-height: 1.4;
    }

    &--size {
        &-l {
            &.date-block {
                align-items: flex-start !important;
            }

            #{$parent}__year,
            #{$parent}__month {
                font-size: var(--fontSizeS);
                line-height: 1.2;

                @include breakpoint(v-tablet) {
                    font-size: var(--fontSizeXs);
                }
            }

            #{$parent}__date {
                font-size: var(--fontSize4xl);
                line-height: 1.2 !important;
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }

            #{$parent}__container {
                flex-direction: column;
            }
        }

        &-xl {
            &.date-block {
                align-items: flex-start !important;
            }

            #{$parent}__year,
            #{$parent}__month {
                font-size: var(--fontSizeS);

                @include breakpoint(v-tablet) {
                    font-size: var(--fontSizeXs);
                }
            }

            #{$parent}__date {
                font-size: var(--fontSize5xlDesktop);
                line-height: 1.2 !important;
            }

            #{$parent}__container {
                flex-direction: column;
            }
        }
    }
}
</style>
