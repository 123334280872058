/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'burger': {
    width: 43,
    height: 43,
    viewBox: '0 0 43 43',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M13 14.786c0-.71.576-1.286 1.286-1.286h15.428a1.286 1.286 0 110 2.571H14.286c-.71 0-1.286-.575-1.286-1.285zm0 7.714c0-.71.576-1.286 1.286-1.286h15.428a1.286 1.286 0 110 2.572H14.286c-.71 0-1.286-.576-1.286-1.286zm1.286 6.429a1.286 1.286 0 100 2.571h15.428a1.286 1.286 0 100-2.571H14.286z" _fill="#0C182A"/>'
  }
})
