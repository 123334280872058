/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'buttons/add_calend': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<g clip-path="url(#clip0_1188_14248)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.8.8a.8.8 0 00-1.6 0v.4H4.6V.8A.8.8 0 103 .8v.4H1.4A1.4 1.4 0 000 2.6v12A1.4 1.4 0 001.4 16h12a1.4 1.4 0 001.4-1.4v-12a1.4 1.4 0 00-1.4-1.4h-1.6V.8zm1.4 4v-2h-1.4v.4a.8.8 0 01-1.6 0v-.4H4.6v.4a.8.8 0 11-1.6 0v-.4H1.6v2h11.6zM1.6 6.4h11.6v8H1.6v-8zm3.2 4.2a.8.8 0 01.8-.8h1v-1a.8.8 0 111.6 0v1h1a.8.8 0 010 1.6h-1v1a.8.8 0 01-1.6 0v-1h-1a.8.8 0 01-.8-.8z" _fill="#0072D9"/></g><defs><clipPath id="clip0_1188_14248"><path pid="1" _fill="#fff" d="M0 0h16v16H0z"/></clipPath></defs>'
  }
})
