/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'general/warning': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M16 28c6.627 0 12-5.373 12-12S22.627 4 16 4 4 9.373 4 16s5.373 12 12 12z" _stroke="#05B" stroke-width="2" stroke-miterlimit="10"/><path pid="1" d="M16 10v7" _stroke="#05B" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path pid="2" d="M16 23a1.5 1.5 0 100-3 1.5 1.5 0 000 3z" _fill="#05B"/>'
  }
})
