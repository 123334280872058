/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendar-blank': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" d="M18.25 5.125H5.75a.625.625 0 00-.625.625v12.5c0 .345.28.625.625.625h12.5c.345 0 .625-.28.625-.625V5.75a.625.625 0 00-.625-.625zM15.75 3.875v2.5M8.25 3.875v2.5M5.125 8.875h13.75" _stroke="#909EB9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
