/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow_slider-defolt': {
    width: 60,
    height: 60,
    viewBox: '0 0 60 60',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M29.444 20.586l11.849 11.85a1 1 0 01-1.414 1.413L29.444 23.414 19.009 33.85a1 1 0 01-1.415-1.414l11.85-11.85z" _fill="#333"/>'
  }
})
