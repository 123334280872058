<template>
    <section v-if="news_data && news_data.length" class="section-news">
        <div class="section-news__header container" data-scroll-translate>
            <z-caption tag="p" size="4xl" weight="600">Новости</z-caption>
            <z-button tag="a" size="l" href="/press-center/news/" data-scroll-translate>
                все новости
            </z-button>
        </div>
        <div class="section-news__content container" data-scroll-reveal>
            <news-list isMain :data="news_data" />
        </div>
    </section>
</template>

<script>
export default {
    name: 'section-news',
    data () {
        return {
            news_data: this.$root.app.components.main.news
        }
    }
}
</script>

<style lang="scss">
@import './index.scss';
</style>
