/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'buttons/ical': {
    width: 14,
    height: 14,
    viewBox: '0 0 14 14',
    data: '<g clip-path="url(#clip0_1188_14266)"><mask id="svgicon_buttons_ical_a" _fill="#fff"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.5 5h-11v6.5a1 1 0 001 1h9a1 1 0 001-1V5zM0 5V2.5A2.5 2.5 0 012.5 0h9A2.5 2.5 0 0114 2.5v9a2.5 2.5 0 01-2.5 2.5h-9A2.5 2.5 0 010 11.5V5zm4.35-1.5a.75.75 0 100-1.5.75.75 0 000 1.5zm5.4 0a.75.75 0 100-1.5.75.75 0 000 1.5z"/></mask><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M12.5 5h-11v6.5a1 1 0 001 1h9a1 1 0 001-1V5zM0 5V2.5A2.5 2.5 0 012.5 0h9A2.5 2.5 0 0114 2.5v9a2.5 2.5 0 01-2.5 2.5h-9A2.5 2.5 0 010 11.5V5zm4.35-1.5a.75.75 0 100-1.5.75.75 0 000 1.5zm5.4 0a.75.75 0 100-1.5.75.75 0 000 1.5z" _fill="#0072D9"/><path pid="2" d="M1.5 5V3.5H0V5h1.5zm11 0H14V3.5h-1.5V5zm-11 1.5h11v-3h-11v3zM0 5v6.5h3V5H0zm0 6.5A2.5 2.5 0 002.5 14v-3a.5.5 0 01.5.5H0zM2.5 14h9v-3h-9v3zm9 0a2.5 2.5 0 002.5-2.5h-3a.5.5 0 01.5-.5v3zm2.5-2.5V5h-3v6.5h3zm-15.5-9V5h3V2.5h-3zm4-4a4 4 0 00-4 4h3a1 1 0 011-1v-3zm9 0h-9v3h9v-3zm4 4a4 4 0 00-4-4v3a1 1 0 011 1h3zm0 2.5V2.5h-3V5h3zm0 6.5V5h-3v6.5h3zm-4 4a4 4 0 004-4h-3a1 1 0 01-1 1v3zm-9 0h9v-3h-9v3zm-4-4a4 4 0 004 4v-3a1 1 0 01-1-1h-3zm0-6.5v6.5h3V5h-3zm5.1-2.25A.75.75 0 014.35 2v3A2.25 2.25 0 006.6 2.75h-3zm.75.75a.75.75 0 01-.75-.75h3A2.25 2.25 0 004.35.5v3zm.75-.75a.75.75 0 01-.75.75v-3A2.25 2.25 0 002.1 2.75h3zM4.35 2a.75.75 0 01.75.75h-3A2.25 2.25 0 004.35 5V2zM9 2.75A.75.75 0 019.75 2v3A2.25 2.25 0 0012 2.75H9zm.75.75A.75.75 0 019 2.75h3A2.25 2.25 0 009.75.5v3zm.75-.75a.75.75 0 01-.75.75v-3A2.25 2.25 0 007.5 2.75h3zM9.75 2a.75.75 0 01.75.75h-3A2.25 2.25 0 009.75 5V2z" _fill="#0072D9" mask="url(#svgicon_buttons_ical_a)"/></g><defs><clipPath id="clip0_1188_14266"><path pid="3" _fill="#fff" d="M0 0h14v14H0z"/></clipPath></defs>'
  }
})
