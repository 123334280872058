<template>
    <div v-if="!data.error" class="schedule">
        <div class="schedule__layout container">
            <div class="schedule__main">
                <schedule-filter
                    class="schedule__filter"
                    @change="onFilterChange"
                    :data="filter"
                />
                <z-preloader v-if="isLoading"/>
                <results
                    class="schedule__results"
                    :data="items"
                    :is-current-week="data.current.value === 'Y'"
                    :is-init="isInit"
                />
            </div>
            <div class="schedule__aside">
                <calendar-block />
            </div>
        </div>
    </div>
</template>
<script>
import ScheduleFilter from './components/Filter.vue'
import Results from './components/Results.vue'
import CalendarBlock from './components/CalendarBlock.vue'
import { getScheduleData } from '@/api/schedule'
import { throttle } from 'throttle-debounce'

export default {
    provide () {
        return {
            studyFormXmlId: () => {
                let result = ''

                for (const key in this.filter.studyForm) {
                    if (this.filter.studyForm[key].id === this.params.studyForm) {
                        result = this.filter.studyForm[key].xmlId
                        break
                    }
                }

                return result
            }
        }
    },
    name: 'schedule',
    components: {
        ScheduleFilter,
        Results,
        CalendarBlock
    },
    data () {
        return {
            isInit: true,
            data: this.$root.app.components.schedule,
            filter: this.$root.app.components.schedule?.filter || {},
            items: this.$root.app.components.schedule?.items || [],
            isLoading: false,
            params: {
                query: '',
                studyForm: '',
                name: '',
                city: '',
                curator: '',
                group: ''
            }
        }
    },
    methods: {
        onFilterChange (params) {
            this.params = { ...this.params, ...params }
            this.isLoading = true
            this.send()
        },
        send: throttle(500, function () {
            getScheduleData(this.params)
                .then(response => {
                    this.items = response.items
                    this.filter = response.filter
                })
                .catch(err => {
                    console.error(err)
                })
                .finally(() => {
                    this.isInit = false
                    this.isLoading = false
                })
        })
    }
}
</script>
<style lang="scss">
.schedule {
    &__layout {
        display: flex;
        overflow: hidden;
        background-color: $token-colors-bg-5;
        border-top-left-radius: $borderRadius;
        border-top-right-radius: $borderRadius;
        padding: 0;
    }

    &__results {
        @include padding-level(right, 2XL);
        padding-left: $token-grids-desktop-offset;

        @include breakpoint(laptop) {
            padding-left: $token-grids-laptop-offset;
        }

        @include breakpoint(tablet) {
            padding-left: $token-grids-tablet-offset;
        }

        @include breakpoint(v-tablet) {
            padding-left: $token-grids-v-tablet-offset;
            padding-right: $token-grids-v-tablet-offset;
        }

        @include breakpoint(mobile) {
            padding-left: $token-grids-mobile-offset;
            padding-right: $token-grids-mobile-offset;
        }
    }

    &__main {
        width: 75%;
        @include padding-level(bottom, 2XL);
        padding-bottom: calc(#{$token-spacers-2-xl} + #{$borderRadius});

        @include breakpoint (tablet) {
            padding-bottom: calc(72px + #{$borderRadius});
        }

        @include breakpoint (v-tablet) {
            padding-bottom: calc(#{$token-spacers-xl} + #{$borderRadius});
            width: 100%;
        }
    }

    &__aside {
        width: 25%;
        @include padding-level(top, S);
        @include padding-level(bottom, S);
        background: $token-colors-white;

        @include breakpoint (v-tablet) {
            display: none;
        }

        @include padding-level(left, S);
        padding-right: $token-grids-desktop-offset;

        @include breakpoint(laptop) {
            padding-right: $token-grids-laptop-offset;
        }

        @include breakpoint(tablet) {
            padding-right: $token-grids-tablet-offset;
        }

        @include breakpoint(v-tablet) {
            padding-right: $token-grids-v-tablet-offset;
        }

        @include breakpoint(mobile) {
            padding-right: $token-grids-mobile-offset;
        }
    }

    @media print {
        .schedule__filter,
        .schedule__aside,
        .schedule-tools,
        .schedule-card__remind {
            display: none !important;
        }

        .schedule__main {
            width: 100%;
        }

        .schedule-card {
            border: 1px solid $token-colors-primary;
            margin-left: 0;
            border-radius: 0;

            &:after {
                display: none;
            }
        }
    }
}
</style>
