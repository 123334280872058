<template>
    <li :class="[
        'z-list-item',
        { 'z-list-item--centered' : centered && icon }
    ]">
        <z-icon
            v-if="iconName"
            class="z-list-item__icon"
            :name="iconName"
        />
        <div class="z-list-item__content">
            <slot/>
        </div>
    </li>
</template>

<script>
export default {
    name: 'z-list-item',
    props: {
        centered: {
            type: Boolean,
            default: false
        },
        icon: {
            type: String,
            default: ''
        }
    },
    computed: {
        iconName () {
            if (this.$parent.tag === 'ol') return ''
            if (this.icon) return this.icon
            return this.$parent.icon
        }
    }
}
</script>

<style lang="scss">
.z-list-item {
    position: relative;
    font-size: 1em;
    display: flex;
    align-items: flex-start;
    line-height: 1.4;

    &--centered {
        align-items: center;
    }

    & + & {
        margin-top: 1.5em;
    }

    &__content {
        flex-grow: 1;
        flex-shrink: 1;
    }

    .z-list--size-xl > & {
        font-weight: 400;
    }

    .z-list--size-s > & {
        font-weight: 400;
    }
}
</style>
