import { axios } from '@/utils/request'

export function getScheduleFile (parameter) {
    return axios({
        url: '/schedule/file/',
        method: 'get',
        params: parameter
    })
}

export function getScheduleData (parameter) {
    return axios({
        url: '/schedule/',
        method: 'get',
        params: parameter
    })
}
