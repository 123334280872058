/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tag_color_dots_icon-1': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<rect pid="0" width="48" height="48" rx="24" _fill="#DB674B"/><mask id="svgicon_tag_color_dots_icon-1_a" _fill="#fff"><path pid="1" fill-rule="evenodd" clip-rule="evenodd" d="M30.273 21.386h-.168C29.45 18.39 26.966 16.16 24 16.16c-2.966 0-5.451 2.23-6.105 5.227h-.168a5.228 5.228 0 000 10.455h12.546a5.227 5.227 0 100-10.455z"/></mask><path pid="2" d="M30.105 21.386l-2.931.64.515 2.36h2.416v-3zm-12.21 0v3h2.416l.515-2.36-2.93-.64zm-.702.027l.303 2.985-.303-2.985zm12.912 2.973h.168v-6h-.168v6zM24 19.16c1.37 0 2.78 1.058 3.174 2.867l5.862-1.279c-.913-4.186-4.474-7.588-9.036-7.588v6zm-3.174 2.867c.395-1.809 1.803-2.867 3.174-2.867v-6c-4.562 0-8.123 3.402-9.036 7.588l5.862 1.279zm-3.099 2.36h.168v-6h-.168v6zm-.231.012a2.3 2.3 0 01.231-.012v-6c-.282 0-.561.015-.837.043l.606 5.969zM15.5 26.614c0-1.152.876-2.102 1.996-2.216l-.606-5.97a8.228 8.228 0 00-7.39 8.186h6zm2.227 2.227a2.227 2.227 0 01-2.227-2.227h-6a8.227 8.227 0 008.227 8.227v-6zm12.546 0H17.727v6h12.546v-6zm2.227-2.227c0 1.23-.997 2.227-2.227 2.227v6a8.227 8.227 0 008.227-8.227h-6zm-2.227-2.228c1.23 0 2.227.998 2.227 2.228h6a8.227 8.227 0 00-8.227-8.228v6z" _fill="#FFEDCB" mask="url(#svgicon_tag_color_dots_icon-1_a)"/>'
  }
})
