<template>
    <header class="header" :class="{ 'header--menu-opened': isMenuOpened }">
        <div class="container header__container">
            <div class="header__logo">
                <z-logo site-dir="/" :is-index="isIndexPage" theme="white" />
            </div>
            <div class="header__wrapper">
                <navigation-main
                    class="hide-me--tablet"
                    @menuToggle="handleMenuToggle"
                />
                <navigation-mobile class="hide-me--default show-me--tablet"/>
                <div class="header__tools-wrapper">
                    <div class="header-tools">
                        <z-link href="/search/" class="header-tools__item hide-me--tablet" :underlined="false">
                            <z-icon name="search" width="40" height="40"/>
                        </z-link>
                        <z-button tag="a" class="header-tools__item" @click="$root.$bus.$emit('open-assist-bar')">
                            <z-tooltip icon-hidden content="Версия для слабовидящих">
                                <z-icon name="enlarged_version-header" width="40" height="40"/>
                            </z-tooltip>
                        </z-button>
                    </div>
                </div>
            </div>
        </div>
    </header>
</template>

<script>
import NavigationMain from '@/components/NavigationMain'
import NavigationMobile from '@/components/NavigationMobile'

export default {
    name: 'header-layout',
    components: {
        NavigationMain,
        NavigationMobile
    },
    data () {
        return {
            contacts: this.$root.app.components['contacts-common'],
            isMenuOpened: false
        }
    },
    computed: {
        contactData () {
            if (!this.contacts) return
            return this.contacts.norilsk?.phone[0] // сколько бы ни было перечислено телефонов, выводиться в хедер будет первый указанный в элементе "Норильск"
        },
        isIndexPage () {
            return window.location.pathname === '/'
        }
    },
    methods: {
        handleMenuToggle (isMenuOpen) {
            this.isMenuOpened = isMenuOpen
        }
    }
}
</script>

<style lang="scss">
.header {
    position: relative;
    display: flex;
    align-items: center;
    @include padding-level (top, XL);
    padding-bottom: calc(#{$token-spacers-xl} + #{$token-spacers-l});

    @include breakpoint (mobile) {
        padding-top: $token-spacers-xs;
        padding-bottom: calc(#{$token-spacers-xs} + #{$token-spacers-l});
    }

    &:before {
        content: '';
        background: linear-gradient(180deg, rgba(13, 32, 70, 0.40) 0%, rgba(29, 92, 219, 0.00) 100%, rgba(29, 92, 219, 0.00) 100%);
        width: 100%;
        height: 100%;
        position: absolute;
        pointer-events: none;
    }

    &--menu-opened {
        background-color: $headerColor;
    }

    &__tools-wrapper {
        display: flex;
        padding-top: 10px;
    }

    &__container {
        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-wrap: wrap;
    }

    &__wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-grow: 1;

        @include breakpoint (tablet) {
            justify-content: flex-end;
        }
    }

    &__logo {
        width: 230px;
        align-self: flex-start;

        @include breakpoint (laptop) {
            max-width: 170px;
        }

        @include breakpoint (tablet) {
            max-width: 190px;
        }

        @include breakpoint (mobile) {
            max-width: 110px;
        }
    }

    &-tools {
        display: flex;
        align-items: center;

        &__item {
            background: transparent !important;
            border-radius: 0!important;
            border-color: transparent !important;
            padding: 0 !important;
            display: inline-block;
            width: 40px;
            height: 40px;
            transition: background-color $transition, border-color $transition, border-radius $transition !important;

            &:nth-child(n+2) {
                @include margin-level(left, 2XS);
            }

            path {
                fill: $token-colors-white;
            }

            &:hover {
                background-color: $token-colors-bg-10 !important;
                border-radius: 50% !important;

                path {
                    fill: $token-colors-link-default;
                }
            }
        }
    }
}
</style>
