<template>
    <nav
        class="navigation-mobile"
        aria-label="Main navigation"
        :class="{'navigation-mobile--index': isIndex}"
    >
        <a
            class="navigation-mobile__burger"
            href="javascript:void(0);"
            :class="{'is-active': getBurgerMenu}"
            @click="toggleDropdown(getBurgerMenu)"
            title=""
        >
            <z-icon
                class="navigation-mobile__burger--open"
                name="burger"
            />
            <z-icon
                class="navigation-mobile__burger--close"
                name="burger-close"
            />
        </a>
        <div
            class="navigation-mobile__dropdown nm-dropdown"
            v-if="getBurgerMenu"
        >
            <div class="nm-dropdown__body">
                <template v-if="!subMenu.visible">
                    <div class="navigation-mobile__header">
                        <div class="container">
                            <div class="navigation-mobile__search">
                                <z-input
                                    name="input2"
                                    theme="search"
                                    class="nm-search"
                                    placeholder="Поиск"
                                    v-model="searchValue"
                                    @keyup.enter.native="searchHandler"
                                ></z-input>
                                <z-button
                                    class="navigation-mobile__link"
                                    tag="a"
                                    :href="urlFromSearch"
                                    :disabled="searchValue === ''"
                                >
                                    Искать
                                </z-button>
                            </div>
                        </div>
                    </div>
                    <div class="container">
                        <ul class="navigation-mobile__list">
                            <li
                                class="navigation-mobile__item"
                                v-for="(item, index) in mobileNavList"
                                :key="index"
                                :class="{'is-active': checkActiveState(item)}"

                            >
                                <span
                                    class="navigation-mobile__item-inner"
                                    @click="showSubMenu(item.items, item.name, index, item)"
                                    v-if="item.items && item.items.length"
                                >
                                    <span v-html="item.name"></span>
                                    <z-icon
                                        class="navigation-mobile__arrow navigation-mobile__arrow--right"
                                        name="caretup"
                                        dir="right"
                                    />
                                </span>
                                <a
                                    v-else
                                    class="navigation-mobile__item-inner"
                                    :href="item.link"
                                    :title="item.name"
                                    v-html="item.name"
                                    :target="item.params && item.params.BLANK ? '_blank' : false"
                                ></a>
                            </li>
                        </ul>
                    </div>
                </template>
                <template v-if="subMenu.visible">
                    <div class="navigation-mobile__header">
                        <div class="container">
                            <div class="navigation-mobile__item navigation-mobile__item--backlink">
                                <span
                                    class="navigation-mobile__item-inner"
                                    @click="hideSubMenu"
                                >
                                    <z-icon
                                        class="navigation-mobile__arrow navigation-mobile__arrow--left"
                                        name="caretup"
                                        dir="left"
                                    />
                                    <a href="javascript:void(0);"><span>{{text.back}}</span></a>
                                </span>
                            </div>
                        </div>
                    </div>
                    <div class="navigation-mobile__submenu">
                        <div class="container">
                            <ul class="navigation-mobile__list">
                                <li class="navigation-mobile__item navigation-mobile__item--subindex">
                                    <a :href="subMenu.parent.link" v-html="subMenu.parent.name" class="navigation-mobile__item-inner"></a>
                                </li>

                                <li
                                    class="navigation-mobile__item navigation-mobile__item--lvl-2"
                                    v-for="(item, index) in subMenu.items"
                                    :key="index"
                                    :class="{'is-active': checkActiveState(item)}"
                                >
                                    <span
                                        class="navigation-mobile__item-inner"
                                        @click="showSubMenu(item.items, item.name, index, item)"
                                        v-if="item.items && item.items.length"
                                    >
                                        <span v-html="item.name"></span>
                                        <z-icon
                                            class="navigation-mobile__arrow navigation-mobile__arrow--right"
                                            name="caretup"
                                            dir="right"
                                        />
                                    </span>
                                    <a
                                        v-else
                                        class="navigation-mobile__item-inner"
                                        :href="item.link"
                                        :title="item.name"
                                        :target="item.params && item.params.BLANK ? '_blank' : false"
                                    ><span><span v-html="item.name"></span> <z-icon class="navigation-mobile__external-icon" v-if="item.params.EXTERNAL" name="external" /></span> </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </template>
            </div>
            <div class="nm-dropdown__footer nm-footer">
                <div class="container">
                    <div class="nm-footer__wrapper">
                        <z-button kind="bordered" tag="a" size="s" href="tel:+73919250200">+7 (3919) 250-200</z-button>
                        <!-- <z-link href="/education/schedule/" class="nm-footer__item">
                            <z-icon name="calendarbell" width="40" height="40" />
                            <span class="nm-footer__item-text">Посмотреть расписание</span>
                        </z-link>
                        <z-button tag="a" class="nm-footer__item nm-footer__item--button" @click="$root.$bus.$emit('open-assist-bar')">
                            <z-icon name="enlarged_version-header" width="40" height="40"/>
                            <span class="nm-footer__item-text">Версия для слабовидящих</span>
                        </z-button> -->
                    </div>
                </div>
            </div>
        </div>
    </nav>
</template>

<script>

export default {
    name: 'navigation-mobile',
    props: {
        isIndex: Boolean
    },
    data () {
        return {
            top: this.$root.app.components.navigation.top,
            bottom: this.$root.app.components.navigation.bottom,
            contacts: this.$root.app.components['contacts-common'],
            staticData: [
                {
                    name: 'Политика конфиденциальности',
                    link: '/privacy-policy/'
                },
                {
                    name: 'Сведения об образовательной организации',
                    link: '/sveden/'
                }
            ],
            subMenu: {
                items: [],
                header: '',
                visible: false
            },
            text: {
                back: 'В главное меню'
            },
            searchValue: ''
        }
    },
    computed: {
        getBurgerMenu () {
            return this.$store.getters.burgerMenu
        },
        mobileNavList () {
            if (!this.top && !this.bottom) return []
            return this.top.concat(this.bottom).concat(this.staticData)
        },
        urlFromSearch () {
            return `/search/?currentPage=1&how=r&q=${this.searchValue}`
        },
        contactData () {
            if (!this.contacts) return
            return this.contacts.norilsk?.phone[0] // сколько бы ни было перечислено телефонов, выводиться в хедер будет первый указанный в элементе "Норильск"
        }
    },
    watch: {
        getBurgerMenu (next, prev) {
            let header = document.querySelector('.header')

            this.subMenu.visible = !(next && header)

            header.classList.toggle('is-open', next && header)
            document.body.classList.toggle('is-disabled', next && header)
            document.body.classList.toggle('mobile-nav-opened', next && header)
        }
    },
    methods: {
        toggleDropdown (state) {
            this.$store.commit('BurgerMenu', !state)
        },
        showSubMenu (items, name, index, parent) {
            this.subMenu.parent = parent
            this.subMenu.items = items
            this.subMenu.header = name
            this.subMenu.visible = true
        },
        hideSubMenu () {
            this.subMenu.items = []
            this.subMenu.header = ''
            this.subMenu.visible = false
        },
        checkActiveState (item) {
            if (item.active === true) return true

            for (let i = 0; i < item.items?.length; i++) {
                if (item.items[i].active === true) return true
            }

            return false
        },
        searchHandler () {
            window.location.replace(this.urlFromSearch)
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
