/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/xlsx': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" d="M8 11a8 8 0 018-8h13l12 12v21a8 8 0 01-8 8H16a8 8 0 01-8-8V11z" _fill="#C6C9D6"/><path pid="1" d="M13.568 25.9l1.76 2.62 1.75-2.62h1.5l-2.46 3.6 2.38 3.5h-1.5l-1.67-2.52-1.68 2.52h-1.51l2.38-3.5-2.46-3.6h1.51zm5.725 7.1v-7.1h1.38v5.89h3.62V33h-5zm10.07-5.24a1.645 1.645 0 00-.26-.19 2.55 2.55 0 00-.45-.23 4.084 4.084 0 00-.57-.19 2.249 2.249 0 00-.63-.09c-.374 0-.657.07-.85.21a.695.695 0 00-.28.59c0 .193.06.347.18.46.12.113.3.21.54.29.24.08.54.167.9.26.466.113.87.253 1.21.42.346.16.61.373.79.64.186.26.28.607.28 1.04 0 .38-.07.707-.21.98-.14.267-.334.483-.58.65-.247.167-.527.29-.84.37a4.38 4.38 0 01-1 .11c-.354 0-.707-.037-1.06-.11a5.822 5.822 0 01-1.02-.31 4.884 4.884 0 01-.9-.5l.61-1.19c.06.06.166.14.32.24.153.093.34.19.56.29.22.093.46.173.72.24.26.067.523.1.79.1.373 0 .656-.063.85-.19a.609.609 0 00.29-.54.6.6 0 00-.23-.5 2.046 2.046 0 00-.64-.31 20.45 20.45 0 00-.97-.3 6.216 6.216 0 01-1.12-.42c-.3-.16-.524-.36-.67-.6-.147-.24-.22-.54-.22-.9 0-.487.113-.893.34-1.22.233-.333.546-.583.94-.75.393-.173.83-.26 1.31-.26.333 0 .646.037.94.11.3.073.58.17.84.29.26.12.493.25.7.39l-.61 1.12zm2.564-1.86l1.76 2.62 1.75-2.62h1.5l-2.46 3.6 2.38 3.5h-1.5l-1.67-2.52-1.68 2.52h-1.51l2.38-3.5-2.46-3.6h1.51z" _fill="#4A5D7F"/><path pid="2" d="M29 3l12 12h-8a4 4 0 01-4-4V3z" _fill="#EFF0F3"/>'
  }
})
