/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'buttons/yahoo': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<g clip-path="url(#clip0_1188_14260)"><path pid="0" d="M3 2.014s.418.094.768.094c.35 0 .963-.108.963-.108l2.884 5.149L10.5 2s.552.115.97.115c.418 0 .76-.108.76-.108L8.424 8.692 8.538 14s-.587-.23-.923-.23c-.331 0-.923.23-.923.23l.116-5.308L3 2.014z" _fill="#0072D9"/></g><defs><clipPath id="clip0_1188_14260"><path pid="1" _fill="#fff" d="M0 0h16v16H0z"/></clipPath></defs>'
  }
})
