/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/zip': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" d="M8 11a8 8 0 018-8h13l12 12v21a8 8 0 01-8 8H16a8 8 0 01-8-8V11z" _fill="#C6C9D6"/><path pid="1" d="M15.643 31.752l4.752-5.82h-4.62V24.48h6.588v1.248l-4.596 5.82H22.4V33h-6.756v-1.248zM23.695 33v-8.52h1.656V33h-1.656zm3.433 0v-8.52h3.612c.392 0 .752.08 1.08.24.328.16.612.376.852.648.248.272.44.576.576.912.136.336.204.68.204 1.032 0 .48-.112.936-.336 1.368a2.875 2.875 0 01-.924 1.056c-.392.272-.856.408-1.392.408h-2.016V33h-1.656zm1.656-4.308h1.92c.2 0 .38-.056.54-.168.16-.112.288-.272.384-.48.096-.216.144-.46.144-.732 0-.296-.056-.548-.168-.756a1.093 1.093 0 00-.42-.468 1.029 1.029 0 00-.552-.156h-1.848v2.76z" _fill="#4A5D7F"/><path pid="2" d="M29 3l12 12h-8a4 4 0 01-4-4V3z" _fill="#EFF0F3"/>'
  }
})
