/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'buttons/arrow_right': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<g clip-path="url(#clip0_1188_14224)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M13.462 8.534a.747.747 0 00.002-1.063L8.532 2.54a.75.75 0 00-1.06 1.06l3.65 3.652H3.07a.75.75 0 000 1.5h8.053L7.47 12.403a.75.75 0 001.061 1.06l4.93-4.929z" _fill="#0078CE"/></g><defs><clipPath id="clip0_1188_14224"><path pid="1" _fill="#fff" transform="matrix(0 -1 -1 0 16 16)" d="M0 0h16v16H0z"/></clipPath></defs>'
  }
})
