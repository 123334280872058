<template>
<div class="files" v-if="formatedData.length">
    <template v-if="formatedData.length > 1">
        <z-accordion multiple theme="white">
            <z-accordion-item
                v-for="accordion in formatedData"
                :key="accordion['xml-id']"
                :id="accordion['xml-id']"
            >
                <template v-slot:header>
                    <span v-html="accordion.name"></span>
                </template>
                <template v-slot:body>
                    <z-filelist>
                        <z-filelist-item
                            v-for="(item, index) in accordion.items"
                            :key="index"
                            :icon="`file/${item.file.type}`"
                            :info="`${item.file.size}, ${item.file.type}`"
                        >
                            <z-link :href="item.file.link" target="_blank" :itemprop="item.itemprop.value">
                                <span v-html="item.name"></span>
                            </z-link>
                        </z-filelist-item>
                    </z-filelist>
                </template>
            </z-accordion-item>
        </z-accordion>
    </template>
    <template v-else>
        <z-caption tag="h2" v-html="data[0].group.value"></z-caption>
        <z-filelist>
            <z-filelist-item
                v-for="(item, index) in formatedData[0].items"
                :key="index"
                :icon="`file/${item.file.type}`"
                :info="`${item.file.size}, ${item.file.type}`"
            >
                <z-link :href="item.file.link" target="_blank" :itemprop="item.itemprop.value">
                    <span v-html="item.name"></span>
                </z-link>
            </z-filelist-item>
        </z-filelist>
    </template>

</div>
</template>

<script>
export default {
    name: 'files',
    props: {
        source: {
            type: String,
            default: 'files'
        }
    },
    data () {
        return {
            data: this.$root.app.components[this.source]
        }
    },
    computed: {
        formatedData () {
            if (!this.data) return []

            const result = this.data.reduce(function (r, a) {
                r[a.group['xml-id']] = r[a.group['xml-id']] || {}
                r[a.group['xml-id']].name = r[a.group['xml-id']].name || a.group.value
                r[a.group['xml-id']]['xml-id'] = r[a.group['xml-id']]['xml-id'] || a.group['xml-id']
                r[a.group['xml-id']].items = r[a.group['xml-id']].items || []
                r[a.group['xml-id']].items.push(a)
                return r
            }, Object.create(null))

            return Object.values(result)
        }
    }
}
</script>
