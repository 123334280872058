<template>
    <div class="page-changer" :class="{ 'page-changer--with-back-link': data['back-link'] }">
        <a
            :href="data.prev ? data.prev.url : 'javascript:void(0)'"
            :class="[
                'page-changer__link page-changer__link--prev',
                { 'is-disabled': !(data.prev && data.prev.url) }
            ]"
        >
            <div class="page-changer__link--icon page-changer__link--icon-prev"></div>
            <span class="page-changer__link-text">
                {{ data.prev && data.prev.name || text.prev }}
            </span>
        </a>
        <div class="page-changer__button-wrapper">
            <z-button
                v-if="data['back-link']"
                class="page-changer__button u-semi-bold"
                tag="a"
                kind="bordered"
                :href="data['back-link']"
            >
                {{ buttonText }}
            </z-button>
        </div>

        <a
            :href="data.next ? data.next.url : 'javascript:void(0)'"
            :class="
                ['page-changer__link page-changer__link--next',
                { 'is-disabled': !(data.next && data.next.url) }
            ]"

        >
            <span class="page-changer__link-text">
                {{ data.next && data.next.name || text.next }}
            </span>
            <div class="page-changer__link--icon page-changer__link--icon-next"></div>
        </a>
    </div>
</template>

<script>
import { localize } from '@/utils/i18n'

export default {
    name: 'page-changer',
    props: {
        data: {
            required: true,
            type: Object
        },
        buttonText: {
            type: String,
            default: localize({
                ru: 'Вернуться к списку',
                en: 'Back to list'
            })
        }
    },
    data () {
        return {
            text: {
                prev: localize({
                    ru: 'Предыдущая',
                    en: 'Previous'
                }),
                next: localize({
                    ru: 'Следующая',
                    en: 'Next'
                })
            }
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
