var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"employees-page"},[_c('div',{staticClass:"employees-page__wrapper",class:[{ 'is-loading': _vm.isLoading }]},[(_vm.data && _vm.data.length)?_c('div',{staticClass:"employees-page__row"},_vm._l((_vm.data),function(item,index){return _c('div',{key:index,staticClass:"employees-page__col"},[_c('employee-card',{attrs:{"data":item}})],1)}),0):_vm._e(),_vm._v(" "),(_vm.isLoading)?_c('z-preloader'):_vm._e()],1),_vm._v(" "),_c('z-pagination',{attrs:{"show-next":"","show-prev":"","show-more":{
            marginBottom: 'xs',
            size: 'm'
        },"show-nav":"","data":{
            currentPage: this.nav.current,
            totalPages: this.nav.total,
            pageRange: 1
        }},on:{"change-page":_vm.onPageChange}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }