/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'Star 1': {
    width: 32,
    height: 32,
    viewBox: '0 0 32 32',
    data: '<path pid="0" d="M15.025 4.318c.234-1.04 1.716-1.04 1.95 0l1.052 4.654a1 1 0 001.51.626L23.57 7.05c.9-.569 1.948.478 1.379 1.38l-2.548 4.034a1 1 0 00.625 1.51l4.655 1.05c1.04.235 1.04 1.717 0 1.951l-4.654 1.052a1 1 0 00-.626 1.51l2.548 4.034c.569.9-.478 1.948-1.38 1.379l-4.034-2.548a1 1 0 00-1.51.625l-1.05 4.655c-.235 1.04-1.717 1.04-1.951 0l-1.052-4.654a1 1 0 00-1.51-.626L8.43 24.95c-.9.569-1.948-.478-1.379-1.38l2.548-4.034a1 1 0 00-.626-1.51l-4.654-1.05c-1.04-.235-1.04-1.717 0-1.951l4.654-1.052a1 1 0 00.626-1.51L7.05 8.43c-.569-.9.478-1.948 1.38-1.379l4.034 2.548a1 1 0 001.51-.626l1.05-4.654z" _fill="#E68D3D"/>'
  }
})
