/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/tif': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" d="M8 11a8 8 0 018-8h13l12 12v21a8 8 0 01-8 8H16a8 8 0 01-8-8V11z" _fill="#C6C9D6"/><path pid="1" d="M22.883 25.932h-2.724V33h-1.644v-7.068h-2.724V24.48h7.092v1.452zM23.941 33v-8.52h1.656V33H23.94zm3.433 0v-8.52h5.724v1.452H29.03v2.196h3.384v1.344H29.03V33h-1.656z" _fill="#4A5D7F"/><path pid="2" d="M29 3l12 12h-8a4 4 0 01-4-4V3z" _fill="#EFF0F3"/>'
  }
})
