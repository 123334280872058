<template>
    <div class="events-detail-materials">
        <z-filelist>
            <z-filelist-item
                v-for="(item, index) in data"
                :key="index"
                :icon="`file/${item.type}`"
                :info="item.size"
            >
                <z-link :href="item.link" :underlined="false">{{ item.name }}</z-link>
            </z-filelist-item>
        </z-filelist>
    </div>
</template>

<script>
export default {
    name: 'events-detail-materials',
    props: {
        data: Array
    }
}
</script>

<style lang="scss">
.events-detail-materials {
    width: 100%;
    padding: $token-spacers-xl 40px;
    border-radius: 20px;
    background-color: $token-colors-white;

    @include breakpoint(v-tablet) {
        padding: $token-spacers-xs;
    }

    @include breakpoint(mobile) {
        padding: $token-spacers-2-xs;
    }

    .z-list {
        margin-bottom: 0;
    }
}
</style>
