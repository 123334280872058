<template>
    <div class="events-detail-speaker">
        <div
            class="events-detail-speaker__item"
            v-for="(speaker, index) in data"
            :key="index"
        >
            <z-contact-card
                horizontal
                :photo="speaker.previewPicture.link"
                :title="speaker.title"
                :subtitle="speaker.position.value"
            >
                <template>
                    <p class="u-bottom-margin--2xs-important u-semi-bold text-size-s"
                       v-html="speaker.workPlace.value "></p>
                    <p class="text-size-s" v-html="speaker.biography.value"></p>
                </template>
            </z-contact-card>
        </div>
    </div>
</template>

<script>
export default {
    name: 'events-detail-speaker',
    props: {
        data: Array
    }
}
</script>

<style lang="scss">
.events-detail-speaker {
    display: flex;
    gap: $token-spacers-xs;
    overflow-x: auto;
    align-items: stretch;
    padding-bottom: $token-spacers-2-xs;

    .z-contact-card {
        border-radius: 20px;
        background: #E8EFF8;
        padding: 40px;

        @include breakpoint(v-tablet) {
            padding: $token-spacers-xs;
        }

        @include breakpoint(mobile) {
            padding: $token-spacers-2-xs;
        }

        img {
            max-width: 120px;
            height: 120px;
            object-fit: cover;
        }

        .z-contact-card__content {
            width: 100%;
        }

        .z-contact-card__head {
            margin-bottom: 32px;
        }

        .z-contact-card__pic {
            margin-bottom: 0;

            @include breakpoint(v-tablet) {
                margin-bottom: $token-spacers-2-xs
            }
        }
    }

    &__item {
        min-width: 526px;
        max-width: 526px;
        width: 100%;
        display: flex;

        @include breakpoint(v-tablet) {
            min-width: 400px;
        }

        @include breakpoint(mobile) {
            min-width: 300px;
        }

        p {
            display: flex;
            flex-wrap: wrap;
        }
    }
}
</style>
