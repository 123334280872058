/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'close-round': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M8 14.8A6.8 6.8 0 118 1.2a6.8 6.8 0 010 13.6zM0 8a8 8 0 1016 0A8 8 0 000 8zm11.546-3.364a.75.75 0 010 1.061L9.152 8.091l2.394 2.394a.75.75 0 01-1.061 1.06L8.09 9.153l-2.394 2.394a.75.75 0 01-1.06-1.061L7.03 8.09 4.636 5.697a.75.75 0 111.061-1.06L8.091 7.03l2.394-2.394a.75.75 0 011.06 0z" _fill="#0072D9"/>'
  }
})
