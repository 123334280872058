/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tooltip': {
    width: 12,
    height: 12,
    viewBox: '0 0 12 12',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M6 1a5 5 0 100 10A5 5 0 006 1zm0-1a6 6 0 100 12A6 6 0 006 0zm0 9.417a.75.75 0 01-.75-.75V6a.75.75 0 111.5 0v2.667a.75.75 0 01-.75.75zm0-5.484a.6.6 0 000-1.2h-.007a.6.6 0 000 1.2H6z" _fill="#3B4C69"/>'
  }
})
