<template>
    <div
        :class="[
            'z-date-picker',
            {
                'z-date-picker--filter': isFilter,
                'is-disabled': disabled,
                'is-filled': isFilled,
                'is-errored': isValid === false,
                'is-valid': isValid && !isFilter
            }
        ]"
    >
        <date-picker
            ref="picker"
            v-bind="$attrs"
            :key="lang"
            :type="type"
            :lang="locale"
            v-model="date"
            :range="range"
            :format="format"
            @change="change"
            :title-format="format"
            :clearable="isClearable"
            :value-type="valueType"
            :placeholder="placeholder"
            :disabled-date="disabledDate"
            range-separator=" — "
            @clear="onClear()"
            :popup-class="`z-date-picker-popup ${range ? 'z-date-picker-popup--range' : '' }`"
        >
        </date-picker>
        <span
          :class="[
            'z-date-picker__error',
            errorClass
          ]"
          v-html="error"
          v-if="error && !isValid"
        ></span>
    </div>
</template>

<script>
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
const ruLangDatePicker = require(`vue2-datepicker/locale/ru`)
const enLangDatePicker = require(`vue2-datepicker/locale/en`)

export default {
    name: 'z-date-picker',
    components: {
        DatePicker
    },
    props: {
        range: Boolean,
        disabled: Boolean,
        clearable: {
            type: Boolean,
            default: false
        },
        required: {
            type: Boolean,
            default: false
        },
        isFilter: {
            type: Boolean,
            default: false
        },
        type: {
            type: String,
            default: 'date',
            validator: prop => ['date', 'datetime', 'year', 'month', 'time', 'week'].includes(prop)
        },
        valueType: {
            type: String,
            default: 'X'
        },
        placeholder: {
            type: String,
            default: ''
        },
        format: {
            type: String,
            default: 'DD.MM.YYYY'
        },
        value: [Array, String],
        disabledDate: {
            type: Function,
            default: () => {}
        },
        errorClass: {
            type: String,
            default: ''
        },
        showValidState: {
            type: Boolean,
            default: false
        }
    },
    data () {
        return {
            isValid: null,
            date: this.value,
            error: '',
            lang: this.$root.lang,
            text: {
                error: {
                    ru: 'Поле обязательно для заполнения',
                    en: 'Required field',
                    cn: '填项目'
                }
            },
            locale: this.lang === 'en' ? enLangDatePicker : ruLangDatePicker
        }
    },
    created () {},
    watch: {
        value (value) {
            this.date = value
        }
    },
    computed: {
        isFilled () {
            if (Array.isArray(this.date)) {
                return Boolean(this.date[0] && this.date[1])
            }

            return Boolean(this.date)
        },
        isClearable () {
            if (this.isFilter) return true
            return this.clearable
        }
    },
    methods: {
        onClear () {
            this.$refs.picker.closePopup()
        },
        change (data) {
            let result = data
            if (Array.isArray(data)) {
                const newValue = String(+data[1] + ((23 * 60 + 59) * 60 + 59))
                data.splice(1, 1, newValue)
            }
            if (Array.isArray(data) && !(data[0] && data[1])) {
                result = undefined
            }

            this.$emit('input', result)
            this.$emit('change', result)
            this.validate()
        },
        validate () {
            if (this.required && !this.isFilled) {
                this.isValid = false
                this.error = this.$root.localize(this.text.error)
                return
            }

            this.isValid = true
            this.error = ''
        }
    }
}
</script>

<style lang="scss">
$clearColorHover: '%23232323';

.z-date-picker {
  font-size: var(--textSize);

    &__error {
        font-size: var(--errorTextSize);
        color: var(--errorTextColor);
        position: relative;
        left: 16px;
    }

    .mx-datepicker {
        width: 100%;
    }

    .mx-icon-calendar {
        display: block !important;
        transition: background 0.2s ease-in;
        width: var(--iconSize);
        height: var(--iconSize);
        display: block;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: 50%;
        background-image: url("data:image/svg+xml,%3Csvg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M5.75 1.60587V0.855865H4.25V1.60587V3.54998H2C1.58579 3.54998 1.25 3.88577 1.25 4.29998V20.3941C1.25 20.8083 1.58579 21.1441 2 21.1441H22C22.4142 21.1441 22.75 20.8083 22.75 20.3941V4.29998C22.75 3.88577 22.4142 3.54998 22 3.54998H19.75V1.60587V0.855865H18.25V1.60587V3.54998H5.75V1.60587ZM18.25 5.60587V5.04998H5.75V5.60587V6.35587H4.25V5.60587V5.04998H2.75V19.6441H21.25V5.04998H19.75V5.60587V6.35587H18.25V5.60587ZM8.99994 7.60587H6.99994V9.60587H8.99994V7.60587ZM6.99994 11.6059H8.99994V13.6059H6.99994V11.6059ZM8.99994 15.6059H6.99994V17.6059H8.99994V15.6059ZM10.9999 7.60587H12.9999V9.60587H10.9999V7.60587ZM12.9999 11.6059H10.9999V13.6059H12.9999V11.6059ZM14.9999 11.6059H16.9999V13.6059H14.9999V11.6059ZM16.9999 15.6059H14.9999V17.6059H16.9999V15.6059ZM10.9999 15.6059H12.9999V17.6059H10.9999V15.6059ZM16.9999 7.60587H14.9999V9.60587H16.9999V7.60587Z' fill='%23A7A9AC'/%3E%3C/svg%3E%0A");
        right: 20px;
        margin-top: 0;

        svg {
            display: none;
        }
    }

    // filled state
    &.is-filled {
        .mx-input {
            border-color: var(--formBorderColorFilled);
        }
    }

    .mx-input {
        border: var(--formBorderWidth) solid var(--formBorderColorDefault);
        border-radius: var(--borderRadius);
        box-shadow: none;
        padding: var(--fieldPaddingS);
        padding-right: 70px;
        box-sizing: border-box;
        font-size: 1em;
        font-weight: 400;
        transition: border 0.2s ease-in;
        line-height: 1.3;
        text-overflow: ellipsis;
        height: var(--fieldHeightM);
        color: var(--formTextColorDefault);
        background: var(--fieldBg);

        &:hover {
            border-color: var(--formBorderColorHover);
        }

        &:focus {
            border-color: var(--formBorderColorFocus);

            + .mx-icon-clear {
                color: var(--formBorderColorFocus);
            }
        }
    }

    .mx-icon-clear {
        display: block;
        right: 48px;
        font-size: var(--iconSize);
        transition: color 0.2s ease-in;
        margin-top: 0;
        background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.8764 8.10355C17.0717 7.90829 17.0717 7.59171 16.8764 7.39645C16.6811 7.20118 16.3646 7.20118 16.1693 7.39645L12.1364 11.4293L8.10355 7.39645C7.90829 7.20118 7.59171 7.20118 7.39645 7.39645C7.20118 7.59171 7.20118 7.90829 7.39645 8.10355L11.4293 12.1364L7.39645 16.1693C7.20118 16.3646 7.20118 16.6811 7.39645 16.8764C7.59171 17.0717 7.90829 17.0717 8.10355 16.8764L12.1364 12.8435L16.1693 16.8764C16.3646 17.0717 16.6811 17.0717 16.8764 16.8764C17.0717 16.6811 17.0717 16.3646 16.8764 16.1693L12.8435 12.1364L16.8764 8.10355Z' fill='%234C535F'/%3e%3c/svg%3e");
        width: var(--iconSize);
        height: var(--iconSize);

        &:hover {
            background-image: url("data:image/svg+xml;charset=UTF-8,%3csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none'%3e%3cpath fill-rule='evenodd' clip-rule='evenodd' d='M16.8764 8.10355C17.0717 7.90829 17.0717 7.59171 16.8764 7.39645C16.6811 7.20118 16.3646 7.20118 16.1693 7.39645L12.1364 11.4293L8.10355 7.39645C7.90829 7.20118 7.59171 7.20118 7.39645 7.39645C7.20118 7.59171 7.20118 7.90829 7.39645 8.10355L11.4293 12.1364L7.39645 16.1693C7.20118 16.3646 7.20118 16.6811 7.39645 16.8764C7.59171 17.0717 7.90829 17.0717 8.10355 16.8764L12.1364 12.8435L16.1693 16.8764C16.3646 17.0717 16.6811 17.0717 16.8764 16.8764C17.0717 16.6811 17.0717 16.3646 16.8764 16.1693L12.8435 12.1364L16.8764 8.10355Z' fill='#{$clearColorHover}'/%3e%3c/svg%3e");
        }

        svg {
            display: none;
        }
    }

    // valid state
    &.is-valid {
        .mx-input {
            border-color: var(--formBorderColorValid);
            color: var(--formTextColorDefault);
        }
    }

    // errored state
    &.is-errored {
        .mx-input {
            border-color: var(--formBorderColorError);
        }
    }

    &--filter {
        // filled state
        &.is-filled {
            .mx-input {
                border-color: var(--formBorderColorFilledAccent);
            }

            .mx-icon-clear {
                color: var(--formBorderColorDefault);

                &:hover {
                    color: var(--formBorderColorHover);
                }
            }
        }
    }

    &.is-disabled {
        .mx-input {
            pointer-events: none;
            background-color: $token-colors-form-bg-disabled;
            color: $token-colors-form-text-color-disabled;
        }
    }
}

.z-date-picker-popup {
    font-size: var(--textSize);

    .mx-btn-text {
        font-weight: inherit;
        color: inherit;
        font-size: inherit;
        text-transform: capitalize;
        font-size: 22px;
        font-weight: 400;
        background: transparent;
        color: var(--formPlaceholderColorFilledAccent);

        @include breakpoint(mobile) {
            // font-size: 18px;
            // font-size: var(--fontSizeL);
        }
    }

    .mx-calendar {
        padding: 24px 32px;
        max-width: 336px;
        width: 100%;
        @include breakpoint(mobile) {
            padding: 16px;
        }

        &-decade-separator {
            &:after {
                content: " — ";
                display: inline-block;
                transform: translateY(-0.1em);
            }
        }

        &-content {
            .cell.in-range,
            .cell.hover-in-range {
                background-color: transparent;
            }

            .cell:hover {
                background: transparent;
            }
        }
    }

    .mx-calendar-header {
        padding: 0;
        margin-bottom: 24px;
        height: auto;
        line-height: 1;

        @include breakpoint(mobile) {
            margin-bottom: 16px;
        }
    }

    .mx-calendar-header-label {
        color: #000;
        font-size: 22px;
    }

    .mx-calendar-content {
        height: auto;
        overflow: hidden;
    }

    .mx-icon-left:before,
    .mx-icon-right:before,
    .mx-icon-double-left:before,
    .mx-icon-double-right:before,
    .mx-icon-double-left:after,
    .mx-icon-double-right:after {
        width: 16px;
        height: 16px;
        color: #B2B2B2;
        border-width: 3px 0 0 3px;

        @include breakpoint(mobile) {
            width: 14px;
            height: 14px;
        }
    }

    .cell {
        color: var(--formTextColorDefault) !important;
        font-weight: 400;
        // font-size: 13px;
        font-size: var(--fontSizeXs);
        line-height: 1;
        padding: 2px 0;

        div {
            margin: 0;
            min-width: 32px;
            min-height: 32px;
            line-height: 32px;
            transition: background 0.2s ease-in;
            max-width: 100%;
            max-height: 100%;
            border-radius: 2px;

            @include breakpoint(mobile) {
                width: 46px;
                height: 24px;
                width: auto;
            }
        }

        &.active {
            background: transparent;

            div {
                background: var(--formBgColor50);
                color: #fff !important;
                width: 70px;
                margin: 0 auto;
            }
        }

        &.in-range,
        &.hover-in-range {
            div {
                border-radius: 0;
                background-color: var(--formBgColor30);
            }

            + .active {
                div {
                    background-color: var(--formBgColor30);
                    box-shadow: inset 0 0 0 var(--formBorderWidth) var(--formBorderColorFilledAccent);
                    color: $token-colors-black !important;
                }
            }

            + .cell:hover {
                div {
                    background-color: var(--formBgColor30);
                    box-shadow: inset 0 0 0 var(--formBorderWidth) var(--formBorderColorFilledAccent);
                }
            }
        }

        &.not-current-month {
            color: var(--formTextColorDisabled) !important;
            pointer-events: none;
        }

        &.in-range {
            div {
                &:hover {
                    // background: var(--formBgColor);
                    // box-shadow: 0px 0px 0px var(--formBorderWidth) var(--formBorderColorHover) inset;
                    // border-radius: 2px;
                    // color: #000;
                }
            }
        }

        &:hover {
            div {
                background-color: var(--formBgColor30);
                box-shadow: inset 0 0 0 var(--formBorderWidth) var(--formBorderColorFilledAccent);
            }
        }
    }

    .mx-btn-current-year {
        color: $token-colors-link-default;

        &:hover {
            color: $token-colors-link-hover;
        }
    }

    .mx-btn-current-month {
        color: $token-colors-link-dark-default;

        &:hover {
            color: $token-colors-link-dark-hover;
        }
    }

    .mx-btn-current-month,
    .mx-btn-current-year {
        font-size: 22px;
        line-height: 1.2;
        transition: color .2s ease-in;

        &:hover {
            background: transparent;
        }
    }

    .mx-table {
        margin: 0;

        th {
            color: #000;
            font-weight: 400;
            // font-size: 13px;
            font-size: var(--fontSizeXs);
            text-align: center;
        }

        td {
            text-align: center;
        }
    }

    /* nav arrows */
    .mx-btn-icon-right,
    .mx-btn-icon-double-right,
    .mx-btn-icon-left,
    .mx-btn-icon-double-left {
        &:active,
        &:focus {
            background-color: transparent;

            .mx-icon-right,
            .mx-icon-double-right,
            .mx-icon-left,
            .mx-icon-double-left {
                &:before,
                &:after {
                    color: #B2B2B2;
                }
            }
        }

        &:hover {
            background: transparent;

            .mx-icon-right,
            .mx-icon-double-right,
            .mx-icon-left,
            .mx-icon-double-left {
                &:before,
                &:after {
                    color: var(--formBorderColorHover);
                }
            }
        }
    }
    /* /nav arrows */

    /* month, year */
    .mx-table-month {
        margin: 0;
        border-spacing: 50px 16px;
    }

    .mx-table-year {
        border-spacing: 70px 16px;
    }

    .mx-table-month,
    .mx-table-year {
        margin: 0;
        border-collapse: separate;
        width: calc(100% + 100px);
        margin-left: -50px;
        margin-top: -16px;

        .cell {
            font-size: 1em;

            div {
                font-size: 1.125em;
            }

            &.active {
                background: transparent;

                + .cell:hover {
                    div {
                        background: var(--formBgColor50);
                        color: #fff;
                        box-shadow: none;
                    }
                }
            }

            &.in-range,
            &.hover-in-range {
                div {
                    border-radius: 0;
                    background-color: var(--formBgColor);
                    color: var(--formTextColorDefault);
                }

                + .active {
                    div {
                        background-color: var(--formBgColor);
                        color: var(--formTextColorDefault);
                        box-shadow: inset 0 0 0 var(--formBorderWidth) var(--formBorderColorHover);
                    }
                }

                + .cell:hover {
                    div {
                        background: var(--formBgColor50);
                        color: #fff;
                        box-shadow: none;
                    }
                }
            }
        }

    }
    /* /month, year */

    &--range {
        .cell {
            &.active {
                background: transparent;

                div {
                    background: var(--formBgColor50);
                }

                + .cell:hover {
                    div {
                        background-color: var(--formBgColor);
                        color: var(--formTextColorDefault);
                        box-shadow: inset 0 0 0 var(--formBorderWidth) var(--formBorderColorHover);
                    }
                }
            }
        }
    }
}

</style>
