/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mappin': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M4.995 3.495A4.25 4.25 0 0112.25 6.5c0 1.98-1.109 3.807-2.316 5.195A15.518 15.518 0 018 13.556a15.518 15.518 0 01-1.934-1.86C4.859 10.306 3.75 8.48 3.75 6.5a4.25 4.25 0 011.245-3.005zm2.574 11.62L8 14.5l-.43.614a.75.75 0 00.86 0L8 14.5l.43.614h.001l.001-.001.005-.003.013-.01a6.186 6.186 0 00.22-.163 17.02 17.02 0 002.396-2.257c1.293-1.487 2.684-3.66 2.684-6.18a5.75 5.75 0 00-11.5 0c0 2.52 1.391 4.693 2.684 6.18a17.012 17.012 0 002.396 2.257 9.626 9.626 0 00.22.163l.013.01.005.003h.001v.001zM6.75 6.5a1.25 1.25 0 112.5 0 1.25 1.25 0 01-2.5 0zM8 3.75a2.75 2.75 0 100 5.5 2.75 2.75 0 000-5.5z" _fill="#0072D9"/>'
  }
})
