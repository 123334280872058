<template>
    <div class="event-list">
        <div class="event-list__header">
            <z-caption weight="700" tag="h2" size="4xl">Мероприятия</z-caption>
            <div class="event-list__filter">
                <z-button
                    kind="black"
                    tag="a"
                    href="/press-center/events/"
                >Все мероприятия</z-button>
                <template v-if="getEventTypeArray && getEventTypeArray.length">
                    <z-button
                        kind="black"
                        v-for="(itemA, indexA) in getEventTypeArray.slice(0, 5)"
                        :key="indexA"
                        v-html="itemA.value"
                        tag="a"
                        :href="getFilterLinks(itemA)"
                    ></z-button>
                    <z-button
                        kind="black"
                        v-if="getEventTypeArray.length > 5"
                        class="more-button"
                    >...</z-button>
                </template>
            </div>
        </div>
        <template v-if="data && data.length">
            <event-card
                :data="item"
                v-for="(item, index) in data.slice(0, 2)"
                :key="index"
                :href="item.detailPageUrl"
                tag="a"
            />
        </template>
        <template v-else>
            <notification hide-image>
                <template v-slot:description>
                    <z-caption class="u-collapse--top" size="m">В&nbsp;ближайшее время мероприятий не&nbsp;ожидается</z-caption>
                    <p>Следите за&nbsp;обновлениями <z-link href="https://vk.com/university.nornik" external target="_blank">в&nbsp;соцсетях</z-link> или смотрите <z-link href="/press-center/events/#tabs-past">наши прошедшие события</z-link>.</p>
                </template>
            </notification>
        </template>
    </div>
</template>

<script>
import EventCard from './components/EventCard.vue'

export default {
    name: 'events-block',
    components: {
        EventCard
    },
    props: {
        data: Array
    },
    methods: {
        getFilterLinks (item) {
            return `/press-center/events/?type[]=${item.id}`
        }
    },
    computed: {
        getEventTypeArray () {
            let result = {}

            this.data.forEach(item => {
                item.type.forEach(itemA => {
                    result[itemA.id] = itemA
                })
            })

            result = Object.values(result)

            return result
        }
    }
}
</script>

<style lang="scss">
.event-list {
    .event-card + .event-card {
        margin-top: $token-spacers-3-xs;
    }

    &__header {
        display: flex;
        margin-bottom: $token-spacers-s;

        @include breakpoint (v-tablet) {
            flex-direction: column;
        }

        .z-caption {
            display: inline-block;
            margin-bottom: 0;
            margin-right: $token-spacers-m;

            @include breakpoint (v-tablet) {
                margin-left: auto;
                margin-right: auto;
                margin-bottom: $token-spacers-xs;
            }
        }

        .event-list__filter {
            margin-left: auto;
            overflow: auto;
            display: flex;
            padding-bottom: 8px;

            &::-webkit-scrollbar {
                height: 5px;
                background: $token-colors-bg-10;
            }

            &::-webkit-scrollbar-thumb {
                background: $token-colors-primary;
            }

            @include breakpoint (v-tablet) {
                margin-left: 0;
                justify-content: normal;

                .z-button {
                    width: auto;
                }
            }

            > * {
                margin: 0 4px;
                flex-shrink: 0;
            }

            .more-button {
                cursor: inherit;

                &:hover {
                    border-color: inherit;
                    background-color: inherit;
                    color: inherit;
                }
            }
        }
    }
}
</style>
