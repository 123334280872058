<template>
    <z-card theme="white" size="l" class="events-detail-address">
        <template v-slot:content>
            <div
                class="events-detail-address__item"
                v-for="(contacts, index) in data"
                :key="index"
            >
                <z-icon name="circle-icon-6" width="40" height="40"></z-icon>
                <div class="events-detail-address__item--info">
                    <p class="u-semi-bold text-size-m" v-html="contacts.value"></p>
                    <z-link :href="contacts.description" external target="_blank" v-if="contacts.description">Проложить
                        маршрут
                    </z-link>
                </div>
            </div>
        </template>
    </z-card>
</template>

<script>
export default {
    name: 'events-detail-address',
    props: {
        data: Array
    }
}
</script>

<style lang="scss">
.events-detail-address {

    .z-card__content {
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: $token-spacers-2-xl $token-spacers-xl;

        @include breakpoint(laptop) {
            gap: $token-spacers-xl $token-spacers-m;
        }

        @include breakpoint(tablet) {
            grid-template-columns: repeat(2, 1fr);
        }

        @include breakpoint(v-tablet) {
            grid-template-columns: repeat(1, 1fr);
            gap: $token-spacers-m;
        }
    }

    &__item {
        display: flex;

        > .z-icon {
            margin-right: $token-spacers-2-xs;

            path {
                fill: $token-colors-white;
            }
        }

        &--info {
            display: flex;
            flex-direction: column;
        }
    }
}
</style>
