<template>
    <div class="z-hero">
        <div v-if="heroSrc" :style="`background-image: url(${heroSrc})`" class="z-hero__image"></div>
        <div class="z-hero__wrapper">
            <div class="container">
                <div class="z-hero__top" v-if="tag || date">
                    <span class="z-hero__date">{{ date }}</span>
                    <span class="z-hero__tag">{{ tag }}</span>
                </div>
                <z-caption :size="computedSize" weight="700" tag="h1" class="white-text u-bottom-margin--2xs">
                    <span v-html="currentTitle"></span>
                    <!-- <z-button
                        kind="secondary"
                        only-icon
                        size="s"
                        class="z-hero__copy"
                        @click="copyUrlToClipboard"
                    >
                        <z-icon name="internal" />
                    </z-button> -->
                </z-caption>
                <slot name="breadcrumbs" />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
    name: 'z-hero',
    props: {
        title: {
            type: String,
            default: ''
        },
        date: String,
        tag: String
    },
    data () {
        return {
            currentLink: window.location.href
        }
    },
    computed: {
        ...mapGetters([
            'heroTitle',
            'heroSrc'
        ]),
        currentTitle () {
            if (this.heroTitle) return this.heroTitle
            return this.title
        },
        computedSize () {
            return this.currentTitle && this.currentTitle.length > 100 ? '3xl' : '4xl'
        }
    },
    methods: {
        copyUrlToClipboard () {
            // почемуто не пашет
            // let currentUrl = window.location.href
            // navigator.clipboard.writeText(currentUrl)
            //     .then(function () {
            //         alert('Текущий URL скопирован в буфер обмена: ' + currentUrl)
            //     })
            //     .catch(function (err) {
            //         console.error('Ошибка при копировании: ', err)
            //     })
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
