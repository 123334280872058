/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'calendar/calendar-31-thin': {
    width: 80,
    height: 80,
    viewBox: '0 0 80 80',
    data: '<path pid="0" d="M65 12.5H15a2.5 2.5 0 00-2.5 2.5v50a2.5 2.5 0 002.5 2.5h50a2.5 2.5 0 002.5-2.5V15a2.5 2.5 0 00-2.5-2.5zM55 7.5v10M25 7.5v10M12.5 27.5h55" _stroke="#0072D9" stroke-width="3" stroke-linecap="round" stroke-linejoin="round"/><path pid="1" d="M28.75 39.999h8.75l-5 6.25a5 5 0 11-3.533 8.538M45 43.749l5-3.75v16.25" _stroke="#0072D9" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
