/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'mobile': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M5 2.25a.25.25 0 00-.25.25v.25h6.5V2.5a.25.25 0 00-.25-.25H5zm-1.75.25v11c0 .966.784 1.75 1.75 1.75h6a1.75 1.75 0 001.75-1.75v-11A1.75 1.75 0 0011 .75H5A1.75 1.75 0 003.25 2.5zm8 9.25v-7.5h-6.5v7.5h6.5zm-6.5 1.5h6.5v.25a.25.25 0 01-.25.25H5a.25.25 0 01-.25-.25v-.25z" _fill="#0072D9"/>'
  }
})
