<template>
    <form class="event-form" @submit.prevent="submitForm">
        <div class="event-form__main">
            <z-input
                class="event-form__input"
                type="text"
                placeholder="Имя Фамилия"
                name="name"
                v-model="params.name"
                ref="name"
                required
            ></z-input>
            <div class="event-form__wrapper">
                <z-input
                    class="event-form__input"
                    type="email"
                    placeholder="E-mail"
                    name="email"
                    v-model="params.email"
                    ref="email"
                    required
                ></z-input>
                <z-input
                    class="event-form__input"
                    type="tel"
                    placeholder="Телефон"
                    name="tel"
                    v-model="params.tel"
                    ref="tel"
                    mask="+7(###)###-##-##"
                    required
                ></z-input>
            </div>
        </div>
        <div class="event-form__select-wrapper" v-if="showEventAddress && this.eventAddressOptions.length">
            <z-select
                class="event-form__input"
                placeholder="Место проведения"
                :data="eventAddressOptions"
                name="event-address"
                v-model="params.eventAddress"
                ref="eventAddress"
                required
            ></z-select>
        </div>
        <z-checkbox
            class="u-top-margin--2xs"
            v-model="params.isChecked"
            name="policy"
            ref="policy"
            required
            size="s"
            :data="[{
                text: `В&#160;соответствии с&#160;Федеральным законом &#171;О&#160;персональных данных&#187; (&#8470;&#160;152-ФЗ) свободно, своей волей и&#160;в&#160;своем интересе я&#160;даю свое согласие ЧОУ ДПО Корпоративный университет &#171;Норильский никель&#187; на&#160;обработку моих персональных данных, указанных при заполнении формы подписки на&#160;новости.`,
                value: 'agree'
            }]"
        >
        </z-checkbox>
        <div class="event-form__bottom u-top-margin--l">
            <z-button size="l">Отправить</z-button>
            <z-link
                href="javacript:void(0)"
                icon="close-round"
                icon-position="right"
                @click="clearForm"
                v-if="showResetButton"
                :underlined="false"
                size="s"
            >Очистить форму</z-link>
        </div>
    </form>
</template>

<script>
import { sendEventForm } from '@/api/event-form.js'
import { showNotyfications } from '@/utils/notifications'

export default {
    name: 'event-registration-form',
    data () {
        return {
            params: {
                name: '',
                email: '',
                tel: '',
                eventAddress: '',
                isChecked: [],
                id: this.id
            }
        }
    },
    props: {
        id: String,
        showEventAddress: {
            type: Boolean,
            default: false
        },
        eventAddress: [Array, Object]
    },
    computed: {
        eventAddressOptions () {
            if (!Array.isArray(this.eventAddress)) return []
            return this.eventAddress.map(item => ({ id: item.value, text: item.value }))
        },
        showResetButton () {
            return Boolean(
                this.params.name !== '' ||
                this.params.email !== '' ||
                this.params.tel !== '' ||
                this.params.eventAddress !== '' ||
                this.params.isChecked.length !== 0
            )
        }
    },
    methods: {
        clearForm () {
            this.params = {
                name: '',
                email: '',
                tel: '',
                eventAddress: '',
                isChecked: [],
                id: this.id
            }
        },
        validateRequiredInputs () {
            const requiredFields = ['tel', 'policy', 'name', 'email']
            if (this.showEventAddress && this.eventAddressOptions.length) {
                requiredFields.push('eventAddress')
            }

            let isValid = true

            requiredFields.forEach((item) => {
                const field = this.$refs[item]
                if (field) {
                    field.validate()
                    if (field.error) isValid = false
                }
            })
            return isValid
        },
        async submitForm () {
            const isValidRequiredInputs = this.validateRequiredInputs()
            if (isValidRequiredInputs) {
                try {
                    const data = new FormData()
                    for (const key in this.params) {
                        data.append(key, this.params[key])
                    }

                    const formResponse = await sendEventForm(data)
                    if (formResponse.status === 'success') {
                        showNotyfications('Отправка успешна', { type: 'success' })
                        this.clearForm()
                    } else {
                        showNotyfications('Ошибка отправки', { type: 'error' })
                    }
                } catch (error) {
                    showNotyfications('Ошибка отправки', { type: 'error' })
                }
            } else {
                showNotyfications('Заполните все обязательные поля', { type: 'error' })
            }
            console.log('submit')
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
