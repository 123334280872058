/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'arrow_buttons': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<g clip-path="url(#clip0_404_13753)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M7.717 2.372a.748.748 0 01.817.166l4.93 4.93a.75.75 0 11-1.061 1.06l-3.651-3.65v8.052a.75.75 0 11-1.5 0V4.877L3.6 8.53a.75.75 0 01-1.06-1.06L7.47 2.535a.748.748 0 01.246-.164z" _fill="#0078CE"/></g><defs><clipPath id="clip0_404_13753"><path pid="1" _fill="#fff" transform="matrix(-1 0 0 1 16 0)" d="M0 0h16v16H0z"/></clipPath></defs>'
  }
})
