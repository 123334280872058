<template>
<div class="assist-bar" v-show="isOpened">
    <div class="container">
        <div class="assist-bar__container">
            <div class="assist-bar-fonts assist-bar__block">
                <z-caption tag="p" class="assist-bar__title" weight="600">
                    Шрифт
                </z-caption>
                <ul class="assist-bar__list">
                    <li class="assist-bar__item"
                        v-for="item in fontSize"
                        :key="item.id"
                    >
                        <z-button
                            size="m"
                            class="assist-bar-fonts__button"
                            :class="[
                                { 'is-active': item.selected },
                                `assist-bar-fonts__button--${item.id}`
                            ]"
                            rounded
                            kind="secondary"
                            :showHoverAnimation="false"
                            @click="changeFontSize(item.id)"
                        ></z-button>
                    </li>
                </ul>
            </div>
            <div class="assist-bar-img assist-bar__block">
                <z-caption tag="p" class="assist-bar__title" weight="600">
                    Изображения
                </z-caption>
                <ul class="assist-bar__list">
                    <li class="assist-bar__item">
                        <z-button
                            size="m"
                            class="assist-bar-img__button"
                            :class="{ 'is-active': showImg }"
                            kind="secondary"
                            @click="toggleImg(true)"
                            :showHoverAnimation="false"
                        >
                            <template v-slot:left>
                                <z-icon name="image-square" width="24" height="24" />
                            </template>
                            Вкл.
                        </z-button>
                    </li>
                    <li class="assist-bar__item">
                        <z-button
                            size="m"
                            class="assist-bar-img__button"
                            :class="{ 'is-active': !showImg }"
                            kind="secondary"
                            @click="toggleImg(false)"
                            :showHoverAnimation="false"
                        >
                            <template v-slot:left>
                                <z-icon name="no-image-square" width="24" height="24" />
                            </template>
                            Выкл.
                        </z-button>
                    </li>
                </ul>
            </div>
            <div class="assist-bar-color assist-bar__block">
                <z-caption tag="p" class="assist-bar__title" weight="600">
                    Цвет
                </z-caption>
                <ul class="assist-bar__list">
                    <li
                        class="assist-bar__item"
                        v-for="item in bgColor"
                        :key="item.id"
                    >
                        <z-button
                            class="assist-bar-color__button"
                            :class="[
                                { 'is-active': item.selected },
                                `assist-bar-color__button--${item.id}`,
                            ]"
                            :kind="item.id === 'contrast' ? 'black' : 'secondary'"
                            size="m"
                            @click="changeBg(item.id)"
                            :showHoverAnimation="false"
                        >
                            <span v-html="item.text"></span>
                        </z-button>
                    </li>
                </ul>
            </div>
            <button class="assist-bar__close" @click="close">
                <z-icon
                    name="burger-close"
                    height="40"
                    width="40"
                />
            </button>
        </div>
    </div>
</div>
</template>

<script>
const cookie = require('cookie_js')

export default {
    name: 'assist-bar',
    data () {
        return {
            isOpened: false,
            showImg: true, // true/false
            fontSize: [
                {
                    id: 'small',
                    selected: true
                },
                {
                    id: 'medium',
                    selected: false
                },
                {
                    id: 'big',
                    selected: false
                }
            ],
            bgColor: [
                {
                    id: 'default',
                    text: 'Стандартный',
                    selected: true
                },
                {
                    id: 'contrast',
                    text: 'Контрастный',
                    selected: false
                },
                {
                    id: 'yellow',
                    text: 'Жёлтый',
                    selected: false
                },
                {
                    id: 'blue',
                    text: 'Синий',
                    selected: false
                }
            ]
        }
    },
    methods: {
        saveCookie (options) {
            cookie.set(options, {
                domain: '.' + location.host,
                path: '/',
                expires: 365
            })
        },
        changeFontSize (size) {
            this.fontSize.forEach(item => {
                item.selected = item.id === size
            })

            this.saveCookie({ 'fontSize': size })

            // временный костыль, надо нормально удалять/тоглить класс
            document.body.classList.remove(`body--font-size-small`)
            document.body.classList.remove(`body--font-size-medium`)
            document.body.classList.remove(`body--font-size-big`)
            document.body.classList.add(`body--font-size-${size}`)
        },
        toggleImg (isShow) {
            this.showImg = isShow

            this.saveCookie({ 'showImg': isShow })

            !this.showImg
                ? document.body.classList.add(`body--hide-img`)
                : document.body.classList.remove(`body--hide-img`)
        },
        changeBg (color) {
            this.bgColor.forEach(item => {
                item.selected = item.id === color
            })

            this.saveCookie({ 'bgColor': color })

            // временный костыль, надо нормально удалять/тоглить класс
            document.body.classList.remove(`body--bg-default`)
            document.body.classList.remove(`body--bg-contrast`)
            document.body.classList.remove(`body--bg-yellow`)
            document.body.classList.remove(`body--bg-blue`)
            document.body.classList.add(`body--bg-${color}`)
        },
        close () {
            this.isOpened = false
            this.changeBg('default')
            this.changeFontSize('small')
            this.toggleImg(true)
        }
    },
    mounted () {
        const imgArr = Array.from(document.querySelectorAll('img:not(.z-image__img)'))
        imgArr.forEach(item => {
            const wrapper = document.createElement('div')
            wrapper.classList.add('img-wrapper')
            wrapper.setAttribute('alt', item.getAttribute('alt') || 'Изображение')
            const parent = item.parentElement
            wrapper.appendChild(item.cloneNode())
            parent.insertBefore(wrapper, item)
            item.remove()
        })

        this.$root.$bus.$on('open-assist-bar', () => { this.isOpened = true })
    },
    created () {
        const bgColor = cookie.get('bgColor') !== undefined ? cookie.get('bgColor') : 'default'
        const size = cookie.get('fontSize') !== undefined ? cookie.get('fontSize') : 'small'
        const showImg = cookie.get('showImg') !== undefined ? cookie.get('showImg') === 'true' : true

        this.changeBg(bgColor)
        this.changeFontSize(size)
        this.toggleImg(showImg)

        if (bgColor !== 'default' || size !== 'small' || showImg === false) {
            this.isOpened = true
        }
    }
}
</script>

<style lang="scss">
.mobile-nav-opened {
    .assist-bar {
        display: none;
    }
}

.assist-bar-fonts {
    &__button {
        width: 45px !important;
        height: 45px !important;
        position: relative;

        &:before {
            content: 'A';
            position: absolute;
            top: 50%;
            left: 50%;
            font-weight: 600;
            transform: translate(-50%, -57%);
            font-size: inherit;
            line-height: 1;
        }

        &--small {
            font-size: 16px !important;
        }

        &--medium {
            font-size: 24px !important;
        }

        &--big {
            font-size: 28px !important;
        }
    }
}

.assist-bar-color {
    button.assist-bar-color__button {

        > span {
            color: inherit !important;
        }

        &--contrast {
            background: $token-colors-white !important;

            &:hover {
                color: inherit;
            }
        }

        &--yellow {
            border-color: $token-colors-black-90 !important;
            background: $token-colors-black-90 !important;
            color: #FFE800 !important;
            transition: all $transition !important;

            &:hover {
                opacity: 0.85;
            }
        }

        &--blue {
            border-color: #8BD1FF !important;
            background: #8BD1FF !important;
            color: #1D2028 !important;
            transition: all $transition !important;

            &:hover {
                opacity: 0.85;
            }
        }
    }
}

.assist-bar {
    background: $token-colors-white;
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.15);
    z-index: 5;

    &__close {
        position: absolute !important;
        top: 24px;
        right: 0;
        background: none;
        border: none;
        padding: 0;
    }

    &__container {
        display: flex;
        flex-wrap: wrap;
        margin-top: -16px;
        padding-top: 24px;
        padding-bottom: 24px;
        position: relative;
    }

    &__title {
        margin-bottom: 12px !important;
        font-size: 20px;
    }

    &__list {
        display: flex;
        margin-top: -5px;
        margin-left: -5px;
        margin-right: -5px;
        margin-bottom: 0;
        flex-wrap: wrap;
    }

    &__item {
        margin-top: 5px;
        margin-left: 5px;
        margin-right: 5px;

        @include breakpoint(mobile) {
            margin-top: 10px;
        }
    }

    &__block {
        margin-right: 100px;
        margin-top: 16px;

        @include breakpoint (laptop) {
            margin-right: 80px;
        }

        @include breakpoint (tablet) {
            margin-right: 48px;
        }

        @include breakpoint (v-tablet) {
            margin-right: 24px;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    button {
        position: relative;
        font-size: 16px;

        &:after {
            content: '';
            position: absolute;
            top: -7px; //2px + 3px + 2px ширина бордеров + отступ
            left: -7px;
            border-radius: 80px;
            width: calc(100% + 14px);
            height: calc(100% + 14px);;
            border: 3px solid #3192D8;
            pointer-events: none;
            display: block;
            opacity: 0;
            transition: opacity 0.1s linear;
        }

        &.is-active {
            &:after {
                opacity: 1;
            }
        }
    }
}

.img-wrapper {
    flex-shrink: 0;
}
</style>
