<template>
    <div class="history">
        <section class="history__container">
            <div class="history-filter">
                <span class="history-filter__text">Сортировка:</span>
                <button class="history-filter__button" type="button" :disabled="isDisabled(filters.newToOld)" @click="filterHandler(filters.newToOld)">
                    От новых к&nbsp;старым
                </button>
                <button class="history-filter__button" type="button" :disabled="isDisabled(filters.oldToNew)" @click="filterHandler(filters.oldToNew)">
                    От старых к&nbsp;новым
                </button>
            </div>
            <template v-for="(value, index) in sortedData">
                <section class="history-card" :key="`card-${index}`">
                    <h2 class="history-card__title">{{ value.year }}</h2>
                    <div class="history-card__wrapper">
                        <template v-for="(monthItem, index) in value.items">
                            <span class="history-card__month" :key="`month-${index}`">{{ monthItem.month }}</span>
                            <template v-for="(event, index) in monthItem.events">
                                <div class="history-event" :id="event.anchorLink" :key="`event-${value.year}-${monthItem.month}-${index}-${Math.random()}`">
                                    <h3 class="history-event__title" v-html="event.title"></h3>
                                    <vue-raw :raw="event.content"/>
                                </div>
                            </template>
                        </template>
                    </div>
                </section>
            </template>
        </section>
    </div>
</template>

<script>
import {historyData} from './history-data'

export default {
    name: 'history',

    data () {
        return {
            data: historyData,
            filters: {
                newToOld: 'from new to old',
                oldToNew: 'from old to new'
            },
            currentFilter: 'from new to old'
        }
    },

    computed: {
        sortedData () {
            switch (this.currentFilter) {
            case (this.filters.newToOld): {
                return this.data.slice().sort((a, b) => Number(b.year) - Number(a.year))
            }
            case (this.filters.oldToNew): {
                return this.data.slice().sort((a, b) => Number(a.year) - Number(b.year))
            }
            }
        }
    },

    methods: {
        isDisabled (filter) {
            return filter === this.currentFilter
        },

        filterHandler (filter) {
            this.currentFilter = filter
        }
    }
}
</script>

<style lang="scss">
.history {
    margin-left: -80px;
    margin-right: -80px;

    @include breakpoint(tablet) {
        margin-left: -40px;
        margin-right: -40px;
    }

    @include breakpoint(v-tablet) {
        margin-left: -20px;
        margin-right: -20px;
    }

    @include breakpoint(mobile) {
        margin-left: -10px;
        margin-right: -10px;
    }

    &__container {
        margin-top: -$token-spacers-m;
        padding: 0 $token-spacers-2-xl 0 $token-spacers-2-xl;
        border-radius: 30px;

        @include breakpoint(tablet) {
            padding: $token-spacers-2-xs $token-spacers-2-xl 0 $token-spacers-2-xl;
        }

        @include breakpoint(tablet) {
            padding: $token-spacers-2-xs $token-spacers-l 0 $token-spacers-l;
        }

        @include breakpoint(mobile) {
            padding: $token-spacers-2-xs $token-spacers-3-xs 0 $token-spacers-3-xs;
        }

        .history-filter {
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-bottom: 40px;

            &__text {
                font-weight: 600;
                font-size: var(--fontSizeL);
                line-height: 1.4;
                color: $token-colors-black-80;
                margin-right: 30px;

                @include breakpoint(mobile) {
                    margin-right: $token-spacers-3-xs;
                    // font-size: 16px;
                }
            }

            &__button {
                font-weight: 400;
                // font-size: 18px;
                font-size: var(--fontSizeL);
                line-height: 140%;
                color: $token-colors-primary;
                background-color: transparent;
                padding: 0;
                border: none;

                @include breakpoint(mobile) {
                    // font-size: 16px;
                }

                &:not(:last-child) {
                    margin-right: 30px;

                    @include breakpoint(mobile) {
                        margin-right: $token-spacers-2-xs;
                    }
                }

                &:hover {
                    color: $token-colors-link-hover;
                }

                &:disabled {
                    color: $token-colors-black-40;
                }
            }
        }

        .history-card {
            display: flex;
            padding: $token-spacers-s 68px $token-spacers-s $token-spacers-xs;
            background-color: $token-colors-white;
            border-radius: 20px;

            @include breakpoint(v-tablet) {
                padding-right: $token-spacers-xs;
                flex-direction: column;
            }

            &__wrapper {
                padding-left: $token-spacers-l;
                border-left: 2px solid $token-colors-black-30;

                @include breakpoint(v-tablet) {
                    padding-left: $token-spacers-s;
                }
            }

            &:first-of-type {
                .history-event {
                    &:before {
                        border: 14px solid $token-colors-white;
                    }
                }
            }

            &:not(:last-child) {
                margin-bottom: 8px;
            }

            &__title {
                font-family: $token-typo-3-xl-desktop-family;
                font-weight: 700;
                font-size: $token-typo-5-xl-desktop-size;
                line-height: 120%;
                margin-left: $token-spacers-l;
                margin-right: $token-spacers-l;

                @include breakpoint(v-tablet) {
                    margin-left: $token-spacers-xs;
                    margin-right: $token-spacers-xs;
                }

                @include breakpoint(mobile) {
                    font-size: $token-typo-5-xl-mobile-size;
                    margin-left: $token-spacers-2-xs;
                    margin-right: $token-spacers-2-xs;
                }
            }

            &__month {
                font-weight: 600;
                // font-size: 24px;
                font-size: var(--fontSize2xl);
                line-height: 120%;
                color: $token-colors-black-30;
                margin-bottom: 4px;

                @include breakpoint(v-tablet) {
                    font-size: $token-typo-xl-mobile-size;
                }
            }
        }
    }

    .history-event {
        position: relative;

        &:not(:last-child) {
            margin-bottom: $token-spacers-s;
        }

        &__title {
            font-weight: 700;
            // font-size: 24px;
            font-size: var(--fontSize2xl);
            line-height: 1.4;
            color: $token-colors-black;
            margin-bottom: 16px;

            @include breakpoint(mobile) {
                font-weight: $token-typo-xl-mobile-weight;
                font-size: $token-typo-xl-mobile-size;
            }
        }

        p {
            font-weight: 400;
            // font-size: 20px;
            font-size: var(--fontSizeXl);
            line-height: 130%;
            color: $token-colors-black-70;

            @include breakpoint(v-tablet) {
                // font-size: 16px;
            }

            &:not(:last-child) {
            margin-bottom: $token-spacers-s;
        }
        }

        img {
            width: 100%;
            height: 57%; //по соотношению сторон
            border-radius: 10px;
            background-color: $token-colors-black-10;
            object-fit: cover;
        }

        &:before {
            content: '';
            display: block;
            width: 44px;
            height: 44px;
            background-color: $token-colors-primary;
            border: 18px solid $token-colors-white;
            border-radius: 50%;
            position: absolute;
            top: 0;
            left: -79px;

            @include breakpoint(v-tablet) {
                left: -54px;
            }
        }
    }
}

</style>
