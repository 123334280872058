<template>
    <div class="reviews">
        <div class="reviews__head">
            <div class="reviews__count">Отзывов: {{this.nav.count}}</div>
            <div class="reviews__write" v-if="!successFormSend">
                <z-button kind="bordered" @click="scrollToForm">
                    Оставить отзыв
                </z-button>
            </div>
        </div>
        <div :class="['reviews__list', { 'is-loading' : loading }]">
            <div
                class="reviews-card"
                v-for="(card, index) in this.cards" :key="index"
            >
                <div class="reviews-card__training-name" v-html="card.trainingGround.value"></div>
                <div v-if="card.courseName.value" class="reviews-card__course-name" v-html="card.courseName.value"></div>
                <div class="reviews-card__description" v-html="card.text.value"></div>
                <div class="reviews-card__date">
                    {{card.activeFrom | momentFilter('DD MMMM YYYY')}}
                </div>
                <div v-if="card.name" class="reviews-card__author">
                    <z-icon width="20" height="16" name="quotes" />
                    <span v-html="card.name"></span>
                </div>
            </div>
        </div>
        <z-preloader v-if="loading" />
        <z-pagination
            class="reviews__nav"
            show-next
            show-prev
            show-nav
            :show-more="{
                text: 'Загрузить ещё',
                size: 'l',
                marginBottom: 'm'
            }"
            @change-page="onPageChange"
            :data="{
                currentPage: this.nav.current,
                totalItems: this.nav.count,
                totalPages: this.nav.total,
                pageRange: 1,
            }"
        >
        </z-pagination>
        <form class="reviews-form u-top-margin--l" id="form" @submit.prevent="submitForm" v-if="!successFormSend">
            <z-caption tag="h2" size="2xl">Оставить отзыв</z-caption>
            <z-input
                placeholder="Ваше имя"
                name="name"
                v-model="formData.fio"
                ref="name"
            ></z-input>
            <div class="reviews-form__wrapper">
                <z-input
                    placeholder="Название курса"
                    name="input"
                    ref="course"
                    v-model="formData['course-name']"
                ></z-input>
                <z-select
                    ref="training-ground"
                    v-model="formData['training-ground']"
                    required
                    placeholder="Учебная площадка"
                    :data="this.trainingGround"
                ></z-select>
            </div>
            <z-textarea
                placeholder="Текст отзыва"
                required
                name="text"
                ref="text"
                v-model="formData.text"
            ></z-textarea>
            <z-checkbox
                class="u-top-margin--s"
                v-model="isChecked"
                name="policy"
                ref="policy"
                required
                size="s"
                :data="[
                    {
                        text: `Даю согласие на&nbsp;обработку <z-link href='/terms/'>персональных данных</z-link>.`,
                        value: 'agree'
                    }
                ]"
            >
            </z-checkbox>
            <div class="reviews-form__footer">
                <z-button>Отправить</z-button>
                <z-link
                    href="javascript: void(0)"
                    icon-position="right"
                    icon="close-round"
                    size="xl"
                    @click="clearForm"
                    v-if="showResetButton"
                    :underlined="false"
                >Очистить форму</z-link>
            </div>
        </form>
        <notification class="u-top-margin--l" v-else>
            <template v-slot:image>
                <z-image src="/images/not-found/form-success.svg" width="316" height="266"></z-image>
            </template>
            <template v-slot:description>
                <z-caption size="m">Спасибо за отзыв!</z-caption>
                <p class="text-size-xl u-regular">Ваше сообщение отправлено сотруднику университета, в&nbsp;дальнейшем оно может быть опубликовано на&nbsp;сайте.</p>
            </template>
        </notification>
    </div>
</template>

<script>
import { getReviews } from '@/api/reviews'
import { debounce } from 'throttle-debounce'
import { sendReviewsForm } from '@/api/reviews-form.js'
import { showNotyfications } from '@/utils/notifications'
import moment from 'moment'

export default {
    name: 'reviews',
    data () {
        return {
            loading: false,
            page: 1,
            nav: this.$root.app.components.feedback.nav,
            cards: this.$root.app.components.feedback.items,
            trainingGround: this.$root.app.components['feedback-form']['TRAINING-GROUND'],
            formData: {
                fio: '',
                'course-name': '',
                'training-ground': '',
                text: '',
                date: ''
            },
            isChecked: [],
            successFormSend: false
        }
    },
    computed: {
        showResetButton () {
            return Boolean(
                this.formData.fio ||
                this.formData['course-name'] ||
                this.formData['training-ground'] ||
                this.formData.text ||
                this.isChecked.length
            )
        }
    },

    methods: {
        clearForm () {
            this.formData.fio = ''
            this.formData['course-name'] = ''
            this.formData['training-ground'] = ''
            this.formData.text = ''
            this.isChecked = []

            this.clearSelectsOptions()
        },
        clearSelectsOptions () {
            if (this.$refs['training-ground']) this.$refs['training-ground'].removeSelected()
        },
        validateRequiredInputs () {
            const requiredFields = ['training-ground', 'policy', 'text']
            let isValid = true

            requiredFields.forEach((item) => {
                const field = this.$refs[item]
                if (field) {
                    field.validate()
                    if (field.error) isValid = false
                }
            })
            return isValid
        },
        async submitForm () {
            const isValidRequiredInputs = this.validateRequiredInputs()
            if (isValidRequiredInputs) {
                try {
                    this.formData.date = moment().unix()

                    const data = new FormData()

                    for (const key in this.formData) {
                        data.append(key, this.formData[key])
                    }

                    const formResponse = await sendReviewsForm(data)
                    if (formResponse.status === 'success') {
                        showNotyfications('Отправка успешна', { type: 'success' })
                        this.clearForm()
                        this.successFormSend = true
                    } else {
                        showNotyfications('Ошибка отправки', { type: 'error' })
                    }
                } catch (error) {
                    showNotyfications('Ошибка отправки', { type: 'error' })
                }
            } else {
                showNotyfications('Заполните все обязательные поля', { type: 'error' })
            }
        },
        updateNav: function (res) {
            if (res.hasOwnProperty('nav')) {
                this.nav = res.nav
            }
        },
        updateCards: function (res, type) {
            if (res.hasOwnProperty('items')) {
                if (type === 'change-page') {
                    this.cards = res.items
                } else {
                    this.cards = this.cards.concat(res.items)
                }
            }
        },
        onPageChange: function ({page, type}) {
            this.page = page
            this.send(page, type)
            this.loading = true
        },
        send: debounce(500, function (page, type) {
            this.loading = true

            getReviews({page}).then(res => {
                this.updateNav(res)
                this.updateCards(res, type)
                this.loading = false
            })
        }),
        scrollToForm () {
            document.getElementById('form').scrollIntoView({block: 'start', behavior: 'smooth'})
        }
    }
}
</script>

<style lang="scss">
.reviews {
    &__head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        @include margin-level(bottom, XS);
    }

    &__count {
        // @include typo-level(L);
        font-size: var(--fontSizeL);
        font-weight: 600;
    }

    &__list {
        margin-bottom: 40px;
        display: flex;
        flex-wrap: wrap;
        gap: 8px;

        &.is-loading {
            opacity: 0.3;
        }
    }

    &-card {
        flex: 1 1 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 40px;
        max-width: calc(50% - 4px);
        background: #FFFFFF;
        border-radius: 20px;
        @include breakpoint(v-tablet) {
            max-width: 100%;
        }

        &__training-name {
            // font-size: 20px;
            font-size: var(--fontSizeXl);
            font-weight: 600;
            color: $token-colors-black-90;
            @include margin-level(bottom, 3XS);
        }

        &__course-name {
            // font-size: 16px;
            font-size: var(--fontSizeM);
            font-weight: 600;
            color: $token-colors-black-80;
            @include margin-level(bottom, 3XS);
        }

        &__description {
            // font-size: 16px;
            font-size: var(--fontSizeM);
            line-height: 1.30;
            font-weight: 400;
            color: $token-colors-black-80;
            @include margin-level(bottom, 2XS);
        }

        &__date {
            line-height: 1.30;
            font-weight: 400;
            // font-size: 13px;
            font-size: var(--fontSizeXs);
            color: $token-colors-black-40;
            @include margin-level(bottom, S);
        }

        &__author {
            font-weight: 600;
            // font-size: 18px;
            font-size: var(--fontSizeL);
            color: $token-colors-black-90;
            display: flex;
            align-items: flex-start;

            .z-icon {
                display: inline-block;
                margin-top: 4px;
                @include margin-level(right, 3XS);
            }
        }
    }

    &__nav {
        margin: 0 auto;
        width: fit-content;
    }

    .notification {
        padding: $token-spacers-xl;
        border-radius: $borderRadius;
        background: $token-colors-white;
    }
}

.reviews-form {
    padding: $token-spacers-xl $token-spacers-m;
    background: $token-colors-white;
    border-radius: $borderRadius;

    @include breakpoint (mobile) {
        border-radius: $borderRadiusMobile;
        padding: $token-spacers-xs;
    }

    &__wrapper {
        display: flex;
        margin-top: $token-spacers-xs;
        margin-left: -4px;
        margin-right: -4px;

        @include breakpoint (v-tablet) {
            flex-direction: column;
        }

        > * {
            width: 50% !important;
            padding-left: 4px;
            padding-right: 4px;

            @include breakpoint (v-tablet) {
                width: 100% !important;
                margin-bottom: $token-spacers-xs;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &__footer {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: $token-spacers-l;

        .z-link {
            font-weight: 600;

            &__icon {
                bottom: .1em !important;
            }
        }

        @media (max-width: 425px) {
            flex-direction: column;

            .z-link {
                margin-top: $token-spacers-2-xs;
            }
        }
    }

    .z-textarea {
        margin-top: $token-spacers-xs;
    }
}
</style>
