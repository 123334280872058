const qs = require('query-string')

function queryString (params) {
    let string = ''
    delete params.page
    let keys = Object.keys(params)
    let hash = window.location.hash
    let url = window.location.pathname

    if (keys.length > 0) {
        url += `?`
        string = qs.stringify(params, { arrayFormat: 'bracket' })
    }

    url += `${string}${hash}`

    if (history.pushState) {
        try {
            history.pushState({}, '', url)
        } catch (err) {
            console.error('Error in queryString!')
        }
    } else {
        document.location.href = url
    }
}

export { queryString }
