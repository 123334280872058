/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'buttons/share': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<g clip-path="url(#clip0_1188_14275)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M11.5 14.5a1 1 0 100-2 1 1 0 000 2zm0 1.5a2.5 2.5 0 10-1.568-4.447L6.805 9.469a2.492 2.492 0 00.02-1.89l3.31-1.985a2.5 2.5 0 10-.96-1.174l-3.31 1.986a2.5 2.5 0 10-.04 4.214L9.1 12.8A2.501 2.501 0 0011.5 16zm1-12.5a1 1 0 11-2 0 1 1 0 012 0zm-7 5a1 1 0 11-2 0 1 1 0 012 0z" _fill="#0072D9"/></g><defs><clipPath id="clip0_1188_14275"><path pid="1" _fill="#fff" transform="matrix(0 -1 -1 0 16 16)" d="M0 0h16v16H0z"/></clipPath></defs>'
  }
})
