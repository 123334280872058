/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'file/mp4': {
    width: 48,
    height: 48,
    viewBox: '0 0 48 48',
    data: '<path pid="0" d="M8 11a8 8 0 018-8h13l12 12v21a8 8 0 01-8 8H16a8 8 0 01-8-8V11z" _fill="#C6C9D6"/><path pid="1" d="M19.89 33v-5.592l-2.183 4.164h-.924l-2.184-4.164V33h-1.656v-8.52h1.776l2.52 4.848 2.544-4.848h1.764V33H19.89zm3.436 0v-8.52h3.611c.392 0 .752.08 1.08.24.329.16.613.376.852.648.248.272.44.576.576.912.136.336.204.68.204 1.032 0 .48-.111.936-.335 1.368a2.875 2.875 0 01-.924 1.056c-.393.272-.857.408-1.393.408h-2.015V33h-1.656zm1.655-4.308h1.92c.2 0 .38-.056.54-.168.16-.112.289-.272.385-.48.096-.216.143-.46.143-.732 0-.296-.056-.548-.168-.756a1.093 1.093 0 00-.42-.468 1.029 1.029 0 00-.552-.156h-1.848v2.76zM33.815 33v-1.932h-3.588v-1.44l4.092-5.148h1.104v5.136h1.056v1.452h-1.056V33h-1.608zm-1.968-3.384h2.148v-2.712l-2.148 2.712z" _fill="#4A5D7F"/><path pid="2" d="M29 3l12 12h-8a4 4 0 01-4-4V3z" _fill="#EFF0F3"/>'
  }
})
