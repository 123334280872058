/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'buttons/arrow_left': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<g clip-path="url(#clip0_1188_14229)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M2.376 7.717a.748.748 0 00.166.817l4.93 4.93a.75.75 0 101.06-1.061L4.88 8.752h8.052a.75.75 0 000-1.5H4.881L8.53 3.6a.75.75 0 10-1.06-1.06L2.54 7.47a.748.748 0 00-.164.246z" _fill="#0078CE"/></g><defs><clipPath id="clip0_1188_14229"><path pid="1" _fill="#fff" transform="matrix(0 -1 -1 0 16 16)" d="M0 0h16v16H0z"/></clipPath></defs>'
  }
})
