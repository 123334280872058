/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'close': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M17.23 8.457a1 1 0 00-1.414-1.414l-3.68 3.68-3.679-3.68a1 1 0 00-1.414 1.414l3.68 3.68-3.68 3.679a1 1 0 001.414 1.414l3.68-3.68 3.679 3.68a1 1 0 001.414-1.414l-3.68-3.68 3.68-3.679z" _fill="#91A1BD"/>'
  }
})
