/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tag-color/cloud': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<mask id="svgicon_tag-color_cloud_a" fill="#fff"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M18.273 9.386h-.168C17.45 6.39 14.966 4.16 12 4.16c-2.966 0-5.451 2.23-6.105 5.227h-.168a5.228 5.228 0 000 10.455h12.546a5.227 5.227 0 100-10.455z"/></mask><path pid="1" d="M18.105 9.386l-2.931.64.514 2.36h2.417v-3zm-12.21 0v3h2.416l.515-2.36-2.93-.64zm-.702.027l.303 2.985-.303-2.985zm12.912 2.973h.168v-6h-.168v6zM12 7.16c1.37 0 2.78 1.058 3.174 2.866l5.862-1.278C20.123 4.561 16.562 1.16 12 1.16v6zm-3.174 2.866C9.22 8.217 10.63 7.16 12 7.16v-6c-4.562 0-8.123 3.402-9.036 7.588l5.862 1.278zm-3.099 2.361h.168v-6h-.168v6zm-.231.012c.075-.008.152-.012.231-.012v-6a8.29 8.29 0 00-.837.043l.606 5.969zM3.5 14.614c0-1.152.876-2.102 1.996-2.216l-.606-5.97a8.228 8.228 0 00-7.39 8.186h6zm2.227 2.227A2.227 2.227 0 013.5 14.614h-6a8.227 8.227 0 008.227 8.227v-6zm12.546 0H5.727v6h12.546v-6zm2.227-2.227c0 1.23-.997 2.227-2.227 2.227v6a8.227 8.227 0 008.227-8.227h-6zm-2.227-2.228c1.23 0 2.227.998 2.227 2.228h6a8.227 8.227 0 00-8.227-8.228v6z" _fill="#1971CF" mask="url(#svgicon_tag-color_cloud_a)"/>'
  }
})
