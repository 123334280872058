/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'caretup': {
    width: 24,
    height: 24,
    viewBox: '0 0 24 24',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M12.707 6.793a1 1 0 00-1.414 0l-7.5 7.5a1 1 0 101.414 1.414L12 8.914l6.793 6.793a1 1 0 001.414-1.414l-7.5-7.5z" _fill="#0C182A"/>'
  }
})
