/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'tag_add_pin': {
    width: 51,
    height: 51,
    viewBox: '0 0 51 51',
    data: '<circle pid="0" cx="25.5" cy="25.5" r="24.136" _fill="#F9B533"/><rect pid="1" width="51" height="51" rx="25.356" _fill="#F9B533"/><path pid="2" fill-rule="evenodd" clip-rule="evenodd" d="M29.635 15.457c.137-.091.247-.165.343-.227.082.08.175.173.292.29l5.21 5.21c.117.117.21.21.29.292-.062.096-.136.206-.227.343l-3.354 5.03-.082.125c-.802 1.201-1.319 1.977-1.678 2.843-.359.867-.541 1.78-.824 3.197l-.03.146-.433 2.164v.006l-.005-.003c-2.664-1.844-3.92-2.715-5.08-3.684a33.694 33.694 0 01-4.246-4.247c-.97-1.16-1.84-2.415-3.684-5.079l-.003-.004.006-.002 2.164-.432.146-.03c1.416-.283 2.33-.465 3.197-.824.866-.36 1.642-.876 2.843-1.678l.125-.082 5.03-3.354zm-.654 20.134zm.766-.315zm-14.026-14.03zm-.312.773zm14.88-9.94c-.569-.056-1.037.112-1.385.287-.293.147-.609.358-.893.548l-.05.033-5.03 3.353c-1.367.912-1.888 1.251-2.449 1.483-.56.233-1.169.361-2.78.683l-2.164.433-.043.009c-.298.06-.6.12-.85.19-.26.073-.639.201-.982.488a2.514 2.514 0 00-.888 2.198c.048.445.23.8.367 1.034.131.224.306.477.48.727l.024.036.05.071c1.783 2.575 2.733 3.948 3.8 5.225.378.452.767.895 1.166 1.328l-6.152 6.152a1.508 1.508 0 102.133 2.133l6.152-6.152c.433.4.876.788 1.328 1.166 1.277 1.067 2.65 2.018 5.225 3.8l.071.05.036.025c.25.172.503.348.727.479.234.137.589.32 1.034.367.835.09 1.66-.243 2.198-.888.287-.343.415-.721.488-.983.07-.249.13-.55.19-.849l.009-.043.433-2.164c.322-1.611.45-2.22.683-2.78.232-.56.571-1.082 1.483-2.449l3.353-5.03.033-.05c.19-.284.4-.6.548-.893.175-.348.343-.816.287-1.386-.057-.57-.313-.996-.553-1.302-.202-.26-.47-.527-.712-.769l-.042-.042-5.211-5.21-.042-.043a8.992 8.992 0 00-.769-.712c-.306-.24-.732-.496-1.302-.553z" _fill="#FFEDCB"/>'
  }
})
