<template>
    <div class="z-contact-card" :class="{ 'z-contact-card--horizontal': horizontal }">
        <div class="z-contact-card__head" v-if="title || subtitle">
            <picture class="z-contact-card__pic" v-if="photo">
                <img :src="photo" :alt="title" />
            </picture>
            <div class="z-contact-card__text">
                <div class="z-contact-card__title" v-if="title || $slots['title']">
                    <a
                        v-if="link"
                        :href="link"
                        :title="title ? title : ''"
                    >
                        {{ title }}
                    </a>
                    <span v-else v-html="title"></span>
                    <slot name="title"></slot>
                </div>
                <div class="z-contact-card__subtitle" v-if="subtitle || $slots['subtitle']">
                    <span v-html="subtitle" v-if="subtitle"></span>
                    <slot name="subtitle"></slot>
                </div>
            </div>
        </div>
        <div class="z-contact-card__communications-list">
            <template v-if="address && (address.value || address.description)">
                <div class="communication-item communication-item--address">
                    <div class="communication-item__title">
                        <span>{{ text.address }}</span>
                    </div>
                    <div class="communication-item__link">
                        <address v-html="address.value || address.description" />
                    </div>
                </div>
            </template>
            <template v-if="phone && phone.length">
                <div class="communication-item">
                    <div class="communication-item__title">
                        <span>{{ text.phone }}</span>
                    </div>
                    <z-link
                        v-for="(item, index) in phone"
                        class="communication-item__link"
                        theme="dark"
                        :href="`tel:${item.value}`"
                        :key="`phone-${index}`"
                    >
                        {{ item.description || item.value }}
                    </z-link>
                </div>
            </template>
            <template v-if="fax && fax.length">
                <div class="communication-item">
                    <div class="communication-item__title">
                        <span>{{ text.fax }}</span>
                    </div>
                    <z-link
                        v-for="(item, index) in fax"
                        class="communication-item__link"
                        theme="dark"
                        :href="`fax:${item.value}`"
                        :key="`fax-${index}`"
                    >
                        {{ item.description || item.value }}
                    </z-link>
                </div>
            </template>
            <template v-if="schedule && schedule.length">
                <div class="communication-item">
                    <div class="communication-item__title">
                        <span>{{ text.schedule }}</span>
                    </div>
                    <div
                        class="communication-item__link"
                        v-for="(item, index) in schedule"
                        :key="`schedule-${index}`"
                    >
                        {{ item.description || item.value }}
                    </div>
                </div>
            </template>
            <template v-if="email && email.length">
                <div class="communication-item">
                    <div class="communication-item__title">
                        <span>{{ text.email }}</span>
                    </div>
                    <z-link
                        v-for="(item, index) in email"
                        class="communication-item__link"
                        :href="`mailto:${item.value}`"
                        :key="`email-${index}`"
                    >
                        {{ item.description || item.value }}
                    </z-link>
                </div>
            </template>
            <template v-if="site && site.length">
                <div class="communication-item">
                    <div class="communication-item__title">
                        <span>{{ text.site }}</span>
                    </div>
                    <z-link
                        v-for="(item, index) in site"
                        class="communication-item__link"
                        :href="`${item.value}`"
                        :key="`site-${index}`"
                    >
                        {{ item.description || item.value }}
                    </z-link>
                </div>
            </template>
        </div>
        <div class="z-contact-card__content">
            <slot></slot>
        </div>
    </div>
</template>

<script>
import {localize} from '@/utils/i18n'

export default {
    name: 'z-contact-card',
    props: {
        photo: String,
        title: String,
        subtitle: String,
        link: String,
        phone: {
            type: Array
        },
        fax: {
            type: Array
        },
        email: {
            type: Array
        },
        site: {
            type: Array
        },
        address: {
            type: Object
        },
        schedule: {
            type: Array
        },
        horizontal: Boolean
    },
    data () {
        return {
            text: {
                phone: localize({
                    ru: 'Телефон:',
                    en: 'Telephone:'
                }),
                fax: localize({
                    ru: 'Факс:',
                    en: 'Fax:'
                }),
                email: localize({
                    ru: 'E-mail:',
                    en: 'E-mail:'
                }),
                site: localize({
                    ru: 'Сайт:',
                    en: 'Website:'
                }),
                address: localize({
                    ru: 'Адрес:',
                    en: 'Address:'
                }),
                schedule: localize({
                    ru: 'Режим работы:',
                    en: 'Schedule:'
                })
            }
        }
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
