<template>
    <div class="u-inline">
        <div :class="classObject" ref="tooltip">
            <template v-if="!$slots.trigger">
                <slot />
                <z-icon
                    v-show="!iconHidden"
                    width="12"
                    height="12"
                    name="tooltip"
                    dir="down"
                    class="z-tooltip__icon"
                />
            </template>
            <slot name="trigger" v-else></slot>
        </div>
        <div class="tooltip-content" ref="content" v-if="!!$slots.content || content">
            <slot name="content"></slot>
            <span v-html="content"></span>
        </div>
    </div>
</template>

<script>
import tippy from 'tippy.js'

export default {
    name: 'z-tooltip',
    props: {
        arrow: {
            type: Boolean,
            default: true
        },
        placement: {
            type: String,
            default: 'right'
        },
        content: {
            type: String
        },
        trigger: {
            type: String,
            default: 'mouseenter'
        },
        interactive: {
            type: Boolean,
            default: false
        },
        iconHidden: Boolean,
        theme: {
            type: String,
            default: 'default'
        },
        appendTo: {
            type: [String, HTMLElement],
            default: () => document.body
        }
    },
    computed: {
        classObject () {
            return [
                'z-tooltip',
                { 'z-tooltip--click': this.trigger === 'click' }
            ]
        }
    },
    mounted () {
        this.$nextTick(() => {
            tippy(this.$refs.tooltip, {
                content: this.$refs.content,
                placement: this.placement,
                arrow: this.arrow,
                trigger: this.trigger,
                allowHTML: true,
                interactive: this.interactive,
                theme: this.theme,
                appendTo: this.appendTo
            })
        })
    }
}
</script>

<style lang="scss" src="./index.scss"></style>
