/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'round/remind': {
    width: 55,
    height: 55,
    viewBox: '0 0 55 55',
    data: '<circle pid="0" cx="27.5" cy="27.5" r="27.5" _fill="#129BCB"/><path pid="1" d="M45 10H9v36h36V10z" _fill="#129BCB"/><path pid="2" d="M38.55 15.294h-23.1c-.639 0-1.156.517-1.156 1.155v23.102c0 .638.517 1.155 1.155 1.155h23.102c.638 0 1.155-.517 1.155-1.155V16.449c0-.638-.517-1.155-1.155-1.155z" _fill="#129BCB" _stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/><path pid="3" d="M34.412 13.177v4.235M19.588 13.177v4.235M14.294 22.706h25.412M27 29.059v6.353M30.176 32.235h-6.353" _stroke="#fff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>'
  }
})
