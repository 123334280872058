var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"section-stats container"},[_c('div',{staticClass:"section-stats__swiper",attrs:{"data-scroll-scale":""}},[_c('swiper',{attrs:{"options":_vm.swiper_options}},_vm._l((_vm.slider_data),function(ref){
var id = ref.id;
var imageSrc = ref.imageSrc;
var num = ref.num;
var caption = ref.caption;
var caption_2 = ref.caption_2;
var textColor = ref.textColor;
return _c('swiper-slide',{key:id,style:(_vm.getSwiperSlideStyle(imageSrc))},[_c('div',{class:_vm.getSlideContentClass(textColor)},[_c('z-caption',{attrs:{"tag":"p","size":"m","weight":"600"}},[_vm._v("Цифры и факты")]),_vm._v(" "),_c('div',{class:_vm.getSlideNumClass(num)},[_vm._v("\n                        "+_vm._s(_vm.beautifyNum(num))+"\n                    ")]),_vm._v(" "),_c('z-caption',{attrs:{"tag":"p","size":"3xl","weight":"600"},domProps:{"innerHTML":_vm._s(caption)}}),_vm._v(" "),_c('z-caption',{attrs:{"tag":"p","size":"xl","weight":"400"},domProps:{"innerHTML":_vm._s(caption_2)}})],1)])}),1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }