/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'search': {
    width: 40,
    height: 40,
    viewBox: '0 0 40 40',
    data: '<path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M13 19a6 6 0 1112 0 6 6 0 01-12 0zm6-8a8 8 0 105.026 14.225c.04.177.13.344.267.482l3 3a1 1 0 001.414-1.414l-3-3a.995.995 0 00-.482-.267A8 8 0 0019 11z" _fill="#1F2A40"/>'
  }
})
