<template>
    <section class="section-events">
        <div class="section-events__header container">
            <tag data-scroll-translate>Мероприятия</tag>
            <div class="section-events__header-content">
                <z-caption
                    tag="p"
                    size="4xl"
                    weight="600"
                    data-scroll-translate
                    >Предстоящие образовательные и&nbsp;просветительские лекции, ворк-шопы
                    и&nbsp;дискуссии</z-caption
                >
                <z-button tag="a" size="l" href="/press-center/events/" data-scroll-translate>все мероприятия</z-button>
            </div>
        </div>
        <swiper
            v-if="swiper_data && swiper_data.length !== 0"
            class="section-events__swiper container"
            :options="swiper_options"
            :pagination="false"
            data-scroll-reveal
        >
            <swiper-slide
                v-for="event in swiper_data"
                :key="event.id">
                <event-card
                    :data="event"
                    tag="a"
                    cardType="event" />
            </swiper-slide>
        </swiper>
        <div
            v-else
            class="container"
            data-scroll-reveal
            >
            <event-card cardType="no-event" />
        </div>
    </section>
</template>

<script>
import Tag from '../../components/Tag.vue'
import Swiper from '../../components/Swiper/Swiper.vue'
import SwiperSlide from '../../components/SwiperSlide/SwiperSlide.vue'
import EventCard from '../../components/EventCard/EventCard.vue'

export default {
    name: 'section-events',
    components: {
        Tag,
        Swiper,
        SwiperSlide,
        EventCard
    },
    data () {
        return {
            swiper_data: this.$root.app.components.main.events.items,
            swiper_options: {
                slidesPerView: 'auto',
                navigation: false,
                pagination: false,
                simulateTouch: true,
                grabCursor: true,
                effect: 'coverflow',
                centeredSlides: true,
                coverflowEffect: {
                    rotate: 0,
                    stretch: 0,
                    scale: 0.6,
                    depth: 100,
                    modifier: 1,
                    slideShadows: false
                }
            }
        }
    }
}
</script>

<style scoped lang="scss">
@import './index.scss';
</style>
