<template>
    <div
        class="schedule-card"
        :class="{
            'schedule-card--exam' : data.exam.value === 'Y',
            'schedule-card--past' : past,
        }"
        v-if="data"
    >
        <header class="schedule-card__header">
            <span class="schedule-card__tag" v-if="data.group && data.group.value">
                <span class="black-40-text">Номер группы: </span><span v-html="data.group.value"></span>
            </span>
            <span class="schedule-card__tag" v-if="data.curator && data.curator.value">
                <span class="black-40-text">Куратор: </span><span v-html="data.curator.value"></span>
            </span>
            <span class="schedule-card__tag schedule-card__tag--exam" v-if="data.exam.value === 'Y'">
                Экзамен
            </span>
            <div class="schedule-card__remind" @click.stop>
                <remind :data="data.remind">
                    <z-icon
                        class="schedule-card__remind-trigger"
                        name="round/remind-gray"
                        height="40"
                        width="40"
                        color="#1971cf"
                    />
                </remind>
            </div>
        </header>
        <div class="schedule-card__content">
            <p class="schedule-card__time" v-html="data.time.value"></p>
            <p class="schedule-card__name" v-html="data.name"></p>
        </div>
        <footer class="schedule-card__footer" v-if="showFooter">
            <span class="black-40-text">Кабинет:</span> <span v-html="data.classroom.value"></span>
            <template v-if="data.city.value">, г. <span v-html="data.city.value"></span></template>
            <template v-if="data.address.value">, <span v-html="data.address.value"></span></template>
        </footer>
    </div>
</template>
<script>

export default {
    name: 'schedule-card',
    props: {
        data: Object,
        past: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        showFooter () {
            return (
                !!this.data.city ||
                !!this.data.address ||
                !!this.data.classroom
            ) && this.data.classroom.value !== 'ДО'
        }
    }
}
</script>
<style lang="scss">
.schedule-card {
    $parent: &;

    background: $token-colors-white;
    border-radius: 0 24px 20px 0;
    padding: $token-spacers-xs $token-spacers-s $token-spacers-xs $token-spacers-xs;
    position: relative;
    margin-left: 4px;
    break-inside: avoid;

    &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 100%;
        height: 100%;
        width: 8px;
        background: $token-colors-link-default;
        border-radius: 10px 0 0 10px;
    }

    &__tag {
        background: #EFF6FB;
        border-radius: 20px;
        padding: 4px $token-spacers-2-xs;
        color: $token-colors-black-60;
        font-size: var(--fontSize2xs);
        line-height: 1.2;
        display: inline-block;

        &--simple {
            padding: 0;
            background: none;
            color: $token-colors-black-40;
            border-radius: 0;
            margin-right: $token-spacers-xs;
        }

        &--exam {
            background: $token-colors-warning-bg;
        }
    }

    &__remind {
        position: absolute;
        top: 16px;
        right: 24px;
        transition: opacity $transition;

        &:hover {
            opacity: 0.8;
        }
    }

    &__remind-trigger {
        cursor: pointer;

        path {
            fill: $token-colors-white;
        }

        @include breakpoint(mobile) {
            width: 32px !important;
            height: 32px !important;
        }
    }

    &__header {
        padding-right: 40px;
        margin-bottom: $token-spacers-2-xs;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: $token-spacers-3-xs;
    }

    &__time {
        font-size: var(--fontSize3xl);
        font-weight: 600;
        color: $token-colors-link-default;
        margin-bottom: $token-spacers-3-xs;
        line-height: 1.2;
    }

    &__name {
        color: $token-colors-black-80;
        font-size: var(--fontSizeM);
        font-weight: 500;
        line-height: 1.3;
    }

    &__footer {
        padding-bottom: 4px;
        margin-top: $token-spacers-2-xs;
        color: $token-colors-black-60;
        font-size: var(--fontSize2xs);
    }

    &--past {
        #{$parent}__time {
            color: $token-colors-black-20;
        }

        &:after {
            background: $token-colors-black-20;
        }
    }

    &--exam:not(&--past) {
        #{$parent}__time {
            color: #F9B533;
        }

        &:after {
            background: #F9B533;
        }
    }

}
</style>
