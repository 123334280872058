/* eslint-disable */
var icon = require('vue-svgicon')
icon.register({
  'clock': {
    width: 16,
    height: 16,
    viewBox: '0 0 16 16',
    data: '<g clip-path="url(#clip0_1655_15115)"><path pid="0" fill-rule="evenodd" clip-rule="evenodd" d="M15.5 8a7.5 7.5 0 10-15 0 7.5 7.5 0 0015 0zM8 2a6 6 0 110 12A6 6 0 018 2zM6 7.25a.75.75 0 000 1.5h1.75a1 1 0 001-1V4a.75.75 0 00-1.5 0v3.25H6z" _fill="#0072D9"/></g><defs><clipPath id="clip0_1655_15115"><path pid="1" _fill="#fff" transform="matrix(0 -1 -1 0 16 16)" d="M0 0h16v16H0z"/></clipPath></defs>'
  }
})
