const app = {
    state: {
        device: 'desktop',
        burgerMenu: false,
        heroTitle: '',
        heroSrc: ''
    },
    mutations: {
        ToggleDevice: (state, device) => {
            state.device = device
        },
        SetHeroTitle: (state, heroTitle) => {
            state.heroTitle = heroTitle
        },
        SetHeroSrc: (state, heroSrc) => {
            state.heroSrc = heroSrc
        },
        BurgerMenu: (state, bool) => {
            state.burgerMenu = bool
        }
    },
    actions: {
        ToggleDevice ({ commit }, device) {
            commit('ToggleDevice', device)
        },
        SetHeroSrc ({ commit }, heroSrc) {
            commit('SetHeroSrc', heroSrc)
        },
        BurgerMenu ({ commit }, bool) {
            commit('BurgerMenu', bool)
        },
        SetHeroTitle ({ commit }, title) {
            commit('SetHeroTitle', title)
        }
    }
}

export default app
