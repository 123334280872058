<template>
    <div class="swiper-container slider-scrollbar" ref="slider">
        <div class="swiper-wrapper">
            <slot></slot>
        </div>
        <div ref="scrollbar" class="swiper-scrollbar"></div>
    </div>
</template>

<script>
import Swiper from 'swiper/dist/js/swiper.js'
// import 'swiper/swiper-bundle.css'

export default {
    name: 'slider-scrollbar',
    data () {
        return {
            slider: undefined
        }
    },
    props: {
        spaceBetween: {
            type: Number,
            default: 48
        },
        dragSize: {
            type: [Number, String],
            default: 'auto'
        },
        simulateTouch: {
            type: Boolean,
            default: true
        },
        mousewheel: {
            type: Boolean,
            default: false
        },
        hideScrollBar: {
            type: Boolean,
            default: true
        }
    },
    methods: {
        init () {
            let slider = this.$refs.slider
            let scrollbar = this.$refs.scrollbar

            this.slider = new Swiper(slider, {
                slidesPerView: 'auto',
                freeMode: true,
                simulateTouch: this.simulateTouch,
                resistanceRatio: 0,
                spaceBetween: this.spaceBetween,
                scrollbar: {
                    el: scrollbar,
                    hide: this.hideScrollBar,
                    draggable: true,
                    snapOnRelease: false,
                    dragSize: this.dragSize
                },
                mousewheel: this.mousewheel
            })

            this.slideToActiveItem()
        },
        slideToActiveItem () {

        }
    },
    mounted () {
        this.init()
    }
}
</script>

<style lang="scss">
    .slider-scrollbar {
        position: relative;
        padding-bottom: $token-spacers-2-xs;

        &:hover {
            .swiper-scrollbar {
                opacity: 0.5 !important;
            }
        }

        .swiper-scrollbar {
            left: 0;
            width: 100%;

            &:hover,
            &:active {
                opacity: 1 !important;
            }
        }

        .swiper-scrollbar-drag {
            background: $token-colors-primary;
            cursor: pointer;
        }
    }
</style>
